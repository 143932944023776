import React from "react";
import { useTranslation } from "react-i18next";
import {EnvironmentConfig} from"@me8eon/config";
import "./FeedbackWidget.scss";

const FeedbackWidget: React.FC = () => {
    const { t } = useTranslation();
    const handleClick = () => {
        window.location.href = `mailto:${EnvironmentConfig.meAtEonContactEmail}`;
    };

    return (
        <div className="widget-container">
            <button onClick={handleClick} className="widget-button">
                {t("feedbackWidget")}
            </button>
        </div>
    );
};

export default FeedbackWidget;
