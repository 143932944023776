import React, {useEffect, useState} from "react";
import {AdminButton} from "./adminButton";
import {Envs} from "./envs";
import {AdminTable} from "./admin.table";
import {EnvironmentConfig} from"@me8eon/config";
import axios from "axios";

type FeedbackType = {
    key: string
    doc_count: number
}
const FeedbackTypes: FeedbackTypes[] = ['Top N', 'Likes', 'Dislikes', 'Searches'];
type FeedbackTypes = 'Top N' | 'Likes' | 'Dislikes' | 'Searches';
type FeedbackTc = {
    body: (env: string) => any
    results: (res: any) => FeedbackType[];
}
const FeedbackTypeMap: Record<FeedbackTypes, FeedbackTc> = {
        'Top N': {
            body: (env: string) => ({
                "size": 0,
                "query": {
                    "bool": {
                        "filter": [
                            {"term": {"source": "query"}},
                            {"term": {env}}
                        ]
                    }
                },
                "aggs": {
                    "top_queries": {
                        "terms": {
                            "field": "query.keyword",   // Use .keyword if query is a text field
                            "size": 30                  // Limit to top 20 queries
                        }
                    }
                }
            }),
            results: (response: any) => response.data?.aggregations?.top_queries?.buckets
            // 'Recent Likes': '',
            // 'Recent dislikes': ''
        },
        "Likes": {
            body: env => ({
                "query": {
                    "bool": {
                        "must": [
                            {"term": {"source": "chatbot"}},
                            {"term": {"like": "like"}},
                            {"term": {env}}
                        ]
                    }
                },
                "size": 0,
                "aggs": {
                    "top_queries": {
                        "terms": {
                            "field": "query.keyword",
                            "size": 30
                        }
                    }
                }
            }),
            results: (r) => {
                const results = r.data?.aggregations?.top_queries?.buckets;
                // const results = r.data?.hits?.hits.map((h: any) => ({key: h._source.query, doc_count: 0}));
                return results;
            }
        },
        'Dislikes':
            {
                body: env => ({
                    "query": {
                        "bool": {
                            "must": [
                                {"term": {"source": "chatbot"}},
                                {"term": {"like": "dislike"}},
                                {"term": {env}}
                            ]
                        }
                    },
                    "size": 0,
                    "aggs": {
                        "top_queries": {
                            "terms": {
                                "field": "query.keyword",
                                "size": 30
                            }
                        }
                    }
                }),
                results: (r) => {
                    const results = r.data?.aggregations?.top_queries?.buckets;
                    // const results = r.data?.hits?.hits.map((h: any) => ({key: h._source.query, doc_count: 0}));
                    return results;
                }
            },
        'Searches': {
            body: env => ({
                "query": {
                    "bool": {
                        "must": [
                            {"term": {"source": "query"}},
                            {"term": {env}}
                        ]
                    }
                },
                sort: [{"timestamp": "desc"}],
                "size": 30,
            }),
            results: (r) => {
                const results = r.data?.hits?.hits.map((h: any) => ({key: h._source.query, doc_count: 0}));
                return results;
            }
        }
    }
;

export function useFeedback(): (t: FeedbackTypes, env: string) => Promise<FeedbackType[]> {
    const url = `${EnvironmentConfig.elasticSearchBaseUrl}/feedback/_search`;
    const headers = {
        "Content-Type": "application/json",
        Authorization: `ApiKey ${localStorage.getItem("dls-apikey")}`,
    };
    return async (t, env) => {
        const tc = FeedbackTypeMap[t];
        const response = await axios.post(url, tc.body(env), {headers,});
        const buckets = tc.results(response);
        return buckets;
    };

}
export async function allEnvs(): Promise<string[]> {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `ApiKey ${localStorage.getItem("dls-apikey")}`,
    };
    const body = {
        "size": 0,
        "aggs": {
            "unique_envs": {
                "terms": {
                    "field": "env.keyword",
                    "size": 10000
                }
            }
        }
    };
    const response = await axios.post(`${EnvironmentConfig.elasticSearchBaseUrl}/feedback/_search`, body, {headers,});
    const buckets = response.data?.aggregations?.unique_envs?.buckets;
    const envs = buckets.map((b: any) => b.key);
    return envs;
}

export function Feedback() {
    const fetchFeedback = useFeedback();
    const [feedback, setFeedback] = useState<FeedbackType[]>([]);
    const [ft, setFt] = useState<FeedbackTypes>(FeedbackTypes[0]);
    const [env, setEnv] = useState<string>('prod');
    const [envs, setEnvs] = useState<string[]>([]);

    useEffect(() => {
        allEnvs().then(e => {
            setEnvs(e);
            // setEnv(e[0]);
        });
    }, []);

    useEffect(() => {
        fetchFeedback(ft, env).then(f => setFeedback(f));
    }, [ft, env]);
    return (
        <>
            <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap', alignItems: 'center'}}>
                {FeedbackTypes.map(t => <AdminButton key={t} text={t} selected={t === ft} onClick={() => setFt(t)}/>)}
                <div style={{marginLeft: 'auto'}}>
                    <Envs env={env} envs={envs} setEnv={setEnv}/>
                </div>
            </div>
            <AdminTable data={feedback} renderCell={{
                'Query': (f: FeedbackType) => f.key,
                'Count': (f: FeedbackType) => f.doc_count.toLocaleString()
            }} rightJustified={['Count']}/>
        </>);
}