import {
    getLocalValue,
    setLocalValue,
    isTokenExpired,
} from "../util/localstorage";
import ApiCall from "./ApiCall";

const API_BASE_URL = `api/nexthink`;

type NexthinkToken = {
    access_token?: string;
    expires_in?: number;
    expiry_time?: number;
}

export const generateNexthinkToken = async () => {
    const cacheKey = "nexthink-token";
    let tokenData: NexthinkToken = {};

    try {
        tokenData = JSON.parse(getLocalValue(cacheKey) || "{}");
    } catch (error) {
        console.error("Invalid token format, fetching a new one...");
    }

    let { access_token, expires_in, expiry_time } = tokenData;

    if (!access_token || !expiry_time || isTokenExpired(expiry_time, 3)) {
        const token = await ApiCall(`${API_BASE_URL}/nexthink-token`, "GET", "");
        if (token.access_token) {
            access_token = token.access_token;
            expires_in = token.expires_in;
            expiry_time = Date.now() + (expires_in ?? 0) * 1000;
            setLocalValue(cacheKey, JSON.stringify({ ...token, expiry_time }));
        }
    }
    return { access_token, expires_in };
};

export const fetchDeviceDetails = async (
    nexthinkToken: string,
    userName: string
) => {
    return ApiCall(`${API_BASE_URL}/device-detail`, "POST", {
        queryId: "#last_10_devices_of_user_by_session",
        nexthinkToken,
        parameters: { UserName: `*${userName}*` },
    });
};

export const triggerRemoteAction = async (
    remoteActionId: string,
    deviceUid: string,
    nexthinkToken: string
) => {
    return ApiCall(`${API_BASE_URL}/trigger-remote-action`, "POST", {
        remoteActionId,
        devices: [deviceUid],
        expiresInMinutes: 60,
        triggerInfo: {
            externalSource: "ME@EON Integration",
            reason: "Reason-test",
            externalReference: "me8eon-action",
        },
        nexthinkToken,
    });
};

export const fetchSustainableITInfo = async (
    nexthinkToken: string,
    collectorUid: string
) => {
    return ApiCall(`${API_BASE_URL}/sustainable-it`, "POST", {
        queryId: "#sustainable_it",
        parameters: { collector_uid: collectorUid },
        nexthinkToken,
    });
};

export const fetchTriggerStatus = async (request_id: string) => {
    try {
        const tokenResp = await generateNexthinkToken();
        if (!tokenResp.access_token) {
            console.error("fetchTriggerStatus Error: Authentication failed");
            return null;
        }
        return ApiCall(`${API_BASE_URL}/trigger-status`, "POST", {
            queryId: "#get_remote_action_results",
            parameters: { request_id },
            nexthinkToken: tokenResp.access_token,
        });
    } catch (err) {
        console.error("fetchTriggerStatus---", err);
        return null;
    }
};

export const processRemoteAction = async (
    actionKey: string,
    deviceId: string
) => {
    try {
        const tokenResp = await generateNexthinkToken();
        if (!tokenResp?.access_token) {
            console.error("processRemoteAction Error: Authentication failed");
            return null;
        }

        const { access_token } = tokenResp;

        const triggerResponse = await triggerRemoteAction(
            actionKey,
            deviceId,
            access_token
        );
        return triggerResponse;
    } catch (e) {
        console.error("processRemoteAction---", e);
        return null;
    }
};

export const fetchSustainableITReport = async (deviceId: string) => {
    try {
        const tokenResp = await generateNexthinkToken();
        if (!tokenResp?.access_token) {
            console.error(
                "fetchSustainableITReport Error: Authentication failed"
            );
            return null;
        }
        const { access_token } = tokenResp;
        const result = await fetchSustainableITInfo(access_token, deviceId);
        const {
            "Total_energy_consumption.as(format = energy)":
                totalEnergyConsumption,
            "Total_CO2_emissions.as(format = weight)":
                totalCO2Emitted,
            "Days_since_last_full_boot_": daysSinceLastBoot,
            "device.name": deviceName,
            "device.hardware.type": hardwareType,
            "device.boot.days_since_last_full_boot.rating": lastBootRating,
        } = result.data[0];

        return {
            totalCO2Emitted,
            totalEnergyConsumption,
            daysSinceLastBoot,
            deviceName,
            hardwareType,
            lastBootRating,
        };
    } catch (e) {
        console.error("fetchSustainableITReport---", e);
        return null;
    }
};
