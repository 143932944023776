import React, { CSSProperties } from "react";

export const AdvanceUnifiedBox: CSSProperties = {
    backgroundColor: "#fff",
    border: "1px solid #cccbca",
    borderRadius: "8px",
    marginBottom: "12px",
}

export type navBarStylesProps = {
    headerUls: CSSProperties;
    menuButtonWrapper: CSSProperties;
    menuButtonWrapperOpen: CSSProperties;
    menuButton: CSSProperties;
}

export const navBarStyles: navBarStylesProps = {
    headerUls: {
        margin: "0px",
        padding: "0px"
    },
    menuButtonWrapper: {
        borderBottom: "4px solid #ea1b0a",
        fontSize: "18px",
    },
    menuButtonWrapperOpen: {
        border: "2px solid #1ea2b1",
        borderRadius: "12px",
        textDecoration: "underline",
    },
    menuButton: {
        background: "none",
        border: "0px",
        height: "48px",
        width: "100%",
        display: "flex",
        gap: "12px",
        fontSize: "14px",
        padding: "0.5rem 1rem",
        // margin: "0 auto",
        alignItems: "center",
        justifyContent: "space-between",
    }
}