import React, { useEffect, useState } from "react";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import "./ElSearchAdvance.scss";
import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { search, transformSearchResultsToDisplay } from "./ElSearchGetData";
import { SearchInput } from "./ElSearchAdvanceHelpers";
import { FilterSetters } from "./elsearch.domain";
import LoaderComponent from "../loader-component/LoaderComponent";
import { SearchRequest, useSearchFn } from "../../search/search";
import { FeedbackFn, useFeedback } from "@me8eon/feedback";
import { DataSourceFilter, ESApiFinalResponseInterface, MetadataFilters } from "../../interfaces/ElasticSearchInterface";
import AiAssist from "../ai-assist-refactored/AiAssist";
import { AiAssistProvider } from "../ai-assist-refactored/AiAssist.context";
import UnifiedSearch from "./unified-search/UnifiedSearch";
import { ExplainControlCenter } from "../diagnostics/explain";
import { useExplainFlag } from "../../hooks/useExplain";
import { useFilterDebugFlag } from "../../hooks/useFilterDebugFlag";
import { FilterDebug } from "./filter-debug/filter.debug";
import { Scroll } from "../scroll";
import { useDebouncedCallback } from "../../hooks/useDebouncedCallback";
import { transformDataIntoDataSourceFiltersForM365 } from "../../domain/info/m365Info";
import { useComponents } from "./context/components.context";
import { DisplaySearchResults } from "@me8eon/data_views_search_results";
import { ElSearchAdvanceCardType } from "./results/ElSearchAdvanceCard";
import { useSelectedDataView, useSelectedDataViewName } from "@me8eon/data_views";
import { DevMode } from "@me8eon/devmode/src/devmode";
import { ThemedIcon } from "@me8eon/icons";
import { AiAssistantScaffolding, useAiAssist } from "@me8eon/ai_assist_data_widget";
import { DataViewNavBar } from "@me8eon/data_views_nav_bar";
import { needOffice } from "@me8eon/all_data_views";
import { filterDebugName, FilterScaffolding, useAdditionalFiltersStateScaffolding, useFilter, useMetadataFiltersStateScaffolding, useSearchTerm, useSelectedMetadataFiltersStateScaffolding } from "@me8eon/filter_data_widget";
import SearchRefineResult from "./SearchRefineResult";
import { useDebug } from "@me8eon/debug";

function NoData({ noMoreData, searchTerm }: { noMoreData?: boolean, searchTerm?: string; }) {
    const { t } = useTranslation();
    return (
        <div className={noMoreData ? "no-more-data" : "no-data"} key={"noData"}>
            {noMoreData ? <></> : <ThemedIcon name="search" scheme="red500" />}
            {t(`filterPage.filterMenu.${noMoreData ? "noMoreData" : "noData"}`)}
            {noMoreData ? "" : <>&nbsp;<strong>{searchTerm}</strong></>}
        </div>
    );
}

export function transformFoundCountsIntoDataFilters(searchIndex: string[], count: DataSourceFilter[]) {
    if (!searchIndex.length) return count;
    return searchIndex.map((index) => {
        const matchingCount = count.find((c) => c.key === index)?.doc_count ?? 0;
        return { key: index, doc_count: matchingCount };
    });
}


export type DisplayAllSearchResultsProps = {
    resultsToDisplay: any[];
    searchTerm: string;
    ElSearchAdvanceCard: ElSearchAdvanceCardType;
    noMoreData: boolean;
}

function DisplayAllSearchResults({ resultsToDisplay, searchTerm, ElSearchAdvanceCard, noMoreData }: DisplayAllSearchResultsProps) {
    return <>
        <DisplaySearchResults rootId="search_results" data={resultsToDisplay} />
        {noMoreData && <NoData noMoreData={resultsToDisplay.length > 0} searchTerm={searchTerm} />}
    </>;
}


export const ElSearchAdvance: React.FC = () => {
    const [dataView, setDataView] = useSelectedDataViewName();
    const [searchIndex, setSearchIndex] = useState<string[]>([]);
    const [additionalFilters, setAdditionalFilters] = useAdditionalFiltersStateScaffolding();
    const [searchTerm, setSearchTerm] = useSearchTerm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataSourceFilters, setDataSourceFiltersRaw] = useState<DataSourceFilter[]>([]);
    const [m365DocTypes, setM365DocTypes] = useState<string[]>([]);
    const [resultsToDisplay, setResultsToDisplay] = useState<any[]>([]);
    const [filterCount, setFilterCount] = useState<number>(0);
    const [showAiAssist, setShowAiAssist] = useState(false);
    const [metadataFilters, setMetadataFilters] = useMetadataFiltersStateScaffolding();
    const [selectedMetadataFilters, setSelectedMetadataFilters] = useSelectedMetadataFiltersStateScaffolding();
    const { t } = useTranslation();
    const searchFn = useSearchFn();
    const feedbackFn: FeedbackFn = useFeedback();
    const [noMoreData, setNoMoreData] = useState(false);
    const { ElSearchAdvanceCard } = useComponents(dataView);
    const [_a, setAiAssistScaffolding] = useAiAssist();
    const [_f, setFilter] = useFilter();
    const currentDataView = useSelectedDataView();
    const fDebug = useDebug(filterDebugName);
    fDebug("ElSearchAdvance", "additionalFilters", additionalFilters);

    useEffect(() => {
        setAdditionalFilters({});
    }, [dataView, searchTerm]);

    function onSearchTermChanged(newQuery: string) {
        setSearchTerm(newQuery);
        setSelectedMetadataFilters({});
        setAdditionalFilters({});
    }

    useEffect(() => {//Update the search term from the URL on page load
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const searchQuery = urlParams.get("q");
        if (searchQuery) setSearchTerm(searchQuery);
        //     if (!isQaPocUser)
    }, []);

    const setters: FilterSetters = {
        setIsLoading,
        setResultsToDisplay,
        setDataSourceFilters: setDataSourceFiltersFromFound,
        setDataType: setDataView,
        setSearchIndex,
        setAdditionalFilters,
        setMetadataFilters,
        setFilterCount,
        setM365DocTypes
    };

    const actualSearchIndex = currentDataView?.searchIndicies || [];
    const allowOfficeHack = needOffice(currentDataView);

    const rawSearch = search(fDebug, {
        setIsLoading, setResultsToDisplay, searchTerm, resultsToDisplay, searchFn, setNoMoreData, transformSearchResultsToDisplay,
        query: (append: boolean, searchTerm: string, baseData: any[]): SearchRequest => ({
            append,
            dataType: dataView,
            searchTerm,
            searchIndexes: actualSearchIndex,
            allowOfficeHack,
            ...(dataView === "m365" && m365DocTypes.length > 0 ? { m365DocTypes: m365DocTypes } : {}),
            additionalFilters,
            ...(baseData.length && baseData[baseData.length - 1].score && {
                searchAfter: [
                    baseData[baseData.length - 1].score,
                    baseData[baseData.length - 1].id,
                ],
            }),
            expectedRecordSize: 20,
        }),
        setDataSourceFiltersWrapper: setDataSourceFiltersFromFound,
        setMetadataFilters: setMetadataFiltersWrapper,
        metadataFilters,
        feedback: (searchTerm) => feedbackFn({ source: "query", page: "advanced", query: searchTerm }),
        atEnd: () => {
            if (actualSearchIndex.length) {
                setSelectedMetadataFilters(metadataFilters[actualSearchIndex[0]]);
            }
        },
    });
    const debouncedSearch = useDebouncedCallback(rawSearch, 500);

    useEffect(() => {
        fDebug("rawSearch", "additionalFilters are", additionalFilters);
        rawSearch(false); //no need to debounce as searchTerm is already debounced
    }, [searchTerm, searchIndex, additionalFilters, dataView]);


    useEffect(() => {
        if (dataView === "assistance") {
            setShowAiAssist(true);
        }
    }, [dataView]);


    function setDataSourceFiltersFromFound(data: ESApiFinalResponseInterface[], count: DataSourceFilter[]) {
        if (dataView === "m365")
            return setDataSourceFiltersRaw(transformDataIntoDataSourceFiltersForM365(data));
        setDataSourceFiltersRaw(transformFoundCountsIntoDataFilters(searchIndex, count));
    }

    function setMetadataFiltersWrapper(filters: MetadataFilters) {
        try {
            fDebug("setMetadataFiltersWrapper", "additionalFilters are", additionalFilters, "filters", filters);

            for (const key in additionalFilters.selectedMetadata) {
                if (!additionalFilters.selectedMetadata[key].length) continue;
                if (filters[actualSearchIndex[0]][key]) {
                    filters[actualSearchIndex[0]][key] = {
                        ...metadataFilters[actualSearchIndex[0]][key],
                    };
                }
            }
        } catch (e) {
            console.error(e, filters, metadataFilters);
        }
        setMetadataFilters(filters);
    }

    async function onScroll() {
        if (!noMoreData && resultsToDisplay.length > 9) return debouncedSearch(true);
    }

    let explainFlag = useExplainFlag();
    let filterDebug = useFilterDebugFlag();

    const aiAssistScaffolding: AiAssistantScaffolding = {
        setters,
        setShowAiAssist,
        Display: UnifiedSearch,
    };
    useEffect(() => {
        if (searchTerm !== "") {
            setAiAssistScaffolding(aiAssistScaffolding);
        }
    }, [searchTerm]);
    useEffect(() => {
        fDebug("setFilterScaffolding", "additionalFilters are", additionalFilters);
        const scaffolding: FilterScaffolding = {
            dataType: dataView as string,
            additionalFilters,
            selectedMetadataFilters,
            searchIndex: actualSearchIndex,
            dataSourceFilters,
            setters,
            filterCount,
            m365DocTypes,
            Display: SearchRefineResult,
        } as any as FilterScaffolding;
        setFilter(scaffolding);
    }, [searchTerm, additionalFilters, selectedMetadataFilters, searchIndex, dataSourceFilters, filterCount, dataView]);

    return showAiAssist ? (
        <AiAssistProvider>
            <AiAssist
                closeAiAssist={() => {
                    setShowAiAssist(false);
                    setDataView("all");
                }}
                initialPrompt={searchTerm}
            />
        </AiAssistProvider>
    ) : (
        <Scroll className="search-custom-filter-wrapper" onScroll={onScroll}>
            <div style={{ display: "flex" }}>
                <Link to="/tile" style={{ display: "block" }} onClick={() => setSearchTerm("")}>
                    <EonUiLink
                        className="back-button"
                        icon="chevron_small_left"
                        iconPosition="left"
                        href="javascript:void(0)"
                        text={t("filterPage.filterMenu.backBtn")}
                        title={t("filterPage.filterMenu.backBtn")}
                    ></EonUiLink>
                </Link>
            </div>
            {explainFlag && <ExplainControlCenter query={searchTerm} index={searchIndex[0]} />}
            {filterDebug && <FilterDebug setters={setters}
                dataType={dataView}
                searchIndex={searchIndex}
                additionalFilters={additionalFilters}
                metadataFilters={metadataFilters}
                selectedMetadataFilters={selectedMetadataFilters}
                dataSourceFilters={dataSourceFilters}
                filterCount={filterCount} />}
            <div className="search-input">
                <SearchInput onChange={onSearchTermChanged} />
            </div>
            <DataViewNavBar rootId="filterPage.filterMenu" width={0} />
            <LoaderComponent isLoading={isLoading} isLinear />
            <DevMode />
            <div style={{ backgroundColor: "#e4e3e082" }}>
                {dataView !== "assistance" && searchTerm &&
                    <DisplayAllSearchResults resultsToDisplay={resultsToDisplay} searchTerm={searchTerm} ElSearchAdvanceCard={ElSearchAdvanceCard} noMoreData={noMoreData} />}
                {isLoading && (
                    <LoaderComponent isLoading={isLoading} isLinear />
                )}
            </div>
        </Scroll>
    );
};
