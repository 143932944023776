import React from "react";
import { SelectableButton, SelectableButtonProps } from "./selectable.button";
import { useTranslation } from "@me8eon/translation";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";

export const SimpleSelectableButton: SelectableButton =
    ({ selectedOps, text, rootId, icon, className, maxChars, selectedTick }: SelectableButtonProps) => {
        const [selected, setSelected] = selectedOps;
        const translate = useTranslation();
        const isSelected = selected === text;
        const id = `${rootId}.${text}`;

        const maxCharsTruncate = (text: string) => {
            if (maxChars && text.length > maxChars) {
                return text.slice(0, maxChars) + "...";
            }
            return text;
        };

        return (
            <button
                data-testid={id}
                className={className}
                // onMouseEnter={(e) => e.currentTarget.style.borderBottom = `4px solid ${isSelected ? "#ea1b0a" : "#cccbca"}`}
                // onMouseLeave={(e) => e.currentTarget.style.borderBottom = '0px'}
                style={{
                    border: 'none',
                    backgroundColor: isSelected && selectedTick ? '#e1eded' : '#fff',
                    fontSize: '14px',
                    borderBottom: isSelected ? "4px solid var(--primary-color)" : "0px",
                    color: isSelected && !selectedTick ? 'var(--primary-color)' : '#404040',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s, border-color 0.3s',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '0.5rem',
                    height: "48px",
                    padding: '0.5rem 1rem',
                    flexShrink: 0,
                }}
                onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'}
                onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'}
                onClick={() => setSelected(text)}
                title={translate(id)}
            >
                <span style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                    {icon || null}
                    {maxCharsTruncate(translate(id))}
                </span>
                {selectedTick && isSelected && (
                    <span style={{ marginLeft: '8px' }}>
                        <EonUiIcon
                            size="normal"
                            style={{ color: '#1ea2b1' }}
                            className="turquoise-icon"
                            name="radio_checkbox_tick"
                        />
                    </span>
                )}
            </button>
        );
    };


