import React from "react";
import { EonUiImage, EonUiLink } from "@eon-ui/eon-ui-components-react";
import "./footer.scss";
import { useTranslation } from "react-i18next";
import { EnvironmentConfig } from"@me8eon/config";
import eonLogoUrl from "../../assets/image/header-logos/eon.raw.svg";
import { useUserData } from "@me8eon/react_authentication";

export const Footer: React.FC = () => {
    const { t } = useTranslation();
    const year = new Date().getFullYear();
    const { loggedIn } = useUserData();
    return (
        <div className="footer" data-testid="footer">
            <div className="white-section-footer">
                <div className="footer-left-right-spacing">
                    <div className="left-footer">
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_facebook"
                                href="https://www.facebook.com/EON"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_instagram"
                                href="https://www.instagram.com/e.on_se"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_twitter"
                                href="https://twitter.com/EON_SE_en"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_youtube"
                                href="https://www.youtube.com/channel/UC4bHYYtolAq_mRtak-57Ohg"
                            ></EonUiLink>
                        </div>
                        <div className="social-media-icons">
                            <EonUiLink
                                slot="socialmedia"
                                target="_blank"
                                icon="social_linked_in"
                                href="https://www.linkedin.com/company/e-on/"
                            ></EonUiLink>
                        </div>
                    </div>
                    <div className="mid-footer">
                        <div className="footer-links">
                            <EonUiLink
                                data-testid="contact"
                                size="small"
                                slot="links"
                                target="_blank"
                                text={t("footer.contact")}
                                hideIcon={true}
                                href={`mailto:${EnvironmentConfig.meAtEonContactEmail}`}
                            ></EonUiLink>
                        </div>
                        {loggedIn &&
                            <div className="footer-links">
                                <EonUiLink
                                    id="tileTour2"
                                    data-testid="about-me8eon"
                                    size="small"
                                    slot="links"
                                    text={t("footer.aboutMe8EON")}
                                    hideIcon={true}
                                    href={`/about-me8eon`}
                                ></EonUiLink>
                            </div>
                        }
                    </div>
                    <div className="right-footer">
                        <div className="eon-copyright">
                            {`© E.ON SE ${year}`}
                        </div>
                        <EonUiImage
                            height="24px"
                            width="82px"
                            slot="logo"
                            src={eonLogoUrl}
                            alt="E.ON Logo"
                            title="E.ON Logo"
                            lazy
                        ></EonUiImage>
                    </div>
                </div>
            </div>
        </div>
    );
};

