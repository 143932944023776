import React from "react";
import { TimeService, useTime } from "@me8eon/time";
import { makeContextFor } from "@me8eon/context";
import { useSessionId } from "@me8eon/session";
import { useThrowError } from "@me8eon/throw_error";

export type LikeOrDislike = "like" | "dislike";

export type QueryFeedbackPayload = {
    source: "query";
    page: "simple" | "advanced";
    query: string;
};
export type ClickFeedbackPayload = {
    source: "click";
    href: string;
    index: string;
    id: string;
    position?: number;
};
export type SearchFeedbackPayload = {
    source: "search";
    query: string;
    index: string;
    id: string;
    like: LikeOrDislike | undefined;
};
export type ChatbotQueryFeedbackPayload = {
    source: "chatquery";
    query: string;
};
export type AiSource = {
    index: string
    id: string
    summary: string
}
export type AiAssistFeedbackPayload = {
    source: "ai-assist" | "aiassist-query";
    messages: any[];
    query: string;
    like: LikeOrDislike;
    sources: AiSource[]
};
export type FreeTextPayload = {
    source: "freeTextFromChat";
    text: string;
    likeDislike?: LikeOrDislike;
};

export type FeedbackPayload =
    | SearchFeedbackPayload
    | AiAssistFeedbackPayload
    | QueryFeedbackPayload
    | ClickFeedbackPayload
    | ChatbotQueryFeedbackPayload
    | FreeTextPayload;

export type FeedbackFn = (p: FeedbackPayload) => Promise<void>;
export type RawFeedbackFn = (
    sessionId: string,
    timeService: TimeService
) => FeedbackFn;

export const { Provider: FeedbackProvider, context: FeedbackContext } = makeContextFor<RawFeedbackFn, 'feedbackFn'>('feedbackFn');

export const useFeedback = (): FeedbackFn => {
    const sessionId = useSessionId();
    const time = useTime();
    const throwError = useThrowError();
    const feedback = React.useContext(FeedbackContext);
    if (feedback === undefined) return throwError('s/w', 'useFeedback needs a feedback provider');
    return feedback(sessionId, time);
};

export type FeedbackProps = {
    dataToSend: (likeDislike: LikeOrDislike) => FeedbackPayload;
    aiChatbotIndex?: number;
    createIncidentHandler?: (reason: string) => void;
    showCreateIncident?: boolean;
    source: string;
};

export type DisplayFeedbackComponent = (props: FeedbackProps) => React.ReactElement;
export const { use: useDisplayFeedbackComponent, Provider: DisplayFeedbackComponentProvider } = makeContextFor<DisplayFeedbackComponent, 'displayFeedbackComponent'>('displayFeedbackComponent');