import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import TableComponent from "../../generic-components/data-table/TableComponent";
import { MessageWithActionAndData } from "../AiAssist.context";
// @ts-ignore
import AiIcon  from "../../../assets/image/ai-icon32.svg";
import jiraHeaderLabel from "../../../jira/transformation/jiraHeaderLabel.json";
import { EnvironmentConfig } from "@me8eon/config";
import "./showJQL.component.scss";
import StringList from "../../generic-components/string-list/StringList";

const tableFields = ["issue", "project", "summary", "issueType", "priority", "status"];
type TableLabelData = {
    [lang: string]: {
        [key: string]: string;
    };
};
const supportedQuestions = [
    "What tickets are assigned to user KID@eon.com",
    "Find stories assigned to sprint 27 and 28",
    "Get bugs assigned to me in the past month",
    "Show all tickets created in the past 90 days",
    "Show high-priority tasks in project <project id>"
];

const tableHeaderData : TableLabelData = jiraHeaderLabel;

const ShowJQL: React.FC<MessageWithActionAndData> = ({ data }) => {
    const { i18n, t } = useTranslation();
    const language = i18n.language;

    const openJQLConsole = useCallback(() => {
        window.open(`${EnvironmentConfig.jiraURL}/issues?jql=${data.jql}`, "_blank");
    }, [data.jql]);

    const renderError = () => (
        data.errorMsg === t("chatBot.jiraIntegration.supportedQuestionMsg") ? (
            <>
                <span>{t("chatBot.jiraIntegration.supportedQuestionMsg")}</span>
                <StringList items={supportedQuestions} />
            </>
        ) : (
            <div>{data.errorMsg}</div>
        )
    );

    const renderData = () => (
        <>
            <div>{t("chatBot.jiraIntegration.responseHeading")}</div>
            <div className="results-summary">
                {data.totalCount > 10 && (
                    <div>
                        <strong>{t("chatBot.jiraIntegration.responseSubheading")}</strong>
                    </div>
                )}
                <div className="result-more">
                    <EonUiLink
                        data-testid="about-me8eon"
                        size="small"
                        slot="links"
                        target="_blank"
                        text={t("chatBot.jiraIntegration.viewInJira")}
                        onClick={openJQLConsole}
                    />
                </div>
            </div>
            <TableComponent
                showPagination={false}
                rows={data.data}
                cols={tableFields.map(field => tableHeaderData[language][field])}
                headerMaps={tableFields}
            />
        </>
    );

    return (
        <div className="bot-header">
            <div className="eon-logo">
                <AiIcon />
            </div>
            <div className="bot-reply-container">
                {data.error ? renderError() : data?.data?.length > 0 ? renderData() : <div>{t("chatBot.jiraIntegration.noResponse")}</div>}
            </div>
        </div>
    );
};

export default ShowJQL;