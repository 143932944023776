import { Env } from "./ConfigInterface";
import featureFlagConfig, { FeatureFlagType } from "./FeatureFlagConfigs";
import myApprovalConfigs, { ApprovalConfig } from "./MyApprovalConfig";
import otherConfigs, { OtherConfig } from "./OtherConfigs";
import proxyConfig, { ProxyConfig } from "./ProxyConfig";

export type UrlConfig = {
    gatewayURL: string
    fromJiraAuth: string
    apikeyUrl: string
    gingcoURL: string
    elasticSearchBaseUrl: string
    elasticSearchDashboardUrl: string
    serviceUrl: string
    gingcoRedirectURL: string
    jiraURL: string
    jiraClientId: string
};


const envConfig: Record<Env, UrlConfig> = {
    local: {
        gatewayURL: "https://dev-me.eon.com",
        apikeyUrl: "https://dev-me.eon.com/apikey",
        fromJiraAuth: "http://localhost:8080/fromJiraAuth",
        // apikeyUrl: "http://localhost:1236/apikey",
        gingcoURL: "https://dev-me.eon.com",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        elasticSearchDashboardUrl: "https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/",
        jiraURL: "https://jira.pre-prod.eon.com",
        jiraClientId: "40873da2f2b33118d3f5f1857991bb74",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/dev",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    dev: {
        gatewayURL: "https://dev-me.eon.com",
        apikeyUrl: "https://dev-me.eon.com/apikey",
        fromJiraAuth: "https://dev-me.eon.com/fromJiraAuth",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        elasticSearchDashboardUrl: "https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/",
        jiraURL: "https://jira.pre-prod.eon.com",
        jiraClientId: "1aca69ae8a371428a5d525810457579d",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/dev",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    qa: {
        gatewayURL: "https://qa-me.eon.com",
        apikeyUrl: "https://qa-me.eon.com/apikey",
        fromJiraAuth: "https://qa-me.eon.com/fromJiraAuth",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        elasticSearchDashboardUrl: "https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/",
        jiraURL: "https://jira.pre-prod.eon.com",
        jiraClientId: "6c2bae8c7ffbab498bec72ef689fe32a",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/preprod",
        gingcoRedirectURL: "https://eon-test.gingco-estate.com",
    },
    pp: {
        gatewayURL: "https://pp-me.eon.com",
        apikeyUrl: "https://pp-me.eon.com/apikey",
        fromJiraAuth: "https://pp-me.eon.com/fromJiraAuth",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        elasticSearchDashboardUrl: "https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/",
        jiraURL: "https://jira.eon.com",
        jiraClientId: "2c25ea9b115d829e5250ee36d4929f9e",
        serviceUrl: "https://api-nonprod.eon.com/edt/itsm/preprod",
        gingcoRedirectURL: "https://eon.gingco-estate.com",
    },
    prod: {
        gatewayURL: "https://me.eon.com",
        apikeyUrl: "https://me.eon.com/apikey",
        fromJiraAuth: "https://me.eon.com/fromJiraAuth",
        gingcoURL: "",
        elasticSearchBaseUrl: "https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com:9243",
        elasticSearchDashboardUrl: "https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/",
        jiraURL: "https://jira.eon.com",
        jiraClientId: "2c25ea9b115d829e5250ee36d4929f9e",
        serviceUrl: "https://api.eon.com/edt/itsm",
        gingcoRedirectURL: "https://eon.gingco-estate.com",
    },
};


let env: Env;
switch (process.env.REACT_APP_ENV) {
case "dev":
    env = "dev";
    break;
case "qa":
    env = "qa";
    break;
case "pp":
    env = "pp";
    break;
case "prod":
    env = "prod";
    break;
default:
    env = "local";
    break;
}

export function elasticSearchWebUrl() {
    const esUrl = envConfig[env].elasticSearchBaseUrl;
    if (esUrl.includes("f0571c62")) return "https://me8eon-elasticsearch-test.kb.westeurope.azure.elastic-cloud.com:9243/";
    if (esUrl.includes("c3224bc0")) return "https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/";
    throw new Error(`Unknown Elastic Search URL ${esUrl}`);
}

export type AllConfig = UrlConfig & OtherConfig & ApprovalConfig & FeatureFlagType & ProxyConfig;
export const EnvironmentConfig: AllConfig = {
    ...envConfig[env],
    ...otherConfigs[env],
    ...myApprovalConfigs[env],
    ...featureFlagConfig[env],
    ...proxyConfig[env]
};
