import React, { useEffect, useRef, ReactNode } from "react";

interface OutsideClickProps {
    onClickOutside: () => void;
    children: ReactNode;
}

export const OutsideClick: React.FC<OutsideClickProps> = ({ onClickOutside, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside?.();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClickOutside]);

    return <div ref={ref}>{children}</div>;
};
