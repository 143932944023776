import {DataPlugin, DisplayData, DisplayDataWidget} from "../../data";
import {GitLabDisplay, SimpleGitLabWidget, SimpleGitLabOneLineDisplay} from "./simple.gitlab.display";


export const GitLabDataName = 'gitlab';

export type GitLabData = {
    id: string;
    index: string;
    icon: string;
    fullPath: string;
    source: string;
    url: string;
    type: string
    status: string
    summary: string
    issue: string
    content: string
}

export function GitLabDataPlugin(
    DisplayData: DisplayData<GitLabData> = GitLabDisplay,
    DisplayDataOneLine: DisplayData<GitLabData> = SimpleGitLabOneLineDisplay,
    DisplayDataWidget: DisplayDataWidget<GitLabData> = SimpleGitLabWidget): DataPlugin<GitLabData> {
    return {
        plugin: 'data',
        name: 'gitlab',
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget,
        idFrom: data => `${data.index}.${data.id}`
    };
}
