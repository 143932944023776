import { getLocalValue, setLocalValue } from "../util/localstorage";
import {attemptPopupAuthorization, defaultOauth2Config, Oauth2Config} from "./jira.consent.popup";

export function defaultJiraTokenFn(
    config: Oauth2Config = defaultOauth2Config
): () => Promise<string> {
    return async () => {
        const cacheKey = "jira-token";
        let tokenData: { access_token?: string; expires_at?: number } = {};

        try {
            tokenData = JSON.parse(getLocalValue(cacheKey) || "{}");
        } catch (error) {
            console.error("Invalid Jira token format, fetching new...");
        }

        let { access_token, expires_at } = tokenData;

    
        if (!access_token || !expires_at || isTokenExpired(expires_at, 5)) {
            console.log("Fetching new Jira token...");
            const token = await attemptPopupAuthorization("consent", config);
            if (token && token.access_token) {
                access_token = token.access_token;
                expires_at = Date.now() + token.expires_in * 1000;

          
                setLocalValue(cacheKey, JSON.stringify({ access_token, expires_at }));
            }
        }

        return access_token || ""; 
    };
}


const isTokenExpired = (expiry: number, p0: number): boolean => {
    if (!expiry) {
        return true;
    }

    if (isNaN(expiry)) {
        return true;
    }
    const expiryBuffer = expiry - (5 * 60 * 1000);
    const timeDiff = Date.now() - expiryBuffer;
    return timeDiff > 0;
};

