import {JiraDisplay, OneLineJiraDisplay, SimpleJiraWidget} from "./simple.jira.display";
import {DataPlugin, DisplayData, DisplayDataWidget} from "../../data";


export const JiraDataName = 'jira';

export type JiraData = {
    id: string;
    index: string;
    url: string;
    type: string
    status: string
    summary: string
    issue: string
    description: string
    last_updated: string
    created_by: string
}

export function JiraDataPlugin(
    DisplayData: DisplayData<JiraData> = JiraDisplay,
    DisplayDataOneLine: DisplayData<JiraData> = OneLineJiraDisplay,
    DisplayDataWidget: DisplayDataWidget<JiraData> = SimpleJiraWidget): DataPlugin<JiraData> {
    return {
        plugin: 'data',
        name: 'jira',
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget,
        idFrom: data => `${data.index}.${data.id}`
    };
}
