import {
    EonUiButton,
} from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../loader-component/LoaderComponent";

import { clearSystemMemory } from "../../../services/nextThink";
import React, { useState } from "react";
import TypingEffect from "../utils/typing-effect/TypingEffect";
import { useAuth } from "../../../MsalContext";

const aiSystemActionTrigger: React.FC<{ setHideDataToggle: (arg0: boolean) => void }> = ({
    setHideDataToggle,
}) => {
    const { t } = useTranslation();
    const { kId } = useAuth();
    const [loading, setLoading] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [memoryCleanUpResp, setMemoryCleanUpResp] = useState("");

    const clearSystem = async () => {
        setLoading(true);
        const resp = await clearSystemMemory(kId);
        setMemoryCleanUpResp(resp ? "success" : "failure");
        setLoading(false);
    };

    return (
        <div>
            <TypingEffect
                className="bot-text"
                text={t("chatBot.aiActionMsg")}
                effecttEnd={() => setShowButtons(true)}
                speed={1}
            />
            {loading && (<LoaderComponent isLoading={loading} isLinear />)}
            {memoryCleanUpResp === "success" && (
                <h4>{t("chatBot.aiActionSuccess")}</h4>
            )}
            {memoryCleanUpResp === "failure" && (
                <h4>{t("chatBot.aiActionFailed")}</h4>
            )}
            {showButtons && !memoryCleanUpResp && (
                <div className="ai-action-btn-wrapper">
                    <EonUiButton
                        fullWidthOnMobile
                        text={t("chatBot.aiActionPrimaryBtn")}
                        rank="primary"
                        size="small"
                        onClick={() => clearSystem()}
                        disabled={loading}
                    ></EonUiButton>
                    <EonUiButton
                        fullWidthOnMobile
                        text={t("chatBot.aiActionSecondaryBtn")}
                        rank="secondary"
                        size="small"
                        onClick={() => setHideDataToggle(false)}
                        disabled={loading}
                    ></EonUiButton>
                </div>
            )}
        </div>
    );
};

export default aiSystemActionTrigger;