import {EnvironmentConfig} from "@me8eon/config";
import {getFrom} from "../../../util/map";

const addUrlIfPresent = (searchResult: any) => {
    /**
     * @TODO - This is a temporary solution to delete .doc files from search results
     */
    if (searchResult.url && !searchResult.url.includes(".doc")) {
        return `URL: ${searchResult.url}`;
    }
    return "";
};

const getDefaultTextForQuestionator = (searchResult: any) => {
    return `**Question**: ${searchResult.keyfield}
    **Answer**: ${searchResult.answer}
    ${addUrlIfPresent(searchResult)}`;
};

const getDefaultTextForSemantic = (searchResult: any) => {
    return `${searchResult.keyfield}
    ${addUrlIfPresent(searchResult)}`;
};

const textByIndex = {
    "apps-prod": (searchResult: any) => {
        let target = searchResult.target;
        if (target.startsWith('/')) {
            target = EnvironmentConfig.gatewayURL + target;
        }

        return `App Title: ${searchResult["title"]}
        App Url: ${target}`;
    },
    "office-buddy-prod": (searchResult: any) => getDefaultTextForQuestionator(searchResult),
    "questionator-office-buddy-prod": (searchResult: any) => getDefaultTextForQuestionator(searchResult),
    "ai-azureblob-test": (searchResult: any) => getDefaultTextForQuestionator(searchResult),
    "q-edis-confluence-prod": (searchResult: any) => getDefaultTextForQuestionator(searchResult),
    "semantic-testemogpt-sharepoint-prod": (searchResult: any) => getDefaultTextForSemantic(searchResult),
    "semantic-azureblob-swedish-prod": (searchResult: any) => getDefaultTextForSemantic(searchResult),
    "semantic-azureblob-prod": (searchResult: any) => getDefaultTextForSemantic(searchResult),
    "semantic-edis-confluence-prod": (searchResult: any) => getDefaultTextForSemantic(searchResult),
    "semantic-meateon-confluence-prod": (searchResult: any) => getDefaultTextForSemantic(searchResult),
    "semantic-meeondata-azureblob-prod": (searchResult: any) => getDefaultTextForSemantic(searchResult),
    "semantic-kb-servicenow-prod": (searchResult: any) => getDefaultTextForSemantic(searchResult),
    default: (searchResult: any) => {
        throw new Error(`There's no document mapping for this search index: ${searchResult.index}`);
    }
};

const mapSearchResultToDocument = (searchResult: any) => {
    return getFrom(textByIndex)(searchResult.index)(searchResult);
};

export default mapSearchResultToDocument;