import React from 'react';

interface StringListProps {
  items: string[];
}

const StringList: React.FC<StringListProps> = ({ items }) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

export default StringList;
