import React, { useEffect, useState } from "react";
import { SelectFilterButton } from "./SelectFilterButton";
import {
    EonUiDropdown,
    EonUiDropdownOption,
    EonUiIcon,
} from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "@me8eon/translation";
import "./SearchFiltersRight.scss";
import { showDateFilters } from "../../domain/info";
import { SearchFiltersInterface, TimeRangeOptionsInterface } from "../../interfaces/ElasticSearchInterface";
import { getTimeInSeconds } from "../../util/date";
import { searchFilterClick } from "./elsearch.domain";
import { EonUiDropdownCustomEvent } from "@eon-ui/eon-ui-components";
import { useAdditionalFiltersStateScaffolding, useMetadataFiltersStateScaffolding, useSelectedMetadataFiltersStateScaffolding } from "@me8eon/filter_data_widget";
import DataSourceFilters from "./DataSourceFilters";

const SearchRefineResult = (props: SearchFiltersInterface) => {
    const {
        setters,
        searchIndex,
        dataType,
        dataSourceFilters,
        m365DocTypes
    } = props;

    const {
        setIsLoading,
        setResultsToDisplay,
    } = setters;

    const [additionalFilters, setAdditionalFilters] = useAdditionalFiltersStateScaffolding();
    const [selectedMetadataFilters] = useSelectedMetadataFiltersStateScaffolding();
    const [metadataFilters, setMetadataFilters] = useMetadataFiltersStateScaffolding();
    console.log({ metadataFiltersExpectCOrrect: metadataFilters, selectedMetadataFilters });
    const t = useTranslation();

    const [enableDateFilters, setEnableDateFilters] = useState<boolean>(false);
    const [enableMetadataFilters, setEnableMetadataFilters] = useState<boolean>(false);

    const timeRangeOptions = [
        { label: "All", value: "", icon: "", default: true },
        { label: "1 Day", value: "1", icon: "" },
        { label: "7 Days", value: "7", icon: "" },
        { label: "1 Month", value: "30", icon: "" },
        { label: "6 Months", value: "180", icon: "" },
        { label: "1 Year", value: "365", icon: "" },
    ];

    // useEffect(() => {
    //     console.log({ searchIndex, selectedMetadataFilters })
    //     setEnableDateFilters(showDateFilters(searchIndex[0]));
    //     const enableMetadataFilters: boolean = searchIndex.length > 0 && selectedMetadataFilters && Object.keys(selectedMetadataFilters).length > 0;
    //     setEnableMetadataFilters(enableMetadataFilters);
    // }, [searchIndex, selectedMetadataFilters]);

    useEffect(() => {
        if (additionalFilters.selectedTime) {
            setAdditionalFilters({ ...additionalFilters, selectedTime: "" });
        }
    }, []);

    const updateMetadataFilters = (value: string, item: string) => {
        setIsLoading(true);
        setResultsToDisplay([]);
        const newAdditionalFilters = { ...additionalFilters, selectedMetadata: { ...additionalFilters.selectedMetadata, [item]: value } };
        setAdditionalFilters(newAdditionalFilters);
    };

    return (
        <div className="refine-filters">
            <div className="search-filter-header refine-filters-container">
                <div className="filter-header-left">
                    <EonUiIcon
                        className="eon-ui-svg-icon"
                        scheme="red500"
                        size="normal"
                        key={"funnel-icon"}
                        name="filter"
                    ></EonUiIcon>
                    <span className="filter-heading">{t("filterPage.filterMenu.refineResults")}</span>
                </div>
                <SelectFilterButton
                    isReset={true}
                    onClick={searchFilterClick("all", setters.setDataType, setters.setSearchIndex)}
                    textkey="filterPage.filterMenu.reset"
                    dataType="all"
                />
            </div>
            {dataType.match("jira") && (
                <EonUiDropdown
                    onValueChanged={(e: EonUiDropdownCustomEvent<string>) => {
                        setIsLoading(true);
                        setResultsToDisplay([]);
                        setAdditionalFilters({ ...additionalFilters, selectedTime: e.target.value });
                    }}
                    onDropdownOpened={(e: EonUiDropdownCustomEvent<string>) => {
                        e.target.style.zIndex = getTimeInSeconds().toString();
                    }}
                    onDropdownClosed={(e: EonUiDropdownCustomEvent<string>) => {
                        e.target.style.removeProperty('z-index');
                    }}
                    value={additionalFilters?.selectedTime?.toString() ?? ""}
                    name="time_range"
                    size="normal"
                    label={t("filterPage.filterMenu.timeRange")}
                    showSelectedItems={true}
                >
                    {timeRangeOptions.map((item: TimeRangeOptionsInterface, index: number) => (
                        <EonUiDropdownOption key={index} value={item.value} label={t(`filterPage.timeRangeOptions.${item.label}`)} />
                    ))}
                </EonUiDropdown>
            )}
            {dataType === "m365" ? (
                <DataSourceFilters
                    setters={setters}
                    dataSourceFilters={dataSourceFilters}
                    searchIndex={searchIndex}
                    m365DocTypes={m365DocTypes}
                />
            ) : (
                <>{
                    Object.keys(metadataFilters).map((obj: any) => {
                        return Object.keys(metadataFilters[obj]).map((dataObjKey: any, index: number) => {
                            const dataObj = metadataFilters[obj][dataObjKey];
                            if (!obj.match(dataType)) {
                                return null;
                            }
                            const sorted = dataObj.value.sort((a: any, b: any) => a.label.localeCompare(b.label));
                            const item = dataObj.label;
                            if (sorted.length > 0) {
                                return (
                                    <EonUiDropdown
                                        onValueChanged={(e: EonUiDropdownCustomEvent<string>) => {
                                            updateMetadataFilters(e.target.value, dataObjKey);
                                        }}
                                        onDropdownOpened={(e: EonUiDropdownCustomEvent<string>) => {
                                            e.target.style.zIndex = getTimeInSeconds().toString();
                                        }}
                                        onDropdownClosed={(e: EonUiDropdownCustomEvent<string>) => {
                                            e.target.style.removeProperty('z-index');
                                        }}
                                        value={additionalFilters?.selectedMetadata?.[dataObjKey] ? additionalFilters?.selectedMetadata?.[dataObjKey].toString() : ""}
                                        name={item}
                                        size="normal"
                                        label={t(`filterPage.filterMenu.${dataObjKey}`)}
                                        key={`${item}-${index}-${additionalFilters?.selectedMetadata?.[dataObjKey]}`}
                                        multiselect={true}
                                        showSelectedItems={true}
                                        height="400px">
                                        {sorted.map((option: any, indexInner: number) => (
                                            <EonUiDropdownOption key={`${item}-${index}-${indexInner}`} value={option.key} label={option.label} />
                                        ))}
                                    </EonUiDropdown>
                                )
                            }
                            return null;
                        })
                    })
                }
                </>
            )}
        </div>
    )
};
export default SearchRefineResult;
