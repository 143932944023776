import { DataSourceFilter, ESApiFinalResponseInterface, MetadataFilters } from "../../interfaces/ElasticSearchInterface";
import { DataType } from "./ElSearchAdvanceHelpers";
import { SelectFilterButtonProps } from "./SelectFilterButton";
import { EnvironmentConfig } from"@me8eon/config";

export type SetSearchIndex = (value: string[]) => void;
export type FilterSetters = {
    setIsLoading: (value: boolean) => void;
    setResultsToDisplay: (value: any) => void;
    setDataSourceFilters: (data: ESApiFinalResponseInterface[], value: DataSourceFilter[]) => void;
    setDataType: (value: DataType) => void;
    setSearchIndex: SetSearchIndex;
    setAdditionalFilters: (value: object) => void;
    setMetadataFilters: (value: MetadataFilters) => void;
    setFilterCount: (value: number) => void;
    setM365DocTypes: (value: string[]) => void;
};

export interface SearchFiltersInterface {
    setSearchIndex: (value: string[]) => void;
    dataType: string;
    setDataType: (value: DataType) => void;
}

type FilterButtonDetails = Omit<SelectFilterButtonProps, 'setSearchIndex' | 'clazz' | 'onClick'> & { value: string[] };
export const buttons: Record<string, FilterButtonDetails> = {
    "all": {
        textkey: "filterPage.filterMenu.all",
        dataType: "all",
        id: "default",
        value: [],
    },
    "apps": {
        textkey: "filterPage.filterMenu.app",
        dataType: "apps",
        value: [EnvironmentConfig.appIndex.toString()],
    },
    "m365": {
        textkey: "filterPage.filterMenu.m365",
        dataType: "m365",
        value: ["m365"],
    },
    people: {
        textkey: "filterPage.filterMenu.people",
        dataType: "people",
        value: ["people"]
    },
    jql: {
        textkey: "filterPage.filterMenu.jql",
        dataType: "jql",
        value: ["jql"],
    },
    "assistance": {
        textkey: "Assistance",
        dataType: "assistance",
        value: [],
    }
};
export const searchFilterClick = (button: string, setDataType: (d: DataType) => void, setSearchIndex: SetSearchIndex) => {
    const buttonProps = buttons[button];
    if (!buttonProps) { throw new Error(`Button ${button} not found. Legal values are ${Object.keys(buttons).join(", ")}`); }
    return () => {
        setDataType(buttonProps.dataType);
        setSearchIndex(buttonProps.value);
    };
};