// Validate JqlAddToAnswerDefn
export function validateJqlAddToAnswerDefn(data: any, pathPrefix: string = "JqlAddToAnswerDefn"): string[] {
    const errors: string[] = [];

    if (data?.type !== "JQL") {
        errors.push(`${pathPrefix} 'type' must be "JQL".`);
    }
    if (typeof data?.JQL !== "string" && typeof data?.Entities !== "string") {
        errors.push(`${pathPrefix} must have either 'JQL' (as a string) or 'Entities' (as a string).`);
    }

    return errors;
}

// Validate JiraAddToAnswerDefn
export function validateJiraAddToAnswerDefn(data: any, pathPrefix: string = "JiraAddToAnswerDefn"): string[] {
    const errors: string[] = [];

    if (data?.type !== "JiraApi") {
        errors.push(`${pathPrefix} 'type' must be "JiraApi".`);
    }
    if (typeof data?.url !== "string") {
        errors.push(`${pathPrefix} 'url' must be a string.`);
    }
    if (typeof data?.body !== "string") {
        errors.push(`${pathPrefix} 'body' must be a string.`);
    }
    return errors;
}

// Validate AddToAnswerDefn
export function validateAddToAnswerDefn(data: any, pathPrefix: string = "AddToAnswerDefn"): string[] {
    const errors: string[] = [];

    if (typeof data?.type !== "string") {
        errors.push(`${pathPrefix} 'type' must be a string.`);
        return errors; // Early exit if 'type' is missing or invalid
    }

    // Delegate validation based on 'type' field
    if (data.type === "JQL") {
        errors.push(...validateJqlAddToAnswerDefn(data, `${pathPrefix}.JqlAddToAnswerDefn`));
    } else if (data.type === "JiraApi") {
        errors.push(...validateJiraAddToAnswerDefn(data, `${pathPrefix}.JiraAddToAnswerDefn`));
    } else {
        errors.push(`${pathPrefix} 'type' must be either "JQL" or "JIRA".`);
    }

    return errors;
}

// Validate QueryDefn
export function validateQueryDefn(data: any, pathPrefix: string = "QueryDefn"): string[] {
    const errors: string[] = [];

    // Check if 'Name' exists; if missing, return error and skip further validation
    if (typeof data?.Name !== "string") {
        errors.push(`${pathPrefix} 'Name' must be a string.`);
        return errors; // Stop validation if 'Name' is missing
    }

    // Update pathPrefix to use 'Name' value for more specific error messages
    const namePath = `${pathPrefix}.${data.Name}`;

    // Continue validation if 'Name' exists
    if (typeof data?.type !== "string") {
        errors.push(`${namePath} 'type' must be a string.`);
    }
    if (typeof data?.prompt !== "string") {
        errors.push(`${namePath} 'prompt' must be a string.`);
    }

    const addToAnswerErrors = validateAddToAnswerDefn(data?.AddToAnswer, `${namePath}.AddToAnswer`);
    errors.push(...addToAnswerErrors);

    return errors;
}

// Validate PromptDefn
export function validatePromptDefn(data: any, pathPrefix: string = "PromptDefn"): string[] {
    const errors: string[] = [];

    if (typeof data?.global !== "object" || data.global === null) {
        errors.push(`${pathPrefix} 'global' must be an object. It is ${data.global}.`);
    } else {
        if (typeof data.global?.prompt !== "string") {
            errors.push(`${pathPrefix}.global 'prompt' must be a string.`);
        }
        if (!Array.isArray(data.global?.queries)) {
            errors.push(`${pathPrefix}.global 'queries' must be an array.`);
        } else {
            for (let i = 0; i < data.global.queries.length; i++) {
                const queryErrors = validateQueryDefn(data.global.queries[i], `${pathPrefix}.global.queries[${i}]`);
                errors.push(...queryErrors);
            }
        }
    }

    return errors;
}

export function validateQAndA(data: any, pathPrefix: string = "relevancy"): string[] {
    const errors: string[] = [];

    if (typeof data?.q !== "string") {
        errors.push(`${pathPrefix}.q must be a string.`);
    }
    if (typeof data?.a !== "number") {
        errors.push(`${pathPrefix}.a must be a number.`);
    }
    return errors;
}

export function validateRelevancy(data: any, pathPrefix: string = "relevancy"): string[] {
    const errors: string[] = [];

    if (data !== undefined && !Array.isArray(data)) {
        errors.push(`${pathPrefix} must be an array.`);
        return errors;
    }

    if (Array.isArray(data)) {
        data.forEach((item, index) => {
            const itemPath = `${pathPrefix}[${index}]`;

            const qAndAErrors = validateQAndA(item, itemPath);
            if (qAndAErrors) {
                errors.push(...qAndAErrors);
            }
        });
    }

    return errors;
}