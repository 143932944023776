import React, { MutableRefObject, useEffect, useRef } from "react";

const useOutsideFocus = <T extends HTMLElement>(
    onOutsideFocus: () => void
): MutableRefObject<T | null> => {
    const ref = useRef<T | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onOutsideFocus();
            }
        };

        const handleFocusOutside = (event: FocusEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onOutsideFocus();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("focusin", handleFocusOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("focusin", handleFocusOutside);
        };
    }, [onOutsideFocus]);

    return ref;
};

export default useOutsideFocus;
