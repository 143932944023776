
import React, {ReactNode} from "react";
import {NameAnd} from "@me8eon/record_utils";
import {makeContextFor} from "@me8eon/context";

export const dataAsJsonFF = 'dataAsJson'

export type DataHasType = {
    type: string
}

export type DataComponents<Data> = {
    DisplayDataWidget: DisplayDataWidget<Data>
    DisplayData: DisplayData<Data>
    DisplayDataOneLine: DisplayData<Data>
}

//From the name of the data to the plugin that handles that data type
export type DataPlugins = NameAnd<DataPlugin<any>>

export type IdFn<Data> = (d: Data) => string
export type DataPlugin<Data> = DataComponents<Data> & {
    plugin: 'data'
    name: string
    /** Has to be unique. So in elastic search this will include the _index as well as the _id */
    idFrom: IdFn<Data>
}

export type DisplayDataProps<Data> = {
    rootId: string
    id: string
    data: Data
}
export type DisplayData<Data> = (props: DisplayDataProps<Data>) => ReactNode

export type DataAndDataSource<Data> = {
    data: Data
    dataSource: string
}

export type DisplayDataArrayProps<Data> = {
    title: string
    rootId: string
    data: Data[]
    // data: DataAndDataSource<Data>[] We will need this in the future I think
    idFn: IdFn<Data>
    Display: DisplayData<Data>
}
export type DisplayDataArray<Data> = (props: DisplayDataArrayProps<Data>) => ReactNode

export type DisplayDataWidgetProps<Data> = {
    type: string
    data: Data[]
    rootId: string
}
export type DisplayDataWidget<Data> = (props: DisplayDataWidgetProps<Data>) => ReactNode

export const {Provider: DataPluginProvider, use: useDataPlugins} = makeContextFor<DataPlugins, 'dataPlugins'>('dataPlugins');

export const dataTranslationPrefix = 'filterPage.searchResult';
export const translateLabel = (name: string) => `${dataTranslationPrefix}.${name}`;