import {AxiosStatic} from "axios";
import {TimeService} from "../../time";
import {FeedbackFn, FeedbackPayload, RawFeedbackFn} from "./feedback";

export const defaultFeedbackFn =
    (elasticSearchBaseUrl: string, envName: () =>string, axios: AxiosStatic, index: string): RawFeedbackFn =>
        (sessionId: string, timeService: TimeService): FeedbackFn =>
            async (p: FeedbackPayload) => {
                const payload: any = {
                    ...p,
                    env: envName(),
                    sessionId,
                    timestamp: timeService().getTime(),
                };
                return axios.post(`${elasticSearchBaseUrl}/${index}/_doc`, payload, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `ApiKey ${localStorage.getItem("dls-apikey")}`,
                    },
                });
            };