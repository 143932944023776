import React, { useMemo } from "react";
import { JiraData } from "./jira.data";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { ClipHeight } from "@me8eon/clip_height";
import { SimpleWidget } from "@me8eon/simple_widget";
// @ts-ignore
import JiraIcon from "./jira.svg";

export const JiraDisplay: DisplayData<JiraData> =
    ({ rootId, id, data }: DisplayDataProps<JiraData>) => {
        const { H1WithIconAndUrl, Markdown, Url } = useRenderers();
        const { Text, Date, DataLayout } = useAttributeValueComponents();
        const newRootId = `${rootId}.${id}`;
        const issueSummaryAndUrl = useMemo(() =>
            ({ url: data.url, title: `${data.issue}: ${data.summary}`, icon: JiraIcon }), [data.url, data.summary]);
        return <DataLayout rootId={newRootId} layout={[1, 1, 4, 1]}>
            <H1WithIconAndUrl rootId={newRootId} attribute={translateLabel('name')} value={issueSummaryAndUrl} />
            <ClipHeight maxHeight='3rem' force={false}>
                <Markdown rootId={id} attribute={translateLabel('description')} value={data.description} />
            </ClipHeight>
            <Text rootId={id} attribute={translateLabel('source')} value='jira' />
            <Text rootId={id} attribute={translateLabel('status')} value={data.status} />
            <Text rootId={id} attribute={translateLabel('createdBy')} value={data.created_by} />
            <Date rootId={id} attribute={translateLabel('lastUpdated')} value={data.last_updated} />
            <Url rootId={id} attribute={translateLabel('url')} value={data.url} />
        </DataLayout>;
    };

export const OneLineJiraDisplay: DisplayData<JiraData> =
    ({ data }: DisplayDataProps<JiraData>) =>
        <span>{data.type} {data.summary}</span>;

export const SimpleJiraWidget: DisplayDataWidget<JiraData> =
    SimpleWidget(
        ['issue', 'summary', 'status'],
        ['issue', 'summary', 'status'],
        ['issue', 'status']);

