import React from "react";
import { cleanForRender, CleanHeaders, idFrom, Render, RenderProps } from "../../renderers";
import DOMPurify from "dompurify";

// Apply Render<string> to handle HTML rendering safely
export const SimpleHtmlRenderer: Render<string> = ({ rootId, attribute, value }: RenderProps<string>) => {
    const id = idFrom(rootId, attribute);
    const sanitizedHtml = DOMPurify.sanitize(cleanForRender(value || ''))

    return (
        <CleanHeaders>
            <span
                style={{whiteSpace:'normal'}}
                id={id}
                data-testid={id}
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            />
        </CleanHeaders>
    );
};
