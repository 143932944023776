{
    "en": {
        "1": "Outside opening hours.",
        "2": "Object is not available on Sundays.",
        "3": "Object is not available on Mondays.",
        "4": "Object is not available on Tuesdays.",
        "5": "Object is not available on Wednesdays.",
        "6": "Object is not available on Thursdays.",
        "7": "Object is not available on Fridays.",
        "8": "Object is not available on Saturdays.",
        "9": "Object is not available on specified time range.",
        "10": "Setup time before stored booking.",
        "11": "An existing booking.",
        "12": "Past.",
        "13": "Room is blocked for setup time before booking.",
        "14": "Outside earliest booking offset.",
        "15": "Outside latest booking offset.",
        "16": "Setup time after stored booking.",
        "17": "Room is blocked for setup time after booking.",
        "18": "Object has static user.",
        "20": "Temporary blocked by other user.",
        "21": "In holiday time.",
        "22": "Booking time to short.",
        "23": "Booking time to long.",
        "24": "Globally blocked by system.",
        "25": "Exceeded maximum of concurrent allowed bookings.",
        "26": "Exceeded maximum of allowed bookings for requested object.",
        "27": "Exceeded maximum of consequent booking days.",
        "28": "Exceeded maximum of consequent booking days for a given object category.",
        "29": "Object is never available.",
        "30": "Object is deactivated.",
        "31": "Exceeded maximum of daily allowed object bookings.",
        "32": "Exceeded maximum of daily allowed user bookings."
    },
    "de": {
        "1": "Außerhalb der Öffnungszeiten.",
        "2": "Das Objekt ist sonntags nicht verfügbar.",
        "3": "Das Objekt ist montags nicht verfügbar.",
        "4": "Das Objekt ist dienstags nicht verfügbar.",
        "5": "Das Objekt ist mittwochs nicht verfügbar.",
        "6": "Das Objekt ist donnerstags nicht verfügbar.",
        "7": "Das Objekt ist freitags nicht verfügbar.",
        "8": "Das Objekt ist samstags nicht verfügbar.",
        "9": "Das Objekt ist im angegebenen Zeitbereich nicht verfügbar.",
        "10": "Einrichtungszeit vor der gespeicherten Buchung.",
        "11": "Eine bestehende Buchung.",
        "12": "Vergangenheit.",
        "13": "Der Raum ist für die Nutzung vor der Buchung gesperrt.",
        "14": "Außerhalb des frühesten Buchungsversatzes.",
        "15": "Außerhalb des letzten Buchungsversatzes.",
        "16": "Aufbauzeit nach hinterlegter Buchung.",
        "17": "Der Raum ist nach der Buchung für die Aufbauzeit gesperrt.",
        "18": "Das Objekt hat einen statischen Benutzer.",
        "20": "Vorübergehend von einem anderen Benutzer blockiert.",
        "21": "In der Ferienzeit.",
        "22": "Buchungszeit zu kurz.",
        "23": "Buchungszeit zu lang.",
        "24": "Weltweit vom System blockiert.",
        "25": "Die maximale Anzahl gleichzeitig zulässiger Buchungen wurde überschritten.",
        "26": "Die maximale Anzahl zulässiger Buchungen für das angeforderte Objekt wurde überschritten.",
        "27": "Maximale Anzahl aufeinanderfolgender Buchungstage überschritten.",
        "28": "Die maximale Anzahl aufeinanderfolgender Buchungstage für eine bestimmte Objektkategorie wurde überschritten.",
        "29": "Objekt ist nie verfügbar.",
        "30": "Objekt ist deaktiviert.",
        "31": "Die maximale Anzahl zulässiger Objektbuchungen pro Tag wurde überschritten.",
        "32": "Das Maximum der täglich zulässigen Benutzerbuchungen wurde überschritten."
    },
    "sv": {
        "1": "Outside opening hours.",
        "2": "Object is not available on Sundays.",
        "3": "Object is not available on Mondays.",
        "4": "Object is not available on Tuesdays.",
        "5": "Object is not available on Wednesdays.",
        "6": "Object is not available on Thursdays.",
        "7": "Object is not available on Fridays.",
        "8": "Object is not available on Saturdays.",
        "9": "Object is not available on specified time range.",
        "10": "Setup time before stored booking.",
        "11": "An existing booking.",
        "12": "Past.",
        "13": "Room is blocked for setup time before booking.",
        "14": "Outside earliest booking offset.",
        "15": "Outside latest booking offset.",
        "16": "Setup time after stored booking.",
        "17": "Room is blocked for setup time after booking.",
        "18": "Object has static user.",
        "20": "Temporary blocked by other user.",
        "21": "In holiday time.",
        "22": "Booking time to short.",
        "23": "Booking time to long.",
        "24": "Globally blocked by system.",
        "25": "Exceeded maximum of concurrent allowed bookings.",
        "26": "Exceeded maximum of allowed bookings for requested object.",
        "27": "Exceeded maximum of consequent booking days.",
        "28": "Exceeded maximum of consequent booking days for a given object category.",
        "29": "Object is never available.",
        "30": "Object is deactivated.",
        "31": "Exceeded maximum of daily allowed object bookings.",
        "32": "Exceeded maximum of daily allowed user bookings."
    }
}
