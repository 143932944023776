import ragComponent from "./rag.component";
import showIncidentsOrOrders from "./showIncidentsOrOrders.component";
import { IActionComponentProps } from "./types";
import { quickActions } from "../quick-actions/quickActions.component";
import showJQL from "./showJQL.component";
import nexthinkActions from "./nexthinkActions.component";
import sustainableIT from "./sustainableIT.component";

const lookupTableAboutChatbotActions: IActionComponentProps = {
    rag: ragComponent,
    "show-incidents": showIncidentsOrOrders,
    "show-orders": showIncidentsOrOrders,
    "jql-query": showJQL,
    "nexthink": nexthinkActions,
    "sustainable-it": sustainableIT,
    "quick-actions": quickActions,
    default: ragComponent,
};

export { lookupTableAboutChatbotActions };
