import { ConfluenceData } from "./confluence.data";
import React, { useMemo } from "react";
import { SimpleWidget } from "../../simple_widget";
import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { useCommonComponents } from "@me8eon/components";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
// @ts-ignore
import icon from './confluence.svg'

export const ConfluenceDisplay: DisplayData<ConfluenceData> =
    ({ rootId, id, data }: DisplayDataProps<ConfluenceData>) => {
        const { H1WithIconAndUrl, Html, Url } = useRenderers();
        const { Text, DataLayout } = useAttributeValueComponents();
        const ClipHeight = useCommonComponents().ClipHeight;
        const newRootId = `${rootId}.${id}`;
        const titleAndUrl = useMemo(() => ({ title: data.title, url: data.url, icon }), [data.title, data.url]);
        return <DataLayout rootId={newRootId} className='confluence-data' layout={[1, 1, 2, 1]}>
            <H1WithIconAndUrl rootId={newRootId} attribute={translateLabel('name')} value={titleAndUrl} />
            {data.body ? <ClipHeight maxHeight='2rem' force={false}>
                {/*<HighlightKeyWords rootId={newRootId} attribute='title' value={data.title}/>*/}
                <Html rootId={newRootId} attribute={translateLabel('desc')} value={data.body} />
            </ClipHeight> : <></>}
            <Text rootId={newRootId} attribute={translateLabel('source')} value={data.type} />
            <Text rootId={newRootId} attribute={translateLabel('lastUpdate')} value={data.last_updated} />
            <Url rootId={newRootId} attribute={translateLabel('url')} value={data.url} />
        </DataLayout>;
    };


export const SimpleConfluenceOneLineDisplay: DisplayData<ConfluenceData> =
    ({ data }: DisplayDataProps<ConfluenceData>) => {
        return <span>{data.type} {data.title}</span>;
    };

export const SimpleConfluenceWidget: DisplayDataWidget<ConfluenceData> =
    SimpleWidget<ConfluenceData>(['name', 'description'], ['title', 'body'], []);
