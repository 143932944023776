import { makeContextForState } from "@me8eon/context";
import { useDataViews } from "./data.views";


export const { use: useSelectedDataViewName, Provider: SelectedDataViewProvider } =
    makeContextForState<string, "selectedDataView">("selectedDataView");

export function useSelectedDataView() {
    const dataViews = useDataViews();
    const [selected] = useSelectedDataViewName();
    const dv = dataViews[selected];
    return dv;
}