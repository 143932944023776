export type UserData = {
    loggedIn: boolean
    name: string
    email: string
    org: string
    company: string
    country: string
    limitedAccess: boolean
    localAccountId: string
    featureFlagEnabled: boolean,
    allRoles: string[],
    currentRole: string
}

export const emptyUserData: UserData = {
    loggedIn: false,
    email: "",
    name: '',
    org: "",
    company: "",
    country: "",
    localAccountId: '',
    limitedAccess: true,
    featureFlagEnabled: false,
    allRoles: [],
    currentRole: ""
};
export type UserDataGetter = () => UserData