import React, { useState, useEffect, ComponentType } from "react";

type CatchWidthProps = {
    width: number;
};

const getWidth = (): number =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

export const CatchWidth = <P extends object>(
    WrappedComponent: ComponentType<P & CatchWidthProps>
): ComponentType<P> => {
    return (props: P) => {
        const [width, setWidth] = useState<number>(window.innerWidth);

        useEffect(() => {
            let timeoutId: NodeJS.Timeout | null = null;
            const resizeListener = (): void => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(() => setWidth(getWidth()), 150);
            };
            window.addEventListener("resize", resizeListener);

            return () => {
                window.removeEventListener("resize", resizeListener);
            };
        }, []);

        return <WrappedComponent {...props} width={width} />;
    };
};
