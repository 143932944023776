import { SharepointDisplay, SimpleSharepointOneLineDisplay, SimpleSharepointWidget } from "./sharepoint.display";
import { DataPlugin, DisplayData, DisplayDataWidget } from "../../data";

export const SharepointDataName = "sharepoint";

export type SharepointData = {
    type: string
    index: string;
    icon: string;
    url: string;
    resource: {
        id: string
        name: string
        createdAt: string
        lastModifiedDateTime: string
        webUrl: string
        createdDateTime: string
        createdBy: {
            user: {
                displayName: string
            }
        };
    };
    summary: string
}

export function SharepointDataPlugin(
    DisplayData: DisplayData<SharepointData> = SharepointDisplay,
    DisplayDataOneLine: DisplayData<SharepointData> = SimpleSharepointOneLineDisplay,
    DisplayDataWidget: DisplayDataWidget<SharepointData> = SimpleSharepointWidget): DataPlugin<SharepointData> {
    return {
        plugin: "data",
        name: SharepointDataName,
        idFrom: (data) => `${data.index}.${data.resource.id}`,
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget,
    };
}

