import { MakeQueryForEsFn } from "./search";

export const makeKeywordsQuery: MakeQueryForEsFn = (
    resultSize: number,
    searchTerm: string,
    dateFilters: any
) => {
    return {
        size: resultSize,
        _source: {
            excludes: [
                "full_text_embeddings"
            ],
        },
        query: {
            query_string: {
                query: `${searchTerm}*`,
            },
            ...(dateFilters.length > 0 && { bool: { ...dateFilters } }),
        },
        highlight: {
            fields: {
                title: {},
                name: {},
                Number: {},
            },
        },
        from: 0,
    };
};
