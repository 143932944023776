import React, {CSSProperties} from "react";

export const adminTableStyles: CSSProperties = {
    borderCollapse: 'collapse',
    width: '100%'
};
export const adminCellStyles: CSSProperties = {
    padding: '8px',
    borderBottom: '1px solid #ddd'
};
export const rightAlignAdminCellStyles: CSSProperties = {
    ...adminCellStyles,
    textAlign: 'right'
};