import { MetadataFilters } from "../../../interfaces/ElasticSearchInterface";

type FilterDebugProps = any;

interface FilterSummaryProps {
    title: string;
    filters: MetadataFilters;
}

const FilterSummary: React.FC<FilterSummaryProps> = ({ title, filters }:FilterSummaryProps) => {
    const summarizeFilters = (filters: MetadataFilters) => {
        return Object.entries(filters).map(([filterGroupKey, filterGroup]) => {
            const groupSummary = Object.entries(filterGroup).map(([key, groupData]) => ({
                key,
                count: groupData?.value?.length || 0,
            }));
            return { filterGroupKey, groupSummary };
        });
    };

    const summary = summarizeFilters(filters);

    return (
        <div style={{ marginBottom: "1em" }}>
            <h4
                style={{ cursor: "pointer" }}
                title={JSON.stringify(filters, null, 2)}
            >
                {title}
            </h4>
            {summary.length === 0 ? (
                <p>No {title.toLowerCase()} available.</p>
            ) : (
                <ul>
                    {summary.map(({ filterGroupKey, groupSummary }) => (
                        <li key={filterGroupKey}>
                            <strong>{filterGroupKey}</strong>:
                            <ul>
                                {groupSummary.map(({ key, count }) => (
                                    <li key={key}>
                                        {key}: {count} items
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export function FilterDebug(props: FilterDebugProps) {
    const { metadataFilters, selectedMetadataFilters = {}, ...rest } = props;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(props, null, 2));
        alert("Data copied to clipboard!");
    };

    return (
        <div style={{ padding: "1em", border: "1px solid #ddd", borderRadius: "5px", position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h3 style={{ margin: 0 }}>Filter Debug Summary</h3>
                <button
                    onClick={copyToClipboard}
                    style={{
                        padding: "0.5em 1em",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                    }}
                >
                    Copy Data to Clipboard
                </button>
            </div>

            {/* Metadata Filters Summary */}
            <FilterSummary title="Metadata Filters" filters={metadataFilters} />

            {/* Selected Metadata Filters Summary */}
            <FilterSummary title="Selected Metadata Filters" filters={selectedMetadataFilters} />

            {/* Other Data Display */}
            <div>
                <h4>Other Props</h4>
                {Object.keys(rest).length === 0 ? (
                    <p>No other props available.</p>
                ) : (
                    <pre style={{ backgroundColor: "#f8f8f8", padding: "1em", borderRadius: "4px" }}>
                        {JSON.stringify(rest, null, 2)}
                    </pre>
                )}
            </div>
        </div>
    );
}