import {adminCellStyles, adminTableStyles} from "./adminTableStyles";
import {useAuth} from "../../MsalContext";
import {elasticSearchWebUrl, EnvironmentConfig} from"@me8eon/config";

function OneLink({name, url}: { name: string, url: string }) {
    return <tr key={name}>
        <td style={adminCellStyles}>{name}</td>
        <td style={adminCellStyles}><a href={url}>{url}</a></td>
    </tr>;
}

export function AdminLinks() {

    const {account} = useAuth();
    console.log(account);
    const email = account?.username;
    return <div>
        <h1>Links</h1>
        <table style={adminTableStyles}>
            <tbody>
            <OneLink name='Elastic Search' url={elasticSearchWebUrl()}/>
            <OneLink name='Elastic Search indicies' url={`${elasticSearchWebUrl()}app/enterprise_search/content/search_indices`}/>
            <OneLink name='Elastic Search dashboards' url={`${EnvironmentConfig.elasticSearchDashboardUrl}app/dashboards`}/>
            <OneLink name='ApiKey debug' url={`${EnvironmentConfig.apikeyUrl}/${email}?debug=true`}/>
            <OneLink name='Node Indexer config' url='https://git.eon-cds.de/repos/me8eonmvp1/node-indexer/-/blob/main/indexer.yaml'/>
            <OneLink name='Node Indexer pipelines' url='https://git.eon-cds.de/repos/me8eonmvp1/node-indexer/-/pipelines'/>
            <OneLink name='me@eon gui pipeline' url='https://git.eon-cds.de/repos/me8eonmvp1/me8eon-sap-connect-poc/-/pipelines'/>
            <OneLink name='Index dashboard on elastic search' url='https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/app/dashboards#/view/50f4431f-1684-46f2-8637-5cdc06b30e04?_g=(filters:!(),refreshInterval:(pause:!f,value:60000),time:(from:now-7d%2Fd,to:now))'/>
            <OneLink name='Feedback dashboard on elastic search' url='https://me8eon-elasticsearchpoc.kb.westeurope.azure.elastic-cloud.com:9243/app/dashboards#/view/632f060c-77f1-4a0a-bbdd-c6d5abaa351e?_g=(filters:!())'/>
            </tbody>
        </table>
    </div>;
}

export function TranslationLinks() {
    return <div>
        <h1>Translation</h1>
        <table style={adminTableStyles}>
            <tbody>
            <OneLink name='View in Gitlab' url='https://git.eon-cds.de/repos/me8eonmvp1/language-pipeline/-/blob/main/csv?ref_type=heads'/>
            <OneLink name='Download about us' url='https://git.eon-cds.de/repos/me8eonmvp1/language-pipeline/-/raw/main/csv/about-us.csv?ref_type=heads&inline=false'/>
            <OneLink name='Download room booking' url='https://git.eon-cds.de/repos/me8eonmvp1/language-pipeline/-/raw/main/csv/room-booking.csv?ref_type=heads&inline=false'/>
            <OneLink name='Download translation' url='https://git.eon-cds.de/repos/me8eonmvp1/language-pipeline/-/raw/main/csv/translation.csv?ref_type=heads&inline=false'/>
            </tbody>
        </table>
    </div>;
}