import axios from "axios";
import AzureAiClientBase, { TAzureAiClientConstructor } from "./ai.base.client";
import { Message } from "../ai.types";
import { SearchFn, SearchRequest } from "../../../search/search";
import { ESApiFinalResponseInterface } from "../../../interfaces/ElasticSearchInterface";
import { getFrom } from "../../../util/map";
import { OutputAndSource } from "../ai.rag.client";
import { defaultSearchContext } from "../../../search/searchContext";
import { knnSearch } from "../../../search/all.searchs";
import { AiActionByCustomType, AiActionByType } from "../ai.actions";
import { getDecisionTree } from "../../ai-assist-refactored/quick-actions/decisionTree";
import { QuickActionType } from "../../ai-assist-refactored/AiAssist.context";
import aiPromptClassifier from "../actions/ai.promptClassifier";
import getRagPromptWithDocuments from "../prompts/ai.prompts.rag";
import mapSearchResultToDocument from "../actions/ai.mapSearchResultToDocument";
import getRagPromptWithPersonalizedDocuments from "../prompts/ai.prompts.ragPersonalized";
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { jiraSearch } from "../../../search/jql.search";
import { nextthinkdsl } from "../../../nextthink/ai.prompt.nextthink";
import { openAiClientForNextthink } from "../../../nextthink/ai/ai.client.for.nextthink";
import React from "react";
import { DebugLog } from "@me8eon/debug";
import { fetchDeviceDetails, generateNexthinkToken } from "../../../services/nexthinkService";
import { getKID } from "../../../util/auth";
import { aiConfig, jqlAiClient } from "../../../index";

export const indexToNameMap: Record<string, string> = {
    'azureblob': 'E.ON Knowledge Base',
    'office': 'E.ON Knowledge Base',
    'servicenow': `E.ON IT Knowledge Base`,
    'confluence': `E.ON Confluence`,
    'jira': `E.ON Jira`,
    'sharepoint': `E.ON SharePoint`,
    'default': `General knowledge`
};

export function getFeedbackDataFromSource(indexNames: Array<string>) {
    const displayNamesArr = indexNames.map((index) => {
        const machedData = Object.keys(indexToNameMap).filter((indexObj) => index.match(indexObj))
        const machedTitle = indexToNameMap[machedData[0]];
        return machedTitle;
    })
    const displayNamesArrUnique = [...new Set(displayNamesArr)].filter((name) => name).join(", ");
    return displayNamesArrUnique;
}

interface GptResponse {
    category: string;
    response: string;
}

type AiRagConfig = {
    searchFn: SearchFn<ESApiFinalResponseInterface>;
    searchIndicies: string[];
    makeOutputAndSource: (
        searchResultId: Array<string>,
        messages: Message[],
    ) => OutputAndSource;
};

export function defaultAiRagConfig(
    searchIndicies: string[],
    searchFn: SearchFn<ESApiFinalResponseInterface>,
): AiRagConfig {
    return {
        searchIndicies,
        searchFn,
        makeOutputAndSource: (
            searchResultId: Array<string>,
            messages: Message[],
            data?: any,
        ): OutputAndSource => {
            return ({
                output: messages[0].content,
                source: getFeedbackDataFromSource(searchResultId),
                action: "rag",
                data,
            })
        },
    };
}

export interface IAiRagClient {
    config: TAzureAiClientConstructor;
    elasticApiKey?: string;
    rememberSearchOps: GetterSetter<ESApiFinalResponseInterface[]>;
    searchIndicies?: string[];
    debug: DebugLog;
}

const personalizedSearchIndiciesByCompany = {
    "E.ON Sverige AB": ["semantic-azureblob-swedish-prod"],
    "E.ON Digital Technology GmbH": ["semantic-meateon-confluence-prod"],
    "E.DIS Netz GmbH": ["semantic-edis-confluence-prod"],
    default: []
};

export type Setter<T> = Dispatch<SetStateAction<T>>
export type GetterSetter<T> = [T, Setter<T>]
export const AiSourceDataMemoryContext = createContext<GetterSetter<ESApiFinalResponseInterface[]> | undefined>(undefined);

export function AiSourceDataMemoryProvider({ children }: { children: ReactNode }) {
    const ops: GetterSetter<ESApiFinalResponseInterface[]> = useState<ESApiFinalResponseInterface[]>([]);
    return <AiSourceDataMemoryContext.Provider value={ops}>{children}</AiSourceDataMemoryContext.Provider>;
}

export function useAiSourceMemory() {
    const context = useContext(AiSourceDataMemoryContext);
    if (!context) throw new Error("useAiSourceDataMemory must be used within AiSourceDataMemoryProvider");
    return context;
}

export const aiRagDebug = "aiRagDebug";

export class AiRagClient extends AzureAiClientBase {
    protected elasticApiKey: string | undefined;
    private readonly searchContext: ReturnType<typeof defaultSearchContext>;
    private aiRagConfig: AiRagConfig;
    setRememberSearch: Setter<ESApiFinalResponseInterface[]>;
    private debug: DebugLog;

    constructor({ config, elasticApiKey, searchIndicies, rememberSearchOps, debug }: IAiRagClient) {
        super(config);
        this.elasticApiKey = elasticApiKey;
        this.debug = debug;
        this.searchContext = defaultSearchContext(axios, this.elasticApiKey);
        this.setRememberSearch = rememberSearchOps[1];
        this.aiRagConfig = {
            ...defaultAiRagConfig(
                searchIndicies || [],
                knnSearch(this.searchContext),
            ),
        };
    }

    public async aiClientQuickActions(history: Message[], query: string, quickActionType: QuickActionType) {
        const lastMessageContent = history[history.length - 1]?.content;
        const category = lastMessageContent ? "ongoing" : "new";
        const findNode = getDecisionTree().find(node => node.userCommand === query && (!node.condition || node.condition({
            category,
            quickActionType,
        })));
        return getFrom(AiActionByCustomType)("quick-actions")(findNode);
    }

    public async aiClientWithRag(history: Message[], query: string, language: string, companyName: string, customSearchIndicies?: string[], featureFlagEnabled?: boolean) {
        const classificationResult = await aiPromptClassifier(query, companyName, featureFlagEnabled);

        console.log(classificationResult);
        const sendWithPreviousHistory = (messages: Message[], otherConfig?: any) => this.sendRequest([...history, ...messages], otherConfig);

        const searchQuery = (indicies: string[]) => this.aiRagConfig.searchFn({
            dataType: "assistance",
            searchTerm: query,
            searchIndexes: indicies,
        });

        const classificationMap = {
            "action": async () => {
                const documents = await searchQuery(["actions-prod"]);
                this.setRememberSearch(documents.data);
                return await getFrom(AiActionByType)(documents.data[0].action)(sendWithPreviousHistory);
            },
            "rag": async () => {
                const searchResults = await searchQuery(customSearchIndicies || this.aiRagConfig.searchIndicies);
                const firstThreeResults = searchResults.data.slice(0, 3);
                const uniqueIndexArr = [...new Map(firstThreeResults.map(item => [item.index, item])).keys()];
                this.setRememberSearch(firstThreeResults);
                this.debug(aiRagDebug, "rag", firstThreeResults);
                const mappedTextFromDocuments = firstThreeResults.map(mapSearchResultToDocument);
                const aiPrompt = getRagPromptWithDocuments(mappedTextFromDocuments, language);
                const message = [{ role: "system", content: aiPrompt }, { role: "user", content: query }] as Message[];
                const outputMessages = await sendWithPreviousHistory(message);
                return this.aiRagConfig.makeOutputAndSource(uniqueIndexArr, outputMessages);
            },
            "personalizedRag": async () => {
                const personalizedIndiciesForCompany = getFrom(personalizedSearchIndiciesByCompany)(companyName);
                const searchResults = await searchQuery(customSearchIndicies || this.aiRagConfig.searchIndicies);
                const personalizedIndiciesSearchResults = personalizedIndiciesForCompany.length > 0 ? await searchQuery(personalizedIndiciesForCompany) : { data: [] };

                const firstThreeSearchResults = searchResults.data.slice(0, 3);
                const firstThreePersonalizedSearchResults = personalizedIndiciesSearchResults.data.slice(0, 3);
                const mappedTextFromDocuments = firstThreeSearchResults.map(mapSearchResultToDocument);
                const mappedTextFromPersonalizedDocuments = firstThreePersonalizedSearchResults.map(mapSearchResultToDocument);
                const jointData = [...firstThreeSearchResults, ...firstThreePersonalizedSearchResults];
                this.setRememberSearch(jointData);
                const uniqueIndexArr = [...new Map(jointData.map(item => [item.index, item])).keys()];
                this.debug(aiRagDebug, "personalizedRag", firstThreeSearchResults, firstThreePersonalizedSearchResults);
                const aiPrompt = getRagPromptWithPersonalizedDocuments(mappedTextFromDocuments, mappedTextFromPersonalizedDocuments, language);
                const message = [{ role: "system", content: aiPrompt }, { role: "user", content: query }] as Message[];
                const outputMessages = await sendWithPreviousHistory(message, { model: "gpt-4o" });
                return this.aiRagConfig.makeOutputAndSource(uniqueIndexArr, outputMessages);
            },
            "jira": async () => {
                const searchFn = jiraSearch(jqlAiClient);
                const searchRequest: SearchRequest = {
                    searchTerm: query,
                    dataType: "jql",
                    searchIndexes: ["jql"],
                };

                const { data, jql, totalCount, error, errorMsg } = await searchFn(searchRequest);
                const topTenData = data.slice(0, 10);
                //We don't record the topTenData because they are outputs from the process, and the setRememberSearch is recording the inputs
                //The purpose of setRememberSearch is to allow us to improve the quality of the documents, and
                //storing these would just confuse that issue
                this.setRememberSearch([]);
                this.debug(aiRagDebug, "jira", []);
                return {
                    output: "Following are the results for your query",
                    source: "jql",
                    action: "jql-query",
                    data: { data: topTenData, jql, totalCount, error, errorMsg },
                };
            },
            "nextthink": async () => {
                const aiPrompt = `${nextthinkdsl}
    Input: "${query}"
    Output:`;

                const messages: Message[] = [
                    { role: "system", content: aiPrompt },
                    { role: "user", content: query },
                ];

                const nextthinkAiClient = openAiClientForNextthink(aiConfig);
                const outputMessages = await nextthinkAiClient(messages);
                let lastContent = outputMessages?.[outputMessages.length - 1]?.content?.trim();
                let gptResponse: GptResponse | null = null;

                try {
                    if (lastContent) {
                        try {
                            gptResponse = JSON.parse(lastContent);
                        } catch (parseError) {
                            try {
                                console.warn("Invalid JSON detected, attempting to fix...");
                                lastContent = lastContent.replace(
                                    /([{,])\s*([a-zA-Z0-9_]+)\s*:/g,
                                    '$1"$2":'
                                );
                                lastContent = lastContent.replace(/,\s*([}\]])/g, "$1");
                                gptResponse = JSON.parse(lastContent);
                            } catch (fixError) {
                                console.error("Failed to fix and parse GPT response:", fixError);
                                gptResponse = null;
                            }
                        }
                    }
                } catch (error) {
                    console.error("Unexpected error parsing GPT response:", error);
                }

                const ragresponse = await classificationMap["rag"]();
                const nexthinkToken = await generateNexthinkToken();
                let deviceDetails = null;
                if (nexthinkToken.access_token) {
                    deviceDetails = await fetchDeviceDetails(nexthinkToken.access_token, getKID());
                }

                return {
                    output: gptResponse?.response ?? "",
                    source: "nexthink",
                    action: gptResponse?.category === "sustainable-it" ? "sustainable-it" : "nexthink",
                    data: {
                        category: gptResponse?.category ?? "",
                        rag: ragresponse.output ?? null,
                        deviceDetails
                    },
                };
            },

            default: async () => {
                this.setRememberSearch([]);
                this.debug(aiRagDebug, "default", []);
                throw new Error(`Classification result is not supported`);
            },
        };

        return getFrom(classificationMap)(await classificationResult)();
    }
}