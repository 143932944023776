import React from "react";
import { DataView, DataViews, DataViewsProvider } from "../data.views";
import { SelectedDataViewProvider } from "../data.view.selected";
import { DataViewNavBarLayoutProvider } from "../navbar_layout/data.view.nav.bar.layout";
import { SimpleDataNavBarViewLayout } from "../navbar_layout/simple.data.view.nav.bar.layout";
import { TestOneLineLayout, TestSearchResultLayout } from "./test.search.result.layout";
import { BaseDataSourceDetails } from "@me8eon/data_sources";
import { SimpleSelectableButton } from "@me8eon/selection_button";
import { FeatureFlags, FeatureFlagsStateProvider } from "@me8eon/featureflags";
import { commonComponents, CommonComponentsProvider } from "@me8eon/components";
import { SearchFn, SearchFnResult } from "me-at-eon/src/search/search";
import { allTestDataWidgets, DataWidgetProvider, DataWidgets } from "@me8eon/data_widget";
import { DebugStateProvider } from "@me8eon/debug";
import { DataPluginProvider, DataPlugins } from "@me8eon/data";
import { TestDataPlugins } from "@me8eon/all_data";
import { AttributeValueProvider, SimpleAttributeValueLayout, SimpleDataLayout } from "@me8eon/renderers";
import { allRenderers } from "@me8eon/all_renderers";
import { RawThemeProvider, themeForTests } from "@me8eon/themes";

export const TestDataSourceJiraFromDS1: BaseDataSourceDetails = {
    type: "someDataSource1",
    names: ["jira"],
};
export const TestDataSourceJiraConfluenceFromDS1: BaseDataSourceDetails = {
    type: "someDataSource1",
    names: ["jira", "confluence", "mygenius"],
};
export const TestDataSourcePeopleFromDS1: BaseDataSourceDetails = {
    type: "someDataSource1",
    names: ["people"],
};
export const TestDataSourceJiraFromJql: BaseDataSourceDetails = {
    type: "jql",
    names: ["jira"],
};
export const TestDataSourceMyGeniusDS1: BaseDataSourceDetails = {
    type: "someDataSource1",
    names: ["mygenius"],
};
export const TestDataSourceGitLabDS1: BaseDataSourceDetails = {
    type: "someDataSource1",
    names: ["gitlab"],
};

export const testSearchFn: SearchFn<any> = async (): Promise<SearchFnResult<any>> => ({
    data: [],
    dataSourceFilters: [],
    metadataFilters: {},

});
export const TestDataViewCommon = {
    plugin: "dataview" as const,
    DisplayNavItem: SimpleSelectableButton,
    SearchResultLayout: TestSearchResultLayout,
    OneLineLayout: TestOneLineLayout,
    searchFn: testSearchFn,
};

export const TestDataViewAll: DataView<BaseDataSourceDetails> = {
        ...TestDataViewCommon,
        expectedDataTypes: ["jira", "confluence", "people", "mygenius", "gitlab"],
        searchIndicies: ["jira", "confluence", "people", "mygenius", "gitlab"],
        datasources: [TestDataSourceJiraFromJql, TestDataSourcePeopleFromDS1, TestDataSourceJiraConfluenceFromDS1, TestDataSourceMyGeniusDS1, TestDataSourceGitLabDS1],
        widgets: ["apps", "sharepoint", "restOfElasticSearch"],

    }
;
export const TestDataViewJira: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    searchIndicies: ["jira"],
    datasources: [TestDataSourceJiraFromDS1, TestDataSourceJiraFromJql],
    widgets: ["jira"],
};
export const TestDataViewConfluence: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    searchIndicies: ["confluence"],
    datasources: [TestDataSourceJiraConfluenceFromDS1],
    widgets: ["confluence"],
};
export const TestDataViewPeople: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    searchIndicies: ["people"],
    datasources: [TestDataSourcePeopleFromDS1],
    widgets: ["people"],
};
export const TestDataViewMyGenius: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    searchIndicies: ["mygenius"],
    datasources: [TestDataSourceMyGeniusDS1],
    widgets: ["myGenius"],
};
export const TestDataViewGitLab: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    searchIndicies: ["gitlab"],
    datasources: [TestDataSourceGitLabDS1],
    widgets: ["gitlab"],
};
export const TestDataViews: DataViews<BaseDataSourceDetails> = {
    all: TestDataViewAll,
    jira: TestDataViewJira,
    confluence: TestDataViewConfluence,
    people: TestDataViewPeople,
    myGenius: TestDataViewMyGenius,
    gitlab: TestDataViewGitLab,

};

export type TestDataViewProviderProps<Details = BaseDataSourceDetails> = {
    initialDataView?: string
    dataViews?: (featureFlags: FeatureFlags) => DataViews<Details>
    dataWidgets?: DataWidgets
    dataPlugins?: DataPlugins
    children: React.ReactNode
    featureFlags?: FeatureFlags
}
export const TestDataViewProvider: React.FC<TestDataViewProviderProps> = ({
                                                                              children,
                                                                              dataViews = () => TestDataViews,
                                                                              dataWidgets = allTestDataWidgets,
                                                                              initialDataView = "all",
                                                                              dataPlugins = TestDataPlugins,
                                                                              featureFlags = {},
                                                                          }) => {
    return (
        <RawThemeProvider theme={themeForTests}>
            <FeatureFlagsStateProvider featureFlags={featureFlags}>
                <DebugStateProvider debugState={{}}>
                    <SelectedDataViewProvider selectedDataView={initialDataView}>
                        <DataViewNavBarLayoutProvider dataViewLayout={SimpleDataNavBarViewLayout}>
                            <DataViewsProvider dataViews={dataViews}>
                                <DataWidgetProvider dataWidget={dataWidgets}>
                                    <DataPluginProvider dataPlugins={dataPlugins}>
                                        <CommonComponentsProvider commonComponents={commonComponents}>
                                            <AttributeValueProvider renderers={allRenderers} AttributeValueLayout={SimpleAttributeValueLayout} DataLayout={SimpleDataLayout}>
                                                {children}
                                            </AttributeValueProvider>
                                        </CommonComponentsProvider>
                                    </DataPluginProvider>
                                </DataWidgetProvider>
                            </DataViewsProvider>
                        </DataViewNavBarLayoutProvider>
                    </SelectedDataViewProvider>
                </DebugStateProvider>
            </FeatureFlagsStateProvider>
        </RawThemeProvider>
    );
};

