import React from "react";
import { EonUiWebsiteNavigationColumn, EonUiWebsiteNavigationLogo, EonUiWebsiteNavigationMain } from "@eon-ui/eon-ui-components-react";
import "./header.scss";
import { AuthComponent } from "../auth/AuthComponent";
import LanguageSwitch from "../language-switch/LanguageSwitch";
import { useCombinedStore } from "../../store/headerStore";
import { useTheme } from "@me8eon/themes";
import bayernwerk from "../../assets/image/header-logos/bayernwerk.raw.svg";
import eon from "../../assets/image/header-logos/eon.raw.svg";
import me8bayern from "../../assets/image/header-logos/me8bayern.raw.svg";
import me8eon from "../../assets/image/header-logos/me8eon.raw.svg";
import { setActiveRoleIndex } from "@me8eon/msal_authentication";
import { msalInstance } from "../../msalConfig";
import { useUserData } from "@me8eon/react_authentication";
import { GenericDropdown } from "@me8eon/generic_dropdown";
import { useTranslation } from "@me8eon/translation";

const Header = ({ orgName }: { orgName: string }) => {
    const { language, setLanguage, favData } = useCombinedStore();
    const t = useTranslation();

    console.log({ orgName, language, setLanguage, favData });
    const theme = useTheme();
    const userData = useUserData();
    const { value: themeName, header } = theme;
    const { meAtEonLogoUrl, eonLogoUrl } = header;

    const imageMap: any = {
        bayernwerk,
        eon,
        me8bayern,
        me8eon,
        default_eon: eon,
        default_me8eon: me8eon,
    };

    const roleToggle = (selectedValue: string) => {
        const index = userData.allRoles.findIndex((role) => role === selectedValue);
        setActiveRoleIndex(msalInstance, Number(index));
        window.location.href = "/tile";
    }

    return (
        <EonUiWebsiteNavigationMain
            slot="main"
            className="me-eon-header"
            data-testid="header"
        >

            <EonUiWebsiteNavigationColumn content-alignment="left">
                <EonUiWebsiteNavigationLogo
                    imageSrc={imageMap[meAtEonLogoUrl] || imageMap.default_me8eon}
                    description="me@eon"
                    href="/tile"
                ></EonUiWebsiteNavigationLogo>
            </EonUiWebsiteNavigationColumn>
            <EonUiWebsiteNavigationColumn content-alignment="right">
                <AuthComponent />
                {userData.allRoles.length > 1 && (
                    <GenericDropdown
                        label={t("switchRole")}
                        icon="change_contract_01"
                        rootId="roles-dropdown"
                        options={userData.allRoles}
                        maxWidth="200px"
                        selectedOps={userData.currentRole}
                        clickHandler={roleToggle}
                    />
                )}
                <LanguageSwitch />
                <div className="eon-logo-wrapper">
                    <EonUiWebsiteNavigationLogo
                        imageSrc={imageMap[eonLogoUrl] || imageMap.eon}
                        description="E.ON Logo"
                    ></EonUiWebsiteNavigationLogo>
                </div>
            </EonUiWebsiteNavigationColumn>
        </EonUiWebsiteNavigationMain>
    );
};
export default Header;
