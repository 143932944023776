import React from "react";

import { DevModeDebug } from "./devmode.debug";
import { DevModeFeatureFlags } from "./devmode.feature.flags";
import { DevModeTranslate } from "./devmode.translate";
import { DefaultNavBar, NavBar } from "@me8eon/navbar";
import { makeContextFor, makeContextForState, makeUseStateChild } from "@me8eon/context";
import { NameAnd } from "@me8eon/record_utils";
import { useWindowUrlData } from "@me8eon/routing";
import * as url from "node:url";
import { DevModeAuthentication } from "./devmode.authentication";
import { DevmodeFilters } from "./devmode.filters";

export type DevModeComponent = () => React.ReactElement;
export type DevModeComponents = NameAnd<DevModeComponent>

export type SearchDevModeComponents = {
    DevModeNavBar: NavBar
    components: DevModeComponents
}

const devModeComponents = {
    Hide: () => <></>,
    Debug: DevModeDebug,
    FeatureFlags: DevModeFeatureFlags,
    Translate: DevModeTranslate,
    Authentication: DevModeAuthentication,
    Filters: DevmodeFilters
};
export const simpleDevModeComponents: SearchDevModeComponents = {
    DevModeNavBar: DefaultNavBar("devmode", Object.keys(devModeComponents)),
    components: devModeComponents,
};
export const { Provider: DevModeComponentsProvider, use: useDevModeComponents } = makeContextFor("components", simpleDevModeComponents);

export type DevModeState = {
    selected: string
}
export const { Provider: DevModeStateForSearchProvider, use: useDevModeState } = makeContextForState<DevModeState, "devModeState">("devModeState");
export const useDevModeSelected = makeUseStateChild<DevModeState, string>(useDevModeState, id => id.focusOn("selected"));


export function DevMode() {
    const { DevModeNavBar, components } = useDevModeComponents();
    const selectedOps = useDevModeSelected();
    const [urlData] = useWindowUrlData();

    const allowedbyUserType = true;
    const devModeReqestedAndAllowed = allowedbyUserType && urlData.url.searchParams.get("devMode");
    if (!devModeReqestedAndAllowed) return <></>;
    const [selected] = selectedOps;
    const Component = components[selected] || (() => <></>);
    return <div className="dev-mode">
        <hr />
        <DevModeNavBar selectedOps={selectedOps} />
        <Component />
    </div>;
}