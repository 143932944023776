import React, { CSSProperties, ReactNode } from "react";
import { useDebug } from "@me8eon/debug";
import { useFeatureFlag } from "@me8eon/featureflags";
import { dataAsJsonFF, DataHasType, useDataPlugins } from "@me8eon/data";
import { useThrowError } from "@me8eon/throw_error";
import { DataWidgetLayout, debugDataWidgets } from "@me8eon/data_widget";
import { SearchFeedbackPayload, useDisplayFeedbackComponent, LikeOrDislike, ClickFeedbackPayload, useFeedback } from "@me8eon/feedback";
import { useSearchTerm } from "@me8eon/filter_data_widget";
import { UrlOnClickProvider } from "@me8eon/renderers";

export type DisplayAsResultsListProps<Data> = {
    rootId: string
    data: Data[]
    widgetName: string //just for debugging
}
export type DisplayAsResultsList = <Data extends DataHasType> (props: DisplayAsResultsListProps<Data>) => ReactNode

const preStyleForDebug: CSSProperties = {
    maxWidth: "1500px",
    width: "100%",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    overflowX: "auto", // Optional: Adds horizontal scrollbar if needed
    backgroundColor: "#f5f5f5", // Optional: Improve readability
    padding: "10px", // Optional: Add some padding
    borderRadius: "4px", // Optional: Rounded corners
};

export const DisplayAsResultsList = <Data extends DataHasType>(filter: (d: DataHasType) => boolean,
    Layout: DataWidgetLayout) =>
    ({ rootId, data, widgetName }: DisplayAsResultsListProps<Data>): ReactNode => {
        const debug = useDebug(debugDataWidgets);
        const dataPlugins = useDataPlugins();
        const throwError = useThrowError();
        const ff = useFeatureFlag(dataAsJsonFF);
        const filteredData = data.filter(filter);
        const [searchTerm] = useSearchTerm();
        const feedback = useFeedback();
        const Feedback = useDisplayFeedbackComponent();
        return <Layout rootId={rootId}>{
            filteredData.map((d, i) => {
                const type = d.type;
                const plugin = dataPlugins[type];
                if (!plugin) {
                    return throwError("s/w", `No plugin for data type ${type}. Legal values are ${Object.keys(dataPlugins).sort()}\n${JSON.stringify(d, null, 2)}`);
                }
                const { DisplayData, idFrom } = plugin;
                const id = idFrom(d);
                const title = (d as any).title || (d as any).card_title;

                function dataToSend(likeDislike: LikeOrDislike | undefined) {
                    const payload: SearchFeedbackPayload = {
                        source: "search",
                        query: searchTerm,
                        like: likeDislike,
                        index: (d as any).index,
                        id: id,
                    };
                    return payload;
                }

                function urlOnClick(url: string | undefined) {
                    return () => {
                        const payload: ClickFeedbackPayload = {
                            source: "click",
                            index: (d as any).index,
                            id: id,
                            href: url || "No url specified",
                            position: i,
                        };
                        feedback(payload);
                    };
                }

                debug("DisplayAsResultsList - item", title, { rootId, i, id, d, plugin });
                return <UrlOnClickProvider key={id} urlOnClick={urlOnClick}>
                    <div >{ff ?
                        <pre style={preStyleForDebug}>{JSON.stringify(d, null, 2)}</pre> :
                        <DisplayData key={id} rootId={rootId} id={id} data={d} />}
                        <Feedback dataToSend={dataToSend} source="query" />
                    </div>
                </UrlOnClickProvider>;
            })
        }</Layout>;
    };