import { BaseDataSourceDetails } from "@me8eon/data_sources";
import { SelectableButton, SimpleSelectableButton } from "@me8eon/selection_button";
import { TestOneLineLayout, TestSearchResultLayout } from "@me8eon/data_views/src/fixture/test.search.result.layout";
import { DataView, DataViews, SearchResultLayout } from "@me8eon/data_views";
import "./eon.dataviews.scss";
import React from "react";
import { ThemedIcon } from "@me8eon/icons";
import { useTheme } from "@me8eon/themes";
import { useAiAssist } from "@me8eon/ai_assist_data_widget";
import { Setter } from "@me8eon/context";
import { FeatureFlag, FeatureFlags, OptionsFeatureFlag, useFeatureFlags } from "@me8eon/featureflags";
import { orderFeatureFlagName } from "@me8eon/order_it_data_plugin";
import { EnvironmentConfig } from "@me8eon/config";

export const ElasticSearchDS: BaseDataSourceDetails = {
    type: "elasticsearch",
    names: ["jira-prod", "confluence-prod"],//more later, we aren't using this yet
};

export const Office365DS: BaseDataSourceDetails = {
    type: "office365",
    names: ["people"],
};

export function needOffice(v: DataView<any>) {
    const result = v?.datasources?.includes(Office365DS);
    return result;
}

export const TestDataViewCommon = {
    plugin: "dataview" as const,
    DisplayNavItem: SimpleSelectableButton,
    SearchResultLayout: TestSearchResultLayout,
    OneLineLayout: TestOneLineLayout,
    displayAsWidget: false,
};


export const AllDataViewSearchResultLayout: SearchResultLayout =
    ({ rootId, children }) => {
        const childrenAsArray = React.Children.toArray(children);
        if (childrenAsArray.length !== 3) throw new Error("Expected four child");
        const [ai, rest, sharepoint] = childrenAsArray;
        const { dataLayout } = useTheme();
        return <div className="advance-page-result-root" data-testid={`${rootId}-search-result-layout`}>
            <hr style={{ margin: 0, backgroundColor: "#e6e3e1", height: "1px", border: "none", position: "sticky", top: "116px", zIndex: 98 }} />
            <div className="all_data_view_row_1">
                <div className="ai-list-wrapper">{ai}</div>
                {/* <div className="apps-list-wrapper">{apps}</div> */}
            </div>
            <div className="all_data_view_row_2">
                <div className="sharepoint-wrapper" style={dataLayout.AdvanceUnifiedBox}>
                    {sharepoint}
                </div>
                <div className="all-list-wrapper">
                    {rest}
                </div>
            </div>
        </div>;
    };

const searchIndicies = ["jira-prod", "confluence-prod", "gitlab-prod", "mygenius-prod"];
const searchIndiciesWithOrderIt = [...searchIndicies, "order-it-api"];
const searchIndiciesWithTestForVasu = [...searchIndicies, "order-it-api-test"];

function index(ff: FeatureFlag): string[] {
    if (ff.value === "default") return searchIndicies;
    if (ff.value === "orderIt") return searchIndiciesWithOrderIt;
    if (ff.value === "testForVasu") return searchIndiciesWithTestForVasu;
    throw new Error(`Unknown value ${ff.value}`);
}

export const allDataView = (featureFlags: FeatureFlags, isITOrderEnabled: boolean): DataView<BaseDataSourceDetails> => {
    const isHigherEnv = EnvironmentConfig.isHigherEnv;

    const indiceBuilderObj = {
        ...(isHigherEnv || !isITOrderEnabled ? { value: "default" } : featureFlags[orderFeatureFlagName])
    } as FeatureFlag;
    console.log({ isHigherEnv, isITOrderEnabled, indiceBuilderObj })
    const indicies = index(indiceBuilderObj);
    console.log({ indicies })
    return ({
        ...TestDataViewCommon,
        expectedDataTypes: ["jira", "confluence", "people", "mygenius", "gitlab", "orderit"],
        datasources: [ElasticSearchDS, Office365DS],
        icon: <ThemedIcon name={"all"} />,
        searchFn: null as any,
        searchIndicies: indicies,
        SearchResultLayout: AllDataViewSearchResultLayout,
        widgets: ["ai_assist", "restOfElasticSearch", "sharepointWithMore"],
    });
}
    ;


export const sharepointDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["sharepoint"],
    datasources: [Office365DS],
    icon: <ThemedIcon name={"sharepoint"} shouldChangeColor={false} />,
    searchIndicies: ["m365"],
    searchFn: null as any,
    widgets: ["sharepoint", "filter"],
};


export const appsDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["app"],
    datasources: [ElasticSearchDS],
    searchIndicies: ["apps-dev"],
    icon: <ThemedIcon name={"app"} />,
    searchFn: null as any,
    widgets: ["apps"],
};
export const peopleDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["people"],
    datasources: [Office365DS],
    searchIndicies: ["people"],
    icon: <ThemedIcon name={"user_group"} />,
    searchFn: null as any,
    widgets: ["people"],
};

export const jiraDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["jira"],
    datasources: [ElasticSearchDS],
    searchIndicies: ["jira-prod"],
    icon: <ThemedIcon name={"jira"} shouldChangeColor={false} />,
    searchFn: null as any,
    widgets: ["jira", "filter"],
};

export const confluenceDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["confluence"],
    datasources: [ElasticSearchDS],
    searchIndicies: ["confluence-prod"],
    icon: <ThemedIcon name={"confluence"} shouldChangeColor={false} />,
    searchFn: null as any,
    widgets: ["confluence", "filter"],
};

export const gitlabDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["gitlab"],
    datasources: [ElasticSearchDS],
    searchIndicies: ["gitlab-prod"],
    icon: <ThemedIcon name="gitlab" />,
    searchFn: null as any,
    widgets: ["gitlab"],
};
export const myGeniusDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["mygenius"],
    datasources: [ElasticSearchDS],
    searchIndicies: ["mygenius-prod"],
    icon: <ThemedIcon name={"mygenius"} />,
    searchFn: null as any,
    widgets: ["mygenius", "filter"],
};


const AiAssistantButton: SelectableButton = (props) => {
    const [aiAssist] = useAiAssist();
    const fakeValue = "whocares";
    const setFakeValue: Setter<string> = () => aiAssist.setShowAiAssist(true);
    return <SimpleSelectableButton {...props} selectedOps={[fakeValue, setFakeValue]} />;
};

export const aiAssistantDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: [],
    DisplayNavItem: AiAssistantButton,
    icon: <ThemedIcon name="AiIcon24" />,
    searchFn: null as any, datasources: [],
    searchIndicies: [],
    widgets: [],
};

export const itOrderDataView: DataView<BaseDataSourceDetails> = {
    ...TestDataViewCommon,
    expectedDataTypes: ["orderit"],
    datasources: [ElasticSearchDS],
    searchIndicies: ["order-it-api"],
    icon: <ThemedIcon name="cart" />,
    searchFn: null as any,
    widgets: ["orderit"],
};

export const allDataViews = (featureFlags: FeatureFlags, isITOrderEnabled: boolean): DataViews<BaseDataSourceDetails> => ({
    all: allDataView(featureFlags, isITOrderEnabled),
    apps: appsDataView,
    aiassistant: aiAssistantDataView,
    people: peopleDataView,
    m365: sharepointDataView,
    mygenius: myGeniusDataView,
    jira: jiraDataView,
    confluence: confluenceDataView,
    gitlab: gitlabDataView,
    ...(isITOrderEnabled && !EnvironmentConfig.isHigherEnv && { orderit: itOrderDataView }),
});

// export const dataViewWithFilters: DataView<BaseDataSourceDetails>[] = Object.values(allDataViews).filter(e => e.widgets.length === 2);
