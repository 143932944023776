import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useContext,
} from "react";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import LoaderComponent from "../../loader-component/LoaderComponent";
import { MessageWithActionAndData, AiAssistContext } from "../AiAssist.context";
import { Icon } from "@me8eon/icons";
import TypingEffect from "../utils/typing-effect/TypingEffect";
import {
    processRemoteAction,
    fetchTriggerStatus,
} from "../../../services/nexthinkService";
import { useTranslation } from "@me8eon/translation";
import "./nexthinkActions.component.scss";

const disclaimer =
    "Please note we will not touch your personal or work data. While clean-up takes place in the background, feel free to use assistance.";
const failureMsg = "chatBot.nexthinkIntegration.failureMsg";
const nexthinkRemoteActions: Record<
    string,
    {
        actionId: string;
        primaryBtn: string;
        secondaryBtn: string;
        successMsg: string;
        taskProgressMsg: string;
        disclaimer: string;
    }
> = {
    "disk-cleanup": {
        actionId: "#disk_cleanup_light",
        primaryBtn: "chatBot.nexthinkIntegration.diskClean.primaryBtn",
        secondaryBtn: "chatBot.nexthinkIntegration.diskClean.secondaryBtn",
        successMsg: "chatBot.nexthinkIntegration.diskClean.successMsg",
        taskProgressMsg: "chatBot.nexthinkIntegration.diskClean.progressMsg",
        disclaimer,
    },
    "chrome-cache": {
        actionId: "#clear_chrome_cache",
        primaryBtn: "chatBot.nexthinkIntegration.chromeCache.primaryBtn",
        secondaryBtn: "chatBot.nexthinkIntegration.chromeCache.secondaryBtn",
        successMsg: "chatBot.nexthinkIntegration.chromeCache.successMsg",
        taskProgressMsg: "chatBot.nexthinkIntegration.chromeCache.progressMsg",
        disclaimer,
    },
    "repair-O365-apps": {
        actionId: "#repair_empower_addin",
        primaryBtn: "chatBot.nexthinkIntegration.empowerApp.primaryBtn",
        secondaryBtn: "chatBot.nexthinkIntegration.empowerApp.secondaryBtn",
        successMsg: "chatBot.nexthinkIntegration.empowerApp.successMsg",
        taskProgressMsg: "chatBot.nexthinkIntegration.empowerApp.progressMsg",
        disclaimer: "We will be reinstalling the Empower plugin, Please close all your O365 Apps before proceeding further.",
    },
};

const NexthinkActions: React.FC<MessageWithActionAndData> = ({
    data,
    content,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [responseData, setResponseData] = useState<any>(null);
    const [polling, setPolling] = useState<
        "PROGRESS" | "COMPLETED" | "FAILED" | null
    >(null);
    const [showRag, setShowRag] = useState(false);
    const { addAssistantTask } = useContext(AiAssistContext);
    useEffect(() => {
        if (data.category === "") {
            setError(true);
        }
    }, []);

    const deviceId = data?.deviceDetails?.data?.length
        ? data?.deviceDetails?.data[0]["device.collector.uid"]
        : null;
    const t = useTranslation();
    const currentAction = useMemo(
        () =>
            nexthinkRemoteActions[
                data.category as keyof typeof nexthinkRemoteActions
            ],
        [data.category]
    );

    const triggerAction = useCallback(async () => {
        if (!currentAction.actionId) return;
        setLoading(true);
        const resp = await processRemoteAction(
            currentAction.actionId,
            deviceId
        );
        if (!resp) {
            setError(true);
            setLoading(false);
        } else {
            addAssistantTask({
                requestId: resp.requestId,
                task: currentAction.actionId,
                status: "progress",
            });
            setResponseData(resp);
        }
    }, [currentAction, deviceId]);

    useEffect(() => {
        if (responseData?.requestId) {
            fetchStatus(responseData.requestId);
        }
    }, [responseData]);

    const fetchStatus = useCallback((requestId: string) => {
        setLoading(false);
        setPolling("PROGRESS");
        const interval = setInterval(async () => {
            const { data } = await fetchTriggerStatus(requestId);
            if (data.length && data[0]["remote_action.execution.status"]) {
                const status = data[0]["remote_action.execution.status"];
                if (["success", "failure"].includes(status)) {
                    setPolling(status === "success" ? "COMPLETED" : "FAILED");
                    addAssistantTask({
                        requestId: requestId,
                        task: currentAction.actionId,
                        status: status,
                    });
                    clearInterval(interval);
                }
            }
        }, 5000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    const ActionSection = useMemo(
        () => (
            <div className="bot-reply-container">
                {error ? (
                    <span>{t(failureMsg)}</span>
                ) : (
                    <>
                        <TypingEffect
                            className="bot-text"
                            text={`${content}<div><br/>${
                                currentAction?.disclaimer ?? ""
                            }<br/><b>Source: Nexthink</b></div>`}
                            speed={1}
                            effecttEnd={() => setShowButtons(true)}
                        />
                        {loading && (
                            <LoaderComponent isLoading={loading} isLinear />
                        )}
                        {showButtons && !responseData && (
                            <div className="ai-action-btn-wrapper">
                                <EonUiButton
                                    fullWidthOnMobile
                                    text={t(currentAction.primaryBtn)}
                                    rank="primary"
                                    size="small"
                                    onClick={triggerAction}
                                    disabled={loading}
                                />
                                <EonUiButton
                                    fullWidthOnMobile
                                    text={t(currentAction.secondaryBtn)}
                                    rank="secondary"
                                    size="small"
                                    onClick={() => setShowRag(true)}
                                    disabled={loading}
                                />
                            </div>
                        )}{" "}
                    </>
                )}
            </div>
        ),
        [
            content,
            currentAction,
            loading,
            error,
            responseData,
            triggerAction,
            showButtons,
            t,
        ]
    );

    const PollingStatus = useMemo(() => {
        if (polling === null) return null;
        const statusMsg =
            polling === "PROGRESS"
                ? t(currentAction.taskProgressMsg)
                : polling === "COMPLETED"
                ? t(currentAction.successMsg)
                : t(failureMsg);
        return (
            <div className="bot-header">
                <div className="eon-logo">
                    <Icon name="AiIcon" />
                </div>
                <div className="bot-reply-container">
                    <div className="title"> <LoaderComponent isLoading={polling === "PROGRESS"} isInline size="small" />{statusMsg}</div>
                </div>
            </div>
        );
    }, [polling, currentAction, t]);

    return (
        <>
            <div className="bot-header">
                <div className="eon-logo">
                    <Icon name="AiIcon" />
                </div>
                {showRag || !deviceId ? (
                    <TypingEffect
                        className="bot-text"
                        text={data.rag ?? t(failureMsg)}
                        speed={1}
                    />
                ) : (
                    ActionSection
                )}
            </div>
            {PollingStatus}
        </>
    );
};

export default NexthinkActions;
