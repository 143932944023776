import { openAiClientForJql } from "../../../jira/ai/ai.client.for.jql";
import { aiClassifier } from "../prompts/ai.query.prompt";
import { getFrom } from "../../../util/map";
import { Message } from "../ai.types";
import { aiConfig } from "../../..";

const ragTypeByLanguage = {
    "E.ON Digital Technology GmbH": "personalizedRag",
    "E.ON Sverige AB": "personalizedRag",
    "E.DIS Netz GmbH": "personalizedRag",
    default: "rag",
};

const aiPromptClassifier = async (prompt: string, companyName: string, featureFlagEnabled?: boolean) => {
    if (prompt.toLowerCase().includes("show")) {
        if (prompt.toLowerCase().includes("incidents") || prompt.toLowerCase().includes("orders")) {
            return "action";
        }
    }
    const aiPrompt = `${aiClassifier}
    Input: "${prompt}"
    Output:`;
    const messages: Message[] = [
        { role: "system", content: aiPrompt },
        { role: "user", content: prompt },
    ];

    const jqlAiClient = openAiClientForJql(aiConfig);
    const outputMessages = await jqlAiClient(messages);
    const gptResponse = outputMessages?.[outputMessages.length - 1]?.content?.trim();

    const enableNextThinkIntegration = window.location.href.includes("nexthink=true") || featureFlagEnabled;
    const enableJQLIntegration = window.location.href.includes("jql=true") || featureFlagEnabled;
    if (gptResponse?.toLowerCase() === "jira" && (enableJQLIntegration)) {
        return "jira";
    }
    if (gptResponse?.toLowerCase() === "nextthink" && (enableNextThinkIntegration)) {
        return "nextthink";
    }
    return getFrom(ragTypeByLanguage)(companyName);
};

export default aiPromptClassifier;