import { TabDataInterface } from "../../interfaces/TileInterface";
import "./TabComponent.scss";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import useSearchStore from "../../store/searchStore";
import { translateKey } from "../../util/translate";
import React from "react";
const TabComponent = ({
    favTabs,
    isEditMode,
}: {
    favTabs: TabDataInterface[];
    isEditMode: boolean;
}) => {
    const { showModal, addNewTab, setInputFocused } = useSearchStore();
    const { t } = useTranslation();

    const toggleModal = () => {
        showModal("addNewTab");
    };
    const toggleActiveTab = (clickedIndex: number) => {
        const newTabdata = favTabs.map((obj, index) => {
            return {
                ...obj,
                active: index === clickedIndex,
            };
        });
        addNewTab(newTabdata);
    };
    return (
        <div className="tab-wrapper">
            <div className="tab-scroller">
                {favTabs.map((tab: TabDataInterface, index: number) => {
                    const translatedTabName = translateKey(
                        tab.name.toLowerCase(),
                        tab.name,
                        "moveTabAndTiles."
                    );
                    return (
                        <button
                            key={`${index.toString()}`}
                            disabled={isEditMode}
                            title={translatedTabName}
                            onFocus={() => setInputFocused(false)}
                            className={`tab ${tab.active ? "tab--active" : ""}`}
                            onClick={() => toggleActiveTab(index)}
                        >
                            {translatedTabName} ({tab.count})
                        </button>
                    );
                })}
            </div>
            {favTabs.length < 5 && (
                <button
                    id="tabTour1"
                    className="tab add-new"
                    onClick={() => toggleModal()}
                    disabled={isEditMode}
                    title={t("moveTabAndTiles.addTabTitle")}
                >
                    <span className="icon-wrapper">
                        <EonUiIcon
                            placeholder="add"
                            className="margin-add eon-ui-svg-icon"
                            size="small"
                            name={"add"}
                            scheme="red500"
                        ></EonUiIcon>
                    </span>
                    <span className="add-new-desktop">
                        {t("moveTabAndTiles.addTab")}
                    </span>
                </button>
            )}
        </div>
    );
};

export default TabComponent;
