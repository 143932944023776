import { DataLayout, DataLayoutProps } from "../data.layout";
import { ReactNode } from "react";
import React, { useRef } from "react";
import { useTheme } from "@me8eon/themes";
import { useCommonComponents } from "@me8eon/components";

export const SimpleDataLayout: DataLayout = ({ rootId, layout, children, className }: DataLayoutProps) => {
    const theme = useTheme();
    const { dataLayout: styles } = theme;
    const rows: ReactNode[][] = [];
    let childIndex = 0;

    const childrenArray = React.Children.toArray(children);

    // Create rows based on layout array
    for (const itemsInRow of layout) {
        const row = childrenArray.slice(childIndex, childIndex + itemsInRow);
        if (row.length > 0) {
            rows.push(row);
        }
        childIndex += itemsInRow;
    }
    const { BgMouseOver } = useCommonComponents();
    return (
        <BgMouseOver bgHover={"var(--bg-hover-color)"}>
            <div
                data-testid={`${rootId}-data-layout`}
                className={className || "layout-container"}
                role="presentation" // Suppresses unwanted semantics
                style={styles.dataLayoutContainer}
            >
                {rows.map((row, rowIndex) => (
                    <div className="simple_data_rows" key={rowIndex} role="row" style={{ ...styles.dataLayoutRow, ...(row.length > 1 ? { display: "flex", gap: "8px" } : null) }}>
                        {row.map((child, colIndex) => {
                            if (child) {
                                return (
                                    <div key={colIndex} role="cell" style={{ ...styles.dataLayoutItem, marginTop: "4px" }}>
                                        {child}
                                    </div>
                                )
                            }
                            return null
                        })}
                    </div>
                ))}
            </div>
        </BgMouseOver >
    );
};
