{
  "headerData1": "Willkommen im",
  "headerData2": "me@eon Portal",
  "headerMessage": "24/7 Online-Service für Dich",
  "loginLable": "Login",
  "logOff": "Abmelden",
  "logIn": "Anmelden",
  "userName": "Profilname",
  "switchLanguage": "Sprache wechseln",
  "switchRole": "Rolle wechseln",
  "organiseTiles": "Kacheln organisieren",
  "confirm": "Bestätigen",
  "cancel": "abbrechen",
  "close": "Schließen",
  "continue_Tour": "Tour fortsetzen",
  "skip_Tour": "Tour überspringen",
  "save": "Speichern",
  "submit": "Einreichen",
  "delete": "Löschen",
  "trash_bin": "Löschen",
  "edit": "Bearbeiten",
  "yes": "Ja",
  "no": "Nein",
  "feedbackWidget": "Feedback",
  "registerLable": "Zur Anmeldung ",
  "underDevelopmentMsg": "Diese Seite wird überarbeitet",
  "apiFailDefaultMsg": "Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.",
  "landingPage": {
    "hello": "Hallo",
    "title": "wie kann ich weiterhelfen?",
    "searchApps": "Apps",
    "suggestions": "Vorschläge"
  },
  "footer": {
    "copyrights": "Urheberrechte Inhalt",
    "imprint": "Impressum",
    "privacyPolicy": "Datenschutz",
    "termsofUse": "AGB",
    "contact": "Kontakt",
    "PKI": "PKI",
    "privacySettings": "Datenschutz-Einstellungen",
    "aboutMe8EON": "über me@eon"
  },
  "tilePage": {
    "backToSearch": "zurück zur vorherigen Seite",
    "myInboxCountSuffix": "Ausstehend"
  },
  "myApprovalDataTable": {
    "FilterLable": {
      "all": "Alle",
      "leaveApptoval": "Urlaubsgenehmigung",
      "timeSheet": "Stundenzettel",
      "claim": "Beanspruchen",
      "serviceRequest": "Service-Anfrage"
    },
    "tableData": {
      "approve": "Genehmigen",
      "reject": "ablehnen",
      "requestId": "Anfrage ID",
      "description": "Beschreibung",
      "requestedBy": "Angefordert von",
      "requestedType": "Angeforderter Typ",
      "requestedDate": "Wunschtermin",
      "status": "Status"
    }
  },
  "appData": {
    "time": "Zeiterfassung",
    "meeting": "Raumbuchungen",
    "approval": "Meine Genehmigungen",
    "pr": "Bestellanforderung erstellen",
    "pra": "Erweiterte Bestellanforderung erstellen",
    "processing": "My Inbox",
    "hr": "HR Portal",
    "hrLabel": "HR Portal",
    "concur": "Concur-Reisebuchung und -Kosten",
    "password": "Zurücksetzen des SAP-Passworts",
    "ariba": "Ariba – Classic und Classic+",
    "itresources": "IT-Ressourcen",
    "incident": "Vorfälle",
    "myassets": "Meine IT Ausstattung",
    "order": "Bestellungen",
    "beeline": "Beeline",
    "prisma": "Prisma",
    "gertrud": "Gertrud",
    "notification": "Meldungsportal",
    "zuko": "Zuko",
    "brand": "EON Brand Space",
    "pki": "PKI Portal",
    "mail": "Mailverteiler",
    "journey": "Fahrten und Veranstaltungen",
    "mygenius": "MyGenius",
    "timehover": "Mit der CATS-App kannst du deine Arbeitszeiten und Aktivitäten einfach erfassen und verwalten, indem du alle relevanten Informationen und Funktionen an einem Ort hast.",
    "meetinghover": "Mit Gingco, unserem Raumbuchungstool, kannst du einfach deine Besprechungsräume und Ressourcen verwalten und planen, um eine effiziente Nutzung und Verfügbarkeit sicherzustellen, alles an einem Ort",
    "approvalhover": "Mit myInbox kannst du Rechnungen einfach und effizient freigeben, indem du alle relevanten Informationen und Aktionen an einem Ort hast.",
    "prhover": "Hier findest Du alle relevanten Funktionen,um eine Bestellung anzufordern. Im Konzern häufig auch als BANF bezeichnet.",
    "prahover": "Hier findest Du alle relevanten Funktionen,um eine Bestellung anzufordern. Im Konzern häufig auch als BANF bezeichnet.",
    "processinghover": "Mit myInbox kannst du Rechnungen einfach und effizient freigeben, indem du alle relevanten Informationen und Aktionen an einem Ort hast.",
    "hrhover": "Mit myHR kannst du bequem auf deine personalbezogenen Informationen und Aufgaben zugreifen und diese an einem Ort verwalten.",
    "concurhover": "Mit Concur kannst du deine Reise- und Spesenabrechnungen einfach verwalten und einreichen, indem du alle relevanten Informationen und Aktionen an einem Ort hast.",
    "passwordhover": "Hier kannst du die Zugangspasswörter zu deinen SAP Systemen einfach zurücksetzen lassen.",
    "aribahover": "Mit SAP Ariba kannst du deine Beschaffungsprozesse einfach verwalten, indem du alle relevanten Informationen und Werkzeuge an einem Ort hast.",
    "itresourceshover": "IT-Ressourcen",
    "myassetshover": "Meine IT Ausstattung",
    "orderhover": "Bestellungen",
    "incidenthover": "Vorfälle",
    "beelinehover": "Mit Beeline kannst du deine externe Belegschaft effizient verwalten und deine Personalbeschaffungsprozesse optimieren, indem du alle notwendigen Werkzeuge und Informationen an einem Ort hast",
    "prismahover": "Mit Prisma kannst du deinen Energieverbrauch einfach überwachen und analysieren und deine Energieeffizienz optimieren, indem du alle relevanten Daten und Erkenntnisse an einem Ort abrufst",
    "gertrudhover": "Gertrud",
    "notificationhover": "Meldungsportal",
    "zukohover": "Zuko",
    "brandhover": "EON Brand Space",
    "pkihover": "PKI Portal",
    "mailhover": "Mailverteiler",
    "journeyhover": "Fahrten und Veranstaltungen",
    "mygeniushover": "MyGenius",
    "subTitle": {
      "timeSubTitle": "CATS",
      "meetingSubTitle": "GINGCO",
      "approvalSubTitle": "SNOW/SAP",
      "prSubTitle": "BANF",
      "praSubTitle": "BANF",
      "processingSubTitle": "SAP",
      "hrSubTitle": "MyHR",
      "concurSubTitle": "SAP Concur",
      "passwordSubTitle": "SAP Systems",
      "aribaSubTitle": "PO-Übersicht",
      "itresourcesSubTitle": "SNOW",
      "incidentSubTitle": "SNOW",
      "orderSubTitle": "Order IT Services",
      "myassetsSubTitle": "SNOW",
      "beelineSubTitle": "Externe Dienstleister",
      "prismaSubTitle": "HSE",
      "gertrudSubTitle": "Vorstandssitzungen vorbereiten",
      "notificationSubTitle": "Hausmeisterservice",
      "zukoSubTitle": "Umzüge durchführen",
      "brandSubTitle": "it's on us",
      "pkiSubTitle": "Zertifikate verwalten",
      "mailSubTitle": "pflegen",
      "journeySubTitle": "FuV §37b EstG",
      "mygeniusSubTitle": "Digitale Lernplattform"
    }
  },
  "chatBot": {
    "searchBack": "zurück zur vorherigen Seite",
    "welcomeMessage": "Chat-Eingabeaufforderung",
    "chatbotPlaceholder": "Geben Sie hier ein...",
    "sendbtn": "schicken",
    "showMore": "Mehr anzeigen",
    "askMe": "Stellen Sie Ihre Fragen",
    "feedbackTitle": "War das hilfreich?",
    "feedbackTitle2": "War die Antwort hilfreich?",
    "feedbackThumbsUpLabel": "Ja, das hat geholfen",
    "feedbackThumbsDownLabel": "Nein, ich brauche weitere Unterstützung",
    "feedbackPrompt": "Gib dein Feedback ein",
    "feedbackMsg": "Vielen Dank für Ihr Feedback! Wir schätzen Ihre Hilfe bei der Verbesserung unserer Dienstleistungen.",
    "card": {
      "selfService": "Selbstbedienung",
      "efficiency": "Effizienz",
      "ngService": "Service der nächsten Generation"
    },
    "disclaimerText": "KI-generierte Inhalte könnten fehlerhaft sein",
    "selfServiceTitle": "IT Support",
    "selfServiceContent": "KI-gestützte Self-Service-Unterstützung für die Lösung von Swift-Service-Fällen",
    "knowledgeBaseTitle": "Knowledge Base",
    "knowledgeBaseContent": "Bessere Mitarbeitererfahrung durch Information",
    "internetSearchTitle": "General Questions",
    "wentWell": "Was ist gut gelaufen? (optional)",
    "wentWrong": "Was ist schief gelaufen? (optional)",
    "privacyMsg": "Wir respektieren deine Privatsphäre und versichern dir, dass deine Rückmeldung vertraulich behandelt wird",
    "appreciateService": "Wir schätzen deine Hilfe bei der Verbesserung unserer Services",
    "feedbackMessage": "Vielen Dank für dein Feedback",
    "relevantSearches": "Relevanteste Suchanfragen",
    "aiAssitanceSuggestion": "Du können Ihre Fragen an AI Assistance stellen, indem Du das folgende Eingabefeld verwenden",
    "aiActionMsg": "Ihr interner Speicher scheint voll zu sein. Ich kann Ihnen helfen, das Installationsproblem zu beheben, indem ich Ihre temporären Dateien automatisch säubere und Ihren Papierkorb leere. Möchten Sie, dass ich automatisch säubere?",
    "aiActionPrimaryBtn": "automatisch bereinigen",
    "aiActionSecondaryBtn": "nicht automatisch bereinigen",
    "aiActionSuccess": "Ihr Speicher wurde bereinigt.",
    "aiActionFailed": "Etwas ist schiefgelaufen. Bitte versuchen Sie es später noch einmal. Oder versuchen Sie es bitte manuell.",
    "hey": "Hallo",
    "topicStartMessage": "Womit kann ich helfen?",
    "newChat": "Neuer Chat",
    "ask": "Frag",
    "internet": "Internet",
    "askEon": "E.ON Fragen",
    "askInternet": "Internet Fragen",
    "askFollowUpQuestion": "Stell eine weitere Frage",
    "resolveIssue": "Um dein Problem zu lösen, möchtest du",
    "differentiator": "oder",
    "incident": "ein Störungsticket erstellen",
    "creatingIncident": "Erstellen einer Störung",
    "liveAgent": "Chatte mit dem Service Desk",
    "ticketDescription": "Ticketbeschreibung auf der Grundlage der jüngsten Diskussion",
    "createIncident": "Vorfall erstellen",
    "confirmCreateIncident": "Bearbeiten und Erstellen einer Störung",
    "editCreateIncident": "Störung bearbeiten und erstellen",
    "ticketSuccessMsg": "Ihre Störung wurde erfolgreich erstellt, Ticket-Nummer: ",
    "ticketErrorMsg": "Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.",
    "chatBotTitle": "Powered by AI Assistance",
    "duplicateError": "Doppelte Frage, bitte stellen Sie eine andere Frage",
    "content": "Inhalt",
    "tone": "Ton",
    "information": "Informationen",
    "complete": "Vollständig",
    "other": "Anderes",
    "irelevantContent": "Irrelevanter Inhalt",
    "lackOfDetail": "Fehlen von Details",
    "incorrectInformation": "Falsche Informationen",
    "copyLink": "Link kopieren",
    "openLink": "Link öffnen",
    "internetSearchContent": "Basierend auf Fragen Sie mich etwas EON gpt",
    "liveAgentChat": {
      "connectWithLiveAgent": "Verbindung mit dem Service Desk",
      "connectingWithLiveAgent": "Wir verbinden dich mit dem Service Desk....",
      "connectingHint": "Du kannst zum Assistenten zurückkehren, indem du auf den Link unter dem Chat -Feld klickst",
      "switchToAssistance": "Beenden Sie den Chat und wechseln Sie zur Unterstützung",
      "agentLeftMsg": "Danke, das du E.ON kontaktiert hast",
      "typeMessageHint": "Geben Sie Ihre Nachricht hier ein...",
      "disconnectingMsg": "Beenden des Chats und Wechseln zum Assistenten"
    },
    "quickActions": {
      "heading": "Aktionen",
      "actions": {
        "createIncident": "Hilf mir, ein IT-Problem zu lösen",
        "chatWithServiceDesk": "Chatte mit dem Service Desk",
        "requestCallback": "Rückruf vom Service Desk anfordern",
        "ongoingConversation": "Ja, das ist Bestandteil meines letzten Gesprächs.",
        "newConversation": "Nein. Ich brauche Hilfe bei der Lösung eines neuen Problems.",
        "confirmAndCreateTicket": "Bestätigen und Ticket erstellen",
        "cancelTicketCreation": "Ticket-Erstellung abbrechen",
        "confirmChatWithServiceDesk": "Bestätigen und verbinden mit dem Service Desk",
        "cancelChatWithServiceDesk": "Chat mit Service Desk abbrechen"
      },
      "state": {
        "whatsTheIssue": "Was ist das Problem, das du lösen willst?",
        "lastConversation": "Natürlich, ist das Problem Teil der letzten Unterhaltung?",
        "createIncidentLabel": "Du bist dabei, ein Ticket für eine Störung zu erstellen",
        "cancelAck": "Notiert. Kann ich dir bei einem anderen Problem helfen?",
        "chatWithServiceDeskLabel": "Du bist dabei, dich mit einem Service Desk Agenten zu verbinden",
        "createTicketLabel": "Bestätigen und Ticket erstellen",
        "cancelTicketLabel": "Ticket-Erstellung abbrechen",
        "creatingIncidentTicket": "Störungsticket erstellen",
        "ticketDescription": "Ticket Beschreibung",
        "connectWithServiceDeskLabel": "Bestätige den Chat mit dem Service Desk"
      }
    },
    "askTooltip": {
      "eon": "Erhalte unternehmensspezifische Informationen aus internen Quellen",
      "internet": "Erhalte Informationen von internen und externen Quellen wie E.ON GPT"
    },
    "jiraIntegration": {
      "viewInJira": "Zeige es in Jira",
      "responseHeading": "Hier findest du die Zusammenfassung deiner Jira Tickets:",
      "responseSubheading": "Hier sind deine Top-10 Anfragen",
      "noResponse": "Offenbar sind für Ihre Anfrage derzeit keine JIRA-Tickets verfügbar.",
      "supportedQuestionMsg": "Hey, I am sorry. I didn't quite understand your question. Can you please rephrase it again. I am sharing some sample questions to assist you:"
    },
    "nexthinkIntegration": {
      "failureMsg": "Hoppla! Etwas ist schief gelaufen. Bitte versuchen Sie es später noch einmal. Wenn das Problem weiterhin besteht, melden Sie bitte ein Problem über die Schnellaktionen",
      "assistanceTask": "Aufgaben für des Assistenten",
      "itReportTitle": "Hier ist die Übersicht deiner Energie-Effizienz",
      "days": "Tage",
      "device": "Geräte",
      "sinceRestarted": "Seit Neustart",
      "deviceRestartRecommendation": "Sofortiger Neustart des Gerätes wird empfohlen",
      "deviceInCompliance": "Dein Gerät ist compliant",
      "energyConsumed": "Energiekonsum während der letzten sieben Tage",
      "coEmitted": "CO2 Ausstoß während der letzten sieben Tage",
      "diskClean": {
        "primaryBtn": "Ja, Laufwerk jetzt säubern",
        "secondaryBtn": "Nein, Laufwerk jetzt nicht säubern",
        "successMsg": "Dein Laufwerk wurde erfolgreich gesäubert",
        "failureMsg": "Dein Laufwerk konnte nicht gesäubert werden",
        "progressMsg": "Laufwerk wird gesäubert",
        "disclaimer": "Bitte beachte, dass wir deine persönlichen oder beruflichen Daten nicht anrühren werden. Während die Bereinigung im Hintergrund stattfindet, kannst du gerne weiterhin den Assistenten nutzen."
      },
      "chromeCache": {
        "primaryBtn": "Ja, leere den Chrome Cache",
        "secondaryBtn": "Nein, leere den Chrome Cache nicht",
        "successMsg": "Dein Chrome Cache wurde erfolgreich geleert",
        "failureMsg": "Dein Chrome Cache konnte nicht gesäubert werden",
        "progressMsg": "Chrome Cache wird geleert",
        "disclaimer": "Bitte beachte, dass wir deine persönlichen oder beruflichen Daten nicht anrühren werden. Während die Bereinigung im Hintergrund stattfindet, kannst du gerne weiterhin den Assistenten nutzen."
      },
      "empowerApp": {
        "primaryBtn": "Ja, Reparatur jetzt ausführen",
        "secondaryBtn": "Nein, Reparatur jetzt nicht ausführen",
        "successMsg": "Dein Empower Plugin wurde erfolgreich repariert",
        "failureMsg": "Dein Empower Plugin konnte nicht repariert werden",
        "progressMsg": "Automatische Reparatur wird ausgeführt",
        "disclaimer": "Das Empower Plugin wird jetzt neu installiert. Bitte schließe all deine M365 Apps, bevor du fortfährst."
      }
    }
  },
  "searchPage": {
    "searchPlaceholder": "Suchen...",
    "searchApps": "Suche hier...",
    "relatedApps": "Apps",
    "viewMoreApps": "weitere",
    "removeAppTooltip": "Vom personalisierten Dashboard entfernen",
    "addAppTooltip": "Zum personalisierten Dashboard hinzufügen"
  },
  "pwaText": {
    "header": "Installieren Sie diese App auf Ihrem Home-Bildschirm!",
    "button": "App installieren",
    "iphoneHeader": "Um diese App auf dem iPhone zu installieren",
    "inst1": "Tippen Sie in Safari auf die Schaltfläche <b>Teilen</b>.",
    "inst2": "Scrollen Sie nach unten und wählen Sie <b>\"Zum Home-Bildschirm hinzufügen\".</b>",
    "inst3": "Tippen Sie oben rechts auf <b>Hinzufügen</b>."
  },
  "filterPage": {
    "filterMenu": {
      "backBtn": "zurück zur vorherigen Seite",
      "loading": "Loading",
      "app": "Apps",
      "apps": "Apps",
      "m365": "SharePoint - OneDrive",
      "people": "Personen",
      "jql": "jql",
      "all": "Alle",
      "community": "Gemeinschaft",
      "pages": "Seiten",
      "users": "Benutzer",
      "noData": "Wir konnten keine Ergebnisse finden für",
      "noMoreData": "Zu Ihrer Anfrage wurden keine weiteren Ergebnisse gefunden!",
      "filterHeading": "Filter",
      "reset": "Filter zurücksetzen",
      "refineResults": "Ergebnisse verfeinern",
      "priority": "Priorität",
      "status": "Status",
      "card_languages": "Sprachen",
      "ecl_source_name": "Quelle",
      "standard_card_type": "Typ",
      "mimeType": "Typ",
      "space": "Bereiche",
      "gitlab": "Gitlab",
      "datasources": "Datenquellen",
      "lookingFor": "Ich suche nach",
      "timeRange": "Zeitbereich",
      "mygenius": "My Genius",
      "jira": "Jira",
      "confluence": "Confluence",
      "orderit": "my IT order",
      "aiassistant": "Assistance"
    },
    "searchResult": {
      "source": "Quelle",
      "business": "Geschäft",
      "mobile": "Mobil",
      "type": "Art",
      "duration": "Dauer",
      "category": "Kategorie",
      "deliveryTime": "Lieferzeit",
      "availableLanguages": "Verfügbare Sprachen",
      "status": "Status",
      "lastUpdate": "Letzte Aktualisierung",
      "lastUpdated": "Letzte Aktualisierung",
      "createdOn": "Erstellt am",
      "createdBy": "Erstellt von",
      "company": "Unternehmen",
      "department": "Abteilung",
      "job_title": "Berufsbezeichnung",
      "office_location": "Bürostandort",
      "email": "Email"
    },
    "timeRangeOptions": {
      "All": "Alle",
      "1 Day": "1 Tag",
      "7 Days": "7 Tage",
      "1 Month": "1 Monat",
      "6 Months": "6 Monate",
      "1 Year": "1 Jahr"
    },
    "dataSources": {
      "Confluence": "Confluence",
      "Gitlab": "Gitlab",
      "JIRA": "Jira",
      "MyGenius": "MyGenius",
      "ItOrder": "my IT order",
      "Sharepoint": "SharePoint",
      "Knowledge Base": "Knowledge Base",
      "powerpoint": "PowerPoint",
      "word": "Word",
      "excel": "Excel",
      "pdf": "PDF",
      "loop": "Loop",
      "other": "Andere"
    }
  },
  "snow": {
    "welcomeNote": "Nachfolgend finden Sie eine Übersicht über Ihre offenen Incident-Tickets, Ihre Anfragen und Ihre IT-Ressourcen.",
    "tabName": {
      "incidents": "Vorfälle",
      "orders": "Aufträge",
      "itResources": "IT-Ressourcen",
      "incidentsTable": {
        "noData": "Keine Daten verfügbar",
        "btnText": "Neue Störung eröffnen"
      },
      "orderTable": {
        "number": "Nummer",
        "catItem": "Cat Artikel",
        "approvalSet": "Genehmigungssatz",
        "state": "Zustand",
        "dueDate": "Fälligkeitsdatum",
        "createdOn": "Erstellt am",
        "noData": "Keine Daten verfügbar",
        "btnText": "Bestellen Sie IT-Dienstleistungen"
      },
      "itResourcesTable": {
        "modelCategory": "Modellkategorie",
        "model": "Modell",
        "computerName": "Computername",
        "serialNumber": "Seriennummer",
        "serviceValue1": "Servicewert1",
        "serviceValue2": "Servicewert2",
        "noData": "Keine Daten verfügbar",
        "btnText": "Öffnen Sie ein Ticket für IT-Ressourcen"
      }
    }
  },
  "moveTabAndTiles": {
    "tabNames": "Tab",
    "favourite": "Favoriten",
    "categorizeTiles": "Personalisiertes Dashboard",
    "enterTabName": "Reiternamen eigeben",
    "tabInputLabel": "Reitername",
    "addTabButton": "Reiter hinzufügen",
    "tabNameError": "Bitte gib einen eindeutigen Reiternamen ein, der mindestens 2 und höchstens 20 Zeichen lang ist",
    "personalizeTab": "Reiter personalisieren",
    "personalizeTiles": "Kacheln personalisieren",
    "addTab": "Neuen Reiter hinzufügen",
    "removeTabConfrim": "Bist du sicher, dass du den Reiter ",
    "with": " mit",
    "cardIn": "Kacheln löschen willst?",
    "actionUndone": "Diese Aktion kann nicht rückgängig gemacht werden.",
    "categorizeTilesTitle": "Reiter umbenennen, Reiter entfernen, Kacheln innerhalb des Reiters verschieben",
    "addTabTitle": "Neuen Reiter hinzufügen.",
    "organiseTilesTitle": "Kachelposition anpassen"
  },
  "dataTable": {
    "pagingData": "Objekte pro Seite",
    "itemString": "Objekte",
    "ofString": "von"
  },
  "tourData": {
    "userActions": {
      "back": "Zurück",
      "close": "Schließen",
      "last": "Beenden",
      "next": "Weiter",
      "open": "Dialog öffnen",
      "skip": "Überspringen",
      "start_tour": "Tour starten",
      "tour_info": "Möchtest du die Grundfunktionen in einer kurzen Guided Tour kennen lernen? Es sollte nicht länger als 1-2 Minuten dauern..."
    },
    "tile": {
      "step1": "Nutze me@eon, um deine typischen Arbeitsanwendungen, Prozesse, Informationen und Ressourcen zu finden, die für deine Organisation relevant sind (z. B. HR-Themen, Schulungen, Unternehmensdokumente, Richtlinien und vieles mehr).",
      "step2": "In der me@eon Benutzerdokumentation findest du weitere Informationen, Funktionen & Antworten auf häufig gestellte Fragen sowie hilfreiche Tipps und Tricks.",
      "step3": "Du kannst die bevorzugte Sprache jederzeit von hier aus ändern",
      "step4": "Du kannst dich abmelden, indem du hier klickst",
      "step5": "Nutze den me@eon Assistenten, um Fragen zu stellen sowie Unterstützung und hilfreiche Informationen zu erhalten- sowohl von Internen, als auch externen Quellen."
    },
    "tab": {
      "step1": "Du kannst einen neuen Reiter hinzufügen, indem Du hier klickst",
      "step2": "Klicke hier, um die Reihenfolge der Kacheln zu organisieren.",
      "step3": "Hier kannst du Reiter umbenennen und organisieren"
    }
  }
}