/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from "react";
import { languages } from "./LangInterface";
import { useTranslation } from "react-i18next";
import useSearchStore from "../../store/searchStore";
import useClickOutside from "../../util/useClickOutside";
import { useLanguage } from "@me8eon/language";
import { GenericDropdown } from "@me8eon/generic_dropdown";

const LanguageSwitch = () => {
    const { setPrefData } = useSearchStore();
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [_language, setLanguage] = useLanguage()

    const onClickOutside = () => {
        isOpen && setIsOpen(false);
    };

    useClickOutside("tileTour3", onClickOutside);

    const onLanguageChange = useCallback((code: string) => {
        localStorage.setItem("i18nextLng", code);
        const lang2 = code.slice(0, 2);
        setLanguage(lang2);
        i18n.changeLanguage(code);
        setPrefData(code);
        setIsOpen(false);
    }, []);

    return (
        <GenericDropdown
            label={t("switchLanguage")}
            icon="lang_select"
            rootId="tileTour3"
            options={languages.map((lang) => { return { name: lang.name, value: lang.code } })}
            maxWidth="200px"
            selectedOps={i18n.language}
            clickHandler={onLanguageChange}
        />
    );
};

export default LanguageSwitch;
