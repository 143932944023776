import {SourceInfo} from "../info";



export const peopleInfo: SourceInfo = {
    match: "people",
    dropDown: undefined,
    titleAndTarget: (result: any) => ({
        title: `someTitle ${JSON.stringify(result)}`,
        target: result?.target,
        source: undefined,
    }),
    makeDataObject: (c: any) => {
        return c.resource;
    },
};

