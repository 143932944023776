import {EnvironmentConfig} from"@me8eon/config";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {AdminButton} from "./adminButton";
import {AdminTable} from "./admin.table";

type SpaceDetails = {
    index: string
    space: string
    count: string
}

export function useSpaceDetails(): () => Promise<SpaceDetails[]> {
    return async () => {
        const url = `${EnvironmentConfig.elasticSearchBaseUrl}/unique-prod/_search`;
        const body = {
                "size": 0,
                "aggs": {
                    "latest_per_index": {
                        "terms": {
                            "field": "index.keyword",  // Use `.keyword` if `index` is a `text` field
                            "size": 10                 // Adjust this size based on the number of unique `index` values expected
                        },
                        "aggs": {
                            "latest_document": {
                                "top_hits": {
                                    "size": 1,
                                    "sort": [
                                        {
                                            "timestamp": {
                                                "order": "desc"
                                            }
                                        }
                                    ]
                                }
                            }
                        }
                    }
                }
            }
        ;
        const headers = {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${localStorage.getItem("dls-apikey")}`,
        };
        const response = await axios.post(url, body, {headers,});
        const buckets = response.data?.aggregations?.latest_per_index?.buckets;
        const sources = (buckets || []).map((b: any) => b.latest_document.hits.hits[0]._source);
        return sources.sort((a: any, b: any) => a.index.localeCompare(b.index));
    };
}

export function SpaceDetails() {
    const [details, setDetails] = useState<SpaceDetails[]>([]);
    const fetchDetails = useSpaceDetails();
    const [index, setIndex] = useState<string>('');
    const foundIndex = details.find(d => d.index === index);
    useEffect(() => {
        fetchDetails().then(d => {
            setDetails(d);
            setIndex(d?.[0].index || '');
        });
    }, []);
    const foundEntries = Object.entries(foundIndex || {});
    return <div>        {details.length > 0 && (
        <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap'}}>
            {details.map((d) => <AdminButton key={d.index} text={d.index} selected={d.index === index} onClick={() => setIndex(d.index)}/>)}
        </div>
    )}
        {foundIndex &&
            <AdminTable data={foundEntries} renderCell={{
                'Index': (kv: [string, string]) => kv[0],
                'Count': (kv: [string, string]) => kv[1]
            }} rightJustified={['Count']}/>}

    </div>;
}