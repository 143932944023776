import { AdminTable } from "./admin.table";

import React, { useState } from "react";
import { EnvironmentConfig } from "@me8eon/config";
import { useUserData } from "@me8eon/react_authentication";

export type UserDlsTableProps = {
    indicies: string[]
    details: any[]
}

export function UserDlsTable({ indicies, details }: UserDlsTableProps) {
    return <AdminTable data={indicies} renderCell={{
        "Index": (index: string) => index,
        "DLS": (index: string) => JSON.stringify(details.filter(x => JSON.stringify(x).includes(index))),
    }} />;
}

export function CheckUser() {
    const userData = useUserData();
    const [email, setEmail] = useState<string>(userData.email || "");
    const [result, setResult] = useState<any>({ "status": "No result" });

    function onClick() {
        fetch(`${EnvironmentConfig.apikeyUrl}/${email}?debug=true`).then(async res => {
            if (res.ok) return setResult(await res.json());
            setResult({ status: res.status, error: res.statusText });
        });
    }

    const { details, roleDescriptors } = result || {};
    const { indicies, admin, readOnly, public: pub } = details || {};
    const indexDetails = roleDescriptors?.search?.index?.[0]?.query?.bool?.should || {};
    return <div>
        <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
        <button onClick={onClick}>Check</button>

        <h1>Indicies with DLS</h1>
        <UserDlsTable indicies={indicies || []} details={indexDetails} />
        {result.admin && <><h1>Admin: {result.admin}</h1>
            <pre>{JSON.stringify(admin?.indicies)}</pre>
        </>}

        <h1>Public</h1>
        <p>These are &apos;spaces&apos; or &apos;projects&apos; within an index that are available to all</p>
        <pre>{JSON.stringify(pub, null, 2)}</pre>

        <h1>ReadOnly</h1>
        <p>These full indicies are available to all</p>
        <pre>{JSON.stringify(readOnly, null, 2)}</pre>

        <h1>Everything</h1>
        <pre>{JSON.stringify(result, null, 2)}</pre>
    </div>;
}