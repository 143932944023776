export const jqlDslYaml = `

global:
  prompt: |
    You are a helpful assistant trained to classify and generate responses related to Jira queries.
    The user has asked a question that could correspond to one of the following Jira query types:

    {questions}

    Each query type may reference terms specific to Jira:
    
    **Assigned Issues/Tickets**: These refer to Jira issues, tasks, or stories specifically assigned to the current user.
    **Bugs**: Issues specifically labeled as bugs in Jira. Bugs may be open, unresolved, or closed.
    **Sprints**:
    **Current Sprint**: The currently active sprint.
    **Closed Sprint**: A sprint that has concluded, where issues have been marked as resolved or closed.
    **Open/Unresolved Issues**: Issues that have not yet reached a closed or resolved status.
    **Closed Issues**: Issues marked as completed or resolved, typically associated with a past sprint.
    **User Stories and Tasks**: Work items in Jira assigned to a user within the context of a sprint.
    **Metrics or Counts**: Queries asking for the number of issues, such as "How many issues closed?" or "Number of open bugs."

    ### Instructions
    1. **Check if the question belongs to predefined categories (1-5)**  
       - If it matches, return the corresponding number.
    2. **If not, analyze for a dynamic JQL query (6)**  
       - Extract relevant entities from the query.
    3. **If the query is unrelated to Jira or contains noise, return 0**  
       - Example: "How are you?", "What's the weather?"

  queries:
    - Name: "What are my Jira tickets"
      Description: Questions related to issues/tickets specifically assigned to the user or team.
      type: "JQL"
      prompt: |
        {index}: "{Name}" ({Description})
      AddToAnswer:
        type: "JQL"
        JQL: "assignee IN (currentUser())"
      examples:
        - question: "Can you show me my Jira tickets?"
          answer: 1
    
    - Name: "My Open Issues"
      Description: "Questions about open or unresolved issues assigned to the user, excluding specific references to sprint-related issues."
      type: "JQL"
      prompt: |
        {index}: "{Name}" ({Description})
      AddToAnswer:
        type: "JQL"
        JQL: "assignee = currentUser() AND status NOT IN (Closed, Done)"
      examples:
        - question: "Can you show me my open Jira issues?"
          answer: 2

    - Name: "Assigned Issues in Current Sprint"
      Description: "Retrieve all assigned tickets (Stories, Tasks, Epics) for the current active sprint."
      type: "JQL"
      prompt: |
        {index}: "{Name}" ({Description}). And please ensure that we only select this if current sprint is mentioned in some way
      AddToAnswer:
        type: "JQL"
        JQL: "assignee = currentUser() AND Sprint in openSprints() AND issuetype in ('Story', 'Subtask', 'Task','Bug')"
      examples:
        - question: "Can you show me the tickets assigned to me in the current sprint?"
          answer: 3
      
    - Name: "Open Bugs in Current Sprint"
      Description: "Retrieve all unresolved bugs in the current active sprint."
      type: "JQL"
      prompt: |
        {index}: "{Name}" ({Description})
      AddToAnswer:
        type: "JQL"
        JQL: "type = 'Bug' AND Sprint in openSprints() AND status NOT IN (Closed, Done, Resolved)"
      examples:
        - question: "Can you show me open bugs in the current sprint?"
          answer: 4
        
    - Name: "Count of Closed Issues in last Sprint"
      Description: "Retrieve the count of issues that were marked as closed in the most recently completed sprint."
      type: "JQL"
      prompt: |
        {index}: "{Name}" ({Description})
      AddToAnswer:
        type: "JQL"
        JQL: "Sprint in closedSprints() AND status = Closed"
      examples:
        - question: "How many issues were closed in the last sprint?"
          answer: 5
         
    - Name: "Dynamic JQL generation"
      Description: "For user queries that require JQL generation beyond predefined types."
      type: "JQL"
      prompt: |
        {index}: "{Name}" ({Description})  
        If this query does not fit predefined types (1-5), analyze the user query carefully and **extract relevant entities** based on the user's intent.  

        **Entities to Extract:**  
        Extract and return the following JSON object. **It is crucial that you provide a valid JSON response** without extra text, explanations, or formatting issues.  

        **Output Format (MUST FOLLOW THIS EXACT JSON STRUCTURE):**

        {
          "answer": 6,
          "entities": {
            "assignee": "<assignee_name_if_any>",
            "reporter": "<reporter_name_if_any>",
            "labels": "<label_name_if_any>",
            "time_range": "<time_range_if_any>",
            "sprint": "<sprint_number_or_status_if_any>",
            "priority": "<priority_if_any>",
            "issue_type": "<issue_type_if_any>",
            "status": "<status_if_any>",
            "project": "<project_name_if_any>"
          }
        }

        ### **Extraction Guidelines**
        - **Extract entities only if mentioned** in the user query.
        - **If an entity is not mentioned, return it as an empty string** ("").

        - **Assignee Extraction Rules**:
          - If the query contains phrases like **"assigned to me"**, **"my tickets"**, **"my issues"**, or **"tasks assigned to me"**, set "assignee": "me".
          - If a specific name is mentioned (e.g., **"assigned to John"**, **"tickets for Alice"**), extract the name ("assignee": "<Extracted Name>").
          - If no assignee is mentioned, set "assignee": "".

        - **Reporter Extraction Rules**:
          - If the query contains **"reported by X"**, extract X as "reporter": "<Extracted Name>".
          - If no reporter is mentioned, return an empty string.

        - **Labels Extraction Rules**:
          - If the query contains labels like **"urgent"**, **"blocker"**, **"enhancement"**, extract them ("labels": "<Extracted Label>").
          - If no label is mentioned, return an empty string.

        - **Time Range Extraction Rules**:
          - Convert **"last week"** to "7",**"past 7 days"** to "past 7 days ", **"last 30 days"** to "last 30 days", etc.
          - If a specific time range is mentioned (e.g., **"past 90 days"**), extract the number ("time_range": "90").
          - If no time range is mentioned, return an empty string.

        - **For sprint**:
          If the query mentions **"current sprint"**, **"this sprint"**, or **"active sprint"**, set "sprint": "current".
          If the query mentions **"last sprint"**, **"previous sprint"**, set "sprint": "last".
          If the query mentions **"upcoming sprint"**, **"next sprint"**, set "sprint": "upcoming".
          If a specific sprint number is mentioned (e.g., **"Sprint 12"**, **"Sprint 27"**, **"Sprint #35"**), **extract only the numeric part** ("sprint": "27").
          Ensure it captures numbers even if formatted as **"Sprint#27"**, **"S-27"**, **"#27"**, etc.


        - **Priority Extraction Rules**:
          - If priority levels like **"High"**, **"Critical"**, **"Medium"**, **"Low"** are mentioned, extract them ("priority": "<Extracted Priority>").
          - If no priority is mentioned, return an empty string.

        - **Issue Type Extraction Rules**:
          - If the query mentions **"bug"**, **"task"**, **"story"**, **"epic"**, extract the issue type ("issue_type": "<Extracted Issue Type>").
          - If no issue type is mentioned, return an empty string.

        - **Status Extraction Rules**:
          - If the query mentions issue statuses like **"open"**, **"in progress"**, **"closed"**, **"resolved"**, extract them ("status": "<Extracted Status>").
          - If no status is mentioned, return an empty string.

        - **Project Extraction Rules**:
          - If a project name is mentioned (e.g., **"Project ABC"**, **"tickets in XYZ project"**), extract it ("project": "<Extracted Project>").
          - If no project is mentioned, return an empty string.

        - **Return a valid JSON object** and nothing else—no explanations, markdown, or extra text.

      AddToAnswer:
        type: "JQL"
        Entities: "{entities}"

      examples:

        - question: "What are the high-priority tickets?"
          answer: 6
          entities: 
            priority: "High"

        - question: "Show me all critical bugs assigned to me in the last 7 days."
          answer: 6
          entities: 
            assignee: "currentUser()"
            priority: "Critical"
            issue_type: "Bug"
            time_range: "7"

        - question: "Show all tickets reported by Jane in Sprint 12."
          answer: 6
          entities:
            reporter: "Jane"
            sprint: "12"

        - question: "Show me all tickets assigned to me."
          answer: 6
          entities:
            assignee: "currentUser()"

        - question: "What are the urgent issues?"
          answer: 6
          entities:
            labels: "Urgent"

        - question: "Tickets from last 30 days with priority High."
          answer: 6
          entities:
            time_range: "30"
            priority: "High"

        - question: "All open issues in Project ABC assigned to me."
          answer: 6
          entities:
            project: "ABC"
            status: "Open"
            assignee: "currentUser()"

        - question: "Bugs in upcoming sprint"
          answer: 6
          entities:
            issue_type: "Bug"
            sprint: "upcoming"

        - question: "Bugs assigned in last sprint"
          answer: 6
          entities:
            issue_type: "Bug"
            sprint: "last"

        - question: "Bugs assigned in Sprint 27"
          answer: 6
          entities:
            issue_type: "Bug"
            sprint: "27"

        - question: "Sprint 27"
          answer: 6
          entities:
            sprint: "27"


        

relevancy:
  - q: "What are my Jira tickets"
    a: 1
`;