export interface Language {
    name: string;
    code: string;
}

export const languages: Language[] = [
    { name: "English", code: "en" },
    { name: "Deutsch", code: "de" },
    { name: "Svenska", code: "sv" },
];
