import { NameAnd } from "@me8eon/record_utils";
import React, { ReactNode } from "react";
import { DataHasType } from "@me8eon/data";
import { makeContextFor } from "@me8eon/context";

export const debugDataWidgets = "dataWidget";


export type DataWidgetLayoutProps = {
    rootId?: string;
    children: ReactNode;
}
export type DataWidgetLayout = (props: DataWidgetLayoutProps) => ReactNode


export type DataWidgetProps<Data extends DataHasType> = {
    rootId: string;
    data: Data[]
    widgetName: string
}
export type DataWidgetDisplayer = <Data extends DataHasType>(props: DataWidgetProps<Data>) => ReactNode;
export type DataWidget = {
    plugin: "datawidget"
    name: string
    display: DataWidgetDisplayer
}

export type DataWidgets = NameAnd<DataWidget>

export const { Provider: DataWidgetProvider, use: useDataWidgets } = makeContextFor<DataWidgets, "dataWidget">("dataWidget");

export function fromType(index: string) {
    return (d: DataHasType) => d.type === index;
}

export function fromIndex(index: string) {
    return (d: any) => d.index === index;
}

export function fromIndicies(indicies: string[]) {
    return (d: any) => indicies.includes(d.index);
}

export function notFromindices(indicies: string[]) {
    return (d: any) => !indicies.includes(d.index);
}
