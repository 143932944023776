import { FilterSetters } from "./elsearch.domain";
import { DataSourceFilter } from "../../interfaces/ElasticSearchInterface";
import { useTranslation } from "react-i18next";
import { TabFilterStrategy } from "./TabFilterStrategy";
import { Icon } from "@me8eon/icons";
import "./DataSourceFilters.scss";
import { iconMap } from "../../domain/info/m365Info";
import React from "react";

const DataSourceFilters = ({
                               tabFilterStrategy,
                               setters,
                               dataSourceFilters,
                               searchIndex = [],
                               m365DocTypes,
                           }: {
    tabFilterStrategy?: TabFilterStrategy
    setters: FilterSetters;
    dataSourceFilters: DataSourceFilter[];
    searchIndex?: string[];
    m365DocTypes: string[]
}) => {
    const {
        setIsLoading,
        setResultsToDisplay,
        setSearchIndex,
        setAdditionalFilters,
        setM365DocTypes,
    } = setters;
    const { t } = useTranslation();
    const isSingleSelect = true;

    function onChange(selectedData: any[]) {
        setIsLoading(true);
        setResultsToDisplay([]);
        setAdditionalFilters({});
        let searchIndexs: string[] = [];
        selectedData.forEach((val: any) => {
            const newArry = [val];
            searchIndexs = [...searchIndexs, ...newArry];
        });
        setSearchIndex(searchIndexs);
    }

    const handleCheckboxChange = (option: string) => {
        onChange([option]);
        setIsLoading(true);
        setResultsToDisplay([]);
        setM365DocTypes([option]);
    };

    return (
        <div className="filter-list-wrapper">
            {dataSourceFilters.map((opt: DataSourceFilter, index) => {
                const { key, doc_count } = opt;

                const isChecked = m365DocTypes.some(
                    (selectedOption) => selectedOption === key,
                );
                return (
                    <label
                        htmlFor={key}
                        key={`${index.toString()}`}
                        className={`filter-opt ${isChecked ? "filter-opt--selected" : ""}`}
                    >
                        <div className="current-opt-left">
                            <input
                                id={key}
                                type={isSingleSelect ? "radio" : "checkbox"}
                                checked={isChecked}
                                name="advance-page-filters"
                                onChange={() => handleCheckboxChange(key)}
                            />
                            {iconMap[key] && <Icon name={iconMap[key]} style={{ height: "auto", width: "auto" }} />}
                            <span className="">{t(`filterPage.dataSources.${key}`)}</span>
                        </div>
                    </label>
                );
            })}
        </div>
    );
};

export default DataSourceFilters;
