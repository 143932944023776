import { Message } from "../../components/ai/ai.types";
import { AxiosStatic } from "axios";

export type JqlOpenAiClient = (prompt: Message[]) => Promise<Message[]>

export type OpenAiConfigForJql = {
    axios: AxiosStatic,
    baseURL?: string
    jwtTokenFn: () => string | undefined,
    //Any customisation for the call. See https://platform.openai.com/docs/api-reference/chat/create
    customisation: any
    debug?: boolean
}
export const openAiClientForJql = (config: OpenAiConfigForJql): JqlOpenAiClient => {
    const { axios, baseURL, jwtTokenFn, customisation, debug } = config;
    if (!baseURL) throw new Error("baseURL is required for open ai. Have you set up the .env file?");
    return async (messages: Message[]): Promise<Message[]> => {
        if (debug) console.log("openAiMessagesClient", messages, config);
        const jwtToken = jwtTokenFn();
        const axiosInstance = axios.create({
            baseURL,
            headers: {
                "x-proxy": "azureai",
                Authorization: `Bearer ${jwtToken}`,
                "Content-Type": "application/json",
            },
        });
        try {
            const response = await axiosInstance.post(`openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-02-01`, {
                messages,
                ...customisation,
            });
            return response.data.choices.map((x: any) => x.message);
        } catch (error) {
            console.error("Error calling openai:", messages, error);
            throw error;
        }
    };
};
