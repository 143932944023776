import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { msalInstance } from "./msalConfig";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { EnvironmentConfig } from "@me8eon/config";
import { getParameterByName } from "./util/common-util";
import { useOldUserData } from "@me8eon/userdata";


interface MsalContextType {
    account: AccountInfo | null | undefined;
    login: () => Promise<void>;
    logout: () => void;
    acquireToken: (
        scopes: AccountInfo,
        force: boolean,
    ) => Promise<AuthenticationResult | null>;
    ssoLogin: (loginHint: string) => Promise<AuthenticationResult | null>;
    isExpired: boolean;
    isLimitedAccess: boolean;
    orgName: string;
    kId: string;
}

const MsalContext = createContext<MsalContextType | undefined>(undefined);

interface MsalProviderProps {
    children: ReactNode;
}

export const MsalProvider: React.FC<MsalProviderProps> = ({ children }) => {
    const [account, setAccount] = useState<AccountInfo | null | undefined>(
        null,
    );
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const [isLimitedAccess, setIsLimitedAccess] = useState(false);
    const [orgName, setOrgName] = useState("");
    const [kId, setKId] = useState("");
    const awaitedLogin = useRef<boolean>(false);
    const [userData, setUserData] = useOldUserData()
    useEffect(() => {
        const initializeMsal = async () => {
            await msalInstance.initialize();
            const currentAccounts = msalInstance.getAllAccounts();
            if (currentAccounts.length > 0) {
                setAccount(currentAccounts[0]);
            } else {
                setAccount(undefined);
            }
        };
        initializeMsal().finally(() =>
            msalInstance.handleRedirectPromise().then((response) => {
                console.log("handleRedirectPromise response", response);
                if (response) setAccount(response.account);
            }),
        );
    }, []);

    useEffect(() => {
        if (account) {
            const accessArray = EnvironmentConfig.AiAccess as string[];
            const roleArray = account.idTokenClaims?.roles || [];
            const isPresent = accessArray.some((r) => roleArray.includes(r));
            setIsLimitedAccess(!isPresent);
            const userMail = account.idTokenClaims?.email || account.username;
            const mailArray = account.username.split("@");
            setKId(mailArray.length > 0 ? mailArray[0] : "");
            console.log("bayernwerk test account", account);
            console.log("bayernwerk email", userMail);
            console.log("bayernwerk username", account.username);

            // temp code to set org name
            if (getParameterByName("org")?.match("bayern")) {
                setUserData({ org: "bayernwerk" });
                setOrgName("bayernwerk");
            } else if ((userMail && typeof userMail === "string")) {
                const orgFromEmail = userMail.split("@")[1].split(".")[0];
                // const orgFromEmail = "bayernwerk";
                setOrgName(orgFromEmail);
                setUserData({ org: orgFromEmail });
            } else {
                setUserData({ org: "eon" });
            }

            const checkAuth = () => {
                const { exp } = account.idTokenClaims || {};
                const expiryTime = exp ? exp * 1000 : 0;
                const currentTime = new Date().getTime();
                const isTokenExpired = expiryTime <= currentTime;
                setIsExpired(isTokenExpired);
                if (isTokenExpired) {
                    acquireTokenSilent(account, true);
                }
            };
            checkAuth();
            const interval = setInterval(checkAuth, 30 * 1000);
            return () => clearInterval(interval);
        }
    }, [account]);

    const acquireTokenSilent = async (userAccount: AccountInfo, force: boolean) => {
        try {
            const response = await msalInstance.acquireTokenSilent({
                account: userAccount,
                scopes: ["User.Read"],
                forceRefresh: force,
            });
            if (userAccount.idToken !== response.idToken) {
                console.log("Token acquired silently:", response.idToken);
                setAccount(response.account);
                setIsExpired(false);
            }
            return response;
        } catch (error) {
            console.error("Silent token acquisition failed:", error);
            if ((error as any).name === "InteractionRequiredAuthError") {
                // Fallback to interactive login if silent acquisition fails
                if (!awaitedLogin.current) {
                    awaitedLogin.current = true;
                    await login();
                }
            }
            return null;
        }
    };

    const ssoLogin = async (loginHint: string) => {
        try {
            if (loginHint) {
                const silentRequest = {
                    scopes: ["User.Read"],
                    loginHint,
                };
                const response = await msalInstance.ssoSilent(silentRequest);
                setAccount(response.account);
                setIsExpired(false);
                return response;
            }
            return null;
        } catch (error) {
            console.error("token acquisition failed:", error);
            if ((error as any).name === "InteractionRequiredAuthError") {
                // Fallback to interactive login if silent acquisition fails
                if (!awaitedLogin.current) {
                    awaitedLogin.current = true;
                    await login();
                }
            }
            return null;
        }
    };

    const login = async () => {
        const request = {
            scopes: ["user.read"],
        };
        try {
            await msalInstance.loginRedirect(request);
        } catch (err) {
            //We need to do this because if there is an outstanding login request, we can't do another one until we clear cache
            console.error("Login redirect failed:", err);
            await msalInstance.clearCache();
            await msalInstance.loginRedirect(request);
        } finally {
            awaitedLogin.current = false;
        }
    };

    const logout = async () => {
        await msalInstance.logoutPopup();
        setAccount(null);
        setIsExpired(false);
    };

    return (
        <MsalContext.Provider
            value={{
                account,
                login,
                logout,
                acquireToken: acquireTokenSilent,
                ssoLogin: ssoLogin,
                isExpired,
                isLimitedAccess,
                orgName,
                kId,
            }}
        >
            {children}
        </MsalContext.Provider>
    );
};

export const useAuth = (): MsalContextType => {
    const context = useContext(MsalContext);
    if (!context) {
        throw new Error("useMsal must be used within an MsalProvider");
    }
    return context;
};
