import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { EonUiPreloaderSquares } from "@eon-ui/eon-ui-components-react";
import { SearchFnProvider } from "./search/search";
import { m365OrJiraOrAllSearch } from "./search/all.searchs";
import { defaultSearchContext } from "./search/searchContext";
import axios from "axios";
import { envName } from "./util/common-util";
import { Diagnostics } from "./components/diagnostics/diagnostics";
import { AiRagProvider } from "./components/ai/ai.rag.client";
import { defaultGraphApiTokenFn } from "./search/graph.api.search";
import { msalInstance } from "./msalConfig";
import { ExplainProvider } from "./components/diagnostics/explain.context";
import { WindowProvider } from "./context/WindowContext";
import { defaultEmailToHash, defaultUniqueUsersFn, UniqueUsersFn, UniqueUsersProvider } from "./components/unique-users/unique.users";
import { defaultJqlConfig, JqlAiClientConfig, makeJqlAiClient } from "./jira/jql.client.agent";
import { EnvironmentConfig } from "@me8eon/config";
import { JqlOpenAiClient, openAiClientForJql, OpenAiConfigForJql } from "./jira/ai/ai.client.for.jql";
import { jqlDslYaml } from "./jira/jql.prompt.yaml";
import { defaultJiraTokenFn } from "./jira/jira.token.management";
import { defaultOauth2Config } from "./jira/jira.consent.popup";
import { aiRagDebug, AiSourceDataMemoryProvider } from "./components/ai/clients/ai.rag.client";
import { AiAssistantProvider, emptyAiAssistScaffolding } from "@me8eon/ai_assist_data_widget";
import { themeFF, ThemeProvider } from "@me8eon/themes";
import { allThemes, themeFeatureFlag } from "@me8eon/all_themes";
import { FeatureFlagsStateProvider, newArchitectureFeatureFlag, newArchitectureFF } from "@me8eon/featureflags";
import { dataAsJsonFF, DataPluginProvider } from "@me8eon/data";
import { debugSearchResultsName } from "@me8eon/data_views_search_results";
import { EmptyFilterScaffolding, filterDebugName, FilterProvider, FilterScaffoldingProvider, searchDebugName } from "@me8eon/filter_data_widget";
import { WindowUrlProvider } from "@me8eon/routing";

import { LanguageProvider } from "@me8eon/language";
import { allTranslations } from "@me8eon/translate_eon";
import { TranslationUsedAndNotFoundProvider } from "@me8eon/translation";
import { SimpleTranslationProvider } from "@me8eon/simple_translation";
import { DevModeStateForSearchProvider } from "@me8eon/devmode";
import { createMockDebugLog, DebugStateProvider, makeDebugLog } from "@me8eon/debug";
import { DataViewNavBarLayoutProvider, DataViewsProvider, SelectedDataViewProvider, SimpleDataNavBarViewLayout } from "@me8eon/data_views";
import { allDataViews } from "@me8eon/all_data_views";
import { SelectableButtonProvider, SimpleSelectableButton } from "@me8eon/selection_button";
import { DataWidgetProvider } from "@me8eon/data_widget";
import { allDataWidgets } from "@me8eon/all_data_widgets";
import { allDataPlugins } from "@me8eon/all_data";
import { AttributeEditorProvider } from "@me8eon/editors";
import { allEditors } from "@me8eon/all_editors";
import { AttributeValueProvider, SimpleAttributeValueLayout, SimpleDataLayout } from "@me8eon/renderers";
import { allRenderers } from "@me8eon/all_renderers";
import { defaultFeedbackFn, DisplayFeedbackComponentProvider, FeedbackProvider } from "@me8eon/feedback";
import { Feedback } from "./components/feedback/feedback.component";
import { jqlDebugName, JqlFeatureFlag, jqlFeatureFlagName } from "./hooks/useJqlFlag";
import { OrderFeatureFlag, orderFeatureFlagName } from "@me8eon/order_it_data_plugin";
import { Authenticate, authenticateDebugName, AuthenticationProvider, JwtTokenProvider, LoginConfig, UserDataGetterProvider, UserDataProvider } from "@me8eon/react_authentication";
import { Configuration } from "@azure/msal-browser";
import { loginUsingMsal, msalJwtToken, msalUserDataGetter } from "@me8eon/msal_authentication";

export const aiConfig: OpenAiConfigForJql = {
    axios,
    jwtTokenFn: () => msalInstance.getAllAccounts()[0]?.idToken,
    baseURL: EnvironmentConfig.gatewayURL + "/proxy/",
    customisation: {},
    debug: false,
};

const ai: JqlOpenAiClient = openAiClientForJql(aiConfig);
const jqlConfig: JqlAiClientConfig<any> = defaultJqlConfig(jqlDslYaml, ai, defaultJiraTokenFn(defaultOauth2Config), true);
export let jqlAiClient = makeJqlAiClient(jqlConfig);


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

//it would be great to get this from the Environmental config
//and also setup the right indicies

export const searchContext = defaultSearchContext(axios);
const diagnostics = window.location.href.includes("diagnostics");

const graphApiTokenFn = defaultGraphApiTokenFn(
    [`https://graph.microsoft.com/Sites.Read.All`],
    msalInstance,
);

const searchFn = m365OrJiraOrAllSearch(
    searchContext,
    graphApiTokenFn,
    // defaultJiraTokenFn([`https://jira.eon.com/.default`], msalInstance)
    // defaultJiraTokenFn([`https://jira.eon.com/.default`], msalInstance),
    jqlAiClient,
);
const uniqueUsersFn: UniqueUsersFn = defaultUniqueUsersFn(axios, "unique-users", () => new Date(), defaultEmailToHash);

const featureFlags = {
    [dataAsJsonFF]: { value: false, description: "show data as json" },
    [jqlFeatureFlagName]: JqlFeatureFlag,
    [newArchitectureFF]: newArchitectureFeatureFlag,
    [themeFF]: themeFeatureFlag,
    [orderFeatureFlagName]: OrderFeatureFlag
};

const debugState = {
    [aiRagDebug]: false,
    [authenticateDebugName]: true,
    [debugSearchResultsName]: false,
    [filterDebugName]: false,
    [jqlDebugName]: false,
    [searchDebugName]: false,
};

export const exampleMsalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_MSAL_CLIENT_ID ?? "ec963ff8-b8c7-411e-80b1-9473d0390b3b",
        authority: `https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a`,
        redirectUri: "/tile",
        postLogoutRedirectUri: "/",
    },
};
const login: LoginConfig = loginUsingMsal({ msal: msalInstance });
const userDataGetter = msalUserDataGetter(msalInstance);
msalInstance.initialize({}).then(() => {
    const hackedInGetJwtToken = async () => {
        await login.login(makeDebugLog(debugState, "login"));
        return msalJwtToken(msalInstance)();
    };
    const { featureFlagEnabled } = userDataGetter();
    root.render(
        <React.StrictMode>
            <DisplayFeedbackComponentProvider displayFeedbackComponent={Feedback}>
                <WindowUrlProvider>
                    <FilterScaffoldingProvider>
                        <FilterProvider filter={EmptyFilterScaffolding}>
                            <AiAssistantProvider aiAssist={emptyAiAssistScaffolding}>
                                <DebugStateProvider debugState={debugState}>
                                    <JwtTokenProvider jwtToken={hackedInGetJwtToken}>
                                        {/*<OldUserDataProvider userData={{ org: "eon" }}>*/}
                                        {/*    <MsalProvider>*/}
                                        <UserDataGetterProvider userData={userDataGetter}>
                                            <UserDataProvider>
                                                <AuthenticationProvider loginConfig={login}>
                                                    <LanguageProvider language={localStorage.getItem("i18nextLng")?.slice(0, 2) || Object.keys(allTranslations)[0]}>
                                                        <TranslationUsedAndNotFoundProvider usedAndNotFound={{ used: new Set(), notFound: new Set(), errors: new Set() }}>
                                                            <SimpleTranslationProvider languageLookup={allTranslations}>
                                                                <DevModeStateForSearchProvider devModeState={{ selected: "" }}>
                                                                    <SelectedDataViewProvider selectedDataView={Object.keys(allDataViews(featureFlags, featureFlagEnabled))[0]}>
                                                                        <SelectableButtonProvider selectableButton={SimpleSelectableButton}>
                                                                            <DataViewNavBarLayoutProvider dataViewLayout={SimpleDataNavBarViewLayout}>
                                                                                <FeatureFlagsStateProvider featureFlags={featureFlags}>
                                                                                    <ThemeProvider themes={allThemes}>
                                                                                        <DataViewsProvider dataViews={allDataViews}>
                                                                                            <DataWidgetProvider dataWidget={allDataWidgets}>
                                                                                                <DataPluginProvider dataPlugins={allDataPlugins}>
                                                                                                    <AttributeEditorProvider editors={allEditors} AttributeEditorLayout={SimpleAttributeValueLayout} DataLayout={SimpleDataLayout}>
                                                                                                        <AttributeValueProvider renderers={allRenderers} AttributeValueLayout={SimpleAttributeValueLayout} DataLayout={SimpleDataLayout}>
                                                                                                            <AiSourceDataMemoryProvider>
                                                                                                                <WindowProvider>
                                                                                                                    <UniqueUsersProvider uniqueUsers={uniqueUsersFn}>
                                                                                                                        <ExplainProvider>
                                                                                                                            <FeedbackProvider feedbackFn={defaultFeedbackFn(EnvironmentConfig.elasticSearchBaseUrl, envName, axios, "feedback")}>
                                                                                                                                <SearchFnProvider searchFn={searchFn}>
                                                                                                                                    <AiRagProvider>
                                                                                                                                        <Authenticate LoadingDisplay={() => <div />}>
                                                                                                                                            <Suspense
                                                                                                                                                fallback={
                                                                                                                                                    <EonUiPreloaderSquares
                                                                                                                                                        className="pre-loader-gif"
                                                                                                                                                    ></EonUiPreloaderSquares>
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                <App />
                                                                                                                                                {diagnostics && <Diagnostics />}
                                                                                                                                            </Suspense>
                                                                                                                                        </Authenticate>
                                                                                                                                    </AiRagProvider>
                                                                                                                                </SearchFnProvider>
                                                                                                                            </FeedbackProvider>
                                                                                                                        </ExplainProvider>
                                                                                                                    </UniqueUsersProvider>
                                                                                                                </WindowProvider>
                                                                                                            </AiSourceDataMemoryProvider>
                                                                                                        </AttributeValueProvider>
                                                                                                    </AttributeEditorProvider>
                                                                                                </DataPluginProvider>
                                                                                            </DataWidgetProvider>
                                                                                        </DataViewsProvider>
                                                                                    </ThemeProvider>
                                                                                </FeatureFlagsStateProvider>
                                                                            </DataViewNavBarLayoutProvider>
                                                                        </SelectableButtonProvider>
                                                                    </SelectedDataViewProvider>
                                                                </DevModeStateForSearchProvider>
                                                            </SimpleTranslationProvider>
                                                        </TranslationUsedAndNotFoundProvider>
                                                    </LanguageProvider>
                                                </AuthenticationProvider>
                                            </UserDataProvider>
                                        </UserDataGetterProvider>
                                    </JwtTokenProvider>
                                    {/*</MsalProvider>*/}
                                    {/*</OldUserDataProvider>*/}
                                </DebugStateProvider>

                            </AiAssistantProvider>
                        </FilterProvider>
                    </FilterScaffoldingProvider>
                </WindowUrlProvider>
            </DisplayFeedbackComponentProvider>
        </React.StrictMode>,
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
