import {QueryDefn} from "./jql.ai.types";
import {simpleTemplate} from "../../util/simple.template";
import {EnvironmentConfig} from"@me8eon/config";
import { translateKey } from "../../util/translate";


export type JqlQueryExecutor = (query: QueryDefn, context: JqlContext) => Promise<{data: any[], jql: string, totalCount: number, error: boolean, errorMsg: string}>

export type JqlContext = {
    KID: string
}

export function jqlQueryExecutor(tokenFn: () => Promise<string>, debug: boolean): JqlQueryExecutor {
    return async function (query: QueryDefn, context: JqlContext) {
        let jql: string| undefined = "";
        try {
            if (query.type !== 'JQL') throw Error(`Expected JQL query, got ${query.type}`);
            const rawJql = (query.AddToAnswer as any).JQL;           
            jql = simpleTemplate(rawJql, context);
            const token = await tokenFn();
            const headers: Record<string, string> = {
                'Authorization': `Bearer ${token}`,
                'x-proxy': EnvironmentConfig.jqlProxy
            };
            const url = `${EnvironmentConfig.gatewayURL}/proxy/rest/api/2/search?jql=${jql}`;
            const res = await fetch(url, {headers});
            const status = res.status;
            if (debug) console.log(url, `JQL status: ${status}`);
            const resp = await res.json();
            return {
                data: resp.issues ?? [],
                jql: jql ?? "",
                totalCount: resp.total ?? 0,
                error: false,
                errorMsg: ""
            };
        } catch (e: any) {
            console.error(e);
            return {
                data: [],
                jql: jql ?? "",
                totalCount: 0,
                error: true,
                errorMsg: translateKey('ticketErrorMsg', 'Something went wrong', 'chatBot.')
            };
        }
    };
}
