import { Env } from "./ConfigInterface";

export type ApprovalConfig = {
    myApprovalOAuthUrl: string;
    myApprovalOAuthRedirectUrl: string;
    myApprovalOAuthScope: string;
    myApprovalOAuthTokenUrl: string;
    myApprovalOAuthClientId: string;
}

const myApprovalConfigs: Record<Env, ApprovalConfig> = {
    local: {
        myApprovalOAuthUrl:
            "https://atcgadkea.accounts.ondemand.com/oauth2/authorize",
        myApprovalOAuthRedirectUrl: "http://localhost:8080/authorize/sapias",
        myApprovalOAuthScope: "openid email profile",
        myApprovalOAuthTokenUrl:
            "https://atcgadkea.accounts.ondemand.com/oauth2/token",
        myApprovalOAuthClientId: "",
    },
    dev: {
        myApprovalOAuthUrl:
            "https://atcgadkea.accounts.ondemand.com/oauth2/authorize",
        myApprovalOAuthRedirectUrl:
            "https://dev-me.eon.com/authorize/sapias",
        myApprovalOAuthScope: "openid email profile",
        myApprovalOAuthTokenUrl:
            "https://atcgadkea.accounts.ondemand.com/oauth2/token",
        myApprovalOAuthClientId:
            process.env.REACT_APP_SAP_OAUTH_CLIENT_ID || "",
    },
    qa: {
        myApprovalOAuthUrl: "",
        myApprovalOAuthRedirectUrl:
            "https://qa-me.eon.com/authorize/sapias",
        myApprovalOAuthScope: "openid email profile",
        myApprovalOAuthTokenUrl: "",
        myApprovalOAuthClientId:
            process.env.REACT_APP_SAP_OAUTH_CLIENT_ID || "",
    },
    pp: {
        myApprovalOAuthUrl: "",
        myApprovalOAuthRedirectUrl: "https://pp-me.eon.com/authorize/sapias",
        myApprovalOAuthScope: "openid email profile",
        myApprovalOAuthTokenUrl: "",
        myApprovalOAuthClientId:
            process.env.REACT_APP_SAP_OAUTH_CLIENT_ID || "",
    },
    prod: {
        myApprovalOAuthUrl: "",
        myApprovalOAuthRedirectUrl: "https://me.eon.com/authorize/sapias",
        myApprovalOAuthScope: "openid email profile",
        myApprovalOAuthTokenUrl: "",
        myApprovalOAuthClientId:
            process.env.REACT_APP_SAP_OAUTH_CLIENT_ID || "",
    },
};

export default myApprovalConfigs;
