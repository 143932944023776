import React, { useState } from "react";
// @ts-ignore
import AiIcon  from "../../../assets/image/ai-icon32.svg";
import { MessageWithActionAndData } from "../AiAssist.context";
import TypingEffect from "../utils/typing-effect/TypingEffect";
import AiSystemActionTrigger from "./aiSystemActionTrigger";


const ragComponent: React.FC<MessageWithActionAndData> = ({
    content,
    isActionTrigger,
}: MessageWithActionAndData) => {
    const [hideDataToggle, setHideDataToggle] = useState(isActionTrigger);

    return (
        <div className="bot-header">
            <div className="eon-logo">
                <AiIcon />
            </div>
            {hideDataToggle ? (
                <AiSystemActionTrigger setHideDataToggle={setHideDataToggle} />
            ) : (
                <div className="bot-reply-container">
                    <TypingEffect className="bot-text" text={content} speed={1} />
                </div>
            )}
        </div>
    );
};

export default ragComponent;
