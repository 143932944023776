export function initializeQualtrics() {
    class G {
        e: number;
        h: string;
        f: string;
        g: string;
        constructor(e: number, h: string, f: string, g: string) {
            this.e = e;
            this.h = h;
            this.f = f;
            this.g = g;
        }
        get = (a: string) => {
            const aEq = a + "=";
            const c = document.cookie.split(";");
            for (let b = 0, e = c.length; b < e; b++) {
                let d = c[b];
                while (d.charAt(0) === " ") d = d.substring(1, d.length);
                if (d.indexOf(aEq) === 0)
                    return d.substring(aEq.length, d.length);
            }
            return null;
        };
        set = (a: string, c: string) => {
            const b = new Date();
            b.setTime(b.getTime() + 6048e5);
            const expires = "; expires=" + b.toUTCString();
            document.cookie = a + "=" + c + expires + "; path=/;";
        };
        check = () => {
            let a: any = this.get(this.f);
            if (a) a = a.split(":");
            else if (this.e !== 100) {
                if (this.h === "v")
                    this.e = Math.random() >= this.e / 100 ? 0 : 100;
                a = [this.h, this.e, 0];
                this.set(this.f, a.join(":"));
            } else return true;
            const c = a[1];
            if (c === 100) return true;
            switch (a[0]) {
                case "v":
                    return false;
                case "r": {
                    const innerC: any = a[2] % Math.floor(100 / c);
                    a[2]++;
                    this.set(this.f, a.join(":"));
                    return !innerC;
                }
                default:
                    return true;
            }
        };
        go = () => {
            if (this.check()) {
                const a = document.createElement("script");
                a.type = "text/javascript";
                a.src = this.g;
                document.body && document.body.appendChild(a);
            }
        };
        start = () => {
            if (document.readyState !== "complete") {
                window.addEventListener("load", this.go, false);
            } else {
                this.go();
            }
        };
    }
    try {
        new G(
            100,
            "r",
            "QSI_S_ZN_6DzARFvr5BOqHOu",
            "https://zn6dzarfvr5boqhou-eon.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_6DzARFvr5BOqHOu"
        ).start();
    } catch (i) {
        console.error(i);
    }
}