import React from "react";
import {SelectableButton, SelectableButtonProps} from "@me8eon/selection_button";
import {useTranslation} from "@me8eon/translation";


export const EonSelectionButton: SelectableButton = ({selectedOps, text, rootId}: SelectableButtonProps) => {
    const translate = useTranslation();
    const [value, setValue] = selectedOps;
    const isActive = value === text;
    const className = isActive ? 'tablinks active' : 'tablinks';

    const id = `${rootId}.${text}`;
    return <button id={id} data-testid ={id} aria-selected={isActive} role="tab" className={className} onClick={() => setValue(text)}>{translate(id)}</button>;
};