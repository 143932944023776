import { DataWidgetLayout } from "./data.view.widget";
import { useTheme } from "@me8eon/themes";
import React from "react";

export const SimpleDataViewWidgetLayout: DataWidgetLayout = ({ children, rootId }) => {
    const { dataLayout } = useTheme();
    return (
        <div data-testid={`${rootId}-layout`} style={dataLayout.AdvanceUnifiedBox}>
            <div className="unified-box-heading">
                Search Results
            </div>
            <div className="unified-box-content">{children}</div>
        </div>
    );
};
