import AiMessages from "./wrappers/AiMessages";
import AiHeader from "./wrappers/AiHeader";
import AiFooter from "./wrappers/AiFooter";
import "./AiAssist.scss";
import {useContext, useEffect} from "react";
import {AiAssistContext} from "./AiAssist.context";
import useSearchStore from "../../store/searchStore";
import React from "react";
export type TSetShowAiAssist = (showAiAssist: boolean) => void;

interface IAiAssistRefactoredProps {
    closeAiAssist: () => void
    initialPrompt?: string;
}

const AiAssistRefactored = ({closeAiAssist, initialPrompt}: IAiAssistRefactoredProps) => {

    const {showLiveAgentChat} = useSearchStore();

    const {handleAiQuery} = useContext(AiAssistContext);

    useEffect(() => {
        if (initialPrompt) {
            handleAiQuery(initialPrompt);
        }
    }, []);

    return (
        <>
            <AiHeader closeAiAssist={closeAiAssist}/>
            <AiMessages/>
            {!showLiveAgentChat && <AiFooter/>}
        </>
    );
};

export default AiAssistRefactored;
