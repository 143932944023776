import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAuthHeader } from "../../util/auth";
import { HttpServiceGet } from "../../services/httpService";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import LoaderComponent from "../../components/loader-component/LoaderComponent";
import TableComponent from "../../components/generic-components/data-table/TableComponent";
import "./ServiceRequest.scss";
import { EnvironmentConfig } from"@me8eon/config";
import { isLocalHost } from "../../util/common-util";
import tableHeaderLabel from "./tableHeaderLabel.json";

export const serviceRequestTypes = {
    incidents: {
        url: `${EnvironmentConfig.serviceUrl}/itsm-tablequery-api/v1/list/inc/byKid?isActive=true`,
        redirectUrl:
            "https://siam.service-now.com/esp?id=esp_incident_form&sys_id=5f6c0e71db71b700caaa320a68961991",
        errorMessage: "snow.tabName.incidentsTable.noData",
        buttonText: "snow.tabName.incidentsTable.btnText",
    },
    itResources: {
        url: `${EnvironmentConfig.serviceUrl}/itsm-tablequery-api/v1/list/asset/byKid?isActive=true`,
        redirectUrl: null,
        errorMessage: "snow.tabName.itResourcesTable.noData",
        buttonText: null,
    },
    orders: {
        url: `${EnvironmentConfig.serviceUrl}/itsm-tablequery-api/v1/list/ritm/byKid?isActive=true`,
        redirectUrl: "https://siam.service-now.com/esp?id=esp_sc_home",
        errorMessage: "snow.tabName.orderTable.noData",
        buttonText: "snow.tabName.orderTable.btnText",
    },
};

interface ServiceRequestPageProps {
    type: keyof typeof serviceRequestTypes;
}
type tableDataType = {
    [key: string]: string;
};
type tableDataLang = {
    [key: string]: tableDataType;
};
function ServiceRequestPage({ type }: Readonly<ServiceRequestPageProps>) {
    const { url, redirectUrl, buttonText, errorMessage } =
        serviceRequestTypes[type];
    const [resData, setResData] = useState<any[]>([]);
    const [preLoader, setPreLoader] = useState(false);
    const [cols, setCols] = useState<string[]>([]);
    const [headerMaps, setHeaderMaps] = useState<string[]>([]);
    const { t, i18n } = useTranslation();
    const lang = i18n.language;

    const tableHeaderData: tableDataLang = tableHeaderLabel;

    useEffect(() => {
        if (resData.length === 0) return;
        const hasClosedAt = resData.some(item => item.closed_at);
        const hasResolvedAt = resData.some(item => item.resolved_at);
        const filteredKeys = Object.keys(resData[0]).filter(key =>
            (key !== 'closed_at' || hasClosedAt) &&
            (key !== 'resolved_at' || hasResolvedAt));

        const columns = filteredKeys.map(key => tableHeaderData[lang][key]);
        setCols(columns);
        setHeaderMaps(filteredKeys);

    }, [resData, lang]);

    useEffect(() => {
        getOnloadAPIData();
    }, []);

    const getOnloadAPIData = async () => {
        try {
            setPreLoader(true);
            const authHeader = getAuthHeader();
            const response = await HttpServiceGet(url, authHeader);
            const queryResult =
                (response?.data?.result?.queryResult as any[]) ?? [];

            if (queryResult.length == 0 && isLocalHost()) {
                queryResult.push(
                    {
                        model_category: "Google",
                        portalURL: "https://www.google.com",
                    },
                    {
                        model_category: "HCL Tech",
                        portalURL: "https://www.hcltech.com",
                    }
                );
            }

            const results = queryResult.map((result: any) => {
                const {
                    u_service_value_2,
                    asset_tag,
                    cat_item_sys_id,
                    ...rest
                } = result;
                console.log(
                    "Keys removed: u_service_value_2,asset_tag,closed_at,cat_item_sys_id",
                    u_service_value_2,
                    asset_tag,
                    cat_item_sys_id
                );
                return rest;
            });
            setResData(results);
        } catch (error) {
            console.error(`Service Request: ${type}`, error);
        } finally {
            setPreLoader(false);
        }
    };

    return (
        <div className="child-container" data-testid="serviceRequestRoot">
            {preLoader && (
                <div className="loader-container" data-testid="loader">
                    <LoaderComponent isLoading isLinear />
                </div>
            )}
            {!preLoader && resData.length ? (
                <TableComponent rows={resData} cols={cols} headerMaps={headerMaps} showPagination={true} />
            ) : (
                <div className="child-no-data-message">
                    <p>{t(errorMessage)}</p>
                </div>
            )}

            {redirectUrl && (
                <div className="child-ticket-add">
                    <EonUiButton
                        data-testid="addButton"
                        onClick={() => window.open(redirectUrl, "_blank")}
                        icon-position={"left"}
                        icon={"add"}
                        text={t(buttonText)}
                    />
                </div>
            )}
        </div>
    );
}

export default ServiceRequestPage;
