import { EonUiIcon, EonUiLink } from "@eon-ui/eon-ui-components-react";
import { t } from "i18next";
import { useContext, useState } from "react";
import { AiAssistContext } from "../AiAssist.context";
import { useWindowProperties } from "../../../context/WindowContext";
import { Icon } from "@me8eon/icons";
import React from "react";
import AssistanceTask from "../assistance-task/AssistanceTask";
interface IAiHeaderProps {
    closeAiAssist: () => void;
}

const AiHeader = ({ closeAiAssist }: IAiHeaderProps) => {

    const { handleResetChat, assistanceTasks } = useContext(AiAssistContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { isScrollY } = useWindowProperties();

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="search-wrapper" style={{ padding: isScrollY ? "12px 0" : "0" }}>
            <EonUiLink
                className="back-button"
                iconPosition="left"
                icon="chevron_small_left"
                onClick={() => closeAiAssist()}
                text={t("chatBot.searchBack")}
                title={t("chatBot.searchBack")}
                href="javascript:void(0)"
                size={isScrollY ? "small" : "normal"}
            ></EonUiLink>
            {<button
                className="new-ai-chat"
                onClick={() => {
                    handleResetChat();
                    window.scrollTo(0, 0);
                }}
                title={t("chatBot.newChat")}
            >
                <span className="icon-wrapper">
                    <EonUiIcon
                        className="margin-add eon-ui-svg-icon"
                        size={isScrollY ? "tiny" : "small"}
                        name={"add"}
                        scheme="red500"
                    ></EonUiIcon>
                </span>
                <span className="add-new-ai-chat" style={{ fontSize: isScrollY ? "14px" : "18px" }}>{t("chatBot.newChat")}</span>
            </button>}
            {assistanceTasks.length > 0 && <div className="header-assitance-task">
                <button className="task-button" onClick={toggleDropdown} title={t("chatBot.nexthinkIntegration.assistanceTask")}><Icon size="normal" name="plus_circle" /></button>
                {
                    isOpen && (<div className="dropdown-menu">
                       <AssistanceTask />
                    </div>)
                }
            </div>}
        </div>
    );
};

export default AiHeader;