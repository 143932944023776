import { deepCombineObjects } from "@me8eon/record_utils/src/combine";
import common from "./common/common.json";
import deAboutUs from "./locales/de/about-us.json";
import deRoomBooking from "./locales/de/room-booking.json";
import deTranslation from "./locales/de/translation.json";

import enAboutUs from "./locales/en/about-us.json";
import enRoomBooking from "./locales/en/room-booking.json";
import enTranslation from "./locales/en/translation.json";

import svAboutUs from "./locales/sv/about-us.json";
import svRoomBooking from "./locales/sv/room-booking.json";
import svTranslation from "./locales/sv/translation.json";

import devMode from "./devMode/devmode.json";

export const allDe = deepCombineObjects(devMode, common, deAboutUs, deRoomBooking, deTranslation);
export const allEn = deepCombineObjects(devMode, common, enAboutUs, enRoomBooking, enTranslation);
export const allSv = deepCombineObjects(devMode, common, svAboutUs, svRoomBooking, svTranslation);


export const allTranslations = {
    de: allDe,
    en: allEn,
    sv: allSv,
};

export const legacyTranslations = {
    en: {
        translation: enTranslation,
        "about-us": enAboutUs,
        "room-booking": enRoomBooking,
    },
    de: {
        translation: deTranslation,
        "about-us": deAboutUs,
        "room-booking": deRoomBooking,
    },
    sv: {
        translation: svTranslation,
        "about-us": svAboutUs,
        "room-booking": svRoomBooking,
    },
};
