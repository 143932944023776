import React, {ReactNode} from "react";
import {DataHasType, useDataPlugins} from "@me8eon/data";
import {useDataViews, useSelectedDataViewName} from "@me8eon/data_views";
import {useThrowError} from "@me8eon/throw_error";


export type DisplayAsOneLineProps<Data> = {
    rootId: string
    data: Data[]
}
export type DisplayAsOneLine = <Data extends DataHasType>(props: DisplayAsOneLineProps<Data>) => ReactNode;

export const DisplayAsOneLine: DisplayAsOneLine =
    <Data extends DataHasType>({rootId, data}: DisplayAsOneLineProps<Data>): ReactNode => {
        const [selected] = useSelectedDataViewName();
        const dataViews = useDataViews();
        const dataView = dataViews[selected];

        const dataPlugins = useDataPlugins();
        const throwError = useThrowError();
        const {OneLineLayout} = dataView;

        return <OneLineLayout rootId={rootId}>{
            data.map(d => {
                const plugin = dataPlugins[d.type];
                if (!plugin) return throwError('s/w', `No plugin for data type ${d.type}. Legal values are ${Object.keys(dataPlugins).sort()} `);
                const {DisplayDataOneLine, idFrom} = plugin;
                const id = idFrom(d);
                return <DisplayDataOneLine key={id} rootId={rootId} id={id} data={d}/>;
            })
        }</OneLineLayout>;
    };
