import { translateKey } from "../../../util/translate";

export interface DecisionNode {
    id: string;
    userCommand: string;
    systemAnswer: string;
    type: string;
    options?: DecisionOption[];
    condition?: (context: Record<string, any>) => boolean;
}

interface DecisionOption {
    label: string;
    nextNodeId: string;
    icon: string;
    scheme: string;
}

const createOption = (labelKey: string, nextNodeId: string, icon: string, scheme: string): DecisionOption => ({
    label: translateKey(labelKey),
    nextNodeId,
    icon,
    scheme
});

const createIncidentDecisionTree = (): DecisionNode[] => [
    {
        id: "createIncidentNew",
        userCommand: translateKey("chatBot.quickActions.actions.createIncident"),
        systemAnswer: translateKey("chatBot.quickActions.state.whatsTheIssue"),
        type: "text",
        condition: (context) => context.category === "new",
    },
    {
        id: "createIncidentOngoing",
        userCommand: translateKey("chatBot.quickActions.actions.createIncident"),
        systemAnswer: translateKey("chatBot.quickActions.state.lastConversation"),
        type: "options",
        options: [
            createOption("yes", "ongoingConversation", "radio_checkbox_tick", "neutral800"),
            createOption("no", "newConversation", "cross", "neutral800"),
        ],
        condition: (context) => context.category === "ongoing",
    },
    {
        id: "ongoingConversation",
        userCommand: translateKey("chatBot.quickActions.actions.ongoingConversation"),
        systemAnswer: translateKey("chatBot.quickActions.state.createIncidentLabel"),
        type: "create-incident",
        options: [
            createOption("chatBot.quickActions.state.createTicketLabel", "confirmCreateIncident", "radio_checkbox_tick", "neutral800"),
            createOption("chatBot.quickActions.state.cancelTicketLabel", "cancelCreateIncident", "cross", "red500"),
        ],
        condition: (context) => context.category === "ongoing" && context.quickActionType === "createIncident",
    },
    {
        id: "newConversation",
        userCommand: translateKey("chatBot.quickActions.actions.newConversation"),
        systemAnswer: translateKey("chatBot.quickActions.state.whatsTheIssue"),
        type: "text",
    },
    {
        id: "confirmCreateIncident",
        userCommand: translateKey("chatBot.quickActions.actions.confirmAndCreateTicket"),
        systemAnswer: translateKey("chatBot.quickActions.state.creatingIncidentTicket"),
        type: "create-incident-response",
    },
    {
        id: "cancelCreateIncident",
        userCommand: translateKey("chatBot.quickActions.actions.cancelTicketCreation"),
        systemAnswer: translateKey("chatBot.quickActions.state.cancelAck"),
        type: "text",
    },
];

const chatWithServiceDeskDecisionTree = (): DecisionNode[] => [
    {
        id: "chatWithServiceDeskNew",
        userCommand: translateKey("chatBot.quickActions.actions.chatWithServiceDesk"),
        systemAnswer: translateKey("chatBot.quickActions.state.whatsTheIssue"),
        type: "text",
        condition: (context) => context.category === "new",
    },
    {
        id: "chatWithServiceDeskOngoing",
        userCommand: translateKey("chatBot.quickActions.actions.chatWithServiceDesk"),
        systemAnswer: translateKey("chatBot.quickActions.state.lastConversation"),
        type: "options",
        options: [
            createOption("yes", "ongoingConversationChat", "radio_checkbox_tick", "neutral800"),
            createOption("no", "newConversation", "cross", "neutral800"),
        ],
        condition: (context) => context.category === "ongoing",
    },
    {
        id: "ongoingConversationChat",
        userCommand: translateKey("chatBot.quickActions.actions.ongoingConversation"),
        systemAnswer: translateKey("chatBot.quickActions.state.chatWithServiceDeskLabel"),
        type: "chat-with-service-desk",
        options: [
            createOption("chatBot.quickActions.state.connectWithServiceDeskLabel", "confirmChatWithServiceDesk", "radio_checkbox_tick", "neutral800"),
            createOption("cancel", "cancelChatWithServiceDesk", "cross", "red500"),
        ],
        condition: (context) => context.category === "ongoing" && context.quickActionType === "chatWithServiceDesk",
    },
    {
        id: "cancelChatWithServiceDesk",
        userCommand: translateKey("chatBot.quickActions.actions.cancelChatWithServiceDesk"),
        systemAnswer: translateKey("chatBot.quickActions.state.cancelAck"),
        type: "text",
    },
];

export const getDecisionTree = (): DecisionNode[] => [
    ...createIncidentDecisionTree(),
    ...chatWithServiceDeskDecisionTree(),
];

export const getUserCommand = (nodeId: string): string =>
    getDecisionTree().find(item => item.id === nodeId)?.userCommand ?? "";