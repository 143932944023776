import { FilterSetters } from "../components/el-search-advance/elsearch.domain";
import { DataType } from "../components/el-search-advance/ElSearchAdvanceHelpers";

export interface ESApiResponseInterface {
    _source: object;
    _index: string;
    _score: number;
    highlight: string;
}

export type QueryType = "knn" | "keywords" | "boostAndPhrase";
export type QueryTypeAnd<T> = {
    knn: T;
    keywords: T;
    boostAndPhrase: T;
};

export interface ESApiFinalResponseInterface {
    action?: string;
    queryType: QueryType;
    body: string;
    created_by: string;
    highlight: string;
    id: string;
    index: string;
    is_truncated: boolean;
    last_updated: string;
    model_id: string;
    score: number;
    space: string;
    status: string;
    title: string;
    type: string;
    url?: string;
    keyfield?: string;
    question?: string;
    answer?: string;
    alias?: string;
    sha?: string;
}

export interface SelectedTimeInterface {
    [key: string]: string;
}

export interface SelectedMetadataInterface {
    [key: string]: string;
}

export interface AdditionalFilters {
    selectedTime?: SelectedTimeInterface[];
    selectedMetadata?: SelectedMetadataInterface[];
}

export interface TimeRangeOptionsInterface {
    label: string;
    value: string;
    icon: string;
    default?: boolean;
}

export interface SearchFiltersInterface {
    dataType: string
    setters: FilterSetters;
    searchIndex: string[];
    additionalFilters: AdditionalFilters;
    selectedMetadataFilters: SelectedMetadataFilters;
    dataSourceFilters: DataSourceFilter[];
    filterCount: number;
    m365DocTypes: string[];
}

export type DataSourceFilter = { key: string, doc_count: number };

export function mergeDataSourceFilters(filters: DataSourceFilter[]): DataSourceFilter[] {
    const mergedFilters = new Map<string, number>();

    filters.forEach((filter) => {
        mergedFilters.set(
            filter.key,
            Math.max((mergedFilters.get(filter.key) || 0), filter.doc_count)
        );
    });

    return Array.from(mergedFilters.entries()).map(([key, doc_count]) => ({ key, doc_count }));
}

export interface SelectedMetadataFilters {
    [key: string]: {
        label: string;
        value: string[];
    }
}

export interface MetadataFilters {
    [key: string]: SelectedMetadataFilters
}

export function mergeSelectedMetadataFilters(
    filters: SelectedMetadataFilters[]
): SelectedMetadataFilters {
    return filters.reduce<SelectedMetadataFilters>((mergedFilters, currentFilters) => {
        for (const [key, filter] of Object.entries(currentFilters)) {
            if (mergedFilters[key]) {
                // Merge values and keep the original label
                mergedFilters[key] = {
                    label: mergedFilters[key].label, // Keep the original label
                    value: [...new Set([...mergedFilters[key].value, ...filter.value])] // Merge and deduplicate values
                };
            } else {
                // Add new filter if it doesn't exist
                mergedFilters[key] = filter;
            }
        }
        return mergedFilters;
    }, {});
}

export function mergeMetadataFilters(filtersArray: MetadataFilters[]): MetadataFilters {
    return filtersArray.reduce<MetadataFilters>((mergedFilters, currentFilters) => {
        for (const [groupKey, selectedFilters] of Object.entries(currentFilters)) {
            if (mergedFilters[groupKey]) {
                // Merge existing groups using mergeSelectedMetadataFilters
                mergedFilters[groupKey] = mergeSelectedMetadataFilters([
                    mergedFilters[groupKey],
                    selectedFilters,
                ]);
            } else {
                // Add new group
                mergedFilters[groupKey] = selectedFilters;
            }
        }
        return mergedFilters;
    }, {});
}


export interface MetadataKeyFilterInterface {
    key: string;
    doc_count: number;
    label: string;
    multi_key?: string;
}

export interface Option {
    value: string;
    label: string;
    icon?: string;
    type?: string;
    default?: boolean;
}