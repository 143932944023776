import React, { useEffect, useState } from "react";
import { useSelectedDataViewName } from "@me8eon/data_views";
import { ThemeProvider, useTheme } from "@me8eon/themes";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { useCommonComponents } from "@me8eon/components";
import { OutsideClick } from "@me8eon/outside_click";
import { ThemedIcon } from "@me8eon/icons";
type MoreDropdownProps = {
    children: React.ReactNode;
    moreTextHidden: boolean;
    menuItemSelected: string
};

const MoreDropdown: React.FC<MoreDropdownProps> = ({ children, moreTextHidden, menuItemSelected }) => {
    const selectedOps = useSelectedDataViewName();
    const [isOpen, setIsOpen] = useState(false);
    const { navBarStyles } = useTheme().dataLayout;
    const { BgMouseOver } = useCommonComponents();

    const defaultDropdownText = "More";
    const visibleHeading = moreTextHidden && !isOpen && menuItemSelected ? menuItemSelected : defaultDropdownText;

    useEffect(() => {
        setIsOpen(false);
    }, [selectedOps]);

    return (
        <OutsideClick onClickOutside={() => setIsOpen(false)}>
            <div className="nav-bar-dropdown" style={{
                position: "relative",
                width: "140px",
            }}>
                <div style={{
                    position: "absolute",
                    zIndex: 99,
                    ...(isOpen ? navBarStyles.menuButtonWrapperOpen : {}),
                    ...(visibleHeading !== defaultDropdownText ? navBarStyles.menuButtonWrapper : {})
                }}>
                    <BgMouseOver bgHover={"var(--bg-hover-color)"}>
                        <button
                            data-testid="MoreDropdownIcon"
                            tabIndex={0}
                            title="More"
                            style={{
                                ...navBarStyles.menuButton,
                                ...(isOpen ? {
                                    borderTopLeftRadius: "12px",
                                    borderTopRightRadius: "12px",
                                } : {}),
                            }}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {visibleHeading === "More" && !isOpen && <ThemedIcon name="more_small" />}
                            {visibleHeading}
                            <ThemedIcon
                                style={{ marginTop: "8px" }}
                                title={"down arrow"}
                                size="normal"
                                name={`chevron_bold_${isOpen ? "up" : "down"}`}
                            />
                        </button>
                    </BgMouseOver>
                    {isOpen && (<>
                        {children}
                    </>)}
                </div>
            </div >
        </OutsideClick>
    );
};

export default MoreDropdown;