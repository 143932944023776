import TileTour from "./TileTour";
import React, { useEffect, useState } from "react";
import { Step } from "react-joyride";
import { useTranslation } from "react-i18next";
// @ts-ignore
import actionGif from "../../../public/search.gif";

const TourStart = ({ tourId }: { tourId: string }) => {
    const [run, setRun] = useState<boolean>(false);
    const [steps, setSteps] = useState<Step[]>([]);
    const { t } = useTranslation();

    const defineSteps = (tourtype: string) => {
        if (tourtype === "tour1") {
            setSteps(tileSteps);
        } else if (tourtype === "tour2") {
            setSteps(tabSteps);
        } else {
            const isTabPresent =
                document.getElementsByClassName("tab-wrapper")[0];
            setSteps([...tileSteps, ...(isTabPresent ? tabSteps : [])]);
        }
    };

    useEffect(() => {
        defineSteps(tourId);
        setRun(tourId !== "");
    }, [tourId]);

    const tileSteps: Step[] = [
        {
            target: "#tileTour1",
            content: (
                <div>
                    {/* <h3>Click here to prefill below values</h3>
                    <div onClick={() => itemClicked("My HR")}>My HR</div>
                    <div onClick={() => itemClicked("Meeting Room")}>
                        Meeting Room
                    </div> */}
                    <img src={actionGif} alt="example action" width="100%" />
                    {t("tourData.tile.step1")}
                </div>
            ),
            disableBeacon: true,
        },
        {
            target: "#tileTour2",
            content: t("tourData.tile.step2"),
        },
        {
            target: "#tileTour3",
            content: t("tourData.tile.step3"),
        },
        {
            target: "#tileTour5",
            content: t("tourData.tile.step5"),
        },
    ];

    const tabSteps: Step[] = [
        {
            target: "#tabTour1",
            content: t("tourData.tab.step1"),
            disableBeacon: true,
        },
        {
            target: "#tabTour2",
            content: t("tourData.tab.step2"),
        },
        {
            target: "#tabTour3",
            content: t("tourData.tab.step3"),
        },
    ];

    const starTour = (e: any) => {
        const element = document.querySelector('[data-test-id="button-beacon"]') as HTMLElement;
        if (element) {
            element.click();
        } else {
            e.preventDefault();
            defineSteps("complete");
            setRun(true);
        }
    };

    return (
        <>
            <TileTour steps={steps} run={run} onTourEnd={() => setRun(false)} />
            <div className="fixed">
                <button
                    onClick={starTour}
                    className="tour-button"
                    data-testid="tourStart"
                >
                    {t("tourData.userActions.start_tour")}
                </button>
            </div>
        </>
    );
};

export default TourStart;
