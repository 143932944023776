import * as React from "react";
import { SVGProps } from "react";
const Loop = (props: SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height={24}
        viewBox="0 0 24 24"
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
    >
        <radialGradient
            id="a"
            cx={0}
            cy={0}
            gradientTransform="matrix(13.40004 -2.3 6.04701 35.2304 .3 8.2)"
            gradientUnits="userSpaceOnUse"
            r={1}
        >
            <stop offset={0} stopColor="#6d05e8" />
            <stop offset={1} stopColor="#6d05e8" stopOpacity={0} />
        </radialGradient>
        <linearGradient
            id="b"
            gradientUnits="userSpaceOnUse"
            x1={14.2}
            x2={10.9}
            y1={9.1}
            y2={22.6}
        >
            <stop offset={0} stopColor="#9237e3" />
            <stop offset={1} stopColor="#48e6f3" />
        </linearGradient>
        <linearGradient
            id="c"
            gradientUnits="userSpaceOnUse"
            x1={8.5}
            x2={17.3}
            y1={20.5}
            y2={18.5}
        >
            <stop offset={0} stopColor="#51e6ff" />
            <stop offset={1} stopColor="#71d4ff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
            id="d"
            gradientUnits="userSpaceOnUse"
            x1={6.1}
            x2={11.2}
            y1={12.7}
            y2={5.5}
        >
            <stop offset={0} stopColor="#6e06e9" />
            <stop offset={1} stopColor="#ab5add" stopOpacity={0} />
        </linearGradient>
        <path
            d="m12 2a10 10 0 0 0 -10 10v10h10a10 10 0 0 0 0-20zm0 6.5a3.5 3.5 0 1 1 0 7h-3.5v-3.5a3.5 3.5 0 0 1 3.5-3.5z"
            fill="url(#b)"
        />
        <path
            d="m22 12a10 10 0 0 1 -10 10h-10a6.5 6.5 0 0 1 6.5-6.5h3.5a3.5 3.5 0 0 0 3.5-3.5z"
            fill="url(#c)"
        />
        <path
            d="m22 12a10 10 0 0 1 -10 10h-10a6.5 6.5 0 0 1 6.5-6.5h3.5a3.5 3.5 0 0 0 3.5-3.5z"
            fill="none"
        />
        <path
            d="m22 12a10 10 0 0 1 -10 10h-10a6.5 6.5 0 0 1 6.5-6.5h3.5a3.5 3.5 0 0 0 3.5-3.5z"
            fill="none"
        />
        <path
            d="m12 2a10 10 0 0 0 -10 10v10a6.5 6.5 0 0 0 6.5-6.48l.02-.02h-.02v-3.5a3.5 3.5 0 0 1 6-2.46l4.6-4.58a9.97 9.97 0 0 0 -7.1-2.96z"
            fill="url(#d)"
        />
        <path
            d="m12 2a10 10 0 0 0 -10 10v10a6.5 6.5 0 0 0 6.5-6.48l.02-.02h-.02v-3.5a3.5 3.5 0 0 1 6-2.46l4.6-4.58a9.97 9.97 0 0 0 -7.1-2.96z"
            fill="url(#a)"
        />
    </svg>
);
export default Loop;
