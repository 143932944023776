import {DataPlugin, DisplayData, DisplayDataWidget} from "../../data";
import {OrderItDisplay, SimpleOrderItWidget, OneLineOrderItDisplay} from "./simple.order.it.display";
import {SimpleDisplayDataArray} from "../../simple_display_data_array";


export const OrderItDataName = 'order-it';

export type OrderItData = {
    type: string;
    id: string;
    index: string;
    icon: 'cart';
    link: string;
    title: string;
    description: string;
    deliveryTime: string;
    category: string;
}

export function OrderItDataPlugin(
    DisplayData: DisplayData<OrderItData> = OrderItDisplay,
    DisplayDataOneLine: DisplayData<OrderItData> = OneLineOrderItDisplay,
    DisplayDataWidget: DisplayDataWidget<OrderItData> = SimpleOrderItWidget): DataPlugin<OrderItData> {
    return {
        plugin: 'data',
        name: 'order-it',
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget,
        idFrom: data => `${data.index}.${data.id}`
    };
}
