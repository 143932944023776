
import { OptionsFeatureFlag } from "@me8eon/featureflags";
import { bayernwerkTheme } from "@me8eon/bayernwerk_themes";
import { Theme } from "@me8eon/themes";
import { eonTheme } from "@me8eon/eon_themes";


export const allThemes: Record<string, Theme> = {
    eon: eonTheme,
    bayernwerk: bayernwerkTheme,
};

export type ThemeName = keyof typeof allThemes;

export const themeNames: ThemeName[] = Object.keys(allThemes) as ThemeName[];


export const themeFeatureFlag: OptionsFeatureFlag = {
    description: "Choose the theme",
    value: "default",
    options: ["default", ...themeNames],
};