import React from "react";
import {
    useAdditionalFiltersStateScaffolding,
    useMetadataFiltersStateScaffolding,
    useSelectedMetadataFiltersStateScaffolding,
} from "@me8eon/filter_data_widget";

// Global style objects
const gridContainerStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "20px",
    padding: "20px",
};

const gridItemStyle: React.CSSProperties = {
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "10px",
    overflow: "auto",
    maxHeight: "300px", // Adjust maxHeight as needed
    backgroundColor: "#f9f9f9",
};

export function DevmodeFilters() {
    const [additionalFilters] = useAdditionalFiltersStateScaffolding();
    const [metadataFilters] = useMetadataFiltersStateScaffolding();
    const [selectedMetadataFilters] = useSelectedMetadataFiltersStateScaffolding();

    return (
        <div style={gridContainerStyle}>
            <div style={gridItemStyle}>
                <h1>Additional filters</h1>
                <pre data-testid='devmode.filters.additional'>{JSON.stringify(additionalFilters, null, 2)}</pre>
            </div>
            <div style={gridItemStyle}>
                <h1>Metadata filters</h1>
                <pre data-testid='devmode.filters.metadata'>{JSON.stringify(metadataFilters, null, 2)}</pre>
            </div>
            <div style={gridItemStyle}>
                <h1>Selected metadata filters</h1>
                <pre data-testid='devmode.filters.selected'>{JSON.stringify(selectedMetadataFilters, null, 2)}</pre>
            </div>
        </div>
    );
}
