import { AxiosResponse } from "axios";
import {EnvironmentConfig} from "@me8eon/config";
import { PostServiceRequest } from "./serviceRequestApiCall";

interface ApiResponse {
    result: {
        number: string;
        message: string;
    };
}

export const raiseIncident = async (description?: string) => {
    const msalIdData = localStorage.getItem("msal.account.keys") || "";
    const msalID = JSON.parse(msalIdData)[0] || "";
    const userEmail = JSON.parse(localStorage.getItem(msalID) || "")?.username;
    const userKID = userEmail.split("@")[0];

    const url = `${EnvironmentConfig.serviceUrl}/service-mgmt-api/v1/inc/create`;
    const payload = JSON.stringify({
        caller_id: userKID,
        contact_number: userEmail,
        service: "",
        short_description: "Me8EON",
        description: description,
        external_ticket_id: "INF192344",
        category: "User Service Request",
        sub_category: "Degraded/Service",
        contact_type: "Email",
        reported_by: userKID,
        location_id: "",
        configuration_item: "DECT Manager",
        assignment_group: "infosys-snow-operations-2",
        file_name: "Test doc - Copy.txt",
        file_data: "VGhpcyBpcyBhIHRlc3QgYXR0YWNobWVudC4NCg==",
    });

    try {
        const response: AxiosResponse<ApiResponse> = await PostServiceRequest(url, payload);
        return response?.data?.result;
    } catch (e) {
        console.log(e);
    }
};
