import { DataWidget, DataWidgets } from "./data.view.widget";
import { interleave } from "me-at-eon/src/util/arrays";

export type TestDataForRest = {
    index: string
    id: string
    type: string;
    name: string;
}
export type TestDataSharepoint = {
    type: string;
    index: string
    resource: { id: string }
    name: string;
}
export type TestData = TestDataForRest | TestDataSharepoint;
export const dataForAppWidget: TestDataForRest[] = [
    { type: "app", name: "widget11", title:'App1', index: "app-prod", id: "11" },
    { type: "app", name: "widget12", title:'App2',index: "app-prod", id: "12" },
    { type: "app", name: "widget13", title:'App3',index: "app-prod", id: "13" },
] as any;
export const dataForSharepointWidget: TestDataSharepoint[] = [
    { type: "sharepoint", name: "widget21", index: "sharepoint-prod", resource: { id: "21" } },
    { type: "sharepoint", name: "widget22", index: "sharepoint-prod", resource: { id: "22" } },
    { type: "sharepoint", name: "widget23", index: "sharepoint-prod", resource: { id: "23" } },
];
export const dataForRestWidget: TestDataForRest[] = [
    { type: "jira", name: "widget31", index: "jira-prod", id: "31" },
    { type: "confluence", name: "widget32", index: "confluence-prod", id: "32" },
    { type: "gitlab", name: "widget33", index: "gitlab-prod", id: "33" },
];

export const allWidgetTestData: TestData[] = interleave<TestData>([dataForAppWidget, dataForSharepointWidget, dataForRestWidget]);

export function testDataWidget(name: string, filter: (d: any) => boolean): DataWidget {
    return {
        plugin: "datawidget",
        name,
        display: (props) => {
            return <div data-testid={props.rootId} data-name={name}>{props.data.filter(filter).map((d, i) => {
                const id = (d as any)?.id || (d as any)?.resource?.index || i;
                return <pre key={id} data-testid={id}>{JSON.stringify(d)}</pre>;
            })}</div>;
        },
    };
}

export const allTestDataWidgets: DataWidgets = {
    apps: testDataWidget("apps", (d) => d.type === "app"),
    confluence: testDataWidget("confluence", (d) => d.type === "confluence"),
    gitlab: testDataWidget("gitlab", (d) => d.type === "gitlab"),
    jira: testDataWidget("jira", (d) => d.type === "jira"),
    myGenius: testDataWidget("myGenius", (d) => d.type === "myGenius"),
    people: testDataWidget("people", (d) => d.type === "person"),
    sharepoint: testDataWidget("sharepoint", (d) => d.type === "sharepoint"),
    restOfElasticSearch: testDataWidget("restOfElasticSearch", (d) => !["app", "sharepoint"].includes(d.type)),
};


