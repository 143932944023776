// import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { cancelBookingApi } from "../../../services/roomBooking";
import useRoomBookingStore from "../../../store/roomBookingStore";
import "./CancelBooking.scss";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "../../../services/accessToken";
import LoaderComponent from "../../loader-component/LoaderComponent";

type CancelBookingProps = {
    callback?: () => void;
};

const CancelBooking = ({callback}: CancelBookingProps) => {

    const {
        setCancelBookingModal,
        currentBookingData,
        manageBookingRawData
    } = useRoomBookingStore();

    const { t } = useTranslation("room-booking");
    const [successErrMsg, setSuccessErrMsg] = useState<string>("");
    const [cancelSuccess, setCancelSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const date = new Date(currentBookingData.meeting_on);
    const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        day: "2-digit",
        month: "short",
    };
    const formattedMeetingDate = date
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, " ");

    const cancelBooking = async (meeting_id: number) => {
        setCancelSuccess(true);
        setIsLoading(true);
        const actualToken = await getAccessToken();
        const payload = {
            "booking_ids": [meeting_id],
            "send_mails": true,
            "accesstoken": actualToken || ""
        };
        try {
            const response = await cancelBookingApi(payload);
            if (response.status == "success" &&  response.data.booking_results[0].result && !response.data.booking_results[0].errors.length) {
                setSuccessErrMsg(t("cancelBookingApiSucessMsg"));
            } else {
                setSuccessErrMsg(t("apiFailDefaultMsg"));
                setCancelSuccess(false);
                console.log("something went wrong!", response);
            }
        } catch(e) {
            setSuccessErrMsg(t("apiFailDefaultMsg"));
            setCancelSuccess(false);
            console.log("something went wrong!", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getMeetingDetailsById = (meeting_id: number) => {
        return manageBookingRawData.find(obj => obj.booking_id === meeting_id);
    };

    const getMeetingDuration = (meeting_id: number) => {
        const meeting = getMeetingDetailsById(meeting_id);
        const diff = (meeting.end.timestamp - meeting.begin.timestamp) / 3600;
        
        return (diff === 1) ? `${diff} Hour` : `${diff} Hours`;
    };

    const closePopup = () => {
        setCancelBookingModal(false);
        cancelSuccess && callback && callback();
    };

    return (
        <div className="cancel-booking-container">
            { isLoading && <LoaderComponent isLoading /> }
            <div className="heading">{t("manageBooking.cancelMeeting")}</div>
            <div className="title">{currentBookingData.meeting_desc}</div>
            <div className="other-details">
            <div className="location">
                    <div className="label">{t("manageBooking.meetingLocation")}</div>
                    <div className="loc">
                        {currentBookingData.meeting_location}
                    </div>
                </div>
                <div className="timing">
                    <div className="date">
                        {formattedMeetingDate}
                    </div>
                    <div className="time">
                        {currentBookingData.meeting_timing}
                    </div>
                    <div className="duration">
                        {t("duration")}: {getMeetingDuration(currentBookingData.meeting_id)}
                    </div>
                </div>
            </div>
            <div className="success-err-msg">{successErrMsg}</div>
            <div className="btn">
                <div className="confirm">
                    <EonUiButton text={t("confirm")} disabled={cancelSuccess} onClick={() => cancelBooking(currentBookingData.meeting_id)}></EonUiButton>
                </div>
                <div className="cancel">
                    <EonUiButton rank="secondary" text={successErrMsg === t("cancelBookingApiSucessMsg") ? t("close") : t("cancel")} onClick={closePopup}></EonUiButton>
                </div>
            </div>
        </div>
    );
};

export default CancelBooking;
