import React from "react";
// @ts-ignore
import app from "./customIcons/app.svg";
// @ts-ignore
import eon from "./headerLogos/eon.svg";
// @ts-ignore
import bayernwerk from "./headerLogos/bayernwerk.svg";
// @ts-ignore
import me8bayern from "./headerLogos/me8bayern.svg";
// @ts-ignore
import me8eon from "./headerLogos/me8eon.svg";

// @ts-ignore
import confluence from "./customIcons/confluence.svg";
// @ts-ignore
import teams from "./customIcons/teams.svg";
// @ts-ignore
import outlook from "./customIcons/outlook.svg";
// @ts-ignore
import gertrud from "./customIcons/gertrud.svg";
// @ts-ignore
import tools from "./customIcons/tools.svg";
// @ts-ignore
import gitlab from "./customIcons/gitlab.svg";
// @ts-ignore
import AiIcon from "./customIcons/ai-icon32.svg";
// @ts-ignore
import docx from "./customIcons/docx.svg";
// @ts-ignore
import cart from "./customIcons/cart.svg";

// @ts-ignore
import xlsx from "./customIcons/xlsx.svg";
// @ts-ignore
import pptx from "./customIcons/pptx.svg";
// @ts-ignore
import pdf from "./customIcons/pdf.svg";
// @ts-ignore
import loop from "./customIcons/loop.tsx";



// @ts-ignore
import AiIcon24 from "./customIcons/ai-icon24.svg";
// @ts-ignore
import jira from "./customIcons/jira.svg";
// @ts-ignore
import journey from "./customIcons/journey.svg";
// @ts-ignore
import knowledge from "./customIcons/knowledge.svg";
// @ts-ignore
import logoEonRed from "./customIcons/logoEonRed.svg";
// @ts-ignore
import mail from "./customIcons/mail.svg";
// @ts-ignore
import mygenius from "./customIcons/mygenius.svg";
// @ts-ignore
import pki from "./customIcons/pki.svg";
// @ts-ignore
import quick_actions from "./customIcons/quick_actions.svg";
// @ts-ignore
import sharepoint from "./customIcons/sharepoint.svg";
// @ts-ignore
import genericfile from "./customIcons/genericfile.svg";
// @ts-ignore
import user from "./customIcons/user.svg";
// @ts-ignore
import zuko from "./customIcons/zuko.svg";
// @ts-ignore
import all from "./customIcons/all.svg";

// Added above svg needs to be removed from below link
// frontend-react\modules\frontend\public\icons\


// @ts-ignore
import add from "./icons/add.svg";
// @ts-ignore
import alert from "./icons/alert.svg";
// @ts-ignore
import all_in_one from "./icons/all_in_one.svg";
// @ts-ignore
import all_in_one_alt from "./icons/all_in_one_alt.svg";
// @ts-ignore
import arrow_down from "./icons/arrow_down.svg";
// @ts-ignore
import arrow_left from "./icons/arrow_left.svg";
// @ts-ignore
import arrow_right from "./icons/arrow_right.svg";
// @ts-ignore
import arrow_small_down from "./icons/arrow_small_down.svg";
// @ts-ignore
import arrow_small_left from "./icons/arrow_small_left.svg";
// @ts-ignore
import arrow_small_right from "./icons/arrow_small_right.svg";
// @ts-ignore
import arrow_small_up from "./icons/arrow_small_up.svg";
// @ts-ignore
import arrow_up from "./icons/arrow_up.svg";
// @ts-ignore
import ask_eon from "./icons/ask_eon.svg";
// @ts-ignore
import battery_0 from "./icons/battery-0.svg";
// @ts-ignore
import battery_25 from "./icons/battery-25.svg";
// @ts-ignore
import battery_50 from "./icons/battery-50.svg";
// @ts-ignore
import battery_75 from "./icons/battery-75.svg";
// @ts-ignore
import battery_full from "./icons/battery-full.svg";
// @ts-ignore
import bookmark from "./icons/bookmark.svg";
// @ts-ignore
import bookmark_filled from "./icons/bookmark_filled.svg";
// @ts-ignore
import bookmark_outline from "./icons/bookmark_outline.svg";
// @ts-ignore
import briefcase from "./icons/briefcase.svg";
// @ts-ignore
import bulb from "./icons/bulb.svg";
// @ts-ignore
import bulb_green from "./icons/bulb_green.svg";
// @ts-ignore
import bad_rating from "./icons/bad-rating.svg";
// @ts-ignore
import bulb_idea from "./icons/bulb_idea.svg";
// @ts-ignore
import bulletlist from "./icons/bulletlist.svg";
// @ts-ignore
import bulletlist_square from "./icons/bulletlist_square.svg";
// @ts-ignore
import calendar from "./icons/calendar.svg";
// @ts-ignore
import call_center from "./icons/call-center.svg";
// @ts-ignore
import camera from "./icons/camera.svg";
// @ts-ignore
import camera_video from "./icons/camera_video.svg";
// @ts-ignore
import camera_video_alt from "./icons/camera_video_alt.svg";
// @ts-ignore
import captions_off from "./icons/captions-off.svg";
// @ts-ignore
import captions_on from "./icons/captions-on.svg";
// @ts-ignore
import car_electric from "./icons/car_electric.svg";
// @ts-ignore
import change_contract_01 from "./icons/change_contract_01.svg";
// @ts-ignore
import change_contract_01_alt from "./icons/change_contract_01_alt.svg";
// @ts-ignore
import change_contract_02 from "./icons/change_contract_02.svg";
// @ts-ignore
import change_contract_02_alt from "./icons/change_contract_02_alt.svg";
// @ts-ignore
import charger from "./icons/charger.svg";
// @ts-ignore
import chart_bar from "./icons/chart_bar.svg";
// @ts-ignore
import chart_pie from "./icons/chart_pie.svg";
// @ts-ignore
import chat from "./icons/chat.svg";
// @ts-ignore
import chat_settings from "./icons/chat_settings.svg";
// @ts-ignore
import check_carrier from "./icons/check_carrier.svg";
// @ts-ignore
import checkbox from "./icons/checkbox.svg";
// @ts-ignore
import checkbox_checked from "./icons/checkbox_checked.svg";
// @ts-ignore
import checkbox_checked_inverted from "./icons/checkbox_checked_inverted.svg";
// @ts-ignore
import checkbox_filled from "./icons/checkbox_filled.svg";
// @ts-ignore
import checkbox_inverted from "./icons/checkbox_inverted.svg";
// @ts-ignore
import checkbox_mixed from "./icons/checkbox_mixed.svg";
// @ts-ignore
import checkbox_mixed_inverted from "./icons/checkbox_mixed_inverted.svg";
// @ts-ignore
import chevron_bold_down from "./icons/chevron_bold_down.svg";
// @ts-ignore
import chevron_bold_left from "./icons/chevron_bold_left.svg";
// @ts-ignore
import chevron_bold_right from "./icons/chevron_bold_right.svg";
// @ts-ignore
import chevron_bold_up from "./icons/chevron_bold_up.svg";
// @ts-ignore
import chevron_circle_down from "./icons/chevron_circle_down.svg";
// @ts-ignore
import chevron_circle_left from "./icons/chevron_circle_left.svg";
// @ts-ignore
import chevron_circle_right from "./icons/chevron_circle_right.svg";
// @ts-ignore
import chevron_circle_up from "./icons/chevron_circle_up.svg";
// @ts-ignore
import chevron_small_down from "./icons/chevron_small_down.svg";
// @ts-ignore
import chevron_small_left from "./icons/chevron_small_left.svg";
// @ts-ignore
import chevron_small_right from "./icons/chevron_small_right.svg";
// @ts-ignore
import chevron_small_up from "./icons/chevron_small_up.svg";
// @ts-ignore
import clock from "./icons/clock.svg";
// @ts-ignore
import clock_alt from "./icons/clock_alt.svg";
// @ts-ignore
import close from "./icons/close.svg";
// @ts-ignore
import close_minimize from "./icons/close_minimize.svg";
// @ts-ignore
import collect_download from "./icons/collect_download.svg";
// @ts-ignore
import collect_download_selected from "./icons/collect_download_selected.svg";
// @ts-ignore
import comma_digits from "./icons/comma_digits.svg";
// @ts-ignore
import communities from "./icons/communities.svg";
// @ts-ignore
import compare from "./icons/compare.svg";
// @ts-ignore
import compare_alt from "./icons/compare_alt.svg";
// @ts-ignore
import consumption from "./icons/consumption.svg";
// @ts-ignore
import contract from "./icons/contract.svg";
// @ts-ignore
import contract_alt from "./icons/contract_alt.svg";
// @ts-ignore
import contract_alt_2 from "./icons/contract_alt_2.svg";
// @ts-ignore
import copy from "./icons/copy.svg";
// @ts-ignore
import credit_card from "./icons/credit_card.svg";
// @ts-ignore
import cross from "./icons/cross.svg";
// @ts-ignore
import cross_carrier from "./icons/cross_carrier.svg";
// @ts-ignore
import currency_dollar from "./icons/currency_dollar.svg";
// @ts-ignore
import cross_red from "./icons/cross_red.svg";
// @ts-ignore
import deviceIcon from "./customIcons/laptop.svg";
// @ts-ignore
import co2 from "./customIcons/CO2.svg";
// @ts-ignore
import currency_dollar_alt from "./icons/currency_dollar_alt.svg";
// @ts-ignore
import currency_euro from "./icons/currency_euro.svg";
// @ts-ignore
import currency_pound from "./icons/currency_pound.svg";
// @ts-ignore
import dashboard from "./icons/dashboard.svg";
// @ts-ignore
import dashboard_02 from "./icons/dashboard_02.svg";
// @ts-ignore
import dashboard_alt_1 from "./icons/dashboard_alt_1.svg";
// @ts-ignore
import dashboard_alt_2 from "./icons/dashboard_alt_2.svg";
// @ts-ignore
import datepicker from "./icons/datepicker.svg";
// @ts-ignore
import digit_0 from "./icons/digit_0.svg";
// @ts-ignore
import digit_0_light from "./icons/digit_0_light.svg";
// @ts-ignore
import digit_0_solid from "./icons/digit_0_solid.svg";
// @ts-ignore
import digit_1 from "./icons/digit_1.svg";
// @ts-ignore
import digit_1_light from "./icons/digit_1_light.svg";
// @ts-ignore
import digit_1_solid from "./icons/digit_1_solid.svg";
// @ts-ignore
import digit_2 from "./icons/digit_2.svg";
// @ts-ignore
import digit_2_light from "./icons/digit_2_light.svg";
// @ts-ignore
import digit_2_solid from "./icons/digit_2_solid.svg";
// @ts-ignore
import digit_3 from "./icons/digit_3.svg";
// @ts-ignore
import digit_3_light from "./icons/digit_3_light.svg";
// @ts-ignore
import digit_3_solid from "./icons/digit_3_solid.svg";
// @ts-ignore
import digit_4 from "./icons/digit_4.svg";
// @ts-ignore
import digit_4_light from "./icons/digit_4_light.svg";
// @ts-ignore
import digit_4_solid from "./icons/digit_4_solid.svg";
// @ts-ignore
import digit_5 from "./icons/digit_5.svg";
// @ts-ignore
import digit_5_light from "./icons/digit_5_light.svg";
// @ts-ignore
import digit_5_solid from "./icons/digit_5_solid.svg";
// @ts-ignore
import digit_6 from "./icons/digit_6.svg";
// @ts-ignore
import digit_6_light from "./icons/digit_6_light.svg";
// @ts-ignore
import digit_6_solid from "./icons/digit_6_solid.svg";
// @ts-ignore
import digit_7 from "./icons/digit_7.svg";
// @ts-ignore
import digit_7_light from "./icons/digit_7_light.svg";
// @ts-ignore
import digit_7_solid from "./icons/digit_7_solid.svg";
// @ts-ignore
import digit_8 from "./icons/digit_8.svg";
// @ts-ignore
import digit_8_light from "./icons/digit_8_light.svg";
// @ts-ignore
import digit_8_solid from "./icons/digit_8_solid.svg";
// @ts-ignore
import digit_9 from "./icons/digit_9.svg";
// @ts-ignore
import digit_9_light from "./icons/digit_9_light.svg";
// @ts-ignore
import digit_9_solid from "./icons/digit_9_solid.svg";
// @ts-ignore
import digitalisation from "./icons/digitalisation.svg";
// @ts-ignore
import divide from "./icons/divide.svg";
// @ts-ignore
import download from "./icons/download.svg";
// @ts-ignore
import download_carrier from "./icons/download_carrier.svg";
// @ts-ignore
import dragndrop from "./icons/dragndrop.svg";
// @ts-ignore
import dragndrop_alt from "./icons/dragndrop_alt.svg";
// @ts-ignore
import edit from "./icons/edit.svg";
// @ts-ignore
import edit_no_line from "./icons/edit_no_line.svg";
// @ts-ignore
import electricity from "./icons/electricity.svg";
// @ts-ignore
import electricity_filled from "./icons/electricity_filled.svg";
// @ts-ignore
import emergency_numbers from "./icons/emergency_numbers.svg";
// @ts-ignore
import equal_sign from "./icons/equal_sign.svg";
// @ts-ignore
import error from "./icons/error.svg";
// @ts-ignore
import expand from "./icons/expand.svg";
// @ts-ignore
import facebook from "./icons/facebook.svg";
// @ts-ignore
import favourite from "./icons/favourite.svg";
// @ts-ignore
import favourite_outline from "./icons/favourite_outline.svg";
// @ts-ignore
import fax from "./icons/fax.svg";
// @ts-ignore
import file from "./icons/file.svg";
// @ts-ignore
import file_alert from "./icons/file_alert.svg";
// @ts-ignore
import file_image from "./icons/file_image.svg";
// @ts-ignore
import file_pdf from "./icons/file_pdf.svg";
// @ts-ignore
import filter from "./icons/filter.svg";
// @ts-ignore
import fingerprint from "./icons/fingerprint.svg";
// @ts-ignore
import floor_plan from "./icons/floor_plan.svg";
// @ts-ignore
import forward from "./icons/forward.svg";
// @ts-ignore
import fullscreen_enter from "./icons/fullscreen_enter.svg";
// @ts-ignore
import fullscreen_exit from "./icons/fullscreen_exit.svg";
// @ts-ignore
import funnel from "./icons/funnel.svg";
// @ts-ignore
import gas from "./icons/gas.svg";
// @ts-ignore
import get_in_touch from "./icons/get_in_touch.svg";
// @ts-ignore
import gift from "./icons/gift.svg";
// @ts-ignore
import graph_growth from "./icons/graph_growth.svg";
// @ts-ignore
import heat from "./icons/heat.svg";
// @ts-ignore
import home from "./icons/home.svg";
// @ts-ignore
import home_add_solution from "./icons/home_add_solution.svg";
// @ts-ignore
import home_add_solution_alt from "./icons/home_add_solution_alt.svg";
// @ts-ignore
import home_green from "./icons/home_green.svg";
// @ts-ignore
import home_green_alt from "./icons/home_green_alt.svg";
// @ts-ignore
import home_services from "./icons/home_services.svg";
// @ts-ignore
import home_services_alt from "./icons/home_services_alt.svg";
// @ts-ignore
import home_setup from "./icons/home_setup.svg";
// @ts-ignore
import home_solutions from "./icons/home_solutions.svg";
// @ts-ignore
import hydrogen from "./icons/hydrogen.svg";
// @ts-ignore
import info from "./icons/info.svg";
// @ts-ignore
import info_large from "./icons/info_large.svg";
// @ts-ignore
import info_large_filled from "./icons/info_large_filled.svg";
// @ts-ignore
import info_secondary from "./icons/info_secondary.svg";
// @ts-ignore
import info_secondary_large from "./icons/info_secondary_large.svg";
// @ts-ignore
import info_tertiary from "./icons/info_tertiary.svg";
// @ts-ignore
import landline from "./icons/landline.svg";
// @ts-ignore
import lang_select from "./icons/lang_select.svg";
// @ts-ignore
import link_external from "./icons/link_external.svg";
// @ts-ignore
import location from "./icons/location.svg";
// @ts-ignore
import lock from "./icons/lock.svg";
// @ts-ignore
import logo_eon from "./icons/logo_eon.svg";
// @ts-ignore
import logout from "./icons/logout.svg";
// @ts-ignore
import menu from "./icons/menu.svg";
// @ts-ignore
import menu_small from "./icons/menu_small.svg";
// @ts-ignore
import messenger_01 from "./icons/messenger_01.svg";
// @ts-ignore
import meter_read from "./icons/meter_read.svg";
// @ts-ignore
import meter_read_add from "./icons/meter_read_add.svg";
// @ts-ignore
import meter_read_add_01 from "./icons/meter_read_add_01.svg";
// @ts-ignore
import meter_read_add_alt from "./icons/meter_read_add_alt.svg";
// @ts-ignore
import meter_read_alt from "./icons/meter_read_alt.svg";
// @ts-ignore
import meter_read_auto from "./icons/meter_read_auto.svg";
// @ts-ignore
import meter_read_auto_02 from "./icons/meter_read_auto_02.svg";
// @ts-ignore
import meter_read_auto_02_alt from "./icons/meter_read_auto_02_alt.svg";
// @ts-ignore
import meter_read_auto_alt from "./icons/meter_read_auto_alt.svg";
// @ts-ignore
import meter_read_no_read from "./icons/meter_read_no_read.svg";
// @ts-ignore
import meter_read_no_read_alt from "./icons/meter_read_no_read_alt.svg";
// @ts-ignore
import mobile from "./icons/mobile.svg";
// @ts-ignore
import modular from "./icons/modular.svg";
// @ts-ignore
import more from "./icons/more.svg";
// @ts-ignore
import more_small from "./icons/more_small.svg";
// @ts-ignore
import my_product from "./icons/my_product.svg";
// @ts-ignore
import newsfeed from "./icons/newsfeed.svg";
// @ts-ignore
import newsfeed_alt from "./icons/newsfeed_alt.svg";
// @ts-ignore
import notification from "./icons/notification.svg";
// @ts-ignore
import notification_bell from "./icons/notification_bell.svg";
// @ts-ignore
import notification_outline from "./icons/notification_outline.svg";
// @ts-ignore
import open_folder from "./icons/open-folder.svg";
// @ts-ignore
import open_maximize from "./icons/open_maximize.svg";
// @ts-ignore
import option from "./icons/option.svg";
// @ts-ignore
import pages from "./icons/pages.svg";
// @ts-ignore
import password_invisible from "./icons/password_invisible.svg";
// @ts-ignore
import password_visible from "./icons/password_visible.svg";
// @ts-ignore
import pause from "./icons/pause.svg";
// @ts-ignore
import payment from "./icons/payment.svg";
// @ts-ignore
import pc_mac from "./icons/pc_mac.svg";
// @ts-ignore
import phone from "./icons/phone.svg";
// @ts-ignore
import play from "./icons/play.svg";
// @ts-ignore
import play_carrier from "./icons/play_carrier.svg";
// @ts-ignore
import play_filled from "./icons/play_filled.svg";
// @ts-ignore
import plug_2_pin from "./icons/plug-2-pin.svg";
// @ts-ignore
import plug_3_pin from "./icons/plug-3-pin.svg";
// @ts-ignore
import plug_cable from "./icons/plug_cable.svg";
// @ts-ignore
import plus_circle from "./icons/plus_circle.svg";
// @ts-ignore
import plug_wall from "./icons/plug_wall.svg";
// @ts-ignore
import printer from "./icons/printer.svg";
// @ts-ignore
import profile_logged_in from "./icons/profile_logged_in.svg";
// @ts-ignore
import protection from "./icons/protection.svg";
// @ts-ignore
import put_into_folder from "./icons/put-into-folder.svg";
// @ts-ignore
import pylon from "./icons/pylon.svg";
// @ts-ignore
import question_mark from "./icons/question_mark.svg";
// @ts-ignore
import question_mark_circle from "./icons/question_mark_circle.svg";
// @ts-ignore
import quote from "./icons/quote.svg";
// @ts-ignore
import quote_filled from "./icons/quote_filled.svg";
// @ts-ignore
import radiator from "./icons/radiator.svg";
// @ts-ignore
import radio from "./icons/radio.svg";
// @ts-ignore
import radio_checkbox_ring from "./icons/radio_checkbox_ring.svg";
// @ts-ignore
import radio_checkbox_ring_02 from "./icons/radio_checkbox_ring_02.svg";
// @ts-ignore
import radio_checkbox_ring_03 from "./icons/radio_checkbox_ring_03.svg";
// @ts-ignore
import radio_checkbox_tick from "./icons/radio_checkbox_tick.svg";
// @ts-ignore
import radio_filled from "./icons/radio_filled.svg";
// @ts-ignore
import rating_star_empty from "./icons/rating-star-empty.svg";
// @ts-ignore
import rating_star_half from "./icons/rating-star-half.svg";
// @ts-ignore
import rating_star_outline from "./icons/rating-star-outline.svg";
// @ts-ignore
import rating_star from "./icons/rating-star.svg";
// @ts-ignore
import rating_negative from "./icons/rating_negative.svg";
// @ts-ignore
import rating_neutral from "./icons/rating_neutral.svg";
// @ts-ignore
import rating_positive from "./icons/rating_positive.svg";
// @ts-ignore
import reload from "./icons/reload.svg";
// @ts-ignore
import reward_program from "./icons/reward_program.svg";
// @ts-ignore
import reward_program_alt from "./icons/reward_program_alt.svg";
// @ts-ignore
import rewind from "./icons/rewind.svg";
// @ts-ignore
import save from "./icons/save.svg";
// @ts-ignore
import save_2 from "./icons/save_2.svg";
// @ts-ignore
import search from "./icons/search.svg";
// @ts-ignore
import send from "./icons/send.svg";
// @ts-ignore
import settings from "./icons/settings.svg";
// @ts-ignore
import shading from "./icons/shading.svg";
// @ts-ignore
import share from "./icons/share.svg";
// @ts-ignore
import shell from "./icons/shell.svg";
// @ts-ignore
import shopping_cart from "./icons/shopping_cart.svg";
// @ts-ignore
import shopping_cart_add from "./icons/shopping_cart_add.svg";
// @ts-ignore
import shopping_cart_added from "./icons/shopping_cart_added.svg";
// @ts-ignore
import slider from "./icons/slider.svg";
// @ts-ignore
import smartphone from "./icons/smartphone.svg";
// @ts-ignore
import smartphone_checked from "./icons/smartphone_checked.svg";
// @ts-ignore
import snowflake from "./icons/snowflake.svg";
// @ts-ignore
import social_connect from "./icons/social_connect.svg";
// @ts-ignore
import social_facebook from "./icons/social_facebook.svg";
// @ts-ignore
import social_glass_door from "./icons/social_glass_door.svg";
// @ts-ignore
import social_google_plus from "./icons/social_google_plus.svg";
// @ts-ignore
import social_instagram from "./icons/social_instagram.svg";
// @ts-ignore
import social_kununu from "./icons/social_kununu.svg";
// @ts-ignore
import social_linked_in from "./icons/social_linked_in.svg";
// @ts-ignore
import social_pinterest from "./icons/social_pinterest.svg";
// @ts-ignore
import social_share from "./icons/social_share.svg";
// @ts-ignore
import social_share_alt from "./icons/social_share_alt.svg";
// @ts-ignore
import social_snapchat from "./icons/social_snapchat.svg";
// @ts-ignore
import social_spotify from "./icons/social_spotify.svg";
// @ts-ignore
import social_tiktok from "./icons/social_tiktok.svg";
// @ts-ignore
import social_twitter from "./icons/social_twitter.svg";
// @ts-ignore
import social_whatsapp from "./icons/social_whatsapp.svg";
// @ts-ignore
import social_xing from "./icons/social_xing.svg";
// @ts-ignore
import social_youtube from "./icons/social_youtube.svg";
// @ts-ignore
import solar_panel from "./icons/solar_panel.svg";
// @ts-ignore
import star from "./icons/star.svg";
// @ts-ignore
import step from "./icons/step.svg";
// @ts-ignore
import stop from "./icons/stop.svg";
// @ts-ignore
import stop_filled from "./icons/stop_filled.svg";
// @ts-ignore
import subtract from "./icons/subtract.svg";
// @ts-ignore
import success from "./icons/success.svg";
// @ts-ignore
import sun from "./icons/sun.svg";
// @ts-ignore
import sustainability from "./icons/sustainability.svg";
// @ts-ignore
import sustainability_alt from "./icons/sustainability_alt.svg";
// @ts-ignore
import tablet from "./icons/tablet.svg";
// @ts-ignore
import tablet_alt from "./icons/tablet_alt.svg";
// @ts-ignore
import tag from "./icons/tag.svg";
// @ts-ignore
import talk_to_us from "./icons/talk_to_us.svg";
// @ts-ignore
import television from "./icons/television.svg";
// @ts-ignore
import thermometer from "./icons/thermometer.svg";
// @ts-ignore
import thermostat_analog from "./icons/thermostat_analog.svg";
// @ts-ignore
import thermostat_digital from "./icons/thermostat_digital.svg";
// @ts-ignore
import thermostatic_radiator_valve from "./icons/thermostatic-radiator-valve.svg";
// @ts-ignore
import thumb_up from "./icons/thumb_up.svg";
// @ts-ignore
import tick from "./icons/tick.svg";
// @ts-ignore
import tick_small from "./icons/tick_small.svg";
// @ts-ignore
import toolkit from "./icons/toolkit.svg";
// @ts-ignore
import trash_bin from "./icons/trash_bin.svg";
// @ts-ignore
import tree_large from "./icons/tree_large.svg";
// @ts-ignore
import task_failed from "./icons/task_failed.svg";
// @ts-ignore
import upload from "./icons/upload.svg";
// @ts-ignore
import upload_carrier from "./icons/upload_carrier.svg";
// @ts-ignore
// import user from "./icons/user.svg";
// @ts-ignore
import user_business from "./icons/user_business.svg";
// @ts-ignore
import user_group from "./icons/user_group.svg";
// @ts-ignore
import user_group_alt from "./icons/user_group_alt.svg";
// @ts-ignore
import user_outline from "./icons/user_outline.svg";
// @ts-ignore
import value_proposition from "./icons/value_proposition.svg";
// @ts-ignore
import video_replay from "./icons/video_replay.svg";
// @ts-ignore
import volume_mute from "./icons/volume_mute.svg";
// @ts-ignore
import volume_normal from "./icons/volume_normal.svg";
// @ts-ignore
import water from "./icons/water.svg";
// @ts-ignore
import water_filled from "./icons/water_filled.svg";
// @ts-ignore
import wifi from "./icons/wifi.svg";
// @ts-ignore
import wind from "./icons/wind.svg";
// @ts-ignore
import wind_alt from "./icons/wind_alt.svg";
// @ts-ignore
import germany from "./customIcons/countries/germany.svg";
// @ts-ignore
import hungary from "./customIcons/countries/hungary.svg";
// @ts-ignore
import poland from "./customIcons/countries/poland.svg";
// @ts-ignore
import romania from "./customIcons/countries/romania.svg";
// @ts-ignore
import sweden from "./customIcons/countries/sweden.svg";
// @ts-ignore
import united_kingdom from "./customIcons/countries/united_kingdom.svg";
// @ts-ignore
import czech_republic from "./customIcons/countries/czech_republic.svg";
// @ts-ignore
import denmark from "./customIcons/countries/czech_republic.svg";
// @ts-ignore
import eu from "./customIcons/countries/eu.svg";
// @ts-ignore
import luxembourg from "./customIcons/countries/luxembourg.svg";
// @ts-ignore
import netherlands from "./customIcons/countries/netherlands.svg";
// @ts-ignore
import norway from "./customIcons/countries/norway.svg";
// @ts-ignore
import oneHotseat from "./customIcons/roomBooking/oneHotseat.svg";
// @ts-ignore
import fourRoom from "./customIcons/roomBooking/fourRoom.svg";
// @ts-ignore
import building from "./customIcons/roomBooking/building.svg";
// @ts-ignore
import city from "./customIcons/roomBooking/city.svg";
// @ts-ignore
import floor from "./customIcons/roomBooking/floor.svg";
// @ts-ignore
import area from "./customIcons/roomBooking/area.svg";
// @ts-ignore
import state from "./customIcons/roomBooking/state.svg";
// @ts-ignore
import street from "./customIcons/roomBooking/street.svg";

export const Icons = {
    eon,
    bayernwerk,
    me8bayern,
    me8eon,
    app,
    tools,
    confluence,
    gitlab,
    AiIcon,
    AiIcon24,
    jira,
    journey,
    knowledge,
    logoEonRed,
    mail,
    mygenius,
    pki,
    quick_actions,
    sharepoint,
    user,
    zuko,
    all,
    cart,
    docx,
    xlsx,
    pdf,
    pptx,
    genericfile,
    deviceIcon,
    co2,
    gertrud,
    teams,
    outlook,
    germany,
    hungary,
    poland,
    romania,
    sweden,
    united_kingdom,
    czech_republic,
    denmark,
    eu,
    luxembourg,
    netherlands,
    norway,
    oneHotseat,
    fourRoom,
    building,
    city,
    floor,
    area,
    state,
    street,
    loop,



    //may be not required 
    add,
    alert,
    all_in_one,
    all_in_one_alt,
    arrow_down,
    arrow_left,
    arrow_right,
    arrow_small_down,
    arrow_small_left,
    arrow_small_right,
    arrow_small_up,
    arrow_up,
    ask_eon,
    battery_0,
    battery_25,
    battery_50,
    battery_75,
    battery_full,
    bookmark,
    bookmark_filled,
    bookmark_outline,
    briefcase,
    bulb,
    bad_rating,
    bulb_green,
    bulb_idea,
    bulletlist,
    bulletlist_square,
    calendar,
    call_center,
    camera,
    camera_video,
    camera_video_alt,
    captions_off,
    captions_on,
    car_electric,
    change_contract_01,
    change_contract_01_alt,
    change_contract_02,
    change_contract_02_alt,
    charger,
    chart_bar,
    chart_pie,
    chat,
    chat_settings,
    check_carrier,
    checkbox,
    checkbox_checked,
    checkbox_checked_inverted,
    checkbox_filled,
    checkbox_inverted,
    checkbox_mixed,
    checkbox_mixed_inverted,
    chevron_bold_down,
    chevron_bold_left,
    chevron_bold_right,
    chevron_bold_up,
    chevron_circle_down,
    chevron_circle_left,
    chevron_circle_right,
    chevron_circle_up,
    chevron_small_down,
    chevron_small_left,
    chevron_small_right,
    chevron_small_up,
    clock,
    clock_alt,
    close,
    close_minimize,
    collect_download,
    collect_download_selected,
    comma_digits,
    communities,
    compare,
    compare_alt,
    consumption,
    contract,
    contract_alt,
    contract_alt_2,
    copy,
    credit_card,
    cross,
    cross_carrier,
    cross_red,
    currency_dollar,
    currency_dollar_alt,
    currency_euro,
    currency_pound,
    dashboard,
    dashboard_02,
    dashboard_alt_1,
    dashboard_alt_2,
    datepicker,
    digit_0,
    digit_0_light,
    digit_0_solid,
    digit_1,
    digit_1_light,
    digit_1_solid,
    digit_2,
    digit_2_light,
    digit_2_solid,
    digit_3,
    digit_3_light,
    digit_3_solid,
    digit_4,
    digit_4_light,
    digit_4_solid,
    digit_5,
    digit_5_light,
    digit_5_solid,
    digit_6,
    digit_6_light,
    digit_6_solid,
    digit_7,
    digit_7_light,
    digit_7_solid,
    digit_8,
    digit_8_light,
    digit_8_solid,
    digit_9,
    digit_9_light,
    digit_9_solid,
    digitalisation,
    divide,
    download,
    download_carrier,
    dragndrop,
    dragndrop_alt,
    edit,
    edit_no_line,
    electricity,
    electricity_filled,
    emergency_numbers,
    equal_sign,
    error,
    expand,
    facebook,
    favourite,
    favourite_outline,
    fax,
    file,
    file_alert,
    file_image,
    file_pdf,
    filter,
    fingerprint,
    floor_plan,
    forward,
    fullscreen_enter,
    fullscreen_exit,
    funnel,
    gas,
    get_in_touch,
    gift,
    graph_growth,
    heat,
    home,
    home_add_solution,
    home_add_solution_alt,
    home_green,
    home_green_alt,
    home_services,
    home_services_alt,
    home_setup,
    home_solutions,
    hydrogen,
    info,
    info_large,
    info_large_filled,
    info_secondary,
    info_secondary_large,
    info_tertiary,
    landline,
    lang_select,
    link_external,
    location,
    lock,
    logo_eon,
    logout,
    menu,
    menu_small,
    messenger_01,
    meter_read,
    meter_read_add,
    meter_read_add_01,
    meter_read_add_alt,
    meter_read_alt,
    meter_read_auto,
    meter_read_auto_02,
    meter_read_auto_02_alt,
    meter_read_auto_alt,
    meter_read_no_read,
    meter_read_no_read_alt,
    mobile,
    modular,
    more,
    more_small,
    my_product,
    newsfeed,
    newsfeed_alt,
    notification,
    notification_bell,
    notification_outline,
    open_folder,
    open_maximize,
    option,
    pages,
    password_invisible,
    password_visible,
    pause,
    payment,
    pc_mac,
    phone,
    play,
    play_carrier,
    play_filled,
    plug_2_pin,
    plug_3_pin,
    plug_cable,
    plug_wall,
    plus_circle,
    printer,
    profile_logged_in,
    protection,
    put_into_folder,
    pylon,
    question_mark,
    question_mark_circle,
    quote,
    quote_filled,
    radiator,
    radio,
    radio_checkbox_ring,
    radio_checkbox_ring_02,
    radio_checkbox_ring_03,
    radio_checkbox_tick,
    radio_filled,
    rating_star_empty,
    rating_star_half,
    rating_star_outline,
    rating_star,
    rating_negative,
    rating_neutral,
    rating_positive,
    reload,
    reward_program,
    reward_program_alt,
    rewind,
    save,
    save_2,
    search,
    send,
    settings,
    shading,
    share,
    shell,
    shopping_cart,
    shopping_cart_add,
    shopping_cart_added,
    slider,
    smartphone,
    smartphone_checked,
    snowflake,
    social_connect,
    social_facebook,
    social_glass_door,
    social_google_plus,
    social_instagram,
    social_kununu,
    social_linked_in,
    social_pinterest,
    social_share,
    social_share_alt,
    social_snapchat,
    social_spotify,
    social_tiktok,
    social_twitter,
    social_whatsapp,
    social_xing,
    social_youtube,
    solar_panel,
    star,
    step,
    stop,
    stop_filled,
    subtract,
    success,
    sun,
    sustainability,
    sustainability_alt,
    tablet,
    tablet_alt,
    tag,
    task_failed,
    talk_to_us,
    television,
    thermometer,
    thermostat_analog,
    thermostat_digital,
    thermostatic_radiator_valve,
    thumb_up,
    tick,
    tick_small,
    toolkit,
    trash_bin,
    tree_large,
    upload,
    upload_carrier,
    user_business,
    user_group,
    user_group_alt,
    user_outline,
    value_proposition,
    video_replay,
    volume_mute,
    volume_normal,
    water,
    water_filled,
    wifi,
    wind,
    wind_alt,

};

export type IconName = keyof typeof Icons
