import { AppDisplay, OneLineAppDisplay, SimpleAppWidget } from "./simple.apps.display";
import { DataPlugin, DisplayData, DisplayDataWidget } from "@me8eon/data";


export const AppsDataName = 'app';
//{
//   "queryType": "boostAndPhrase",
//   "synKeywords": "Timesheet, Time, Time Track, time-sheet, CATS, book hour, Zeiterfassung, Stunde erfassen, Catsen, Stundenzettel, Buchstunde, Where can I find my Timesheet, How often should I fill my Timesheet, Wo kann ich meinen Stundenzettel finden, Wie oft sollte ich meinen Stundenzettel ausfüllen",
//   "subTitle": "CATS",
//   "icon": "clock_alt",
//   "alias": "time",
//   "model_id": ".multilingual-e5-small_linux-x86_64",
//   "title": "Time-Tracking",
//   "target": "https://peo.apps.eon.com/sap/bc/ui5_ui5/ui2/ushell/shells/abap/FioriLaunchpad.html#xEONx_CATS-manage",
//   "score": 4.2395744
// }
export type AppsData = {
    id: string;
    index: string;
    type: string
    target: string;
    title: string
    subTitle: string
}

export function AppsDataPlugin(
    DisplayData: DisplayData<AppsData> = AppDisplay,
    DisplayDataOneLine: DisplayData<AppsData> = OneLineAppDisplay,
    DisplayDataWidget: DisplayDataWidget<AppsData> = SimpleAppWidget): DataPlugin<AppsData> {
    return {
        plugin: 'data',
        name: AppsDataName,
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget,
        idFrom: data => `${data.index}.${data.id}`
    };
}
