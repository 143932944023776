import React from 'react';
export type AdminButtonProps = {
    text: string
    selected: boolean
    onClick: () => void
}

export function AdminButton({text, selected, onClick}: AdminButtonProps) {
    return <button
        onClick={onClick}
        aria-pressed={selected}
        style={{
            padding: '8px 12px',
            cursor: 'pointer',
            border: '1px solid #ddd',
            borderRadius: '4px',
            backgroundColor: selected ? '#007bff' : '#f0f0f0',
            color: selected ? '#fff' : '#000',
            outline: selected ? '2px solid #007bff' : '2px solid transparent', // Conditional outline based on selection
            outlineOffset: '2px'
        }}
    >{text}</button>;
}