import {SearchFn, SearchFnResult} from "./search";
import {ESApiFinalResponseInterface} from "../interfaces/ElasticSearchInterface";
import {JqlAiClient} from "../jira/jql.client.agent";
import { getKID } from "../util/auth";


export function jiraSearch(
    jqlAiClient: JqlAiClient<any>
): SearchFn<ESApiFinalResponseInterface> {
    return async (query) => {
        const kid = getKID();
        const data = await jqlAiClient({KID: kid}, query.searchTerm);
        if (Array.isArray(data)) throw Error(data.join(','));
        const result: SearchFnResult<any> = {
            data: data.result,
            jql: data.jql,
            totalCount: data.totalCount,
            error: data.error,
            errorMsg: data.errorMsg,
            metadataFilters: {},
            dataSourceFilters: []
        };
        return result;
    };
}
