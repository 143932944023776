import React, { useState, useEffect, useCallback, useMemo } from "react";
import LoaderComponent from "../../loader-component/LoaderComponent";
import { MessageWithActionAndData } from "../AiAssist.context";
import { Icon, ThemedIcon } from "@me8eon/icons";
import TypingEffect from "../utils/typing-effect/TypingEffect";
import { fetchSustainableITReport } from "../../../services/nexthinkService";
import { useTranslation } from "@me8eon/translation";
import "./sustainableIT.component.scss";

const SustainableIT: React.FC<MessageWithActionAndData> = ({
    data,
    content,
}) => {
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState<any>(null);
    const [showRag, setShowRag] = useState(false);
    const t = useTranslation();
    const fetchReport = useCallback(async (deviceId: string) => {
        setLoading(true);
        try {
            const resp = await fetchSustainableITReport(deviceId);
            if (!resp) {
                setShowRag(true);
            } else {
                setResponseData(resp);
            }
        } catch (error) {
            console.error("Error fetching report:", error);
            setShowRag(true);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const deviceId = data?.deviceDetails?.data?.length
            ? data?.deviceDetails?.data[0]["device.collector.uid"]
            : null;
        if (!deviceId) {
            setShowRag(true);
        } else {
            fetchReport(deviceId);
        }
    }, [data, fetchReport]);

    const ReportContent = useMemo(() => {
        return (
            <div className="report-content">
                <div className="device-info">
                    <div className="device-label">
                        {t("chatBot.nexthinkIntegration.device")}
                        <span className="icon">
                            <ThemedIcon name="deviceIcon" />
                        </span>
                    </div>
                    <div className="device-name">
                        {responseData?.deviceName}{" "}
                        <span className="virtual-tag">
                            {responseData?.hardwareType}
                        </span>
                    </div>
                    <div className="uptime">
                        <div className="restart-days">
                            <Icon
                                name={
                                    responseData?.lastBootRating === "poor"
                                        ? "bad_rating"
                                        : "tick_small"
                                }
                                themedColor={
                                    responseData?.lastBootRating === "poor"
                                        ? "#EA1B0A"
                                        : "#00B876"
                                }
                            />
                            <span>
                                {" "}
                                {responseData?.daysSinceLastBoot}{" "}
                                {t("chatBot.nexthinkIntegration.days")}
                            </span>
                        </div>
                        <span className="virtual-tag">
                            {t("chatBot.nexthinkIntegration.sinceRestarted")}
                        </span>
                        <span className="recommendation">
                            <Icon
                                name={
                                    responseData?.lastBootRating === "poor"
                                        ? "bulb_green"
                                        : "thumb_up"
                                }
                                themedColor="#34C759"
                            />
                            <span>
                                {responseData?.lastBootRating === "poor"
                                    ? t(
                                          "chatBot.nexthinkIntegration.deviceRestartRecommendation"
                                      )
                                    : t(
                                          "chatBot.nexthinkIntegration.deviceInCompliance"
                                      )}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="energy-info">
                    <div className="energy-consumed">
                        <div className="device-label">
                            {t("chatBot.nexthinkIntegration.energyConsumed")}
                            <span className="icon">
                                <ThemedIcon name="bulb" />
                            </span>
                        </div>
                        <div>
                            <span className="value">
                                {responseData?.totalEnergyConsumption?.toFixed(
                                    2
                                ) ?? ""}{" "}
                            </span>
                            <span className="unit">k/W</span>
                        </div>
                    </div>
                    <div className="energy-emitted">
                        <div className="device-label">
                            {t("chatBot.nexthinkIntegration.coEmitted")}
                            <span className="icon">
                                <ThemedIcon name="co2" />
                            </span>
                        </div>
                        <div>
                            <span className="value">
                                {responseData?.totalCO2Emitted?.toFixed(3) ??
                                    ""}{" "}
                            </span>
                            <span className="unit">gms</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [responseData, t]);

    const SustainableReport = useMemo(() => {
        return (
            <div className="bot-reply-container">
                {loading ? (
                    <LoaderComponent isLoading={loading} isLinear />
                ) : (
                    <>
                        <div className="title">
                            {t("chatBot.nexthinkIntegration.itReportTitle")}
                        </div>
                        {ReportContent}
                        <div className="compliance">
                            <TypingEffect
                                className="bot-text"
                                text={`${content}<br/><b>Source: Nexthink</b>`}
                                speed={1}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }, [loading, ReportContent, content, t]);

    return (
        <div className="bot-header">
            <div className="eon-logo">
                <ThemedIcon name="AiIcon" />
            </div>
            {showRag ? (
                <TypingEffect
                    className="bot-text"
                    text={data.rag ?? "Something went wrong"}
                    speed={1}
                />
            ) : (
                SustainableReport
            )}
        </div>
    );
};

export default SustainableIT;
