import axios, { AxiosInstance } from 'axios';
import { useTime, TimeService } from "../domain/useTime";
import { EnvironmentConfig } from"@me8eon/config";

// Define the OAuth2 configuration type
export type Oauth2Config = {
    axios: AxiosInstance;
    clientId: string;
    redirectUri: string;
    authorizationEndpoint: string;
    tokenEndpoint: string;
    scopes: string[];
};

// Default configuration
//All needs to use EnvironmentConfig
export const defaultOauth2Config: Oauth2Config = {
    axios,
    clientId: EnvironmentConfig.jiraClientId,
    redirectUri: EnvironmentConfig.fromJiraAuth,
    authorizationEndpoint: `${EnvironmentConfig.jiraURL}/rest/oauth2/latest/authorize`,
    tokenEndpoint: `${EnvironmentConfig.gatewayURL}/proxy/rest/oauth2/latest/token`,
    scopes: ["READ"]
};

// Function to generate a random code verifier
const generateCodeVerifier = (): string => {
    const array = new Uint32Array(56); // Generates a string with 56 characters (satisfies 43-128)
    crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
};

// Function to generate the code challenge from the code verifier
const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(digest));
    const hashBase64 = btoa(String.fromCharCode(...hashArray))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    return hashBase64;
};

export async function getTokenFromCode(codeVerifier: string, code: string | null, config: Oauth2Config = defaultOauth2Config, timeService: TimeService = useTime()): Promise<Oauth2Token> {
    if (!code || !codeVerifier) {
        throw new Error('Authorization code or code verifier not found');
    }
    const params = new URLSearchParams({
        client_id: config.clientId,
        redirect_uri: config.redirectUri,
        code,
        grant_type: 'authorization_code',
        code_verifier: codeVerifier,
    });

    const response = await config.axios.post(config.tokenEndpoint, params.toString(), {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-proxy': EnvironmentConfig.jqlProxy
        },
    });
    const { access_token, expires_in, refresh_token } = response.data;
    const expires_at = timeService().getTime() + (expires_in * 1000);
    return { access_token, expires_in, expires_at, refresh_token };
}


export type PromptType = 'none' | 'login' | 'consent';

export type Oauth2Token = {
    access_token: string;
    expires_in: number;
    expires_at: number,
    refresh_token: string
}

/**
 * Attempts to authorize using a popup window for user approval.
 * @param config OAuth2 configuration
 * @returns Promise that resolves with the authorization code
 */
export async function attemptPopupAuthorization(prompt: PromptType, config: Oauth2Config = defaultOauth2Config): Promise<Oauth2Token> {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    const params = new URLSearchParams({
        client_id: config.clientId,
        redirect_uri: config.redirectUri,
        response_type: 'code',
        prompt,
        scope: config.scopes.join(" "),
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
    });

    const authorizationUrl = `${config.authorizationEndpoint}?${params.toString()}`;

    return new Promise<Oauth2Token>((resolve, reject) => {
        // Open a popup window
        const popup = window.open(
            authorizationUrl,
            'oauth2_popup',
            'width=1200,height=600'
        );

        if (!popup) {
            return reject(new Error('Failed to open popup window'));
        }

        // Polling to check if the popup has been redirected to the redirect URI
        const interval = setInterval(() => {
            try {

                if (popup.closed) {
                    clearInterval(interval);
                    reject(new Error('Popup closed by user'));
                }

                const popupUrl = new URL(popup.location.href);
                if (popupUrl.origin === new URL(config.redirectUri).origin) {
                    const code = popupUrl.searchParams.get('code');
                    if (code) {
                        getTokenFromCode(codeVerifier, code, config).then(token => resolve(token));
                        clearInterval(interval);
                        popup.close();
                    } else {
                        reject(new Error('Authorization code not found in popup'));
                        clearInterval(interval);
                        popup.close();
                    }
                }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (err) {
                console.log(err);
                // Ignore cross-origin access errors until the redirect URI is loaded
            }
        }, 500);

        // Timeout to reject the promise if authorization does not complete in time
        setTimeout(() => {
            clearInterval(interval);
            if (!popup.closed) {
                popup.close();
            }
            reject(new Error('Popup authorization timed out'));
        }, 60000); // 60 seconds timeout
    });
}
