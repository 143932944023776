import { MetadataKeyFilterInterface, SelectedMetadataInterface } from "../../interfaces/ElasticSearchInterface";
import { dateFormat } from "../../util/date";
import getMimeType, { allMimeTypes } from "../../util/mimeType";
import { emptyDataObject, SourceInfo } from "../info";

import {AGGREGATION_SIZE} from "@me8eon/config";

function getDesc(result: any) {
    return result.text && result.text.length > 0 ? result.text : result.url;
}

const index = "sharepoint-prod";

export const sharepointInfo: SourceInfo = {
    match: "sharepoint",
    dropDown:undefined,
    titleAndTarget: (result) => ({
        title: result?.name,
        target: result.url,
        source: "Sharepoint",
    }),
    makeDataObject: (result) => ({
        ...emptyDataObject,
        id: result.id,
        icon: "sharepoint",
        index: result.index,
        link: result.url,
        name: result.name,
        source: result.type,
        desc: getDesc(result),
        type: getMimeType(result?.mimeType),
        createdAt: dateFormat(result.createdDateTime),
        createdBy: result.createdBy,
        lastUpdate: dateFormat(result.last_updated),
    }),
    dateFilterKey: "last_updated",
    refineFilterLabels: (key: string, value: any) => {
        switch (key) {
            case "mimeType": {
                value.map((item: any) => item.label = getMimeType(item.key));
                const uniqueMimeTypes: { [key: string]: MetadataKeyFilterInterface } = {};
                value.forEach((obj: MetadataKeyFilterInterface) => {
                    if (!uniqueMimeTypes[obj.label]) {
                        uniqueMimeTypes[obj.label] = { key: obj.label, doc_count: obj.doc_count, label: obj.label, multi_key: key };
                    } else {
                        // if (uniqueMimeTypes[obj.label].key) uniqueMimeTypes[obj.label].key += ", ";
                        uniqueMimeTypes[obj.label].multi_key = obj.key;
                        uniqueMimeTypes[obj.label].doc_count += obj.doc_count;
                    }
                });
                const uniqueMimeTypesArray: MetadataKeyFilterInterface[] = Object.values(uniqueMimeTypes);
                return { label: "Type", value: uniqueMimeTypesArray };
            }
            default:
                return { label: key, value };
        }
    },
    refineFiltersAggregationQuery: {
        [index]: {
            filter: {
                term: {
                    _index: index,
                },
            },
            aggs: {
                mimeType: {
                    terms: {
                        field: "mimeType.keyword",
                        size: AGGREGATION_SIZE
                    },
                },
            },
        }
    },
    createMetadataFilters: (selectedMetadata: SelectedMetadataInterface): any => {
        const searchQuery = [];
        for (const key in selectedMetadata) {
            if (!selectedMetadata[key].length) continue;
            switch (key) {
                case "mimeType": {
                    if (!selectedMetadata[key].length) return null;
                    searchQuery.push({
                        terms: {
                            [`${key}.keyword`]: selectedMetadata[key].split(", ").flatMap(item => allMimeTypes[item])
                        }
                    });
                    break;
                }
                default:
                    return [];
            }
        }
        return searchQuery;
    },
};
