import {EnvironmentConfig} from "@me8eon/config";

const parseLinks = (str: string) => {
    // Step 1: Escape all code sections (inline code and multi-line code blocks)
    const escapedBackticks = str
        // Handle inline code wrapped in backticks (e.g., `code`)
        .replace(/`([^`]+)`/g, (match) => {
            return `{{BACKTICK_SECTION_${Math.random().toString(36).substr(2, 9)}}}`;
        })
        // Handle multi-line code blocks wrapped in triple backticks (e.g., ``` code ``` or `code block`)
        .replace(/```([\s\S]*?)```/g, (match) => {
            return `{{BACKTICK_BLOCK_${Math.random().toString(36).substr(2, 9)}}}`;
        });

    // Step 2: Replace markdown-style links with HTML <a> tags (Only in non-code sections)
    const withHtmlLinks = escapedBackticks.replace(
        /\[(.*?)\]\((.*?)\)/g,
        (match, p1, p2) => {
            const urlText = p1.startsWith("https:")
                ? `link`: `${p1}`;
            if (p2.startsWith(EnvironmentConfig.azureBlobUrl as string)) {
                p2 = `${p2}?${process.env.REACT_APP_AZURE_BLOB_ACCESS_KEY}`;
            }
            return `<a target="_blank" href="${p2}" data-original-text="${urlText}">(${urlText}) </a>`;
        }
    );

    const withCustomLinks = withHtmlLinks.replace(
        /\[\[(https?:\/\/[^\s]+?)\]\]/g, // Match URLs inside [[ ]]
        (match, p1) => {
            return generateLink(p1);
        }
    );

    // Replace bare URLs with HTML <a> tags
    // This regex will not try to exclude URLs already inside an href attribute,
    // but it's more widely supported.
    const withParsedLinks = withCustomLinks.replace(/(https?:\/\/[^\s]+)/g, (match, p1) => {
        // To avoid double-wrapping URLs already in <a> tags,
        // we can check if they are already wrapped in <a>
        if (
            /href="/.test(
                withCustomLinks.slice(
                    withCustomLinks.indexOf(match) - 10,
                    withCustomLinks.indexOf(match) + 10
                )
            )
        ) {
            return match; // Return the match as-is if already within an href attribute
        }
        return generateLink(p1);
    });

    // Step 3: Reinsert the backtick sections (both inline and block code)
    return withParsedLinks
        .replace(/{{BACKTICK_SECTION_[a-z0-9]+}}/g, (match) => {
            // Reinsert the inline code section from the original string
            const originalMatch = str.match(/`([^`]+)`/);
            if (originalMatch) {
                return originalMatch[0]; // Inline code section
            }
            return match;
        })
        .replace(/{{BACKTICK_BLOCK_[a-z0-9]+}}/g, (match) => {
            // Reinsert the block code section from the original string
            const originalMatch = str.match(/```([\s\S]*?)```/);
            if (originalMatch) {
                return originalMatch[0]; // Code block
            }
            return match;
        });
};

function generateLink(p1: string): string {
    const urlWithoutParams: string = p1.split(/[?#]/)[0];

    if (isPdfLink(urlWithoutParams)) {
        const pdfName = extractPdfName(urlWithoutParams);
        if (
            urlWithoutParams.startsWith(
                EnvironmentConfig.genAiApiBaseUrl as string
            )
        ) {
            return `<a target="_blank" href="${p1}" style="display:none" data-original-text="${pdfName}">(${pdfName}) </a>`;
        }
        return `<a target="_blank" href="${p1}" data-original-text="${pdfName}">(${pdfName}) </a>`;
    }
    return `<a target="_blank" href="${p1}" data-original-text="link">(${p1}) </a>`;
}

function isPdfLink(url: string): boolean {
    return url.endsWith(".pdf");
}

function extractPdfName(url: string): string {
    const pdfName = url.split("/").pop()?.split("__").pop();
    return decodeURIComponent(pdfName || "pdf doc");
}

export default parseLinks;

export const parsedLinkArray = (text: string) => {
    const regex = /href="(.*?)"(?:.*?data-original-text="(.*?)")?/g;
    let matches;
    const links = [];
    while ((matches = regex.exec(text)) !== null) {
        const linkObj = {
            url: matches[1],
            text: matches[2] || null,
        };
        links.push(linkObj);
    }
    return links;
};
