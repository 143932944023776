import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import React, { useEffect } from "react";
import "./RelatedApps.scss";
import useSearchStore from "../../../../store/searchStore";
import { FilterSetters } from "../../elsearch.domain";
import { EnvironmentConfig } from "@me8eon/config";
import { useTranslation } from "react-i18next";
import { ThemedIcon } from "@me8eon/icons";

// Main RelatedApps component
interface RelatedAppsProps {
    setters: FilterSetters;
    searchTerm: string;
}

const RelatedApps: React.FC<RelatedAppsProps> = ({ searchTerm, setters }) => {
    const {
        data: displayApps,
        setPrefData,
        toggleFavourite,
        fetchSearchData,
    } = useSearchStore();
    const {
        setIsLoading,
        setResultsToDisplay,
        setDataType,
        setSearchIndex,
    } = setters;

    const { t } = useTranslation();

    useEffect(() => {
        fetchSearchData(searchTerm);
    }, [searchTerm]);

    const onAddFavorite = (appData: any) => {
        toggleFavourite(appData);
        setPrefData();
    };

    const appsAsActive = () => {
        const indices = [EnvironmentConfig.appIndex.toString()];
        setIsLoading(true);
        setResultsToDisplay([]);
        setSearchIndex([]);
        setDataType("apps");
        setSearchIndex(indices);
    };

    if (displayApps.length === 0) {
        return null;
    }

    return (
        <div className="related-apps-container">
            <div className="unified-box-heading">
                <span>{t("searchPage.relatedApps")}</span>
                {displayApps.length >= 3 && (
                    <div className="view-more-apps">
                        <EonUiLink
                            data-testid="about-me8eon"
                            size="small"
                            slot="links"
                            target="_blank"
                            text={t("searchPage.viewMoreApps")}
                            onClick={appsAsActive}
                        ></EonUiLink>
                    </div>
                )}
            </div>

            <ul className="related-apps-ul unified-box-content">
                {displayApps.map((appData, index) => {
                    const { target, title, alias } = appData;
                    const translatedLabel = alias
                        ? t(`appData.${alias}`)
                        : title;
                    const label = translatedLabel.startsWith("appData.")
                        ? title
                        : translatedLabel;

                    return (
                        <li key={index} className="related-apps-li">
                            <a
                                href={target ? target : `javascript:void(0)`}
                                target={target ? "_blank" : "_self"}
                                rel="noreferrer"
                                className="related-apps-link"
                            >
                                {label && (
                                    <div
                                        className="related-apps-label"
                                        dangerouslySetInnerHTML={{
                                            __html: label,
                                        }}
                                    ></div>
                                )}
                            </a>
                            <button
                                onClick={() => onAddFavorite(appData)}
                                className="add-icon"
                                title={
                                    appData.status
                                        ? t("searchPage.removeAppTooltip")
                                        : t("searchPage.addAppTooltip")
                                }
                            >
                                <ThemedIcon name={appData.status ? "subtract" : "add"} scheme="red500" title={appData.status ? t("searchPage.removeAppTooltip") : t("searchPage.addAppTooltip")} />
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default RelatedApps;
