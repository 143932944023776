{
    "en": {
        "meeting_id": "Meeting ID",
        "meeting_on": "Meeting on",
        "meeting_timing": "Timing",     
        "meeting_desc": "Meeting description",
        "meeting_location": "Location",
        "meeting_type": "Type",
        "meeting_participants": "Participants",
        "actions": "Actions"
    },
    "de": {
        "meeting_id": "Besprechungs-ID",
        "meeting_on": "Besprechung am",
        "meeting_timing": "Zeitpunkt",
        "meeting_desc": "Besprechungsbeschreibung",
        "meeting_location": "Standort",
        "meeting_type": "Typ",
        "meeting_participants": "Teilnehmer",
        "actions": "Aktionen"
    },
    "sv": {
        "meeting_id": "MötesID",
        "meeting_on": "Möte på",
        "meeting_timing": "Tid",
        "meeting_desc": "Mötesbeskrivning",
        "meeting_location": "Plats",
        "meeting_type": "Typ",
        "meeting_participants": "Deltagare",
        "actions": "Åtgärder"
    }
}