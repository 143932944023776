import { SearchResultLayout } from "./search_results_layout/data.view.search.results.layout";
import { NameAnd } from "@me8eon/record_utils";
import { SelectableButton } from "@me8eon/selection_button";
import { makeContextFor } from "@me8eon/context";
import { SearchFn } from "me-at-eon/src/search/search";
import { FeatureFlags, useFeatureFlags } from "@me8eon/featureflags";
import React, { useMemo } from "react";
import { useUserData } from "@me8eon/react_authentication";

export const dataViewDebug = "dataView";

export type DataViews<DataSourceDetails> = NameAnd<DataView<DataSourceDetails>>

//for example all, jira, confluence, (maybe ai assistance)
export type DataView<DataSourceDetails> = {
    plugin: "dataview" //purely for documentation and debug
    datasources: DataSourceDetails[]
    widgets: string[]
    searchFn: SearchFn<any> //scaffolding
    DisplayNavItem?: SelectableButton
    searchIndicies: string[]
    icon?: React.ReactElement
    SearchResultLayout: SearchResultLayout
    OneLineLayout: SearchResultLayout
    expectedDataTypes?: string[]
}
export const { Provider: RawDataViewsProvider, use: useDataViews } = makeContextFor<DataViews<any>, "dataViews">("dataViews");

export type DataViewsProviderProps = {
    children: React.ReactNode
    dataViews: (featureFlags: FeatureFlags, featureFlagEnabled: boolean) => DataViews<any>
}

export function DataViewsProvider({ children, dataViews }: DataViewsProviderProps) {
    const [featureFlags] = useFeatureFlags();
    const { featureFlagEnabled } = useUserData();
    const value = useMemo(() => dataViews(featureFlags, featureFlagEnabled), [featureFlags, dataViews]);
    return <RawDataViewsProvider dataViews={value}>{children}</RawDataViewsProvider>;
}
