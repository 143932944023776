import {Message} from "./ai.types";
import {OutputAndSource} from "./ai.rag.client";
import {serviceRequestTypes} from "../../pages/service-request/ServiceRequest";
import { ServiceRequestReponse } from "../../interfaces/ServiceRequestInterface";
import { ellipses } from "../../util/strings";
import {EnvironmentConfig} from"@me8eon/config";
import { GetServiceRequest } from "../../services/serviceRequestApiCall";

interface IActionByType {
    [key: string]: (sendRequest: (messages: Message[]) => Promise<Message[]>) => Promise<OutputAndSource>;
}
interface IActionByCustomType {
    [key: string]: (node: any) => OutputAndSource;
}

const ServiceDataLimit = 3;
const incidentPortalUrl = `${EnvironmentConfig.gatewayURL}/incidents`;
const ordersPortalUrl = `${EnvironmentConfig.gatewayURL}/orders`;
console.log('incidentAndOrdersPortalUrl', incidentPortalUrl, ordersPortalUrl);

export const AiActionByType: IActionByType = {
    "show-incidents": async (sendRequest) => {
        const url = serviceRequestTypes.incidents.url;
        return await GetServiceRequest(url).then(async (response) => {
            if (!response) throw new Error("No response from the server");
            const messages: Message[] = [{
                role: "system",
                content: `Tell the user that all incidents can be found in the table below. If he needs more details, he should visit the Incidents tab in the portal, add following link as citation ${incidentPortalUrl}`
            }];
            const aiResponse = await sendRequest(messages);
            let queryResult =
                (response?.data?.result?.queryResult as ServiceRequestReponse[]) ?? [];
            if (!queryResult.length) throw new Error("No response from the server");
            queryResult = queryResult.splice(0, ServiceDataLimit);
            queryResult = queryResult.map((res) =>  {
                res.description = ellipses(res.description);
                return res;
            });
            return {
                output: aiResponse[0].content,
                source: "no source",
                action: "show-incidents",
                data: queryResult,
            };
        }).catch(async () => {
            const aiResponse = await sendRequest([{
                role: "system",
                content:  `Tell the user that you couldn't find the incidents. He can try again later or check the Incidents tab in the portal, add following link as citation ${incidentPortalUrl}`
            }]);
            return {
                output: aiResponse[0].content,
                source: "no source",
                action: "show-incidents",
            };
        });
    },
    "show-orders": async (sendRequest) => {
        const url = serviceRequestTypes.orders.url;
        return await GetServiceRequest(url).then(async (response) => {
            if (!response) throw new Error("No response from the server");
            const messages: Message[] = [{
                role: "system",
                content: `Tell the user that all orders can be found in the table below. If he needs more details, he should visit the orders tab in the portal, add following link as citation ${ordersPortalUrl}`
            }];
            const aiResponse = await sendRequest(messages);
            let queryResult =
                (response?.data?.result?.queryResult as ServiceRequestReponse[]) ?? [];
            if (!queryResult.length) throw new Error("No response from the server");
            queryResult = queryResult.splice(0, ServiceDataLimit);
            queryResult = queryResult.map((res) =>  {
                res.description = ellipses(res.description);
                return res;
            });
            return {
                output: aiResponse[0].content,
                source: "no source",
                action: "show-orders",
                data: queryResult,
            };
        }).catch(async () => {
            const aiResponse = await sendRequest([{
                role: "system",
                content: `Tell the user that you couldn't find the orders. He can try again later or check the Orders tab in the portal, add following link as citation ${ordersPortalUrl}`
            }]);
            return {
                output: aiResponse[0].content,
                source: "no source",
                action: "show-orders",
            };
        });
    },
    default: async () => {
        return {
            output: "Sorry, I couldn't find the action",
            source: "no source",
            action: "fail"
        };
    }
};

export const AiActionByCustomType: IActionByCustomType = {
    "quick-actions": (node) => {
        return {
            output: node.systemAnswer,
            source: "no source",
            action: "quick-actions",
            data: node
        };
    },
    default: () => {
        return {
            output: "Sorry, I couldn't find the action",
            source: "no source",
            action: "fail"
        };
    }
};