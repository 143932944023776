import TourStart from "../../components/tour-guide/TourStart";
import Modal from "../../components/modal/Modal";
import TourLangOptions from "../../components/tour-lang-options/TourLangOptions";
import { useTranslation } from "react-i18next";
import SearchList from "../../components/search-list";
import TileComponent from "../../components/tile-component";
import TabComponent from "../../components/tab-component";
import NewTabComponent from "../../components/tab-component/NewTabComponent";
import "./TilePage.scss";
import Organize from "../../components/organize-tile-page/Organize";
import PersonalizeDashboard from "../../components/organize-tile-page/PersonalizeDashboard";
import React, { useEffect, useState } from "react";
import LoaderComponent from "../../components/loader-component/LoaderComponent";
import useSearchStore from "../../store/searchStore";
import AiAssistRefactored from "../../components/ai-assist-refactored/AiAssist";
import { AiAssistProvider } from "../../components/ai-assist-refactored/AiAssist.context";
import AiButton from "../../components/ai-button/AiButton";
import FeedbackWidget from "../../components/feedback/FeedbackWidget";
import { DevMode } from "@me8eon/devmode/src/devmode";
import { useUserData } from "@me8eon/react_authentication";
import { useFirstName } from "../../components/ai-assist-refactored/hooks/useFirstName";

const TilePage = () => {
    const { loggedIn, localAccountId } = useUserData()
    const [inputVal, setInputVal] = useState("");
    const firstName = useFirstName()
    const { t } = useTranslation();

    const {
        isPageLoading,
        displayedModal,
        currentTourId,
        isEditMode,
        showModal,
        startTour,
        firstTourResponse,
        fetchUserPref,
        favData,
        setPrefData,
        setUserId,
    } = useSearchStore();
    const { favTabs, favApps } = favData;
    const showTabComponent = favApps.length > 0;
    const [showAiAssist, setShowAiAssist] = useState(false);
    const activeTabObj = favTabs.filter((tab) => tab.active);
    const activeTab = activeTabObj.length > 0 ? activeTabObj[0].name : "";

    useEffect(() => {
        if (loggedIn) {
            setUserId(localAccountId);
            fetchUserPref(localAccountId);
        }
    }, [localAccountId, loggedIn]);

    const tourAction = (actionName: string) => {
        firstTourResponse(actionName);
        setPrefData();
        if (actionName === "start") {
            startTour("tour1");
        }
    };

    return showAiAssist ? (
        <AiAssistProvider>
            <AiAssistRefactored initialPrompt={inputVal} closeAiAssist={() => { setShowAiAssist(false); }} />
        </AiAssistProvider>
    ) : (
        <div className="tilePage">
            <LoaderComponent isLoading={isPageLoading} />
            <TourStart tourId={currentTourId} />
            <FeedbackWidget />
            <Modal
                toggleModal={!!displayedModal}
                closeModal={() => showModal("")}
                isCloseIcon
                closeOnBackdrop={false}
            >
                {displayedModal === "firstTour" && (
                    <TourLangOptions tourAction={tourAction} />
                )}
                {displayedModal === "addNewTab" && (
                    <NewTabComponent favTabs={favTabs} />
                )}
                {displayedModal === "personalizeDashboard" && (
                    <PersonalizeDashboard favTabs={favTabs} favApps={favApps} />
                )}
            </Modal>
            <div className={`${isEditMode ? "edit-mode--opaque" : ""}`}>
                <div className="tile-width--limited">
                    <h1
                        title={t("landingPage.title")}
                        key={t("landingPage.title")}
                        className="tile-heading"
                    >
                        {t("landingPage.hello")} {firstName},{" "}
                        {t("landingPage.title")}
                    </h1>
                    <div className="search-wrapper">
                        <div style={{ width: "100%" }}>
                            <SearchList queryChange={(value) => setInputVal(value)} />
                            {/* pass - query, indicies, language */}
                        </div>
                        <AiButton
                            title={t("chatBot.chatBotTitle")}
                            isEditMode={isEditMode}
                            aiToggle={() => setShowAiAssist(true)}
                        />
                    </div>
                </div>
                <DevMode />
                {showTabComponent && (
                    <TabComponent favTabs={favTabs} isEditMode={isEditMode} />
                )}
            </div>
            {favApps.length > 0 && (
                <div className="tile-background">
                    <Organize favTabs={favTabs} isEditMode={isEditMode} />
                    <TileComponent
                        favApps={favApps}
                        activeTab={activeTab}
                        isEditMode={isEditMode}
                        favTabs={favTabs}
                    />
                </div>
            )}
        </div>
    );
};
export default TilePage;
