import parseLinks, {
    parsedLinkArray,
} from "../../ai-assist-refactored/utils/parseLinks";
import {
    AiClient,
    OutputAndSource,
    useAiWithRag,
} from "../../ai/ai.rag.client";
import RelatedApps from "./related-apps/RelatedApps";
import "./UnifiedSearch.scss";
import React, { useEffect, useState } from "react";
import { marked } from "marked";
import { FilterSetters } from "../elsearch.domain";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { TSetShowAiAssist } from "../../ai-assist-refactored/AiAssist";
import AiIcon from "../../../assets/image/ai-icon32.svg";
import LoaderComponent from "../../loader-component/LoaderComponent";
import { useTheme } from "@me8eon/themes";
import { useSearchTerm } from "@me8eon/filter_data_widget";
import { useUserData } from "@me8eon/react_authentication";
import { ThemedIcon } from "@me8eon/icons";

const AiChatBotResponse = ({
    aiResponse,
    setShowAiAssist,
}: {
    aiResponse: string[];
    setShowAiAssist: TSetShowAiAssist;
}) => {
    const linksArray = parsedLinkArray(
        aiResponse.length > 0 ? aiResponse[0] : ""
    );
    const { t } = useTranslation();

    return (
        <>
            <div className="role-list">
                {aiResponse.map(
                    (aiResponse, index) =>
                        aiResponse && (
                            <div
                                className="unified-bot-text-format"
                                key={index}
                                dangerouslySetInnerHTML={{
                                    __html: marked.parseInline(
                                        aiResponse
                                    ) as string,
                                }}
                            />
                        )
                )}
                {linksArray && linksArray.length > 0 && (
                    <div className="citation-container">
                        {linksArray.map((link, index) => (
                            <EonUiButton
                                size="small"
                                rank="secondary"
                                text={link.text ?? link.url}
                                onClick={() => window.open(link.url, "")}
                                key={index}
                                className="citation-item"
                            ></EonUiButton>
                        ))}
                    </div>
                )}
            </div>
            <div className="show-more-button">
                <span style={{ background: "white" }}>
                    <EonUiButton
                        title={t("chatBot.sendbtn")}
                        text={t("chatBot.showMore")}
                        onClick={() => {
                            setShowAiAssist(true);
                            console.log("Send button clicked");
                        }}
                        size="small"
                        rank="secondary"
                    ></EonUiButton>
                </span>
            </div>
        </>
    );
};

const useSearchAiChatBot = (searchTerm: string) => {
    const ai: AiClient = useAiWithRag();
    const [response, setResponse] = useState<string | null>(null);
    const lang: string =
        localStorage.getItem("i18nextLng") === "de" ? "de" : "en";
    const { featureFlagEnabled } = useUserData();

    useEffect(() => {
        const fetchResponse = async () => {
            const result: OutputAndSource = await ai.aiClientWithRag(
                [],
                searchTerm,
                lang,
                [
                    "questionator-office-buddy-prod",
                    "apps-prod",
                    "semantic-meeondata-azureblob-prod",
                    "semantic-kb-servicenow-prod",
                ],
                featureFlagEnabled
            );
            setResponse(`${parseLinks(result.output)}`);
        };
        if (searchTerm) {
            fetchResponse();
        }
    }, [searchTerm]);

    return response;
};

const UnifiedSearch = ({
    setters,
    setShowAiAssist,
}: {
    setters: FilterSetters;
    setShowAiAssist: TSetShowAiAssist;
}) => {
    const [searchTerm] = useSearchTerm();
    const aiResponse = useSearchAiChatBot(searchTerm);
    const { dataLayout } = useTheme();

    return (
        <div className="advance-unified-assist advance-unified-assist--flex">
            <>
                {aiResponse ? (
                    <>
                        <div className="advance-unified-ai" style={dataLayout.AdvanceUnifiedBox}>
                            <div className="unified-box-heading">
                                <span className="ai-logo"><ThemedIcon name={"AiIcon"} /><span>Assistance</span></span>
                            </div>
                            <div className="unified-box-content" style={{ overflowY: "hidden" }}>
                                <AiChatBotResponse
                                    aiResponse={[aiResponse]}
                                    setShowAiAssist={setShowAiAssist}
                                />
                            </div>
                        </div>
                        <div style={dataLayout.AdvanceUnifiedBox}>
                            <RelatedApps searchTerm={searchTerm} setters={setters} />
                        </div>
                    </>
                ) : (
                    <div className="no-response-loader">
                        <LoaderComponent isLoading={true} isLinear />
                    </div>
                )}
            </>
        </div>
    );
};
export default UnifiedSearch;
