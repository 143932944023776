import { DataWidget, DataWidgetLayout } from "@me8eon/data_widget";
import { DisplayAsResultsList } from "./display.as.results";
import { SimpleDataViewWidgetLayout } from "@me8eon/data_widget";


/** For example we could
 *
 *  searchResultsDataViewWidget("apps", d => d.type === "app")
 *
 */
export function searchResultsDataViewWidget(name: string, filter: (d: any) => boolean, Layout: DataWidgetLayout = SimpleDataViewWidgetLayout): DataWidget {
    return {
        plugin: "datawidget",
        name,
        display: DisplayAsResultsList(filter, Layout),
    };
}