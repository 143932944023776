{
    "releaseNotes": {
        "tabName": "Versionshistorie",
        "accordianHeading": "Geben Sie me@eon V<version> frei",
        "whatsNew": "Was ist neu",
        "tipsAndTricks": "Tipps und Tricks",
        "faq": "FAQ"
    },
    "ourTeam": {
        "tabName": "Unser Team",
        "keyMembers": "Schlüsselmitglieder"
    }
}
