import { emptyDataObject, SourceInfo, TitleTargetAndSource } from "../info";

function getDuration(duration: number): string {
    const days = Math.floor(duration / 86400); // 86400 seconds in a day
    const hours = Math.floor((duration % 86400) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    const result = [];

    if (days > 0) {
        result.push(`${days} ${days > 1 ? "days" : "day"}`);
    }
    if (hours > 0) {
        result.push(`${hours} ${hours > 1 ? "hours" : "hour"}`);
    }
    if (minutes > 0) {
        result.push(`${minutes} ${minutes > 1 ? "minutes" : "minute"}`);
    }

    return result.join(" ");
}

const index = "order-it-api";

export const itOrderInfo: SourceInfo = {
    match: "order-it",
    dropDown: {
        label: "ItOrder",
        value: index,
        icon: "cart",
    },
    makeDataObject: (result) => ({
        ...emptyDataObject,
        index: result?.index,
        icon: "cart",
        link: result?.link,
        name: result?.title,
        source: "my IT order",
        type: "my IT order",
        desc: result?.description,
        deliveryTime: getDuration(result?.delivery_time),
        category: result?.category,
        render: "markdown",
    }),
    titleAndTarget: function (): TitleTargetAndSource {
        throw new Error("Function not implemented.");
    }
};
