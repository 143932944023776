import {PromptDefn} from "./jql.ai.types";
import {validatePromptDefn} from "./jql.dsl.validation";
import {simpleTemplate} from "../../util/simple.template";
import {Message} from "../../components/ai/ai.types";


//if string we can use it, if string[] those are the errors
export type MakeAssisantPromptForDsl = (json: PromptDefn) => string | string[]

export const makeAssistantPromptForDsl: MakeAssisantPromptForDsl = (json: PromptDefn): string | string[] => {
    const errors = validatePromptDefn(json);
    if (errors.length > 0) return errors;
    const globalPrompt = json.global.prompt;
    const questions = json.global.queries.map((q, i) =>
        simpleTemplate(q.prompt, {Name: q.Name, index: i + 1, Description: q.Description})!.trim()).join('\n');
    const n = json.global.queries.length;
    const examples = json.global.queries.flatMap(q => (q.examples || []).flatMap((fse, i) =>
        [`  Example question ${i + 1}: ${fse.question}, Example answer ${i + 1}: ${fse.answer}`, ''])).join('\n');
    return simpleTemplate(globalPrompt, {n, questions, examples})!;
};

type MessageForDsl = {
    originalQuestion: string
    messages: Message[]
}

export type MakeFullPromptForDsl = (json: PromptDefn, question: string) => string[] | MessageForDsl

export const makeFullPromptForDsl: MakeFullPromptForDsl = (json: PromptDefn, question: string): string[] | MessageForDsl => {
    const errors = validatePromptDefn(json);
    if (errors.length > 0) return errors;
    const promptOrErrors = makeAssistantPromptForDsl(json);
    if (Array.isArray(promptOrErrors)) return promptOrErrors;
    return {
        originalQuestion: question,
        messages: [
            {role: 'system', content: promptOrErrors},
            {role: 'user', content: question}
        ]
    };
};