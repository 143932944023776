import React, { useEffect, useState } from "react";
import { useTheme } from "@me8eon/themes";
import { useTranslation } from "@me8eon/translation";
import {
    EonUiButton,
} from "@eon-ui/eon-ui-components-react";

export const getCookie = (name: string) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return false;
}
export const setCookie = (name: string, value: string, days: number) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

export const PwaInstaller = () => {
    const [showBanner, setShowBanner] = useState<Boolean>(!getCookie("pwaButton"));
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [showInstallBanner, setShowInstallBanner] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);

    const { dataLayout } = useTheme();
    const t = useTranslation();
    console.log({ dataLayout });

    useEffect(() => {
        // Detect iOS (iPhone/iPad)
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
        setIsIOS(isIosDevice);

        // Check if the app is already installed
        setIsInStandaloneMode(
            "standalone" in window.navigator && (window.navigator as any).standalone
        );

        // Handle beforeinstallprompt (for Android/Chrome)
        const handleBeforeInstallPrompt = (e: Event) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setShowInstallBanner(true);
        };

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        };
    }, []);

    const hidePopup = () => {
        setCookie("pwaButton", "true", 30);
        setShowBanner(false);
    }

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the install prompt");
                } else {
                    console.log("User dismissed the install prompt");
                }
                setDeferredPrompt(null);
                setShowInstallBanner(false);
            });
        }
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div style={dataLayout.pwaBannerStyle}>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <EonUiButton
                    fullWidthOnMobile
                    text={"X"}
                    rank="secondary"
                    // style={{ position: "absolute", right: "12px", top: "12px" }}
                    size="small"
                    scheme="discreetDark"
                    onClick={hidePopup}
                ></EonUiButton>
            </div>
            {/* Show install button for Android */}
            {showInstallBanner && !isIOS && (
                <div>
                    <p style={{ fontWeight: "bold" }}>{t("pwaText.header")}</p>
                    <EonUiButton
                        fullWidthOnMobile
                        text={t("pwaText.button")}
                        rank="secondary"
                        size="small"
                        scheme="discreetDark"
                        onClick={handleInstallClick}
                    ></EonUiButton>
                </div>
            )}

            {/* Show instructions for iOS */}
            {isIOS && !isInStandaloneMode && (
                <div>
                    <p style={{ fontWeight: "bold" }}>{t("pwaText.iphoneHeader")}</p>
                    <ol style={{ textAlign: "left" }}>
                        <li dangerouslySetInnerHTML={{ __html: t("pwaText.inst1") }}></li>
                        <li dangerouslySetInnerHTML={{ __html: t("pwaText.inst2") }}></li>
                        <li dangerouslySetInnerHTML={{ __html: t("pwaText.inst3") }}></li>
                    </ol>
                </div>
            )}

        </div>
    );
};
