import React, { useState } from "react";
import "./generic.dropdown.scss";
import { OutsideClick } from "@me8eon/outside_click";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "@me8eon/translation";

export type DropdownOption = {
    name: string
    value?: string
    icon?: string
}

export type GenericDropdownProps = {
    label: string
    options: DropdownOption[] | string[]
    icon: string
    selectedOps?: string
    rootId: string
    closeOnSelect?: boolean
    maxWidth?: string
    showSelectTick?: boolean
    clickHandler: (clickedItem: any) => void
}
export type GenericDropdown = (props: GenericDropdownProps) => React.ReactNode

export const GenericDropdown: GenericDropdown = ({ label, icon, options, selectedOps, maxWidth, rootId, clickHandler, closeOnSelect = true, showSelectTick = true }: GenericDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const t = useTranslation();

    const onLanguageChange = (clickedItem: any) => {
        closeOnSelect && setIsOpen(false);
        clickHandler(clickedItem)
    }

    return (
        <OutsideClick onClickOutside={() => setIsOpen(false)}>
            <div className="dropdown-overlay" id={rootId}>
                <button
                    data-testid="languageSwitchIcon"
                    className={`action-wrapper ${!isOpen ? "action-closed" : ""}`}
                    aria-label="Navigate to "
                    role="menuitem"
                    tabIndex={0}
                    onClick={() => setIsOpen(!isOpen)}
                    title={label}
                >
                    <EonUiIcon
                        title={label}
                        size="normal"
                        name={icon}
                    />
                </button>
                <div className="overlay-wrapper" data-testid="languageSwitchPopup">
                    <div className={`dropdown-body ${isOpen ? "open" : ""}`}>
                        <div
                            className="action-wrapper"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <span> {isOpen && label} </span>
                            <EonUiIcon
                                title={label}
                                size="normal"
                                name={icon}
                            />
                        </div>
                        {options.map((option, index: number) => {
                            if (typeof option === "string") {
                                option = { name: option, value: option }
                            }
                            const isSelected = option.value === selectedOps || option.name === selectedOps;
                            return (
                                <button
                                    data-testid={`${option.name}-${index}`}
                                    key={`key-${option.name}-${index}`}
                                    className={`dropdown-item ${isSelected ? "dropdown-item--highlight" : ""}`}
                                    onClick={() => onLanguageChange(option.value || option.name)}
                                    title={option.name}
                                >
                                    <div className="option-val" style={{
                                        maxWidth: maxWidth,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}>
                                        {option.name}
                                    </div>
                                    {
                                        isSelected && (
                                            <EonUiIcon
                                                size="normal"
                                                className="turquoise-icon"
                                                name="radio_checkbox_tick"
                                            />
                                        )
                                    }
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div >
        </OutsideClick >
    )
};