import React, {ReactNode} from "react";
import {makeContextFor} from "@me8eon/context";

export type DataViewNavBarLayoutProps = {
    id?: string
    children: ReactNode
}

export type DataViewNavBarLayout = (props: DataViewNavBarLayoutProps) => ReactNode

export const {use: useDataViewNavBarLayout, Provider: DataViewNavBarLayoutProvider} = makeContextFor<DataViewNavBarLayout, 'dataViewLayout'>('dataViewLayout');


