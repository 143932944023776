import { DataWidget, DataWidgets, fromType, notFromindices } from "@me8eon/data_widget";
import { searchResultsDataViewWidget } from "@me8eon/search_results_data_widget";
import { appsDataWidget } from "@me8eon/apps_data_widget";
import { LayoutWithTitleAndMore } from "@me8eon/data_widget/";
import { aiAssistDataWidget } from "@me8eon/ai_assist_data_widget";
import { filterDataWidget } from "@me8eon/filter_data_widget";

//Note just hacked in the filters. Get them right during integration
const AppsDataWidget: DataWidget = appsDataWidget;
const sharepointLabel = "Sharepoint";
const SharepointWithMoreDataWidget: DataWidget = searchResultsDataViewWidget("sharepoint", fromType("sharepoint"), LayoutWithTitleAndMore(sharepointLabel));
const SharepointDataWidget: DataWidget = searchResultsDataViewWidget("sharepoint", fromType("sharepoint"));
const JiraDataWidget: DataWidget = searchResultsDataViewWidget("jira", fromType("jira"));
const ConfluenceDataWidget: DataWidget = searchResultsDataViewWidget("confluence", fromType("confluence"));
const PeopleDataWidget: DataWidget = searchResultsDataViewWidget("people", fromType("person"));
const MygeniusWidget: DataWidget = searchResultsDataViewWidget("myGenius", fromType("mygenius"));
const restOfElasticSearch: DataWidget = searchResultsDataViewWidget("restOfElasticSearch", notFromindices(["apps-dev", "m365"]));
const gitlabDataWidget: DataWidget = searchResultsDataViewWidget("gitlab", fromType("gitlab"));
const orderitDataWidget: DataWidget = searchResultsDataViewWidget("order-it", fromType("order-it"));

export const allDataWidgets: DataWidgets = {
    ai_assist: aiAssistDataWidget,
    apps: AppsDataWidget,
    confluence: ConfluenceDataWidget,
    filter: filterDataWidget,
    gitlab: gitlabDataWidget,
    jira: JiraDataWidget,
    orderit: orderitDataWidget,
    mygenius: MygeniusWidget,
    people: PeopleDataWidget,
    sharepoint: SharepointDataWidget,
    sharepointWithMore: SharepointWithMoreDataWidget,
    restOfElasticSearch: restOfElasticSearch,
};


