import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import "./AuthComponent.scss";
import { useTranslation } from "react-i18next";
import React from "react";
import { useLogin, useUserData } from "@me8eon/react_authentication";

export function AuthComponent() {
    const { name,loggedIn}= useUserData()
    const {login, logout} = useLogin()
    const { t } = useTranslation();
    const authTitle = loggedIn ? t("logOff") : t("logIn");
    return (
        <>
            {loggedIn && <div className="username">{name}</div>}
            <button
                className="auth-wrapper"
                id="tileTour4"
                onClick={() => {
                    loggedIn ? logout() : login();
                }}
                title={authTitle}
            >
                {/*{isExpired && <div className="loggedout-alert"></div>}*/}
                <EonUiIcon
                    title={authTitle}
                    size="normal"
                    name={loggedIn ? "logout" : "user_outline"}
                />
            </button>
        </>
    );
}

