import React from "react";
import {
    EonUiButton,
    EonUiDatePicker,
    EonUiDropdown,
    EonUiDropdownOption,
    EonUiForm,
    EonUiInput,
} from "@eon-ui/eon-ui-components-react";
import LocationDropdown from "../../room-locations/LocationDropdown";
import TimePicker from "../../time-picker/TimePicker";
import { EonUiInputCustomEvent } from "@eon-ui/eon-ui-components";
import { useTranslation } from "react-i18next";
import useRoomBookingStore from "../../../store/roomBookingStore";
import { getAccessToken } from "../../../services/accessToken";
import {
    DURATION_OPTIONS,
    MEETING_ROOM_TYPES,
    MeetingRoomType,
    RoomBookingInterface,
} from "../RoomBookingInterface";
import ApiCall from "../../../services/ApiCall";
import { getTimeInSeconds } from "../../../util/date";

const RoomBookingForm = () => {
    const {
        clickedFloor,
        setIsPageLoading,
        timePickerVal,
        setTimePickerVal,
        numberOfParticipants,
        setNumberOfParticipants,
        setPastTimeError,
        setLocationDropdownError,
        setRoomDetailsData,
        setUnavailabilityInfo,
        setCapacityInfo,
        setIsRoomBooking,
        setDatePickerError,
        datePickerError,
        currentLocationIds,
        setIsError,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        datePicker,
        toHr,
        setDatePicker,
        setToHr,
        roomType,
        setRoomType,
    } = useRoomBookingStore();
    const { t, i18n } = useTranslation("room-booking");

    const meeting_room_type: MeetingRoomType[] = MEETING_ROOM_TYPES;

    const formatTime = (date: string, time: string, addDiff?: string) => {
        const startDate = new Date(`${date} ${time}:00`);
        const startDateFormat = formatDateTime(startDate);
        if (addDiff) {
            const multiplier = addDiff.match("Hr") ? 60 : 1;
            const diffVal = addDiff.split(" ");
            const endDateFormat = addMinutes(
                startDate,
                parseInt(diffVal[0]) * multiplier
            );
            return endDateFormat;
        }
        return startDateFormat;
    };

    const addMinutes = (date: Date, minutes: number) => {
        const newDate = new Date(date.getTime() + minutes * 60000);
        return formatDateTime(newDate.getTime());
    };

    const formatDateTime = (timestamp: number | Date): string => {
        const date =
            typeof timestamp === "number" ? new Date(timestamp) : timestamp;
        const pad = (n: number) => n.toString().padStart(2, "0");
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    const timeChanged = (timeVal: string) => {
        if (timeVal) {
            // setTime(timeVal);
            setTimePickerVal(timeVal);
            const formatFromDate = formatTime(datePicker, timeVal);
            const formatToDate = formatTime(datePicker, timeVal, toHr);
            setStartTime(formatFromDate);
            setEndTime(formatToDate);
            console.log("timeChanged", { formatFromDate, formatToDate });
        }
    };
    const datePickerChange = (selectedDate: string) => {
        if (!selectedDate) {
            setStartTime("");
            setDatePicker("");
        } else {
            setDatePicker(selectedDate);
            setDatePickerError("");
            const formatFromDate = formatTime(selectedDate, timePickerVal);
            const formatToDate = formatTime(selectedDate, timePickerVal, toHr);
            setStartTime(formatFromDate);
            setEndTime(formatToDate);
            console.log("datePickerChange", { formatFromDate, formatToDate });
        }
    };

    const durationEndHour = (endHour: string) => {
        const formatToDate = formatTime(datePicker, timePickerVal, endHour);
        setToHr(endHour);
        setEndTime(formatToDate);
        console.log("durationEndHour", { startTime, formatToDate });
    };

    const roomTypeChange = (roomType: string) => {
        console.log("roomtype", roomType);
        if (roomType === "Hot Desk") {
            setRoomType(meeting_room_type[1]);
        } else if (roomType === "Meeting Room") {
            setRoomType(meeting_room_type[2]);
        } else {
            setRoomType(meeting_room_type[0]);
        }
    };

    const searchAvailableRooms = async () => {
        setPastTimeError(false);
        setLocationDropdownError("");
        if (clickedFloor.trim().length === 0) {
            setLocationDropdownError(t("selectLocation"));
            return;
        }
        if(startTime === "" || datePicker === "") {
            setDatePickerError(t("selectDate"));
            return;
        }
        if (new Date(startTime).getTime() < new Date().getTime()) {
            setPastTimeError(true);
            return;
        }
        setIsPageLoading(true);
        setIsError(false);
        setRoomDetailsData([]);

        const actualToken = await getAccessToken();
        const getRoomApiResponse = await ApiCall(
            "api/gingco/SearchObjects",
            "post",
            {
                object_type_ids: roomType.id ? [roomType.id] : [],
                capacity: parseInt(numberOfParticipants),
                object_name: "",
                timeframes: [
                    {
                        begin: {
                            datetime: startTime,
                            timezone_id: "Europe/Berlin",
                        },
                        end: {
                            datetime: endTime,
                            timezone_id: "Europe/Berlin",
                        },
                    },
                ],
                location_ids: [currentLocationIds[currentLocationIds.length - 1]], // #todo need to change this logic
                // hierarchy_location_ids: currentLocationIds,
                include: ["seating", "unavailability", "timeline"],
                // dummytoken: "",
                accesstoken: actualToken ? actualToken : "",
            }
        );
        setIsRoomBooking(false);
        // #2 get availability of available rooms
        if (getRoomApiResponse.status === "success") {
            if (getRoomApiResponse.data.objects.length > 0) {
                // filter only rooms
                const filteredRoom = getRoomApiResponse.data.objects.filter(
                    (room: RoomBookingInterface) =>
                        (room.object_type_id === 3 ||
                            room.object_type_id === 2) &&
                        room.availability !== 2
                );
                if (filteredRoom.length < 1) {
                    setIsError(true);
                } else {
                    setRoomDetailsData(filteredRoom);
                    setUnavailabilityInfo(
                        getRoomApiResponse.data.unavailabilities
                    );
                    setCapacityInfo(getRoomApiResponse.data.seatings);
                }
            } else {
                // show error that we could not find any room for this location
                console.log(
                    "show error that we could not find any room for this location"
                );
                setIsError(true);
            }
        } else {
            if (
                getRoomApiResponse.data ===
                "Required access token missing or expired."
            ) {
                await getAccessToken(true);
            }
            setIsError(true);
        }
        setIsPageLoading(false);
    };

    return (
        <div>
            <EonUiForm placeholder="form">
                <div className="search-form-row">
                    <div className="search-form-col col-min-width">
                        <LocationDropdown />
                    </div>
                    <div className="search-form-col">
                        <EonUiDatePicker
                            placeholder="datepicker"
                            onValueChanged={(e) =>
                                datePickerChange(e.target.value)
                            }
                            onDatePickerOpened={(e) => {
                                const parentElement = e.target.parentElement;
                                if (parentElement) {
                                    parentElement.style.zIndex = getTimeInSeconds().toString();
                                }
                            }}
                            onDatePickerClosed={(e) => {
                                const parentElement = e.target.parentElement;
                                if (parentElement) {
                                    parentElement.style.removeProperty('z-index');
                                }
                            }}
                            value={datePicker}
                            name="date"
                            size="small"
                            label={t("date")}
                            labelOutside={true}
                            maxDaysBefore={0}
                            selectToday={true}
                            dateFormat="dd/MM/yyyy"
                            lang={i18n.language}
                            key={i18n.language}
                        />
                        <div className="selected-location-error">
                            {datePickerError}
                        </div>
                    </div>
                    <div className="search-form-col tab--fix-size">
                        <TimePicker
                            date={datePicker}
                            time={timePickerVal}
                            onValueChangedCallback={(timeValue: string) =>
                                timeChanged(timeValue)
                            }
                        />
                    </div>
                    <div className="search-form-col tab--fix-size">
                        <EonUiDropdown
                            placeholder="dropdown"
                            onValueChanged={(e) =>
                                durationEndHour(e.target.value)
                            }
                            value={toHr}
                            name="dropdown"
                            size="small"
                            label={t("duration")}
                            labelOutside={true}
                        >
                            {DURATION_OPTIONS.map((val, index) => (
                                <EonUiDropdownOption
                                    placeholder="option"
                                    key={`${index.toString()}`}
                                    value={val}
                                    label={t(`durationOptions.${val}`)}
                                />
                            ))}
                        </EonUiDropdown>
                    </div>
                    <div className="search-form-col tab--fix-size col--auto-size">
                        <EonUiInput
                            trailingIconName="user_group_alt"
                            type="number"
                            label={t("participants")}
                            labelOutside
                            placeholder="Ex. 3 or 5"
                            value={numberOfParticipants}
                            max="9999"
                            size="small"
                            onValueChanged={(e: EonUiInputCustomEvent<any>) =>
                                setNumberOfParticipants(e.target.value)
                            }
                        />
                    </div>
                    <div className="search-form-col tab--fix-size cl-mw-150">
                        <EonUiDropdown
                            placeholder="dropdown"
                            onValueChanged={(e) =>
                                roomTypeChange(e.target.value)
                            }
                            value={roomType.name}
                            name="dropdown"
                            size="small"
                            label={t("list")}
                            labelOutside={true}
                        >
                            {meeting_room_type.map((val, index) => (
                                <EonUiDropdownOption
                                    placeholder="option"
                                    key={`${index.toString()}`}
                                    value={val.name}
                                    label={t(`meetingRoomTypes.${val.name}`)}
                                />
                            ))}
                        </EonUiDropdown>
                    </div>
                    <div className="search-form-col tab--fix-size mt-auto">
                        <EonUiButton
                            title={`${t("PageTitle")} ${t("btnSearch")}`}
                            fullWidthOnMobile={true}
                            placeholder="button"
                            onClick={() => searchAvailableRooms()}
                            className="meeting__label"
                            text={t("btnSearch")}
                            size="small"
                        />
                    </div>
                </div>
            </EonUiForm>
        </div>
    );
};
export default RoomBookingForm;
