import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
 
interface WindowContextType {
    screenWidth: number;
    isMobile: boolean;
    isScrollY: boolean;
}
 
const WindowContext = createContext<WindowContextType | undefined>(undefined);
 
interface WindowProviderProps {
    children: ReactNode;
}
 
export const WindowProvider: React.FC<WindowProviderProps> = ({ children }) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(true);
    const [isScrollY, setIsScrollY] = useState<boolean>(false);
    
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        const handleScroll = () => setIsScrollY(!!window.scrollY);
        window.addEventListener("resize", handleResize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (screenWidth) {
            const mobile = screenWidth < 1024 ? true : false;
            setIsMobile(mobile);
        }
    }, [screenWidth]);
 
    return (
        <WindowContext.Provider value={{screenWidth, isMobile, isScrollY}}>
            {children}
        </WindowContext.Provider>
    );
};
 
export const useWindowProperties = (): WindowContextType => {
    const context = useContext(WindowContext);
    if (!context) {
        throw new Error("useWindow must be used within a WindowProvider");
    }
    return context;
};