import React, { CSSProperties, ReactElement } from "react";
import { makeContextFor } from "@me8eon/context";
import { useFeatureFlag } from "@me8eon/featureflags";
import { useThrowError } from "@me8eon/throw_error";

import { AdvanceUnifiedBox, navBarStyles, navBarStylesProps } from "./advance.page.styles";
import { useUserData } from "@me8eon/react_authentication";
export const themeFF = "theme";
export type RendererStyles = {
    h1WithUrl: CSSProperties
    label: CSSProperties
    text: CSSProperties
    link: CSSProperties
    headerLink: CSSProperties
    // date: CSSProperties
}

export type CSSVariables = {
    [key: string]: string | number;
}

export type DataStyles = {}

export type DataLayoutStyles = {
    dataLayoutContainer: CSSProperties,
    dataLayoutRow: CSSProperties,
    dataLayoutItem: CSSProperties,
    colorVariables: CSSVariables,
    mouseOverColor: string,
    linkMouseOverColor: string,
    AdvanceUnifiedBox: CSSProperties,
    navBarStyles: navBarStylesProps,
    themedIconColor: string,
    pwaBannerStyle: CSSProperties
}
export type HeaderTheme = {
    meAtEonLogoUrl: string
    eonLogoUrl: string
}

export type Theme = {
    name: string
    value: string
    description: string
    header: HeaderTheme
    renderer: RendererStyles
    data: DataStyles
    dataLayout: DataLayoutStyles
}

export const commonLink: CSSProperties = {
    color: "#958d8b",
    display: "inline-block",
    gap: "8px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "calc(100% - 16px)",
};
export const commonRendererStyles: RendererStyles = {
    // verma
    h1WithUrl: {
        color: "#262626",
        fontSize: "18px",
        fontWeight: 500,
        margin: "0 0 4px 0", // Reduced bottom margin
        padding: "0",        // Removed padding
        lineHeight: "1.2",   // Adjusted line height for tighter spacing
    },
    label: {
        color: "#5c5c5c",
        fontSize: "12px",
        alignContent: "center",
    },
    text: {
        color: "#262626",
        fontSize: "14px",
    },
    headerLink: {
        ...commonLink,
        display: "flex",
        fontSize: "18px",
        color: "#262626",
    },
    link: {
        ...commonLink,
        fontSize: "16px",
    },
};

export const commonDataStyles: DataStyles = {};


export const eongray100 = "#f8f5f2";
export const eonred500 = "#ea1b0a";

export const CommonDataLayoutStyles: DataLayoutStyles = {
    dataLayoutContainer: {
        display: "flex",  // Change to flex to allow stretching
        flexDirection: "column",
        // flexGrow: 1,      // Allow it to grow and fill parent space
        // alignSelf: "stretch",  // Stretch to parent width
        // border: "1px solid #ddd",
        gap: "0.1rem",
        padding: "0.5rem",
        borderRadius: "8px"
        // flex: 2,
    },
    dataLayoutRow: {
        // display: "flex",
        // width: "100%",    // Ensure rows fill container
        // gap: "0.2rem",
    },
    dataLayoutItem: {
        flex: 1,  // Allow items to fill row space
        // padding: "0.5rem",
        borderRadius: "4px",
        whiteSpace: "break-spaces",  // Keep line breaks within items
    },
    colorVariables: {
        "--primary-color": "#ea1b0a",
        "--heading-color": "#ea1b0a",
        "--heading-color-hover": "#c90a01",
        "--super-light-bg": "#f9f6f4",
        "--light-bg": "#f7e8da59",
        "--normal-bg": "#f7e8da",
        "--bg-hover-color": "#f0ebe5"
    },
    mouseOverColor: eongray100,
    linkMouseOverColor: eonred500,
    AdvanceUnifiedBox,
    navBarStyles,
    themedIconColor: "#ea1b0a",
    pwaBannerStyle: {
        background: "var(--primary-color)",
        color: "#fff",
        padding: "12px",
        textAlign: "center",
        position: "fixed",
        width: "100%",
        bottom: "0px",
        zIndex: 1000
    }
};

export const themeForTests: Theme = {
    name: "eon theme",
    value: "eon",
    description: "The theme for me@eon",
    data: commonDataStyles,
    header: {
        eonLogoUrl: "someEonLogoUrl",
        meAtEonLogoUrl: "someMeAtEonLogoUrl",
    },
    renderer: commonRendererStyles,
    dataLayout: CommonDataLayoutStyles,
};


export const { use: useTheme, Provider: RawThemeProvider } = makeContextFor<Theme, "theme">("theme");

export type ThemeProviderProps = {
    themes: Record<string, Theme>
    children: ReactElement
}

export function ThemeProvider({ children, themes }: ThemeProviderProps) {
    const userData = useUserData();
    const theme = userData.org || "eon";
    const ff = useFeatureFlag(themeFF);
    const realTheme = (ff === "default" || !ff) ? themes[theme] : themes[ff as string];
    const throwError = useThrowError();
    if (!realTheme) throwError("s/w", `Theme ${realTheme} not found. Feature flag is ${ff}, DefaultTheme is ${theme} themes are ${Object.keys(themes).join(", ")}`);
    return <RawThemeProvider theme={realTheme}>{children}</RawThemeProvider>;
}