import React, {useEffect, useRef} from "react";

/**
 * A custom hook to manage a debounced callback function.
 *
 * Key points:
 * - The timeout is cleared on unmount to prevent memory leaks.
 * - Always invokes the latest version of the callback.
 * - Resets the delay timer if the function is called again before the delay elapses.
 *
 * @param callback The function to debounce.
 * @param delay The debounce delay in milliseconds.
 * @returns A debounced version of the callback.
 */
export function useDebouncedCallback<T extends any[]>(
    callback: (...args: T) => void,
    delay: number
): (...args: T) => void {
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null); // Tracks the timeout ID

    // Cleanup on unmount
    useEffect(() => () => {
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
    }, []);

    // Debounced function
    const result = (...args: T) => {
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => callback(...args), delay);
    };
    return result;
}
