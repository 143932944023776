{
    "PageTitle": "Buchung von Besprechungsräumen / Arbeitsplätzen",
    "location": "Standort / Raum auswählen",
    "date": "Datum",
    "from_date": "Von Datum",
    "to_date": "Bis Datum",
    "toDateError": "Das „Bis-Datum“ muss gleich oder später als das „Von-Datum“ sein, und der Abstand zwischen den beiden Daten darf höchstens 30 Tage betragen.",
    "topic": "Thema",
    "timeFrom": "Zeit von",
    "timeUntil": "Zeit bis",
    "peopleCount": "Wie viele Personen nehmen teil?",
    "btnSubmit": "Einreichen",
    "btnReset": "Zurücksetzen",
    "formReset": "Formular zurücksetzen",
    "errMsg": "Bitte fülle alle Felder aus…",
    "inputPlaceholder": "Eingabe…",
    "hours": "Std.",
    "minutes": "Minuten",
    "starting": "Beginn",
    "duration": "Dauer",
    "capacity": "Kapazität",
    "btnSearch": "Suchen",
    "locationOptionLabel": "Ort, Raumname / Nummer",
    "startOptionLabel": "Startzeit auswählen",
    "durationOptionLabel": "Dauer auswählen",
    "capacityOptionLabel": "Kapazität auswählen",
    "searchText": "Bitte warte, wir versuchen, eine Verbindung zum Server herzustellen",
    "dummyLocationValueOne": "Standort 1",
    "dummyLocationValueTwo": "Standort 2",
    "roomAvailableTextDummy": "27 Plätze gefunden für Apr 24, 13 - 14 Uhr in Hallstadter Straße 119 - Schulungsgebäude",
    "roomAvailableFound": "Gefunden",
    "roomAvailableSpaces": "Plätze in",
    "roomAvailablein": "in",
    "floorDropdownDummy": " EG Schulungsgebäude",
    "selectedFloorOptionOneDummy": "EG",
    "selectedFloorOptionTwoDummy": "OG",
    "meetingLocationLabel": "Besprechungsort",
    "titleOfMeetingLabel": "Besprechungstitel",
    "titleOfMeetingPlaceholder": "Gib den Betreff oder das Thema der geplanten Besprechung ein",
    "descOfMeetingPlaceholder": "Beschreibung hinzufügen",
    "meetingDescriptionLabel": "Besprechungs-Beschreibung",
    "availableLabel": "Verfügbar",
    "busyLabel": "Belegt",
    "confirmBookingBtn": "Buchung bestätigen",
    "bookingSuccessLabel": "Raumbuchung ist erfolgreich",
    "bookingFailureLabel": "Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.",
    "selectRoomLocation": "Standort/Raum auswählen",
    "futureDateError": "Bitte wähle das richtige zukünftige Datum/die richtige Uhrzeit aus.",
    "noSlotAvailable": "Im gewählten Buchungszeitraum ist kein Raum verfügbar. Bitte passe die Zeit oder die Ortseinstellung an.",
    "tokenRequired": "Erforderlicher Zugriffstoken fehlt oder ist abgelaufen.",
    "roomBooked": "Der Raum ist bereits gebucht",
    "roomPartiallyBooked": "Der Raum ist zum Teil gebucht",
    "participantError": "Du planst für ",
    "participantErrorMsg": " Teilnehmer buchen, jedoch hat der Raum eine Kapazität von ",
    "partiallyFree": "Teilweise belegt",
    "startingTime": "Anfangszeit",
    "participants": "Anzahl der Teilnehmer",
    "max": "max",
    "capacityLabel": "Kapazität ",
    "dashboard": "Meine Buchungen anzeigen",
    "manageBookings": "Buchungen verwalten",
    "bookAgain": "Nochmals buchen",
    "cancelBookingConfrim": "Möchtest Du diese Besprechung wirklich absagen?",
    "list": "List",
    "search": "Suchen",
    "type": "Typ",
    "updateBookingApiSucessMsg": "Buchung erfolgreich aktualisiert",
    "rebookingApiSucessMsg": "Erfolgreich gebucht",
    "cancelBookingApiSucessMsg": "Die Buchung wurde erfolgreich abgesagt",
    "noUpcomingBooking": "Derzeit hast Du keine bevorstehenden Buchungen",
    "noPastBooking": "Derzeit hast Du keine bevorstehenden Buchungen",
    "apiFailDefaultMsg": "Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.",
    "confirm": "Bestätigen",
    "cancel": "Abbrechen",
    "close": "Schließen",
    "fieldRequired": "Dieses Feld ist erforderlich",
    "selectLocation": "Bitte wähle einen Standort",
    "selectDate": "Bitte wähle ein Datum für die Raumbuchung",
    "availability": {
        "text": "Verfügbarkeit",
        "free": "Ja, verfügbar",
        "busy": "Leider nicht verfügbar",
        "busyHint": "Versuche das Datum oder die Uhrzeit zu ändern.Du kannst auch nach Räumen in der Nähe suchen",
        "partialFree": "Teilweise freies Zimmer",
        "unknown": "Unbekannt"
    },
    "manageBooking": {
        "meetingLocation": "Besprechungsort",
        "reschedule": "Verschieben",
        "bookAgain": "Wieder buchen",
        "rescheduleTo": "Verschieben zu",
        "cancelMeeting": "Besprechung stornieren",
        "searchPlaceholder": "Suche nach Besprechung ID, Beschreibung, Ort"
    },
    "durationOptions": {
        "15 Min": "15 Min",
        "30 Min": "30 Min",
        "45 Min": "45 Min",
        "1 Hr": "1 Std.",
        "2 Hr": "2 Std.",
        "4 Hr": "4 Std.",
        "8 Hr": "8 Std."
    },
    "meetingRoomTypes": {
        "All": "Alle",
        "Hot Desk": "Arbeitsplatz",
        "Meeting Room": "Besprechungsraum"
    }
}