export function uppercaseFirstLetterOnly(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function ellipses(s: string | undefined, len: number = 100): string {
    if (s === undefined) return "";
    return s.length > len ? s.substring(0, len - 3) + "..." : s;
}

export const sanitize = (input: string): string => {
    // Define a whitelist of allowed characters:
    // Alphas (a-z, A-Z), digits (0-9), period (.), underscore (_), slash (/), and parentheses (())
    const whitelist = /^[a-zA-Z 0-9._/()]+$/;

    // Replace any character not in the whitelist with an empty string
    return input
        .split("")
        .filter((char) => whitelist.test(char))
        .join("");
};

export function getExtension(url: string | undefined): string {
    if (!url) return '';
    try {
        const pathname = new URL(url).pathname;
        const extension = pathname.split('.').pop() || '';
        // Return empty if extension contains a slash (e.g., if no real extension)
        return extension.includes('/') ? '' : extension;
    } catch (error) {
        return '';
    }
}