import { DataWidgetLayout } from "./data.view.widget";
import { EonUiLink } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import { useSelectedDataViewName } from "@me8eon/data_views";
import React from "react";


export const LayoutWithTitleAndMore = (title: string): DataWidgetLayout =>
    ({ children, rootId }) => {
        const { t } = useTranslation();
        const [dataView, setDataView] = useSelectedDataViewName();
        return (
            <div data-testid={`${rootId}-layout`}>
                <div className="unified-box-heading">
                    {title}
                    <EonUiLink
                        data-testid="about-me8eon"
                        size="small"
                        slot="links"
                        target="_blank"
                        text={t("searchPage.viewMoreApps")}
                        // hideIcon={true}
                        onClick={() => setDataView("m365")}
                    ></EonUiLink>
                </div>
                <div className="unified-box-content">{children}</div>
            </div >)
    };