import { DataWidget, DataWidgetDisplayer } from "@me8eon/data_widget";
import { makeContextForState } from "@me8eon/context";
import React from "react";

export const searchDebugName = "searchDebug";

export const { use: useSearchTerm, Provider: SearchTermProvider } = makeContextForState<any, "searchTerm">("searchTerm");
export const { use: useAdditionalFiltersStateScaffolding, Provider: AdditionalFiltersProviderScaffolding } = makeContextForState<any, "additionalFilters">("additionalFilters");
export const { use: useMetadataFiltersStateScaffolding, Provider: MetadataFiltersProviderScaffolding } = makeContextForState<any, "metadataFilters">("metadataFilters");
export const { use: useSelectedMetadataFiltersStateScaffolding, Provider: SelectedMetadataFiltersProviderScaffolding } = makeContextForState<any, "selected">("selected");

export function FilterScaffoldingProvider({ children }: { children: React.ReactNode }) {
    return (
        <AdditionalFiltersProviderScaffolding additionalFilters={{}}>
            <MetadataFiltersProviderScaffolding metadataFilters={{}}>
                <SelectedMetadataFiltersProviderScaffolding selected={{}}>
                    <SearchTermProvider searchTerm="">
                        {children}
                    </SearchTermProvider>
                </SelectedMetadataFiltersProviderScaffolding>
            </MetadataFiltersProviderScaffolding>
        </AdditionalFiltersProviderScaffolding>
    );
}


export const filterDebugName = "filterDebug";
export type FilterProps = {}
export type SearchFiltersRightModalProps = {
    dataType: any
    setSearchFiltersRightModal: (value: boolean) => void;
    setters: any;
    searchIndex: string[];
    additionalFilters: any;
    selectedMetadataFilters: any;
    dataSourceFilters: any[];
    filterCount: number;
};
export type FilterScaffolding = SearchFiltersRightModalProps & {
    Display: (props: SearchFiltersRightModalProps) => React.ReactNode
}

export const EmptyFilterScaffolding: FilterScaffolding = {
    Display: () => (<></>),
} as any as FilterScaffolding;

export const { use: useFilter, Provider: FilterProvider } = makeContextForState<FilterScaffolding, "filter">("filter");

export const DisplayFilter: DataWidgetDisplayer =
    ({ rootId, data }) => {
        const [filter] = useFilter();
        const { Display, ...props } = filter;
        return <Display {...props} />;
    };


export const filterDataWidget: DataWidget = {
    plugin: "datawidget",
    name: "filter",
    display: DisplayFilter,
};