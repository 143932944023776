import { SharepointData } from "./sharepoint.data";
import React, { useMemo } from "react";

import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { useCommonComponents } from "@me8eon/components";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
// @ts-ignore
import icon from "./sharepoint.svg";
import { SimpleWidget } from "@me8eon/simple_widget";

export const SharepointDisplay: DisplayData<SharepointData> =
    ({ rootId, id, data }: DisplayDataProps<SharepointData>) => {
        const { H1WithIconAndUrl, Html, Url } = useRenderers();
        const { Text, Date, DataLayout } = useAttributeValueComponents();
        const ClipHeight = useCommonComponents().ClipHeight;
        const newRootId = `${rootId}.${id}`;
        const url = `${data.resource.webUrl}?web=1`;
        const titleAndUrl = useMemo(() => ({ title: data.resource.name, url, icon }), [data.resource.name, url]);
        return <DataLayout rootId={newRootId} className="confluence-data" layout={[1, 1, 3, 1]}>
            <H1WithIconAndUrl rootId={newRootId} attribute={translateLabel("name")} value={titleAndUrl} />
            {data.summary && <ClipHeight maxHeight="2rem" force={false}>
                {/*<HighlightKeyWords rootId={newRootId} attribute='title' value={data.title}/>*/}
                <Html rootId={newRootId} attribute={translateLabel("desc")} value={data.summary} />
            </ClipHeight>}
            <Text rootId={newRootId} attribute={translateLabel("createdBy")} value={data.resource.createdBy.user.displayName} />
            <Date rootId={newRootId} attribute={translateLabel("createdOn")} value={data.resource.createdDateTime} />
            <Date rootId={newRootId} attribute={translateLabel("lastUpdate")} value={data.resource.lastModifiedDateTime} />
            <Url rootId={newRootId} attribute={translateLabel("url")} value={`${data.resource.webUrl}?web=1`} />
        </DataLayout>;
    };


export const SimpleSharepointOneLineDisplay: DisplayData<SharepointData> =
    ({ data }: DisplayDataProps<SharepointData>) => {
        return <span>{data.type} {data.resource.name}</span>;
    };

export const SimpleSharepointWidget: DisplayDataWidget<SharepointData> =
    SimpleWidget<SharepointData>(["name", "description"], [], []);
