import {makeFullPromptForDsl, MakeFullPromptForDsl} from "./categorisation/make.ai.prompt.for.jql";
import {Message} from "../components/ai/ai.types";
import {PromptDefn} from "./categorisation/jql.ai.types";
import {JqlContext, jqlQueryExecutor, JqlQueryExecutor} from "./categorisation/jql.from.ai.execute";
import jsYaml from 'js-yaml';
import {JqlOpenAiClient} from "./ai/ai.client.for.jql";
import {JiraIssue, jiraIssueToIndexedJiraIssue} from "./transformation/jira.transformation";
import { translateKey } from "../util/translate";
import { generateJQL, reformatResponse } from "./jira.postprocessing";


export type JqlAiClientConfig<Result> = {
    yaml: string
    yamlParser: (yaml: string) => any | string[]
    makePrompt: MakeFullPromptForDsl
    ai: JqlOpenAiClient
    extractResult: (m: Message[]) => string[] | number
    execute: JqlQueryExecutor
    transformer: (j: JiraIssue) => Result
}

export type JqlResult<Result> = {
    prompt: Message[]
    answer: Message[]
    answerNumber: number
    jql: string,
    totalCount: number;
    error: boolean;
    errorMsg: string;
    result: Result[]
}
export type JqlAiClient<Result> = (context: JqlContext, query: string,) => Promise<string[] | JqlResult<Result>>

function hasErrors<T>(e: T | string[]): e is string[] {
    return Array.isArray(e);
}

export function makeJqlAiClient<Result>({yaml, yamlParser, ai, makePrompt, extractResult, execute, transformer}: JqlAiClientConfig<Result>): JqlAiClient<Result> {
    const parsedDsl: PromptDefn | string[] = yamlParser(yaml);
    return async (context, query): Promise<string[] | JqlResult<Result>> => {
        if (hasErrors(parsedDsl)) return parsedDsl;
        const prompt = makePrompt(parsedDsl, query);   
             if (hasErrors(prompt)) return prompt;
       let answer = await ai(prompt.messages);
       answer = reformatResponse(answer);
        const questionNumber = extractResult(answer);
        if (hasErrors(questionNumber)) return questionNumber;
        const queryDefn = parsedDsl.global.queries[questionNumber - 1];
        if (queryDefn === undefined) return {prompt: prompt.messages, answer, answerNumber: questionNumber, result: [], jql: "", totalCount: 0, error: true, errorMsg: translateKey('supportedQuestionMsg', 'Something went wrong', 'chatBot.jiraIntegration.')};
        if (questionNumber === 6 && queryDefn.AddToAnswer) {
            const generatedJQL = generateJQL(((answer[0] as any)?.entities) || {});
            queryDefn.AddToAnswer.JQL = generatedJQL;
            console.log("Generated JQL:", generatedJQL);
            answer[0].jql = generatedJQL;
        }
        const {data, jql, totalCount, error, errorMsg} = await execute(queryDefn, context);
        const result = data.map(transformer);
        return {prompt: prompt.messages, answer, answerNumber: questionNumber, result, jql, totalCount, error, errorMsg};
    };
}

export function jsyamlParser<T>(s: string): string[] | T {
    try {
        return jsYaml.load(s) as T;
    } catch (e: any) {
        return [e.toString()];
    }
}


const extractResult = (messages: Message[]): number | string[] => {
    const result = Number.parseInt(messages[0].content);
    if (isNaN(result)) return [`Expected a number but got ${messages[0].content}`];
    return result;
};

export function defaultJqlConfig(yaml: string, ai: JqlOpenAiClient, jiraTokenFn: () => Promise<string>, debug: boolean): JqlAiClientConfig<any> {
    return {
        yaml,
        yamlParser: jsyamlParser,
        makePrompt: makeFullPromptForDsl,
        ai,
        extractResult,
        execute: jqlQueryExecutor(jiraTokenFn, debug),
        transformer: x => {
            return { ...jiraIssueToIndexedJiraIssue(x),type: 'jira', index: 'jira-prod'};
        }
    };
}
