import AiIcon from "../../assets/image/ai-icon32.svg"; //AiIcon is a string
import "./AiButton.scss";
import React from "react";

interface AiButtonProps {
    isEditMode?: boolean;
    aiToggle?: (value?: boolean) => void;
    title?: string;
    isTabMenu?: boolean
}

const AiButton = ({ isEditMode, aiToggle, title, isTabMenu }: AiButtonProps) => {
    return (
        <button
            className={`ai-button-wrapper ${isTabMenu ? "ai-button-wrapper-as-menu" : "ai-button-wrapper-as-button"}`}
            onClick={() => (!isEditMode && aiToggle ? aiToggle(true) : null)}
            title={title}
            id="tileTour5"
        >
            <AiIcon />
            <div
                data-testid="about-me8eon"
                className="ai-button-label"
            // onClick={() => setShowAiAssist(true)}
            >
                Assistance
            </div>
        </button>
    );
};

export default AiButton;
