{
    "releaseNotes": {
        "tabName": "Release History",
        "accordianHeading": "Release me@eon V<version>",
        "whatsNew": "What's New",
        "tipsAndTricks": "Tips and Tricks",
        "faq": "FAQ"
    },
    "ourTeam": {
        "tabName": "Our Team",
        "keyMembers": "Key Members"
    }
}
