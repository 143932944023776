import axios from "axios";
import {
    ESApiFinalResponseInterface,
    ESApiResponseInterface,
} from "../interfaces/ElasticSearchInterface";
import {EnvironmentConfig} from "@me8eon/config";

export const getSearchResultsForProd = async (
    searchTerm: string
): Promise<ESApiFinalResponseInterface[]> => {
    const searchIndex = EnvironmentConfig.appIndex;

    const ELASTICSEARCH_URL = `https://f0571c62200b4d249a4c6750ab7f4716.westeurope.azure.elastic-cloud.com/${searchIndex}/_search`; // Replace with your Elasticsearch URL
    // const ELASTICSEARCH_URL = `https://c3224bc073f74e73b4d7cec2bb0d5b5e.westeurope.azure.elastic-cloud.com:9243/jira_prod/_search`; // Replace with your Elasticsearch URL
    try {
        const response = await axios.post(
            ELASTICSEARCH_URL,
            {
                knn: {
                    // nearest neighbour
                    field: "full_text_embeddings",
                    query_vector_builder: {
                        text_embedding: {
                            model_id: ".multilingual-e5-small_linux-x86_64",
                            model_text: searchTerm,
                        },
                    },
                    k: 3, // two nearest nbr
                    num_candidates: 15, // total 5
                },
            },
            {
                headers: {
                    Authorization:
                        "ApiKey NWZDNE00d0Jtc3pXTjAwWXBXNU46MTFad2tiUTJSRUNuSldHbmR3Vzc5dw==",
                },
            }
        );
        const suggestions: ESApiFinalResponseInterface[] =
            response.data.hits.hits.map(
                (hit: ESApiResponseInterface) => {
                    return {
                        ...hit._source,
                        highlight: hit.highlight,
                        index: hit._index,
                        score: hit._score,
                    };
                } // Replace your_field_name with the field you want to autocomplete
            );
        return suggestions;
    } catch (error) {
        console.error("Error fetching autocomplete suggestions:", error);
        return [];
    }
};
