import { Env } from "./ConfigInterface";

export type FeatureFlagType = {
    enableDefaultTileAppFeature: boolean;
    enableJQLIntegration: boolean;
    enableNextThinkIntegration: boolean;
    enableOrderIt: boolean;
};

const featureFlagConfig: Record<Env, FeatureFlagType> = {
    local: {
        enableDefaultTileAppFeature: false,
        enableJQLIntegration: true,
        enableNextThinkIntegration: true,
        enableOrderIt: true,
    },
    dev: {
        enableDefaultTileAppFeature: false,
        enableJQLIntegration: false,
        enableNextThinkIntegration: false,
        enableOrderIt: true,
    },
    qa: {
        enableDefaultTileAppFeature: false,
        enableJQLIntegration: false,
        enableNextThinkIntegration: false,
        enableOrderIt: false,
    },
    pp: {
        enableDefaultTileAppFeature: false,
        enableJQLIntegration: false,
        enableNextThinkIntegration: false,
        enableOrderIt: false,
    },
    prod: {
        enableDefaultTileAppFeature: false,
        enableJQLIntegration: false,
        enableNextThinkIntegration: false,
        enableOrderIt: false,
    },
};

export default featureFlagConfig;
