import {ConfluenceDisplay, SimpleConfluenceOneLineDisplay, SimpleConfluenceWidget} from "./confluence.display";
import {DataPlugin, DisplayData, DisplayDataWidget} from "../../data";

export const ConfluenceDataName = 'confluence'

export type ConfluenceData = {
    type: string
    id: string;
    index: string;
    icon: string;
    url: string;
    title: string
    body: string
    last_update_date: string
    last_updated: string
}

export function ConfluenceDataPlugin(
    DisplayData: DisplayData<ConfluenceData> = ConfluenceDisplay,
    DisplayDataOneLine: DisplayData<ConfluenceData> = SimpleConfluenceOneLineDisplay,
    DisplayDataWidget: DisplayDataWidget<ConfluenceData> = SimpleConfluenceWidget): DataPlugin<ConfluenceData> {
    return {
        plugin: 'data',
        name: 'confluence',
        idFrom: (data) => `${data.index}.${data.id}`,
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget
    };
}

