import React, { useMemo } from "react";
import { MyGeniusData } from "./mygenius.data";
import { getDuration, getLanguages } from "./mygenius.reference";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { ClipHeight } from "@me8eon/clip_height";
import { SimpleWidget } from "@me8eon/simple_widget";
// @ts-ignore
import icon from './mygenius.svg'

export const MyGeniusDisplay: DisplayData<MyGeniusData> =
    ({ rootId, id, data }: DisplayDataProps<MyGeniusData>) => {
        const { H1WithIconAndUrl, Markdown, Url } = useRenderers();
        const { Text, DataLayout } = useAttributeValueComponents();
        const newRootId = `${rootId}.${id}`;
        const link = `https://eon.edcast.com/insights/ECL-${data.ecl_id}`
        const issueSummaryAndUrl = useMemo(() =>
            ({ url: link, title: data.card_title, icon }), [link, data.card_title]);

        const duration = getDuration(data?.Duration_HHMM)
        const language = getLanguages(data?.card_languages) || '<None>'

        return <DataLayout rootId={newRootId} className='jira-data' layout={[1, 1, 4, 1]}>
            <H1WithIconAndUrl rootId={newRootId} attribute={translateLabel('name')} value={issueSummaryAndUrl} />
            {data.card_description !== 'undefined' ? <ClipHeight maxHeight='2rem' force={false}>
                <Markdown rootId={id} attribute={translateLabel('data.description')} value={data.card_description} />
            </ClipHeight> : <></>}
            <Text rootId={id} attribute={translateLabel('source')} value={`"MyGenius - ["${data.ecl_source_name}"]"`} />
            <Text rootId={id} attribute={translateLabel('type')} value={data.type} />
            <Text rootId={id} attribute={translateLabel('duration')} value={duration} />
            <Text rootId={id} attribute={translateLabel('availableLanguages')} value={language} />
            <Url rootId={id} attribute={translateLabel('url')} value={link} />
        </DataLayout>;
    };

export const SimpleMyGeniusOneLineDisplay: DisplayData<MyGeniusData> =
    ({ data }: DisplayDataProps<MyGeniusData>) =>
        <span>{data.type} {data.card_title}</span>;

export const SimpleMyGeniusWidget: DisplayDataWidget<MyGeniusData> =
    SimpleWidget(
        ['issue', 'summary', 'status'],
        ['type', 'Duration_HHMM', "card_languages"],
        ['ecl_id']);

