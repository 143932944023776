export function dateFormat(
    date: string | number | Date,
    locale: string = "default"
): string {
    if (typeof date === "string" || typeof date === "number")
        date = new Date(date);
    if (!(date instanceof Date) || isNaN(date.getTime())) {
        throw new Error("Invalid date");
    }

    return new Intl.DateTimeFormat(
        locale === "default" ? undefined : locale
    ).format(date);
}

export const getFormattedDateTime = (
    timestamp: number = new Date().getTime(),
    format: string = "Y-m-d H:i:s",
    use12HourFormat: boolean = false,
    timeZone: string = "Europe/Berlin"
): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: use12HourFormat,
        timeZone: timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    const formattedDate = new Date(timestamp)
        .toLocaleString("en-GB", options)
        .replace(",", "");

    const formattedDateArray = formattedDate.split(" ");
    const [day, month, year] = formattedDateArray[0].split("/");
    const [hours, minutes, seconds] = formattedDateArray[1].split(":");
    let ampm = use12HourFormat ? (parseInt(hours) >= 12 ? "PM" : "AM") : "";

    if (use12HourFormat) {
        ampm = formattedDateArray[formattedDateArray?.length - 1].toUpperCase();
    }

    switch (format) {
        case "H:i":
            return `${hours}:${minutes} ${ampm}`.trim();
        case "Y-m-d":
            return `${year}-${month}-${day}`;
        case "Y-m-d H:i:s":
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`.trim();
        default:
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`.trim();
    }
};

// format date 03 Aug 24
export const formatDate = (dateNum: number) => {
    const date = new Date(dateNum * 1000);
    const options: Intl.DateTimeFormatOptions = {
        day: "2-digit",
        month: "short",
        year: "2-digit",
        timeZone: "Europe/Berlin"
    };
    const formattedDate = date
        .toLocaleDateString("en-GB", options)
        .replace(/ /g, " ");

    return formattedDate;
};

export const formatTime = (
    timestamp: number,
    timeZone: string = "Europe/Berlin"
) => {
    const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: timeZone,
    };

    const formattedDate = new Date(timestamp)
        .toLocaleString("en-GB", options)
        .replace(",", "");
    const [hours, minutes] = formattedDate.split(" ")[1].split(":");

    return `${hours}:${minutes}`;
};

export function convertDurationToMilliseconds(timeString: string): number {
    const timeUnits: { [key: string]: number } = {
        Min: 60 * 1000,
        Hr: 60 * 60 * 1000,
    };
    const [value, unit] = timeString.split(" ");

    return parseInt(value) * timeUnits[unit];
}

export function convertDaysToMilliseconds(days: number): number {
    return days * 24 * 60 * 60 * 1000;
}

export function getTimeInSeconds(): number {
    return Math.floor(new Date().getTime() / 1000);
}