import axios from "axios";
import { EnvironmentConfig } from "@me8eon/config";

interface ResponseData {
    id: string;
    name: string;
    api_key: string;
    encoded: string;
    admin: boolean;
    username: string;
    indicies: string;
    query: object;
}

async function fetchAndStoreDLSApiKey(
    username: string,
    idToken: string,
) {
    try {
        const url = `${EnvironmentConfig.apikeyUrl}/${username}`;
        const resp = await axios.get<ResponseData>(
            url,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            },
        );
        localStorage.setItem("dls-apikey", resp?.data?.encoded);
        localStorage.setItem("admin", resp?.data?.admin?.toString() || "false");
    } catch (err) {
        console.log(err);
    }
}

export default fetchAndStoreDLSApiKey;
