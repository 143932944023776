import React, { useState, useEffect } from "react";

const getWidth = (): number =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

const useCurrentWidth = (): number => {
    const [width, setWidth] = useState<number>(getWidth());

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        const resizeListener = (): void => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    return width;
};

export default useCurrentWidth;
