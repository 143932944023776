import React, { ReactNode, useCallback } from "react";
import { useLoginComponents } from "./react.login";
import { useDebug } from "@me8eon/debug";
import { authenticateDebugName, useLogin, useUserData } from "./authentication.provider";
import { LoginOpsFn } from "@me8eon/authentication";
import { delay } from "@me8eon/time";
import { LoadingOr } from "@me8eon/async";


export type RefreshLoginStrategy = (refreshLogin: LoginOpsFn) => LoginOpsFn;

export type AuthenticateProps = {
    children: React.ReactNode
    LoadingDisplay?: () => ReactNode
    _refreshLogin?: (refreshLogin: LoginOpsFn) => LoginOpsFn

};

export const delayedRefreshLogin: RefreshLoginStrategy = (refreshLogin: LoginOpsFn): LoginOpsFn =>
    async (): Promise<void> => {
        await delay(1000);
        await refreshLogin();
    };

export const noDelayRefreshLogin: RefreshLoginStrategy = (refreshLogin: LoginOpsFn): LoginOpsFn =>
    async (): Promise<void> => refreshLogin();

/** When we load the page we need to refresh any logged in tokens for authenticating.
 * If we set notLoggedIn then we will show the notLoggedIn component until we have refreshed the login.
 * */
export function Authenticate({ children = null, LoadingDisplay, _refreshLogin = noDelayRefreshLogin }: AuthenticateProps) {
    const { refreshLogin } = useLogin();
    const { NotLoggedIn } = useLoginComponents();
    const kleisli: () => Promise<void> = useCallback(_refreshLogin(refreshLogin), [refreshLogin, _refreshLogin]);

    return <LoadingOr kleisli={kleisli} input={undefined} loading={LoadingDisplay}>{() =>
        NotLoggedIn ? <MustBeLoggedIn renderNotLoggedIn={NotLoggedIn}>{children}</MustBeLoggedIn> : children
    }</LoadingOr>;
}

export type MustBeLoggedInProps = {
    renderNotLoggedIn: () => ReactNode,
    children: ReactNode
};

export type MustBeLoggedInDisplay = (props: MustBeLoggedInProps) => React.ReactElement;

export function MustBeLoggedIn({ children, renderNotLoggedIn }: MustBeLoggedInProps): ReactNode {
    const userData = useUserData();
    const debug = useDebug(authenticateDebugName);
    debug("MustBeLoggedIn", userData);
    return (
        <MustBeLoggedInWrapper
            loggedIn={userData?.loggedIn}
            children={children}
            notLoggedIn={renderNotLoggedIn}
        />
    );
}

// The wrapper that decides which content to show
function MustBeLoggedInWrapper({
                                   loggedIn,
                                   children,
                                   notLoggedIn,
                               }: {
    loggedIn: boolean;
    children: ReactNode;
    notLoggedIn: () => ReactNode;
}) {
    // We do not conditionally skip any hooks here.
    // If we had more hooks, they'd always be called.

    // We only conditionally show different content.
    if (loggedIn) {
        // If children is a component that uses hooks, that's fine,
        // we always render children in the "loggedIn" path consistently
        return <>{children}</>;
    } else {
        // If notLoggedIn uses hooks, it is always used in the "loggedOut" path consistently
        return <>{notLoggedIn()}</>;
    }
}
