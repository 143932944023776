// src/Hierarchy.js
import React, { useState, useEffect } from "react";
import "./RoomLocations.scss";
import { sortArray } from "../../util/common-util";
import HierarchyNode from "./HierarchyNode";
import { getBookableLocationsApi } from "../../services/roomBooking";
import { getAccessToken } from "../../services/accessToken";
import useRoomBookingStore from "../../store/roomBookingStore";

export type Location = {
    location_id: number;
    location_type_id: number;
    location_name: string;
    hierarchy_location_ids: number[];
    children?: Location[];
    isOpen?: boolean;
    isHome: boolean;
};

type NameAnd<T> = { [name: string]: T };

interface RoomLocationsProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    clickedLocation: (
        ids: number[],
        name: string,
        closeDropDown?: boolean
    ) => void;
    home_location_id: number;
    clickedFloor: string;
}

const RoomLocations = (props: RoomLocationsProps) => {
    const { locations, setLocations } = useRoomBookingStore();
    const [hierarchy, setHierarchy] = useState<Location[]>([]);
    const { clickedLocation, home_location_id, clickedFloor, ...rest } = props;
    const toplevelLocationType = [1, 2, 40];

    const buildHierarchy = (locations: Location[]) => {
        const map: NameAnd<Location> = {};
        let defaultOpen: number[] = [];
        locations.forEach((location) => {
            map[location.location_id] = { ...location, children: [] };
            if (
                location.location_id === home_location_id &&
                !toplevelLocationType.includes(location.location_type_id)
            ) {
                defaultOpen = [
                    ...location.hierarchy_location_ids,
                    home_location_id,
                ];
                clickedLocation(
                    [...location.hierarchy_location_ids, location.location_id],
                    location.location_name
                );
            }
        });

        locations.forEach((location) => {
            if (location.hierarchy_location_ids.length === 0) {
                return;
            }
            const parentID =
                location.hierarchy_location_ids[
                    location.hierarchy_location_ids.length - 1
                ];
            const children = map[parentID]?.children;
            if (children) {
                children.push({
                    ...map[location.location_id],
                    isOpen: defaultOpen.indexOf(location.location_id) > -1,
                    isHome: location.location_id === home_location_id,
                });
            }
        });

        const worldData = Object.values(map).filter(
            (location: Location) => location.hierarchy_location_ids.length === 0
        );

        return worldData[0].children;
    };

    useEffect(() => {
        if (locations.length > 0) return;
        (async () => {
            try {
                const accessToken = await getAccessToken();
                const payload = {
                    location_ids: [],
                    location_id_type: "",
                    accesstoken: accessToken,
                };
                const res = await getBookableLocationsApi(payload);
                const locationRes: Location[] = sortArray(
                    res.data,
                    "location_id"
                );
                setLocations(locationRes);
            } catch (err) {
                console.log("Error in getting location");
                setLocations([]);
            }
        })();
    }, []);

    useEffect(() => {
        if (home_location_id > -1 && locations.length > 0) {
            const h: Location[] = buildHierarchy(locations) || [];
            setHierarchy(h);
        }
    }, [home_location_id, locations]);

    return (
        <div className={["room-locations", rest.className ?? ""].join(" ")}>
            <div className="location-list-wrapper">
                {hierarchy.map((rootNode: Location) => (
                    <HierarchyNode
                        key={rootNode.location_id}
                        node={rootNode}
                        clickedLocation={clickedLocation}
                        clickedFloor={clickedFloor}
                    />
                ))}
            </div>
        </div>
    );
};

export default RoomLocations;
