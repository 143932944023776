import React, {CSSProperties} from "react";

import {NavBarLayout, NavbarLayoutProps} from "./navbar";
import {camelCase} from "@me8eon/string_utils";

export const defaultNavBarLayoutStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    gap: '1rem',
    flexWrap: 'wrap', // Allow items to wrap on smaller screens

};
export const SimpleNavbarLayout = (purpose: string, style: CSSProperties = defaultNavBarLayoutStyle): NavBarLayout => {
    const result = ({children}: NavbarLayoutProps) => {

        return (
            <nav
                role="navigation"
                aria-label={purpose}
                className={camelCase(purpose)}
                style={style}
            >{children}
            </nav>
        );
    };
    result.displayName = purpose;
    return result;
};