{
    "headerData1": "Welcome to the",
    "headerData2": "me@eon portal",
    "headerMessage": "24/7 online service for you",
    "loginLable": "Login",
    "logOff": "Logout",
    "logIn": "Login",
    "userName": "Profile name",
    "switchLanguage": "Switch language",
    "switchRole": "Switch role",
    "organiseTiles": "Organize tiles",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "close": "Close",
    "continue_Tour": "Continue tour",
    "skip_Tour": "Skip tour",
    "save": "Save",
    "submit": "Submit",
    "delete": "Delete",
    "trash_bin": "Delete",
    "edit": "Edit",
    "yes": "Yes",
    "no": "No",
    "feedbackWidget": "Feedback",
    "registerLable": "To register ",
    "underDevelopmentMsg": "This page is under development",
    "apiFailDefaultMsg": "Something went wrong. Please try again later.",
    "landingPage": {
        "hello": "Hello",
        "title": "how can I help you?",
        "searchApps": "Apps",
        "suggestions": "Suggestions"
    },
    "footer": {
        "imprint": "Imprint",
        "privacyPolicy": "Privacy Policy",
        "termsofUse": "Terms of Use",
        "contact": "Contact",
        "PKI": "PKI",
        "privacySettings": "Privacy Settings",
        "aboutMe8EON": "About me@eon"
    },
    "tilePage": {
        "backToSearch": "Back to previous page",
        "myInboxCountSuffix": "Pending"
    },
    "myApprovalDataTable": {
        "FilterLable": {
            "all": "All",
            "leaveApptoval": "LeaveApproval",
            "timeSheet": "TimeSheet",
            "claim": "Claim",
            "serviceRequest": "ServiceRequest "
        },
        "tableData": {
            "approve": "Approve",
            "reject": "Reject",
            "requestId": "Request Id",
            "description": "Description",
            "requestedBy": "Requested By",
            "requestedType": "Requested Type",
            "requestedDate": "Requested Date",
            "status": "Status"
        }
    },
    "appData": {
        "time": "Time-Tracking",
        "meeting": "Meeting Room Booking",
        "approval": "My Approvals",
        "pr": "Create Purchase Requisition",
        "pra": "Create Purchase Requisition Advanced",
        "processing": "My Inbox",
        "hr": "HR Portal",
        "hrLabel": "HR Portal",
        "concur": "Concur Travel Booking and Expenses",
        "password": "SAP Password Reset",
        "ariba": "Ariba - Classic and Classic+",
        "itresources": "IT Resources",
        "myassets": "My Assets",
        "order": "Order",
        "incident": "Incidents",
        "beeline": "Beeline",
        "prisma": "Prisma",
        "gertrud": "Gertrud",
        "notification": "Notifcation Portal",
        "zuko": "Zuko",
        "brand": "EON Brand Space",
        "pki": "PKI Portal",
        "mail": "Mail DL",
        "journey": "Journeys/Events",
        "mygenius": "MyGenius",
        "timehover": "With the CATS app, you can easily track and manage your work hours and activities by having all the relevant information and functions in one place.",
        "meetinghover": "With Gingco, our room booking tool, you can easily manage and schedule your meeting rooms and resources, ensuring efficient utilization and availability, all in one place",
        "approvalhover": "With myInbox, you can easily and efficiently approve invoices by having all the relevant information and actions in one place.",
        "prhover": "Here you can find all relevant functions to request goods and services. This is also known as BANF (ger. for purchase requisition)",
        "prahover": "Here you can find all relevant functions to request goods and services. This is also known as BANF (ger. for purchase requisition)",
        "processinghover": "With myInbox, you can easily and efficiently approve invoices by having all the relevant information and actions in one place.",
        "hrhover": "With myHR, you can conveniently access and manage your HR-related information and tasks in one place.",
        "concurhover": "With Concur, you can easily manage and submit your travel and expense reports by having all the relevant information and actions in one place.",
        "passwordhover": "With this tile, you can easily reset the access passwords for your SAP systems.",
        "aribahover": "With SAP Ariba, you can easily manage your procurement processes by having all the relevant information and tools in one place.",
        "itresourceshover": "IT Resources",
        "myassetshover": "My Assets",
        "orderhover": "Order",
        "incidenthover": "Incidents",
        "beelinehover": "With Beeline, you can efficiently manage your contingent workforce and streamline your staffing processes by accessing all the necessary tools and information in one place",
        "prismahover": "With Prisma, you can easily monitor and analyze your energy consumption and optimize your energy usage by accessing all the relevant data and insights in one place",
        "gertrudhover": "Gertrud",
        "notificationhover": "Notifcation Portal",
        "zukohover": "Zuko",
        "brandhover": "EON Brand Space",
        "pkihover": "PKI Portal",
        "mailhover": "Mail DL",
        "journeyhover": "Journeys/Events",
        "mygeniushover": "MyGenius",
        "subTitle": {
            "timeSubTitle": "CATS",
            "meetingSubTitle": "GINGCO",
            "approvalSubTitle": "SNOW/SAP",
            "prSubTitle": "BANF",
            "praSubTitle": "BANF",
            "processingSubTitle": "SAP",
            "hrSubTitle": "MyHR",
            "concurSubTitle": "SAP Concur",
            "passwordSubTitle": "SAP Systems",
            "aribaSubTitle": "PO Overview",
            "itresourcesSubTitle": "SNOW",
            "incidentSubTitle": "SNOW",
            "orderSubTitle": "Order IT Services",
            "myassetsSubTitle": "SNOW",
            "beelineSubTitle": "External Hiring",
            "prismaSubTitle": "HSE",
            "gertrudSubTitle": "Prepare Board Meetings",
            "notificationSubTitle": "Facility Services",
            "zukoSubTitle": "Organize Office Move",
            "brandSubTitle": "it's on us",
            "pkiSubTitle": "Manage certificates",
            "mailSubTitle": "Maintain",
            "journeySubTitle": "FuV §37b EstG",
            "mygeniusSubTitle": "Digital Learning Platform"
        }
    },
    "searchPage": {
        "searchPlaceholder": "Search...",
        "searchApps": "Search here...",
        "relatedApps": "Apps",
        "viewMoreApps": "More",
        "removeAppTooltip": "Remove from personal dashboard",
        "addAppTooltip": "Add to personal dashboard"
    },
    "pwaText": {
        "header": "Install this app on your home screen!",
        "button": "Install App",
        "iphoneHeader": "To install this app on iPhone",
        "inst1": "Tap the <b>Share</b> button in Safari.",
        "inst2": "Scroll down and select <b>\"Add to Home Screen\".</b>",
        "inst3": "Tap <b>Add</b> in the top right corner.."
    },
    "filterPage": {
        "filterMenu": {
            "backBtn": "Back to previous page",
            "loading": "Loading",
            "app": "Apps",
            "gitlab": "Gitlab",
            "apps": "Apps",
            "all": "All",
            "m365": "SharePoint - OneDrive",
            "jql": "jql",
            "people": "People",
            "community": "Community",
            "pages": "Pages",
            "users": "Users",
            "noData": "We couldn't find any results for",
            "noMoreData": "No further results found for your query!",
            "filterHeading": "Filters",
            "reset": "Reset Filters",
            "refineResults": "Refine results",
            "priority": "Priority",
            "status": "Status",
            "card_languages": "Languages",
            "ecl_source_name": "Source",
            "standard_card_type": "Type",
            "mimeType": "Type",
            "space": "Space",
            "datasources": "Data sources",
            "lookingFor": "I am looking for",
            "timeRange": "Time range",
            "mygenius": "My Genius",
            "jira": "Jira",
            "confluence": "Confluence",
            "orderit": "my IT order",
            "aiassistant": "Assistance"
        },
        "searchResult": {
            "source": "Source",
            "business": "Business",
            "mobile": "Mobile",
            "type": "Type",
            "duration": "Duration",
            "category": "Category",
            "deliveryTime": "Delivery time",
            "availableLanguages": "Available languages",
            "status": "Status",
            "lastUpdate": "Last update",
            "lastUpdated": "Last update",
            "createdOn": "Created on",
            "createdBy": "Created by",
            "company": "Company",
            "department": "Department",
            "job_title": "Job Title",
            "office_location": "Office Location",
            "email": "Email"
        },
        "timeRangeOptions": {
            "All": "All",
            "1 Day": "1 Day",
            "7 Days": "7 Days",
            "1 Month": "1 Month",
            "6 Months": "6 Months",
            "1 Year": "1 Year"
        },
        "dataSources": {
            "Confluence": "Confluence",
            "Gitlab": "Gitlab",
            "JIRA": "Jira",
            "MyGenius": "MyGenius",
            "ItOrder": "my IT order",
            "Sharepoint": "SharePoint",
            "Knowledge Base": "Knowledge Base",
            "powerpoint": "PowerPoint",
            "word": "Word",
            "excel": "Excel",
            "pdf": "PDF",
            "loop": "Loop",
            "other": "Other"
        }
    },
    "chatBot": {
        "chatIcon": "ChatBot",
        "searchBack": "Back to previous page",
        "welcomeMessage": "Chat Prompt",
        "chatbotPlaceholder": "Type here...",
        "sendbtn": "Send",
        "showMore": "Show more",
        "feedbackTitle": "Was this helpful?",
        "feedbackTitle2": "Was the response helpful?",
        "feedbackThumbsUpLabel": "Yes, it helped",
        "feedbackThumbsDownLabel": "No, I need further assistance",
        "feedbackPrompt": "Type any optional feedback here",
        "askMe": "Ask your querys",
        "card": {
            "selfService": "Self Service",
            "efficiency": "Efficiency",
            "ngService": "Next-Gen Service"
        },
        "disclaimerText": "AI-generated content may be flawed",
        "selfServiceTitle": "IT Support",
        "selfServiceContent": "AI-Enabled Self service support for Swift Service incident resolution",
        "knowledgeBaseTitle": "Knowledge Base",
        "knowledgeBaseContent": "Improving employee experience through information",
        "internetSearchTitle": "General Questions",
        "wentWell": "What went well? (optional)",
        "wentWrong": "What went wrong? (optional)",
        "privacyMsg": "We respect privacy and we assure feedbacks will be handled confidentially.",
        "appreciateService": "We appreciate your help in improving our services.",
        "feedbackMessage": "Thank you for your feedback!",
        "relevantSearches": "Most Relevant Searches",
        "aiAssitanceSuggestion": "You can ask your questions to AI Assistance using the input box below",
        "aiActionMsg": "It seems your internal storage is full. I can help you resolve the installation issue by automatically cleaning your temporary files and emptying your recycle bin. Do you want me to clean up automatically?",
        "aiActionPrimaryBtn": "Clean up automatically",
        "aiActionSecondaryBtn": "Don't clean up automatically",
        "aiActionSuccess": "Your storage has been cleaned up.",
        "aiActionFailed": "Something went wrong Please try after sometime. Or please try it manually.",
        "hey": "Hey",
        "topicStartMessage": "What can I help with?",
        "newChat": "New Chat",
        "ask": "Ask",
        "internet": "Internet",
        "askEon": "Ask E.ON",
        "askInternet": "Ask Internet",
        "askFollowUpQuestion": "Ask a follow up question",
        "resolveIssue": "To resolve your issue do you want",
        "differentiator": "or",
        "incident": "Create an incident",
        "creatingIncident": "Creating an incident",
        "liveAgent": "Chat with Service Desk",
        "ticketDescription": "Ticket description based on recent discussion",
        "createIncident": "Create incident",
        "confirmCreateIncident": "Confirm and create incident",
        "editCreateIncident": "Edit and create incident",
        "ticketSuccessMsg": "Your Incident has been created successfully, Ticket Number: ",
        "ticketErrorMsg": "Something went wrong. Please try again later.",
        "chatBotTitle": "Powered by AI Assistance",
        "duplicateError": "Duplicate question, please ask a different question",
        "content": "Content",
        "tone": "Tone",
        "information": "Information",
        "complete": "Complete",
        "other": "Other",
        "irelevantContent": "Irrelevant content",
        "lackOfDetail": "Lack of detail",
        "incorrectInformation": "Incorrect information",
        "copyLink": "Copy Link",
        "openLink": "Open Link",
        "internetSearchContent": "Based on ask me anything EON gpt",
        "liveAgentChat": {
            "connectWithLiveAgent": "Connect with Service Desk",
            "connectingWithLiveAgent": "Connecting with Service Desk",
            "connectingHint": "You can switch back to Assistance by clicking the link below the chat box",
            "agentLeftMsg": "Thanks for contacting E.ON",
            "switchToAssistance": "Exit chat and switch to assistance",
            "typeMessageHint": "Type your message here...",
            "disconnectingMsg": "Exiting the chat and switching to assistance"
        },
        "quickActions": {
            "heading": "Quick Actions",
            "actions": {
                "createIncident": "Help me solve an IT issue",
                "chatWithServiceDesk": "Chat with Service Desk",
                "requestCallback": "Request callback from Service Desk",
                "ongoingConversation": "Yes, its part of my last conversation.",
                "newConversation": "No. I need help in solving new issue",
                "confirmAndCreateTicket": "Confirm and create ticket",
                "cancelTicketCreation": "Cancel ticket creation",
                "confirmChatWithServiceDesk": "Confirm and connect with service desk",
                "cancelChatWithServiceDesk": "Cancel chat with service desk"
            },
            "state": {
                "whatsTheIssue": "What is the issue are you looking to solve?",
                "lastConversation": "Sure, is the issue part of last conversation?",
                "createIncidentLabel": "You are about to raise incident ticket for",
                "cancelAck": "Noted. Can I help you with any other issue?",
                "chatWithServiceDeskLabel": "You are about to connect with a service desk agent",
                "createTicketLabel": "Confirm and create ticket",
                "cancelTicketLabel": "Cancel ticket creation",
                "creatingIncidentTicket": "Creating Incident ticket",
                "ticketDescription": "Ticket Description",
                "connectWithServiceDeskLabel": "Confirm to chat with service desk"
            }
        },
        "askTooltip": {
            "eon": "Get company-specific information from internal sources",
            "internet": "Get information from internal and external sources such as E.ON GPT"
        },
        "jiraIntegration": {
            "viewInJira": "View in Jira",
            "responseHeading": "Here is your Jira tickets summary:",
            "responseSubheading": "Showing top ten records",
            "noResponse": "It looks like there are no JIRA tickets available for your request right now.",
            "supportedQuestionMsg": "Hey, I am sorry. I didn't quite understand your question. Can you please rephrase it again. I am sharing some sample questions to assist you:"
        },
        "nexthinkIntegration": {
            "failureMsg": "Oops! Something went wrong. Please try again later. If the issue persists please raise an issue from Quick Actions",
            "assistanceTask": "Assistance Task",
            "itReportTitle": "Here is your systems energy efficiency snapshot",
            "days": "Days",
            "device": "Device",
            "sinceRestarted": "since restarted",
            "deviceRestartRecommendation": "Immediate device restart is recommended",
            "deviceInCompliance": "Your device is in compliance",
            "energyConsumed": "Energy Consumed in last 7 days",
            "coEmitted": "CO2 Emitted in last 7 days",
            "diskClean": {
                "primaryBtn": "Yes, clean up my disk now",
                "secondaryBtn": "Don't clean up now",
                "successMsg": "Your disk has been cleaned up",
                "failureMsg": "Your disk could not be cleaned up at this point",
                "progressMsg": "Clearing up storage in progress",
                "disclaimer": "Please note we will not touch your personal or work data. While clean-up takes place in the background, feel free to use assistance."
            },
            "chromeCache": {
                "primaryBtn": "Yes, clear chrome cache now",
                "secondaryBtn": "Don't clear chrome cache now",
                "successMsg": "Your chrome cache has been cleaned up",
                "failureMsg": "Your Chrome Cache could not be cleaned up at this point",
                "progressMsg": "Clearing up chrome cache in progress",
                "disclaimer": "Please note we will not touch your personal or work data. While clean-up takes place in the background, feel free to use assistance."
            },
            "empowerApp": {
                "primaryBtn": "Yes, please repair now",
                "secondaryBtn": "Don't repair now",
                "successMsg": "Auto repair successfully completed",
                "failureMsg": "Your Empower Plugin could not be repaired at this point",
                "progressMsg": "Auto repair is in progress",
                "disclaimer": "We will be reinstalling the Empower plugin, Please close all your O365 Apps before proceeding further."
            }
        }
    },
    "snow": {
        "welcomeNote": "Please find below an overview about your open incident tickets, your requests and your IT assets.",
        "tabName": {
            "incidents": "Incidents",
            "orders": "Orders",
            "itResources": "IT Resources",
            "incidentsTable": {
                "noData": "No available data",
                "btnText": "Open new Incident"
            },
            "orderTable": {
                "number": "Number",
                "catItem": "Cat Item",
                "approvalSet": "Approval Set",
                "state": "State",
                "dueDate": "Due Date",
                "createdOn": "Created On",
                "noData": "No available data",
                "btnText": "Order IT Services"
            },
            "itResourcesTable": {
                "modelCategory": "Model Category",
                "model": "Model",
                "computerName": "Computer Name",
                "serialNumber": "Serial Number",
                "serviceValue1": "Service Value1",
                "serviceValue2": "Service Value2",
                "noData": "No available data",
                "btnText": "Open a ticket for IT resources"
            }
        }
    },
    "moveTabAndTiles": {
        "tabNames": "Tab",
        "favourite": "Favourite",
        "categorizeTiles": "Personalized dashboard",
        "enterTabName": "Enter tab name",
        "tabNameError": "Please enter unique Tab name, with minimum 2 to max 20 length!",
        "tabInputLabel": "Tab name",
        "addTabButton": "Add tab",
        "personalizeTab": "Personalize tabs",
        "personalizeTiles": "Personalize tiles",
        "addTab": "Add new tab",
        "removeTabConfrim": "Are you sure to remove the Tab",
        "with": " with",
        "cardIn": "tiles in?",
        "actionUndone": "This action cannot be undone.",
        "categorizeTilesTitle": "Rename tabs, Remove tabs and move tiles within tabs",
        "addTabTitle": "Add new tab. You can create maximum 4 tabs.",
        "organiseTilesTitle": "To move the position of the Tiles"
    },
    "dataTable": {
        "pagingData": "Items per page",
        "itemString": "items",
        "ofString": "of"
    },
    "tourData": {
        "userActions": {
            "back": "Back",
            "close": "Close",
            "last": "Finish",
            "next": "Next",
            "open": "Open the dialog",
            "skip": "Skip",
            "start_tour": "Start tour",
            "tour_info": "Would you like us to show you the basic functionalities with a quick guided tour? It should only take 1-2 minutes..."
        },
        "tile": {
            "step1": "Use me@eon to find your typical work applications, processes, information and resources that are relevant to your organization (for example, HR topics, trainings, company documents, policies and many more).",
            "step2": "You can find out more about me@eon and its functions, find answers to frequently asked questions and learn helpful tips and tricks in the user documentation.",
            "step3": "You can still change the preferred language anytime from here.",
            "step4": "You can logout by clicking here.",
            "step5": "Use me@eon assistance to ask a question, get support and helpful information from internal and external sources."
        },
        "tab": {
            "step1": "You can add new tab by clicking here.",
            "step2": "Click here to organize the sequence of the tiles.",
            "step3": "Here you can rename and organize tabs."
        }
    }
}