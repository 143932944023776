import React, {CSSProperties} from "react";
import {ProfileImage} from "./profile.image";

interface ElSearchAdvanceCardProps {
    result: {
        resource: PersonData;
    };
}

interface PersonData {
    id: string;
    displayName: string;
    givenName: string;
    surname: string;
    companyName: string;
    jobTitle: string;
    department: string;
    officeLocation: string;
    additionalOfficeLocation: string;
    personType: {
        class: string;
        subclass: string;
    };
    userPrincipalName: string;
    imAddress: string;
    phones:Phone[]
}
export type Phone = {
    type: string;
    number: string;
}

const styles: Record<string, CSSProperties> = {
    card: {
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "12px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        width: "500px",
        margin: "0 auto",
    },
    header: {
        margin: "0 0 8px 0",
        color: "#333",
        fontSize: "1.2rem",
    },
    actions: {
        display: "flex",
        gap: "8px",
        marginBottom: "8px",
    },
    actionButton: {
        backgroundColor: "#0078D4",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        padding: "6px 10px",
        cursor: "pointer",
        fontSize: "0.9rem",
    },
    twoColumns: {
        display: "flex",
        justifyContent: "space-between",
        gap: "12px",
    },
    column: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    nameValue: {
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        marginBottom: "8px",
    },
    label: {
        fontSize: "0.9rem",
        fontWeight: "bold",
        color: "#555",
    },
};

const AttributeValue: React.FC<{ label: string; value?: string | React.ReactNode }> = ({label, value}) => {
    if (!value) return null; // Don't render if value is undefined/null
    return (
        <div style={styles.nameValue}>
            <span style={styles.label}>{label}:</span>
            <span>{value}</span>
        </div>
    );
};


type TwoColumnsProps = {
    children: [React.ReactNode, React.ReactNode];
}
const TwoColumns: React.FC<TwoColumnsProps> = (props) => {
    const [left, right] = props.children;
    return (
        <div style={styles.twoColumns}>
            <div style={styles.column}>{left}</div>
            <div style={styles.column}>{right}</div>
        </div>
    );
};

export function ElSearchAdvanceCardForPeople({result}: Readonly<ElSearchAdvanceCardProps>) {
    const person: PersonData = result.resource;
    if (person?.personType?.subclass !=='OrganizationUser') return <></>; //It is hard to do this in the query so we do client side
    if (!person) return <></>;
    const phone = person.phones?.[0]?.number; // what do we do if there are multiple phones?
    return (
        <div style={styles.card}>
            <h2 style={styles.header}>{person.displayName}</h2>
            <TwoColumns>
                <>
                    <ProfileImage userId={person.id} altText={`Profile image of ${person.displayName}`}/>
                    <AttributeValue label="Email" value={person.userPrincipalName}/>
                    <AttributeValue label="Phone" value={phone}/>
                    <AttributeValue label="IM Address" value={person.imAddress}/>
                </>
                <>
                    <AttributeValue label="Company" value={person.companyName}/>
                    <AttributeValue label="Job Title" value={person.jobTitle}/>
                    <AttributeValue label="Department" value={person.department}/>
                    <AttributeValue label="Office Location" value={person.officeLocation}/>
                </>
            </TwoColumns>

        </div>
    );
}
