import React, { ReactNode, useCallback } from "react";
import { NameAnd } from "@me8eon/record_utils";
import { makeContextForState } from "@me8eon/context";
import { useThrowError } from "@me8eon/throw_error";

export const newArchitectureFF = 'newArchitecture'
export const newArchitectureFeatureFlag: FeatureFlag = {
    description: "Use the new architecture",
    value: true
}
export type CommonFeatureFlag<T> = {
    description: string
    value: T
}
export type BooleanFeatureFlag = CommonFeatureFlag<boolean>
export type OptionsFeatureFlag = CommonFeatureFlag<string> & {
    options: string[]
}

export function isBooleanFeatureFlag(flag: CommonFeatureFlag<any>): flag is BooleanFeatureFlag {
    return (flag as any).options === undefined;
}

export function isOptionsFeatureFlag(flag: CommonFeatureFlag<any>): flag is OptionsFeatureFlag {
    return (flag as OptionsFeatureFlag).options !== undefined;
}

export type FeatureFlag = BooleanFeatureFlag | OptionsFeatureFlag
export type FeatureFlags = NameAnd<FeatureFlag>


export const { use: useFeatureFlags, Provider: FeatureFlagsStateProvider } = makeContextForState<FeatureFlags, 'featureFlags'>('featureFlags');

export function useFeatureFlag(name: string): boolean | string {
    const [flags] = useFeatureFlags();
    return flags[name]?.value ?? false;
}

export function flagged<Props>(flag: string, Enabled: (p: Props) => ReactNode, Disabled: (p: Props) => ReactNode): (p: Props) => ReactNode {
    return (props: Props) => {
        const f = useFeatureFlag(flag);
        return f ? Enabled(props) : Disabled(props);
    };
}

export function flaggedValue<Props>(flag: string, options: NameAnd<(p: Props) => ReactNode>): (p: Props) => ReactNode {
    return props => {
        const [flags] = useFeatureFlags();
        const throwError = useThrowError();
        const f = flags[flag];

        if (isOptionsFeatureFlag(f)) {
            const Value = options[f.value];
            if (Value === undefined) return throwError('s/w', `Invalid value ${f.value} for feature flag ${flag}`);
            return Value(props);
        }
        return throwError('s/w', `Feature flag ${flag} is not an options flag`);
    };
}