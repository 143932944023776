{
  "devmode": {
    "Authentication": "Authentication",
    "language": "Language",
    "localStorage": "Local Storage",
    "Debug": "Debug",
    "FeatureFlags": "Feature Flags",
    "Hide": "Hide",
    "Translate": "Translation",
    "Filters": "Filters",
    "translation": {
      "title": "Translate",
      "errors": "Errors",
      "notFound": "Not Found",
      "used": "Used"
    }
  }
}