import React from "react";
import useRoomBookingStore from "../../store/roomBookingStore";

function LocationBreadcrumb({ hierarchy }: { hierarchy: number[] }) {
    const { locations } = useRoomBookingStore();

    const getLocation = (id: number): string => {
        const foundLocation = locations.find(
            (location) => location.location_id === id
        );
        return foundLocation?.location_name ?? "";
    };

    const breadCrumb = () => {
        let locationHierarchy = hierarchy.map((item) => getLocation(item));
        locationHierarchy = locationHierarchy.slice(1);
        return locationHierarchy.join(" > ");
    };
    return <div><p className="label__text">{breadCrumb()}</p></div>;
}

export default LocationBreadcrumb;
