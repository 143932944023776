import React from "react";
import { DataViewNavBarLayoutProps } from "./data.view.nav.bar.layout";

export function SimpleDataNavBarViewLayout({ id, children }: DataViewNavBarLayoutProps) {
    return <div data-testid={id} style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    }}>
        {children}
    </div>;
}