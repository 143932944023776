import { Icon } from "@me8eon/icons";
import { MessageWithActionAndData } from "../AiAssist.context";
// import useFirstName from "../hooks/useFirstName";
import React from "react";

const userQueryComponent = ({ content }: MessageWithActionAndData) => {
    // const {firstName} = useFirstName();
    return (
        <div className="user-header">
            <div className="user-logo">
                <Icon name="user" />
            </div>
            <div className="user-reply-container">
                <div className="bot-text">{content}</div>
            </div>
        </div>
    );
};

export default userQueryComponent;