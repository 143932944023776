export function generateJQL(entities: any): string {
    let jqlParts: string[] = [];

    function sanitizeValue(value: any): string {
        if (typeof value !== "string" || !value.trim()) return "";
        return value.replace(/["']/g, "").trim();
    }

    function parseTimeRange(timeRange: string): string {
        const match = timeRange.match(/(\d+)\s*(day|week|month|year)s?/i);
        if (!match) return "";
        
        let value = parseInt(match[1], 10);
        const unit = match[2].toLowerCase();

        switch (unit) {
            case "week": value *= 7; break;
            case "month": value *= 30; break;
            case "year": value *= 365; break;
        }

        return `created >= -${value}d`;
    }

    if (entities.assignee) {
        const assignee = correctSpelling(sanitizeValue(entities.assignee)).toLowerCase();
        if (assignee === "me" || assignee === "currentuser") {
            jqlParts.push(`assignee = currentUser()`);
        } else {
            jqlParts.push(`assignee = "${assignee}"`);
        }
    }        

    if (entities.reporter) {
        jqlParts.push(`reporter = "${correctSpelling(sanitizeValue(entities.reporter))}"`);
    }

    if (entities.labels) {
        let labelsArray: string[] = [];
    
        if (typeof entities.labels === "string") {
            labelsArray = entities.labels.split(/,\s*/);
        } else if (Array.isArray(entities.labels)) {
            labelsArray = entities.labels;
        }
    
        const labels = labelsArray
            .filter((label: string) => label.trim() !== "")
            .map((label: string) => `"${correctSpelling(sanitizeValue(label))}"`)
            .join(", ");
    
        if (labels.length > 0) {
            if (labelsArray.length > 1) {
                jqlParts.push(`labels IN (${labels})`);
            } else {
                jqlParts.push(`labels = ${labels}`);
            }
        }
    }    

    if (entities.priority) {
        const priorities = sanitizeValue(entities.priority).split(',').map(p => `"${correctSpelling(p.trim())}"`);
        jqlParts.push(`priority IN (${priorities.join(', ')})`);
    }
    
    if (entities.issue_type) {
        const issueTypes: string[] = String(entities.issue_type)
            .split(",")
            .map(type => correctSpelling(sanitizeValue(type.trim())));
    
        jqlParts.push(`issuetype IN (${issueTypes.map(type => `"${type}"`).join(", ")})`);
    }
    
    if (entities.status) {
        jqlParts.push(`status = "${correctSpelling(sanitizeValue(entities.status))}"`);
    }

    if (entities.project) {
        jqlParts.push(`project = "${correctSpelling(sanitizeValue(entities.project))}"`);
    }

    if (entities.time_range) {
        const days = parseInt(sanitizeValue(entities.time_range).trim(), 10);
        
        if (!isNaN(days) && days > 0) {
            jqlParts.push(`created >= -${days}d`);
        }
    }    

    if (entities.sprint) {
        let sprintCondition = "";
        const sprintValue = correctSpelling(sanitizeValue(entities.sprint)).toLowerCase();
        
        const sprintMappings: { [key: string]: string } = {
            "current": "sprint in openSprints()",
            "current sprint": "sprint in openSprints()",
            "open": "sprint in openSprints()",
            "last": "sprint in closedSprints()",
            "upcoming": "sprint in futureSprints()"
        };
    
        if (sprintMappings[sprintValue]) {
            sprintCondition = sprintMappings[sprintValue];
        } else {
            const sprints = sprintValue.split(",").map(s => `"${s.trim()}"`).filter(s => s !== `""`);
            if (sprints.length > 1) {
                sprintCondition = `sprint IN (${sprints.join(", ")})`;
            } else if (sprints.length === 1) {
                sprintCondition = `sprint = ${sprints[0]}`;
            }
        }
    
        if (sprintCondition) {
            jqlParts.push(sprintCondition);
        }
    }
    

    jqlParts = jqlParts.filter(part => part.trim() !== "");

    return jqlParts.length > 0 ? jqlParts.join(" AND ") : "ORDER BY created DESC";
}

function correctSpelling(value: string): string {
    if (!value) return "Unknown";

    const corrections: { [key: string]: string } = {
        "bgg": "Bug", "bg": "Bug", "bugg": "Bug",
        "tsk": "Task", "tas": "Task",
        "stor": "Story", "storiy": "Story",
        "highh": "High", "loww": "Low", "medum": "Medium",
        "don": "Done", "inprogres": "In Progress",
        "todob": "To Do", "canceled": "Cancelled",
        "backlogg": "Backlog", "upcomng": "Upcoming",
        "curent": "Current", "closd": "Closed",
        "me": "currentUser",
        "open sprint": "openSprints",
        "closed sprint": "closedSprints",
        "future sprint": "futureSprints"
    };

    const formattedValue = value.trim().toLowerCase();
    return corrections[formattedValue] || formatJQLString(value);
}

function formatJQLString(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function reformatResponse(response: any): any {
    if (Array.isArray(response)) {
        return response.map(reformatResponse);
    }

    let content = response.content;

    try {
        return processParsedContent(JSON.parse(content), response);
    } catch (error) {
        console.warn("Invalid JSON, trying to fix:", content);
    }

    content = fixInvalidJSON(content);

    try {
        return processParsedContent(JSON.parse(content), response);
    } catch (error) {
        console.error("Still invalid after fixing:", content);
    }

    return response;
}

function processParsedContent(parsedContent: any, response: any) {
    if (parsedContent.answer !== undefined) {
        return {
            content: String(parsedContent.answer),
            jql: String(parsedContent.jql || ""),
            refusal: response.refusal,
            role: response.role,
            entities: parsedContent.entities
        };
    }
    return response;
}

function fixInvalidJSON(content: string): string {
    if (typeof content !== "string") return content;
    content = content.replace(/[\x00-\x1F\x7F]/g, "");
    content = content.replace(/(?<!\\)'/g, '"');
    content = content.replace(/,\s*([\]}])/g, "$1");
    if (!content.startsWith("{") && !content.startsWith("[")) {
        content = `{${content}`;
    }
    if (!content.endsWith("}") && !content.endsWith("]")) {
        content = `${content}}`;
    }

    return content;
}