import { CommonDataLayoutStyles, commonDataStyles, commonRendererStyles, Theme } from "@me8eon/themes";


// @ts-ignore
// import MeAtEonLogo from "./meateon.png";
// @ts-ignore
// import EonLogo from "./eon.png";

export const bayernwerkTheme: Theme = {
    name: "bayernwerk theme",
    value: "bayernwerk",
    description: "The theme for bayern werk",
    header: {
        eonLogoUrl: "bayernwerk",
        meAtEonLogoUrl: "me8bayern",
    },
    data: commonDataStyles,
    renderer: commonRendererStyles,
    dataLayout: {
        ...CommonDataLayoutStyles,
        colorVariables: {
            "--primary-color": "#008eb0",
            "--heading-color": "#008eb0",
            "--heading-color-hover": "#047491",
            "--super-light-bg": "#7fc6d712",
            "--light-bg": "#93abb117",
            "--normal-bg": "#8beaff29",
            "--eon-red500": "#008eb0",
            "--eon-red700": "#008eb0",
            "--bg-hover-color": "#93abb117"
        },
        linkMouseOverColor: "var(--primary-color)",
        themedIconColor: "#008eb0",
    }
};
