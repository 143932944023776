import React from "react";
import { PeopleData } from "./people.data";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { SimpleWidget } from "@me8eon/simple_widget";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { Icon, ThemedIcon } from "@me8eon/icons";

export const PeopleDisplay: DisplayData<PeopleData> =
    ({ rootId, id, data }: DisplayDataProps<PeopleData>) => {
        const { H3, Url } = useRenderers();
        const { Text, DataLayout } = useAttributeValueComponents();
        const newRootId = `${rootId}.${id}`;
        const resource = data.resource;
        const { displayName,
            userPrincipalName,
            jobTitle,
            companyName,
            department,
            officeLocation,
            phones,
            imAddress
        } = resource;
        const photoName = displayName.split(", ").map((name) => name.charAt(0)).join(" ");
        // const KID = userPrincipalName.split("@")[0];
        const justEmail = imAddress ? imAddress.split("sip:")[1] : userPrincipalName;

        return <DataLayout rootId={newRootId} className="people-data" layout={[2]} >
            <div style={{ display: "flex", gap: "10px" }}>
                <div style={{
                    height: "100px",
                    fontSize: "40px",
                    minWidth: "100px",
                    backgroundColor: "var(--bg-hover-color)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    {photoName}
                </div>
                <div>
                    <H3 rootId={newRootId} attribute={translateLabel("name")} value={`${displayName}`} />
                    <Text rootId={newRootId} attribute={""} value={jobTitle} />
                    {officeLocation && <Text rootId={newRootId} attribute={""} value={userPrincipalName} />}
                    {companyName && <Text rootId={newRootId} attribute={translateLabel("company")} value={companyName} />}
                    {department && <Text rootId={newRootId} attribute={translateLabel("department")} value={department} />}
                </div>
            </div>
            <div>
                <Url
                    icon={() => <Icon
                        name="outlook"
                    />}
                    rootId={id} attribute={translateLabel('url')}
                    label={justEmail}
                    value={`mailto:${justEmail}`}
                />
                <Url
                    icon={() => <Icon
                        name="teams"
                    />}
                    rootId={id} attribute={translateLabel('url')}
                    label={justEmail}
                    value={`https://teams.microsoft.com/l/chat/0/0?users=${justEmail}`}
                />
                {phones && phones.map((phone) => {
                    return (
                        <Url
                            key={`${phone.type} ${phone.number}`}
                            icon={() => <ThemedIcon
                                size="normal"
                                name={phone.type === "mobile" ? "smartphone" : "toolkit"}
                            />}
                            rootId={id} attribute={translateLabel('url')}
                            label={phone.number}
                            value={`tel:${phone.number}`}
                        />
                    )
                })}
            </div>
        </DataLayout>;
    };

export const OneLinePeopleDisplay: DisplayData<PeopleData> =
    ({ data }: DisplayDataProps<PeopleData>) =>
        <span>Not done yet</span>;

export const SimplePeopleWidget: DisplayDataWidget<PeopleData> =
    SimpleWidget(
        [],
        [],
        []);

