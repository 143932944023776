import React from "react";
import { Message } from "./ai.types";
import { useJwtTokens } from "../diagnostics/diagnostics";
import SummariseToIncidentClientSingleton from "./clients/ai.createIncident.client";
import { defaultAzureAiConfig, TAzureAiClientConstructor } from "./clients/ai.base.client";
import { useDebug } from "@me8eon/debug";
import { AiRagClient, useAiSourceMemory, aiRagDebug } from "./clients/ai.rag.client";
import { EnvironmentConfig } from "@me8eon/config";
import { QuickActionType } from "../ai-assist-refactored/AiAssist.context";
import { useAuth } from "../../MsalContext";
import { useJwtToken, useUserData } from "@me8eon/react_authentication";

export type OutputAndSource = {
    output: string;
    source: string;
    action: string;
    data?: any;
};

export type AiClientWithRag = (history: Message[], query: string, language: string, indicies?: string[], featureFlagEnabled?: boolean, action?: string) => Promise<OutputAndSource>
export type AiClientQuickActions = (history: Message[], query: string, quickActionType: QuickActionType) => any
export type AiClientSummariseChatHistoryToIncident = (history: Message[], reason: string, language: string) => Promise<OutputAndSource>

export type AiClient = {
    aiClientWithRag: AiClientWithRag,
    aiClientQuickActions: AiClientQuickActions,
    aiClientSummariseChatHistoryToIncident: AiClientSummariseChatHistoryToIncident
}

const AiRagContext = React.createContext<AiClient | undefined>(
    undefined,
);

export function AiRagProvider({ children }: { children: React.ReactNode }) {
    const { company } = useUserData()
    const jwtTokenFn = useJwtToken()

    const aiConfig: TAzureAiClientConstructor = {
        config: defaultAzureAiConfig(EnvironmentConfig.gatewayURL + "/proxy/"),
        jwtTokenFn
    };

    const rememberSearchOps = useAiSourceMemory();
    const debug = useDebug(aiRagDebug);

    const aiSummariseClient = new SummariseToIncidentClientSingleton(aiConfig);
    const aiRagClient = new AiRagClient({
        config: aiConfig,
        rememberSearchOps,
        searchIndicies: ["ai-azureblob-test", "questionator-office-buddy-prod", "apps-prod"],
        debug,
    });
    const aiClientSummariseChatHistoryToIncident = async (history: Message[], reason: string, language: string) => aiSummariseClient.summariseToIncident(history, reason, language);
    const aiClientWithRag: AiClientWithRag = async (history: Message[], query: string, language: string, indicies?: string[], featureFlagEnabled?: boolean) => aiRagClient.aiClientWithRag(history, query, language, company, indicies, featureFlagEnabled);
    const aiClientQuickActions: AiClientQuickActions = async (history: Message[], query: string, quickActionType: QuickActionType) => aiRagClient.aiClientQuickActions(history, query, quickActionType);
    return (
        <AiRagContext.Provider value={{ aiClientSummariseChatHistoryToIncident, aiClientWithRag, aiClientQuickActions }}>
            {children}
        </AiRagContext.Provider>
    );
}

export function useAiWithRag(): AiClient {
    const client = React.useContext(AiRagContext);
    if (!client)
        throw new Error("useAiWithRag must be used within a AiRagProvider");
    return client;
}
