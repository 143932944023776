{
  "ourTeam": {
    "keyMembers": "Nyckelpersoner",
    "tabName": "Vårt team"
  },
  "releaseNotes": {
    "accordianHeading": "Släpp me@eon V<version>",
    "faq": "FAQ",
    "tabName": "Utgivningshistorik",
    "tipsAndTricks": "Tips och knep",
    "whatsNew": "Nyheter"
  }
}