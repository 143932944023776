import { DataWidget, DataWidgetDisplayer } from "@me8eon/data_widget";
import { makeContextForState } from "@me8eon/context";
import React from "react";


export type UnifiedSearchProps = {
    setters: any //really filtersetter but way too hard to do properly
    setShowAiAssist: (show: boolean) => void

}
export type AiAssistantScaffolding = UnifiedSearchProps & {
    Display: (props: UnifiedSearchProps) => React.ReactNode
}
export const { use: useAiAssist, Provider: AiAssistantProvider } = makeContextForState<AiAssistantScaffolding, "aiAssist">("aiAssist");
export const emptyAiAssistScaffolding: AiAssistantScaffolding = {
    setShowAiAssist: () => { },
    setters: undefined as any,
    Display: () => <div>AI Assistant Scaffolding NOT SET UP</div>,
};

export const DisplayAiAssistant: DataWidgetDisplayer =
    ({ rootId, data }) => {
        const [aiAssist] = useAiAssist();
        const { setters, setShowAiAssist, Display } = aiAssist;
        return <Display setters={setters} setShowAiAssist={setShowAiAssist} />
    };


export const aiAssistDataWidget: DataWidget = {
    plugin: "datawidget",
    name: "ai_assist",
    display: DisplayAiAssistant,
};