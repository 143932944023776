import { BooleanFeatureFlag, useFeatureFlag } from "@me8eon/featureflags";

export const jqlDebugName = 'jql'

export const jqlFeatureFlagName = 'jql'
export const JqlFeatureFlag: BooleanFeatureFlag = {
    description: "use JQL in the assistant",
    value: true
}
export function useJqlFlag() {
    return useFeatureFlag(jqlFeatureFlagName)
}
