import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import ThumbUp from "../../assets/image/thumb_up.svg";
// @ts-ignore
import ThumbDown from "../../assets/image/thumb_down.svg";
import "./feedback.scss";
import {
    EonUiButton,
    EonUiIcon,
    EonUiInput,
    EonUiLink,
    EonUiTagPill,
} from "@eon-ui/eon-ui-components-react";
import FeedbackCreateIncidentComponent from "./feedback.createIncident.component";
import useSearchStore from "../../store/searchStore";
import { FeedbackProps, LikeOrDislike, useFeedback } from "@me8eon/feedback";

export type FeedbackData = {
    id: string;
    index: string;
};
export type FeedbackSetterProps = {
    setIsCreateIncidentOpen: (value: boolean) => void,
    setTicketRespLoading: (value: boolean) => void,
    setConfirmTicketCreation: (value: boolean) => void,
};

export type FeedbackGetterProps = {
    isCreateIncidentOpen: boolean,
    ticketRespLoading: boolean,
    confirmTicketCreation: boolean,
};

export function Feedback({
    dataToSend,
    createIncidentHandler,
    aiChatbotIndex,
    showCreateIncident,
    source
}: FeedbackProps) {

    const {
        showLiveAgentChat,
        setShowLiveAgentChat
    } = useSearchStore();

    const [likeDislike, setLikeDislike] = useState<LikeOrDislike | undefined>(
        undefined
    );
    const { t } = useTranslation();

    const likeSuggestions = [
        t("chatBot.content"),
        t("chatBot.tone"),
        t("chatBot.information"),
        t("chatBot.complete"),
        t("chatBot.other"),
    ];
    const dislikeSuggestions = [
        t("chatBot.irelevantContent"),
        t("chatBot.lackOfDetail"),
        t("chatBot.incorrectInformation"),
    ];
    const suggestions =
        likeDislike === "dislike" ? dislikeSuggestions : likeSuggestions;
    const [isFeedbackSend, setIsFeedbackSend] = useState(false);
    const [text, setText] = useState("");
    const [isCreateIncidentOpen, setIsCreateIncidentOpen] = useState(false);
    const [ticketRespLoading, setTicketRespLoading] = useState(false);
    const [confirmTicketCreation, setConfirmTicketCreation] = useState(false);
    const feedback = useFeedback();
    const setters: FeedbackSetterProps = {
        setIsCreateIncidentOpen,
        setTicketRespLoading,
        setConfirmTicketCreation
    };
    const getters: FeedbackGetterProps = {
        isCreateIncidentOpen,
        ticketRespLoading,
        confirmTicketCreation
    };
    const onClick = (likeDislike: LikeOrDislike) => () => {
        if (isFeedbackSend) return;
        setText("");
        setIsFeedbackSend(false);
        setLikeDislike(likeDislike);
        feedback(dataToSend(likeDislike));
    };
    const handleButtonClick = (text: string) => {
        console.log("handleButtonClick");
        setIsFeedbackSend(true);
        feedback({
            source: "freeTextFromChat",
            text,
            likeDislike,
        });
    };
    const getGapForSource = (source: string) => {
        return source === "ai-assistance" ? "24px" : "";
    };
    const getTitleForSource = (source: string) => {
        return source === "ai-assistance" ? "chatBot.feedbackTitle2" : "chatBot.feedbackTitle";
    };
    const supportButtonsDisabled = () => showLiveAgentChat || ticketRespLoading || confirmTicketCreation;

    return (
        <div className="feedback">
            <div className="record-feedback" style={{ gap: getGapForSource(source) }}>
                <span className="title">{t(getTitleForSource(source))}</span>
                <LikeDislike
                    like={"like"}
                    selected={likeDislike}
                    onClick={onClick("like")}
                    label={t("chatBot.feedbackThumbsUpLabel")}
                    source={source}
                    disabled={isFeedbackSend}
                />
                <LikeDislike
                    like={"dislike"}
                    selected={likeDislike}
                    onClick={onClick("dislike")}
                    label={t("chatBot.feedbackThumbsDownLabel")}
                    source={source}
                    disabled={isFeedbackSend}
                />
            </div>
            {likeDislike && (
                <>
                    {!isFeedbackSend ? (
                        <div className="feedback-form">
                            <div className="label-section">
                                {t(
                                    `chatBot.${likeDislike === "like"
                                        ? "wentWell"
                                        : "wentWrong"
                                    }`
                                )}
                            </div>
                            <div>
                                {suggestions.map(
                                    (item: string, index: number) => (
                                        <EonUiTagPill
                                            filter-name={item}
                                            key={index}
                                            text={item}
                                            class="feedback-tag"
                                            scheme="neutral800"
                                            onClick={() => {
                                                setText(item);
                                                handleButtonClick(item);
                                            }}
                                        ></EonUiTagPill>
                                    )
                                )}
                            </div>
                            <div className="text-area-wrapper">
                                <EonUiInput
                                    className="feedback-input"
                                    value={text}
                                    type="text"
                                    placeholder={t("chatBot.feedbackPrompt")}
                                    onValueChanged={(e) =>
                                        setText(e.target.value)
                                    }
                                    size="small"
                                    onKeyDown={(evt) => {
                                        if (evt.key === "Enter") {
                                            setIsFeedbackSend(true);
                                            feedback({
                                                source: "freeTextFromChat",
                                                text,
                                                likeDislike,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="label-section">
                                {t("chatBot.privacyMsg")}
                            </div>
                            <EonUiButton
                                fullWidthOnMobile
                                text={t("submit")}
                                rank="primary"
                                size="small"
                                onClick={() => {
                                    setIsFeedbackSend(true);
                                    feedback({
                                        source: "freeTextFromChat",
                                        text,
                                        likeDislike,
                                    });
                                }}
                            ></EonUiButton>
                        </div>
                    ) : (
                        <div className="feedback-response">
                            <EonUiIcon
                                className="user-icon eon-ui-svg-icon"
                                name="radio_checkbox_tick"
                            />
                            <div className="feedback-message-wrapper">
                                <p className="feedback-message">
                                    {t("chatBot.feedbackMessage")}
                                </p>
                                {likeDislike === "dislike" && (
                                    <p className="feedback-message">
                                        {t("chatBot.appreciateService")}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    {(aiChatbotIndex === 0 || showCreateIncident) &&
                        likeDislike === "dislike" &&
                        isFeedbackSend && (
                            <>
                                <div className="issue-resolver-wrapper">
                                    <div>{t("chatBot.resolveIssue")}</div>
                                    <div className="issue-resolver-content">
                                        <EonUiTagPill
                                            key={"incident"}
                                            text={t("chatBot.incident")}
                                            class="feedback-tag"
                                            scheme="neutral800"
                                            isDisabled={supportButtonsDisabled()}
                                            onClick={() => {
                                                if (supportButtonsDisabled()) return;
                                                if (createIncidentHandler) {
                                                    createIncidentHandler(text);
                                                }
                                                if (showCreateIncident) {
                                                    setIsCreateIncidentOpen(true);
                                                }
                                            }}
                                        ></EonUiTagPill>
                                        <span className="differentiator">
                                            {t("chatBot.differentiator")}
                                        </span>
                                        <EonUiTagPill
                                            key={"liveAgent"}
                                            text={t("chatBot.liveAgent")}
                                            class="feedback-tag"
                                            scheme="neutral800"
                                            isDisabled={supportButtonsDisabled()}
                                            onClick={() => {
                                                if (supportButtonsDisabled()) return;
                                                setShowLiveAgentChat(true);
                                            }}
                                        ></EonUiTagPill>
                                    </div>
                                </div>
                                {isCreateIncidentOpen && showCreateIncident && !showLiveAgentChat && (
                                    <FeedbackCreateIncidentComponent setters={setters} getters={getters} />
                                )}
                            </>
                        )}
                </>
            )}
        </div>
    );
}

export type LikeDislikeProps = {
    like: LikeOrDislike;
    selected: LikeOrDislike | undefined;
    onClick: () => void;
    label: string;
    source: string;
    disabled: boolean;
};

export function LikeDislike({ like, selected, onClick, label, source, disabled }: LikeDislikeProps) {
    const IconName = like === "like" ? ThumbUp : ThumbDown;
    const isSelected = selected === like;
    return (
        <>
            {source === "ai-assistance" ? (
                <div className="like-dislike">
                    <IconName />
                    <EonUiLink
                        className={`thumb ${isSelected ? "thumb--selected" : ""}`}
                        onClick={() => onClick()}
                        hideIcon={true}
                        text={label}
                        size="small"
                        disabled={disabled}
                    ></EonUiLink>
                </div>
            ) : (
                <button
                    onClick={() => onClick()}
                    className={`thumb ${isSelected ? "thumb--selected" : ""}`}
                    disabled={disabled}
                >
                    <IconName />
                </button>
            )}
        </>
    );
}
