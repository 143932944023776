import { DataWidget, DataWidgetDisplayer } from "@me8eon/data_widget";
import { AppsData, AppDisplay } from "@me8eon/apps_data_plugin";
import { useTheme } from "@me8eon/themes";
import React from "react";

export const DisplayApps: DataWidgetDisplayer =
    ({ rootId, data }) => {
        const appsData: AppsData[] = data.filter(d => d.type === "app") as any[] as AppsData[];
        const { dataLayout } = useTheme();
        return (
            <div id={rootId} style={dataLayout.AdvanceUnifiedBox}>
                <div className="unified-box-heading">
                    Apps
                </div>
                <div style={{ padding: "0.5rem" }}>
                    {appsData.map((d, i) => <AppDisplay rootId="someRoot" id='id1' data={d} key={i} />)}
                </div>
            </div>
        );
    };

/** For example we could
 *
 *  searchResultsDataViewWidget("apps", d => d.type === "app")
 *
 */
export const appsDataWidget: DataWidget = {
    plugin: "datawidget",
    name: "apps",
    display: DisplayApps,
};
