import { useTranslation } from "@me8eon/translation";
import { useContext } from "react";
import { AiAssistContext } from "../AiAssist.context";
import { Icon } from "@me8eon/icons";
import "./AssistanceTask.scss";
import LoaderComponent from "../../loader-component/LoaderComponent";

type TaskKeys =
    | "#disk_cleanup_light"
    | "#clear_chrome_cache"
    | "repair-O365-apps";
type StatusKeys = "success" | "failure" | "progress";

const AssistanceTask = () => {
    const t = useTranslation();
    const { assistanceTasks } = useContext(AiAssistContext);

    const taskStatus = {
        "#disk_cleanup_light": {
            success: "chatBot.nexthinkIntegration.diskClean.successMsg",
            failure: "chatBot.nexthinkIntegration.diskClean.failureMsg",
            progress: "chatBot.nexthinkIntegration.diskClean.progressMsg",
        },
        "#clear_chrome_cache": {
            success: "chatBot.nexthinkIntegration.chromeCache.successMsg",
            failure: "chatBot.nexthinkIntegration.chromeCache.failureMsg",
            progress: "chatBot.nexthinkIntegration.chromeCache.progressMsg",
        },
        "#repair_empower_addin": {
            success: "chatBot.nexthinkIntegration.empowerApp.successMsg",
            failure: "chatBot.nexthinkIntegration.empowerApp.failureMsg",
            progress: "chatBot.nexthinkIntegration.empowerApp.progressMsg",
        },
    };

    if (!assistanceTasks.length) return null;

    return (
        <div className="ai-assist-assistance-task">
            <div className="heading" title={t("chatBot.nexthinkIntegration.assistanceTask")}>
                <Icon size="normal" name="plus_circle" />
                <span>{t("chatBot.nexthinkIntegration.assistanceTask")}</span>
            </div>
            <div className="assistance-task-wrapper">
                {assistanceTasks
                    .slice()
                    .reverse()
                    .map((item) => (
                        <div
                            className="assistance-task-item"
                            key={item.requestId}
                        >
                            <span>
                                {item.status === "progress" ? (
                                    <LoaderComponent
                                        isLoading={true}
                                        isInline
                                        size="small"
                                    />
                                ) : (
                                    <Icon
                                        size="small"
                                        name={
                                            item.status === "success"
                                                ? "tick_small"
                                                : "task_failed"
                                        }
                                        {...(item.status === "success" && {
                                            themedColor: "#EA1B0A",
                                        })}
                                    />
                                )}
                            </span>
                            <span>
                                {t(
                                    taskStatus[item.task as TaskKeys][
                                        item.status as StatusKeys
                                    ]
                                )}
                            </span>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default AssistanceTask;
