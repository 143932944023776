import React, { useMemo } from "react";
import { OrderItData } from "./order.it.data";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { ClipHeight } from "@me8eon/clip_height";
import { SimpleWidget } from "@me8eon/simple_widget";
// @ts-ignore
import CartIcon from "./cart.svg";

export const OrderItDisplay: DisplayData<OrderItData> =
    ({ rootId, id, data }: DisplayDataProps<OrderItData>) => {
        const { H1WithIconAndUrl, Markdown, Url } = useRenderers();
        const { Text, DataLayout } = useAttributeValueComponents();
        const newRootId = `${rootId}.${id}`;
        const issueSummaryAndUrl = useMemo(() =>
            ({ url: data.link, title: `${data.title}`, icon: CartIcon }), [data.link, data.title]);
        return <DataLayout rootId='orderit' className='order-it-data' layout={[1, 1, 3, 1]}>
            <H1WithIconAndUrl rootId={newRootId} attribute={translateLabel('name')} value={issueSummaryAndUrl} />
            {data.description !== 'undefined' ? <ClipHeight maxHeight='2rem' force={false}>
                <Markdown rootId={id} attribute={translateLabel('data.description')} value={data.description} />
            </ClipHeight> : <></>}
            <Text rootId={id} attribute={translateLabel('source')} value='my IT order' />
            <Text rootId={id} attribute={translateLabel('type')} value={data.type} />
            {/* <Text rootId={id} attribute={translateLabel('deliveryTime')} value={data.deliveryTime} /> */}
            <Text rootId={id} attribute={translateLabel('category')} value={data.category} />
            <Url rootId={id} attribute={translateLabel('url')} value={data.link} />
        </DataLayout>;
    };

export const OneLineOrderItDisplay: DisplayData<OrderItData> =
    ({ data }: DisplayDataProps<OrderItData>) =>
        <span>{data.type} {data.title}</span>;

export const SimpleOrderItWidget: DisplayDataWidget<OrderItData> =
    SimpleWidget(
        ['name'],
        ['title'],
        []);

