import React, {ReactNode} from "react";
import {GetterSetter} from "@me8eon/context";
import {useDataViewNavBarLayout} from "@me8eon/data_views";
import {useSelectableButton} from "@me8eon/selection_button";

export type NavBarItemProps = { value: string, selectedOps: GetterSetter<string>, sideEffectOnSelect?: (value: string) => void }
export type NavBarItem = (props: NavBarItemProps) => React.ReactElement;
/**The prefix is for translation purposes. The key will be `${prefix}.${value}` */
export type NavBarItemFn = (prefix: string) => NavBarItem;
export type NavbarLayoutProps = { children: React.ReactNode }
export type NavBarLayout = (props: NavbarLayoutProps) => ReactNode;
export type NavBarProps = { selectedOps: GetterSetter<string>, sideEffectOnSelect?: (value: string) => void }
export type NavBar = (props: NavBarProps) => React.ReactElement;


export const DefaultNavBar = (prefix: string, items: string[]): NavBar => ({selectedOps}) => {
    const NavBarLayout = useDataViewNavBarLayout()
    const Button = useSelectableButton()
    return <NavBarLayout>
        {items.map(item => <Button key={item} rootId={prefix} selectedOps={selectedOps} text={item}/>)}
    </NavBarLayout>
}


