import { GetterSetter, makeContextFor } from "@me8eon/context";
import React, { ReactElement } from "react";


export type SelectableButtonProps = {
    rootId: string
    selectedOps: GetterSetter<string>
    text: string
    icon?: ReactElement
    className?: string
    maxChars?: number
    selectedTick?: boolean
}
export type SelectableButton = (props: SelectableButtonProps) => React.ReactNode

export const { use: useSelectableButton, Provider: SelectableButtonProvider } = makeContextFor<SelectableButton, 'selectableButton'>('selectableButton');
