{
    "en": {
        "model": "Items",
        "model_category": "Category",
        "asset_tag": "Asset Tag",
        "serial_number": "Serial number",
        "u_service_value_1": "Value",
        "portalURL": "Portal",
        "cat_item": "Item",
        "opened_by": "Opened by",
        "number": "Number",
        "u_requesttitle": "Request Title",
        "due_date": "Due Date",
        "requested_for": "Requested for",
        "state": "State",
        "opened_at": "Opened at",
        "resolved_at": "Resolved at",
        "closed_at": "Closed at",
        "sys_created_on": "System Created On",
        "approval_set": "Approval Set",
        "description": "Description",
        "short_description": "Short description",
        "actions": "Actions"
    },
    "de": {
        "model": "Artikel",
        "model_category": "Kategorie",
        "asset_tag": "Asset Tag",
        "serial_number": "Seriennummer",
        "u_service_value_1": "Wert",
        "portalURL": "Portal",
        "cat_item": "Artikel",
        "opened_by": "Geöffnet von",
        "number": "Nummer",
        "u_requesttitle": "Bestelltext",
        "due_date": "Fälligkeitsdatum",
        "requested_for": "Angefordert für",
        "state": "Status",
        "opened_at": "Geöffnet",
        "resolved_at": "Gelöst am",
        "closed_at": "Geschlossen am",
        "sys_created_on": "System erstellt am",
        "approval_set": "Genehmigungssatz",
        "description": "Beschreibung",
        "short_description": "Kurzbeschreibung",
        "actions": "Aktionen"
    },
    "sv": {
        "model": "Objekt",
        "model_category": "Kategori",
        "asset_tag": "Tagg",
        "serial_number": "Serienummer",
        "u_service_value_1": "Värde",
        "portalURL": "Portal",
        "cat_item": "Objekt",
        "opened_by": "Beställd av",
        "number": "Nummer",
        "u_requesttitle": "Beställningstitel",
        "due_date": "Förfallodatum",
        "requested_for": "Beställd till",
        "state": "Status",
        "opened_at": "Beställdes",
        "resolved_at": "Löst vid",
        "closed_at": "Stängd vid",
        "sys_created_on": "Skapades",
        "approval_set": "Godkännande",
        "description": "Beskrivning",
        "short_description": "Kort beskrivning",
        "actions": "Åtgärder"
    }
}
