import { DebugLog, useDebug } from "@me8eon/debug";
import { JwtTokenFn, LoginOps, UserData, UserDataGetter } from "@me8eon/authentication";
import { makeContextFor } from "@me8eon/context";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useThrowError } from "@me8eon/throw_error";


export const authenticateDebugName = "authenticate";
export type LoginOutFn = (debugLog: DebugLog) => Promise<void>

export type LoginConfig = {
    refreshLogin: LoginOutFn
    login: LoginOutFn
    logout: LoginOutFn

}

export function defaultMakeSessionId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export type LoginProviderProps = {
    makeSessionId?: () => string
    loginConfig: LoginConfig
    children: React.ReactNode
}


export type AuthenticateContextData = {
    sessionId: string
    loginOps: LoginOps
}

export const { use: useUserDataGetter, Provider: UserDataGetterProvider } = makeContextFor<UserDataGetter, "userData">("userData");
export const { use: useUserData, context: UserDataContext } = makeContextFor<UserData, "userData">("userData");

export function UserDataProvider({ children }: { children: React.ReactNode }) {
    const userDataGetter = useUserDataGetter();
    const initialUserData = userDataGetter();
    const [userData, setUserData] = React.useState<UserData>(initialUserData);
    useEffect(() => {
        const pid = setInterval(() =>{
            const newUserData = userDataGetter();
            if (JSON.stringify(newUserData)!==JSON.stringify(userData)) {
                setUserData(newUserData);
            }
        }, 1000)
        return () => clearInterval(pid);
    }, [userData]);
    return <UserDataContext.Provider value={userData}>
        {children}
    </UserDataContext.Provider>;
}



export const { use: useJwtToken, Provider: JwtTokenProvider } = makeContextFor<JwtTokenFn, "jwtToken">("jwtToken");


export const LoginContext = createContext<AuthenticateContextData | undefined>(undefined);


export function AuthenticationProvider({ loginConfig, children, makeSessionId = defaultMakeSessionId }: LoginProviderProps) {
    const debug = useDebug(authenticateDebugName);
    const { logout, refreshLogin, login } = loginConfig;

    const contextData: AuthenticateContextData = useMemo(() => {
        const loginOps: LoginOps = {
            refreshLogin: async () => refreshLogin(debug),
            login: async () => login(debug),
            logout: async () => logout(debug),
        };
        return { loginOps, sessionId: makeSessionId() };
    }, [login, logout, refreshLogin, debug.debug]);
    debug("AuthenticationProvider", contextData);
    return <LoginContext.Provider value={contextData}>
        {children}
    </LoginContext.Provider>;
}

export function useLogin(): LoginOps {
    const login = useContext(LoginContext);
    const reportError = useThrowError();
    if (!login) return reportError("s/w", "useLogin must be used within a LoginProvider");
    return login.loginOps;
}



