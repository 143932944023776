import {NavBarItem, NavBarItemProps} from "./navbar";
import React from "react";
import {useSelectableButton} from "@me8eon/selection_button";


export const DefaultNavItem = (prefix: string): NavBarItem =>
    ({value, selectedOps}: NavBarItemProps) => {
        const SelectableButton = useSelectableButton()
        return <SelectableButton rootId={prefix} selectedOps={selectedOps} text={value}/>
    };


