import {Env} from "./ConfigInterface";

export type ProxyConfig = {
    snowProxy: string
    snowProxyLiveAgentChat: string
    myApprovalXProxy?: string
    jqlProxy: string
}

const proxyConfigs: Record<Env, ProxyConfig> = {
    local: {
        snowProxy: "snow_dev",
        snowProxyLiveAgentChat: "snow_live_agent_chat_dev",
        myApprovalXProxy: "my_approval_dev",
        jqlProxy: "jira-preprod"
    },
    dev: {
        snowProxy: "snow_dev",
        snowProxyLiveAgentChat: "snow_live_agent_chat_dev",
        myApprovalXProxy: "my_approval_dev",
        jqlProxy: "jira-preprod"
    },
    qa: {
        snowProxy: "snow_pp",
        snowProxyLiveAgentChat: "snow_live_agent_chat_pp",
        jqlProxy: "jira-preprod"
    },
    pp: {
        snowProxy: "snow_pp",
        snowProxyLiveAgentChat: "snow_live_agent_chat_pp",
        jqlProxy: "jira-prod"
    },
    prod: {
        snowProxy: "snow_prod",
        snowProxyLiveAgentChat: "snow_live_agent_chat_prod",
        jqlProxy: "jira-prod"
    },
};

export default proxyConfigs;
