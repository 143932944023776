export function flatten<T>(t: T[][]): T[] {
    return ([] as T[]).concat(...t);
}

export function mapK<V, To>(
    vs: V[],
    fn: (v: V, i: number) => Promise<To>
): Promise<To[]> {
    return Promise.all<To>(vs.map(fn));
}
export function flatMapK<From, To>(
    ts: From[],
    fn: (from: From) => Promise<To[]>
): Promise<To[]> {
    return mapK(ts, fn).then(flatten);
}
