import React, { useEffect } from "react";
import { useJwtToken, useUserData } from "@me8eon/react_authentication";

export function DevModeAuthentication() {
    const userData = useUserData();
    const jwtTokenFn = useJwtToken();
    const [jwt, setJwt] = React.useState<string | undefined>(undefined);
    useEffect(() => {
        jwtTokenFn().then(setJwt);
    }, []);
    return <>
        <h1>User data</h1>
        <pre>{JSON.stringify(userData, null, 2)}</pre>
        <pre>JWT Token{jwt}</pre>
    </>;
}