import { GitLabDataName, GitLabDataPlugin } from "@me8eon/gitlab_data_plugin";
import { MyGeniusDataName, MyGeniusDataPlugin } from "@me8eon/mygenius_data_plugin";
import { OrderItDataName, OrderItDataPlugin } from "@me8eon/order_it_data_plugin";
import { DataPlugins } from "@me8eon/data";
import { JiraDataName, JiraDataPlugin } from "@me8eon/jira_data_plugin";
import { ConfluenceDataName, ConfluenceDataPlugin } from "@me8eon/confluence_data_plugin";
import { SharepointDataName, SharepointDataPlugin } from "@me8eon/sharepoint_data_plugin";
import { PeopleDataName, PeopleDataPlugin } from "@me8eon/people_data_plugin";
import { AppsDataName, AppsDataPlugin } from "@me8eon/apps_data_plugin";


export const allDataPlugins: DataPlugins = {
    [AppsDataName]: AppsDataPlugin(),
    [JiraDataName]: JiraDataPlugin(),
    [ConfluenceDataName]: ConfluenceDataPlugin(),
    [PeopleDataName]: PeopleDataPlugin(),
    [GitLabDataName]: GitLabDataPlugin(),
    [MyGeniusDataName]: MyGeniusDataPlugin(),
    [OrderItDataName]: OrderItDataPlugin(),
    [SharepointDataName]: SharepointDataPlugin(),
};