
import {DebugLog, DebugState, makeDebugLog} from "./debug";
import {makeContextForState} from "@me8eon/context";


export const {use: useDebugState, Provider: DebugStateProvider} = makeContextForState<DebugState, "debugState">("debugState");

export function useDebug(name: string): DebugLog {
    const [debugState] = useDebugState();
    return makeDebugLog(debugState, name);
}

