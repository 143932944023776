import React from "react";

export type ErrorType = 's/w' | 'validation' | 'network' | 'ui'
export type ThrowError = (type: ErrorType, msg: string, e?: any) => never;


export const throwError: ThrowError = (type: ErrorType, msg) => {
    throw new Error(`${type}: ${msg}`);
};

//Note that we can't use makeContextFor here because we are used in that method
export const ThrowErrorContext = React.createContext<ThrowError>(throwError);
export const useThrowError = () => React.useContext(ThrowErrorContext);

export type ThrowErrorProviderProps = {
    logError: ThrowError
    children: React.ReactNode
}

export function ThrowErrorProvider({logError, children}: ThrowErrorProviderProps) {
    return <ThrowErrorContext.Provider value={logError}>{children}</ThrowErrorContext.Provider>;
}
