import { PeopleDisplay, OneLinePeopleDisplay, SimplePeopleWidget } from "./simple.people.display";
import { DataPlugin, DisplayData, DisplayDataWidget } from "../../data";


export const PeopleDataName = 'person';
//{
//   "hitId": "567f9649-2f38-473d-8191-0742b2d0f64f@b914a242-e718-443b-a47c-6b4c649d8c0a",
//   "rank": 1,
//   "summary": "",
//   "resource": {
//     "@odata.type": "#microsoft.graph.person",
//     "id": "567f9649-2f38-473d-8191-0742b2d0f64f@b914a242-e718-443b-a47c-6b4c649d8c0a",
//     "displayName": "Rice, Philip",
//     "givenName": "Philip",
//     "surname": "Rice",
//     "companyName": "E.ON Digital Technology GmbH",
//     "jobTitle": "external",
//     "department": "D_TG6-A",
//     "officeLocation": "EDT Essen",
//     "additionalOfficeLocation": "Essen",
//     "personType": {
//       "class": "Person",
//       "subclass": "OrganizationUser"
//     },
//     "userPrincipalName": "P34350@eon.com",
//     "imAddress": "sip:philip.rice.external@eon.com"
//   },
//   "type": "person",
//   "index": "people"
// }
export type PeopleData = {
    type: string
    hitId: string;
    index: string;
    resource: {
        displayName: string
        companyName: string
        jobTitle: string
        department: string
        officeLocation: string
        userPrincipalName: string
        imAddress: string
        phones: { type: "business" | "mobile", number: string }[]
    }
}

export function PeopleDataPlugin(
    DisplayData: DisplayData<PeopleData> = PeopleDisplay,
    DisplayDataOneLine: DisplayData<PeopleData> = OneLinePeopleDisplay,
    DisplayDataWidget: DisplayDataWidget<PeopleData> = SimplePeopleWidget): DataPlugin<PeopleData> {
    return {
        plugin: 'data',
        name: PeopleDataName,
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget,
        idFrom: data => `${data.index}.${data.hitId}`
    };
}
