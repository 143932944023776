import React from "react";
import {useTranslation} from "react-i18next";
import {DataType} from "./ElSearchAdvanceHelpers";
import {EonUiLink} from "@eon-ui/eon-ui-components-react";

export type SelectFilterButtonProps = {
    isReset?: boolean;
    onClick: () => void;
    textkey: string;
    clazz?: string;
    dataType: DataType;
    // value: string[] | "app" | "m365" | "jql" | "assistance";
    id?: "default";
};

export function SelectFilterButton({
                                       id,
                                       isReset,
                                       onClick,
                                       clazz,
                                       // value,
                                       textkey,
                                   }: SelectFilterButtonProps) {

    const {t} = useTranslation();

    return isReset ? (
        <EonUiLink
            icon="reload"
            className="reload-icon"
            onClick={onClick}
            title={t("filterPage.filterMenu.reset")}
            size="normal"
            href="javascript:void(0)"
        ></EonUiLink>
    ) : (
        <button id={id} className={`tablinks ${clazz}`} onClick={onClick}>
            {t(textkey)}
        </button>
    );
}
