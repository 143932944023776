import { t } from "i18next";
import {
    EonUiIcon,
    EonUiInput,
    EonUiPreloaderSquares,
    EonUiTagPill,
    EonUiTagPillGroup,
} from "@eon-ui/eon-ui-components-react";
import React, { useCallback, useContext, useState } from "react";
import { raiseIncident } from "../../services/raiseIncident";
import { AiAssistContext } from "../ai-assist-refactored/AiAssist.context";
// @ts-ignore
import  AiIcon  from "../../assets/image/ai-icon32.svg";
import { FeedbackGetterProps, FeedbackSetterProps } from "./feedback.component";

interface IFeedbackCreateIncidentComponentProps {
    setters: FeedbackSetterProps;
    getters: FeedbackGetterProps;
}

export const CreateIncidentInput = ({
    isEditIncident
}: { isEditIncident: boolean }) => {
    const { isIncidentTextPending, incidentText, setIncidentText } = useContext(AiAssistContext);
    const handleSetIncidentText = useCallback(
        (text: string) => {
            setIncidentText(text);
        },
        [setIncidentText]
    );
    return (
        <>
            {isIncidentTextPending ? (
                <EonUiPreloaderSquares
                    className="preloader"
                    placeholder="preloader"
                />
            ) : isEditIncident ? (
                <EonUiInput
                    value={incidentText}
                    size="normal"
                    rows={5}
                    textarea
                    onValueChanged={(e) =>
                        handleSetIncidentText(e.target.value)
                    }
                />
            ) : (
                <div>{incidentText}</div>
            )}
        </>
    );
};

export const CreateIncidentResponse = ({
    incidentResponse,
    confirmTicketCreation,
    respLoading
}: { incidentResponse: any, confirmTicketCreation: boolean, respLoading: boolean }) => {
    return (
        <>
            {respLoading ? (
                <div className="loading">
                    <EonUiPreloaderSquares
                        className="preloader"
                        placeholder="preloader"
                    />
                </div>
            ) : confirmTicketCreation ? (
                incidentResponse?.number ? (
                    <div className="ticket-success">
                        <EonUiIcon
                            className="user-icon eon-ui-svg-icon"
                            name="radio_checkbox_tick"
                            placeholder="radio_checkbox_tick"
                            scheme="red500"
                        />
                        <p>
                            {t("chatBot.ticketSuccessMsg")}
                            {incidentResponse.number}
                        </p>
                    </div>
                ) : (
                    <div className="failure-msg">
                        <EonUiIcon
                            className="user-icon eon-ui-svg-icon"
                            name="error"
                            placeholder="error"
                            scheme="red500"
                        />
                        <p>
                            {t("chatBot.ticketErrorMsg")}
                        </p>
                    </div>
                )
            ) : null}
        </>
    );
};

const FeedbackCreateIncidentComponent = ({
    setters,
    getters
}: IFeedbackCreateIncidentComponentProps) => {
    const [isEditIncident, setIsEditIncident] = useState(false);
    const { incidentText } = useContext(AiAssistContext);
    const [incidentResponse, setIncidentResponse] = useState<any>();
    const {
        setIsCreateIncidentOpen,
        setTicketRespLoading,
        setConfirmTicketCreation
    } = setters;
    const {
        ticketRespLoading,
        confirmTicketCreation
    } = getters;

    const createIncidentApi = async (message: string) => {
        setTicketRespLoading(true);
        setIsEditIncident(false);
        try {
            const incidentResponse = await raiseIncident(message);
            setIncidentResponse(incidentResponse);
            setConfirmTicketCreation(true);
        } catch (popupError) {
            console.error(
                "Something went wrong. Please try again later.",
                popupError
            );
        } finally {
            setTicketRespLoading(false);
        }
    };

    return (
        <div className="bot-header">
            <div className="eon-logo">
                <AiIcon />
            </div>
            <div className="bot-reply-container">
                <span className="bot-heading">
                    {t("chatBot.creatingIncident")}
                </span>
                <div className="ticket-container">
                    <div className="ticket-description">
                        <span className="">
                            {t("chatBot.ticketDescription")}
                        </span>
                    </div>
                    <div className="message-content">
                        <CreateIncidentInput isEditIncident={isEditIncident} />
                    </div>
                    {!confirmTicketCreation && (
                        <div className="buttons-section">
                            <EonUiTagPillGroup>
                                <EonUiTagPill
                                    text={
                                        isEditIncident
                                            ? t("chatBot.createIncident")
                                            : t("chatBot.confirmCreateIncident")
                                    }
                                    class="feedback-tag"
                                    scheme="neutral800"
                                    onClick={() =>
                                        createIncidentApi(incidentText)
                                    }
                                ></EonUiTagPill>
                                {!isEditIncident && (
                                    <EonUiTagPill
                                        text={t("chatBot.editCreateIncident")}
                                        onClick={() => setIsEditIncident(true)}
                                        scheme="neutral800"
                                    ></EonUiTagPill>
                                )}
                                <EonUiTagPill
                                    text={t("cancel")}
                                    scheme="neutral800"
                                    onClick={() =>
                                        setIsCreateIncidentOpen(false)
                                    }
                                ></EonUiTagPill>
                            </EonUiTagPillGroup>
                        </div>
                    )}
                    <CreateIncidentResponse
                        incidentResponse={incidentResponse}
                        confirmTicketCreation={confirmTicketCreation}
                        respLoading={ticketRespLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default FeedbackCreateIncidentComponent;
