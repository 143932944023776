import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { Link } from "react-router-dom";
import { TileDataInterface } from "../../interfaces/TileInterface";
import { useTranslation } from "react-i18next";
import SVGIcon from "../svg-icon/SVGIcon";
// import MyApprovalCount from "./MyApprovalCount";
import { EnvironmentConfig } from "@me8eon/config";
import React from "react";
import { ThemedIcon } from "@me8eon/icons";

const TileContent = ({
    isEditMode,
    data,
    handleDeleteTile
}: {
    isEditMode: boolean;
    data: TileDataInterface;
    handleDeleteTile: (tile: TileDataInterface) => void;
}) => {
    const { t } = useTranslation();
    const { alias, title, id, target, icon } = data;
    const isCustomIcon = icon?.endsWith(".svg");
    // const myInboxAlias = "processing";
    let translatedLabel = alias ? t(`appData.${alias}`) : title;
    if (translatedLabel.startsWith("appData.")) {
        translatedLabel = title;
    }

    const resolveForEditMode = (elem: any) => {
        return isEditMode ? (
            <>{elem}</>
        ) : (
            <Link
                title={t(`appData.${alias}hover`)}
                key={title}
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
                to={target ?? ""}
                className="tile-link"
                onClick={(event) => isEditMode && event.preventDefault()}
            >
                {elem}
            </Link>
        );
    };

    const getIconName = (icon: string) => {
        // if it has svg at the end
        if (icon.includes(".svg")) {
            // notification must be used as tools
            const iconName = icon.split(".")[0];
            return iconName === "notification" ? "tools" : iconName;
        }
        return icon;
    }

    return (
        <>
            {resolveForEditMode(
                <div className="tile-body">
                    {!isCustomIcon ? (
                        <EonUiIcon
                            size="normal"
                            className="tile-icon eon-ui-svg-icon"
                            key={"tile-" + id}
                            name={icon}
                        ></EonUiIcon>
                    ) : (
                        <div className="tile-icon" >
                            {icon ? <ThemedIcon name={getIconName(icon)} /> : <></>}
                        </div>
                    )}
                    <div className="tile-text-wrapper">
                        <div className="tile-title">{translatedLabel}</div>
                        <div className="tile-subtext">
                            {t(
                                `appData.subTitle.${alias}SubTitle`
                            ).toUpperCase()}
                        </div>
                        {/* {alias === myInboxAlias && <MyApprovalCount />} */}
                    </div>
                </div>
            )}
            <button
                className={`close-icon ${isEditMode ? "close-icon--editmode" : ""
                    }`}
                onClick={() => handleDeleteTile(data)}
                disabled={isEditMode}
            >
                <EonUiIcon
                    title={t("delete")}
                    key={"trash-" + data.id}
                    className="trash-icon eon-ui-svg-icon"
                    size="small"
                    name="trash_bin"
                ></EonUiIcon>
            </button>
        </>
    );
};
export default TileContent;
