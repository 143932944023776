import { useMemo } from "react";
import axios from "axios";
import { Message } from "../../ai/ai.types";
import { AiClient, OutputAndSource, useAiWithRag } from "../../ai/ai.rag.client";
import { useTranslation } from "react-i18next";
import parseLinks from "../utils/parseLinks";
import { EnvironmentConfig } from "@me8eon/config";
import { QuickActionType } from "../AiAssist.context";
import { useJwtToken, useUserData } from "@me8eon/react_authentication";

export type TIssueList = string[]
export type TIssueListLanguage = {
    [key: string]: TIssueList;
}
type TAllowedFeedbackStrings = "default" | "create-incident";

type TInternetSearchMessage = {
    bot?: string;
    user: string;
};

export interface MessageContentInterface {
    alice: string;
    messageTitle: string;
    messageContent: string;
    icon: string;
    issueList: TIssueList;
    feedback?: TAllowedFeedbackStrings[];
    indicies?: string[];
    disableSearch?: boolean;
    cardPosition: number;
    label?: string;
    handleAiQuery: (
        messages: Message[],
        query: string,
        action?: string,
        quickActionType?: QuickActionType
    ) => Promise<OutputAndSource>;
}

export interface IAiAssistData {
    [key: string]: MessageContentInterface;
}

const serviceIssueList: TIssueListLanguage = {
    en: [
        "I forgot my password. How can I reset it?",
        "I'm having trouble sending/receiving emails. What should I do?",
    ],
    de: [
        "Ich habe mein Passwort vergessen. Wie kann ich es zurücksetzen?",
        "Ich habe Probleme beim Senden/Empfangen von E-Mails. Was sollte ich tun?",
    ],
};

const knowledgeBaseIssueList: TIssueListLanguage = {
    en: ["What is Compliance?"],
    de: ["Was ist Compliance?"],
};

const generalIssueList: TIssueListLanguage = {
    en: [
        "What are the EON values?",
        "Can you help me draft a professional email requesting a meeting with a client?",
    ],
    de: [
        "Was sind die EON-Werte?",
        "Können Sie mir beim Verfassen einer professionellen E-Mail mit der Bitte um ein Treffen mit einem Kunden helfen?",
    ],
};

const indicies = {
    selfService: ["questionator-office-buddy-prod", "apps-prod", "semantic-meeondata-azureblob-prod", "semantic-kb-servicenow-prod"],
    knowledgeBase: ["questionator-office-buddy-prod", "apps-prod", "semantic-meeondata-azureblob-prod", "semantic-kb-servicenow-prod"],
};

const useAiData = (): IAiAssistData => {
    const ai: AiClient = useAiWithRag();
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const jwt = useJwtToken();
    const addTestemogptFeatureFlag = window.location.search.includes("testemogpt=true");
    const addSemanticEdisConfluenceFeatureFlag = window.location.search.includes("edissemantic=true");
    const addSwedishAzureBlobFeatureFlag = window.location.search.includes("azureblobswedishsemantic=true");
    const addMeAtEonFeatureFlag = window.location.search.includes("meateon=true");
    const { featureFlagEnabled } = useUserData();

    const additionalIndicies = useMemo(() => {
        const indices = [];
        if (addTestemogptFeatureFlag) {
            indices.push("semantic-testemogpt-sharepoint-prod");
        }
        if (addSwedishAzureBlobFeatureFlag) {
            indices.push("semantic-azureblob-swedish-prod");
        }
        if (addSemanticEdisConfluenceFeatureFlag) {
            indices.push("semantic-edis-confluence-prod");
        }
        if (addMeAtEonFeatureFlag) {
            indices.push("semantic-meateon-confluence-prod");
        }
        return indices;
    }, [addTestemogptFeatureFlag, addSwedishAzureBlobFeatureFlag,
        addSemanticEdisConfluenceFeatureFlag]);

    const knowledgeBaseIndicies = useMemo(() =>
        [...indicies.knowledgeBase, ...additionalIndicies], [additionalIndicies]);

    const selfServiceIndicies = useMemo(() =>
        [...indicies.selfService, ...additionalIndicies], [additionalIndicies]);


    return {
        selfService: {
            alice: "selfService",
            messageTitle: t("chatBot.selfServiceTitle"),
            messageContent: t("chatBot.selfServiceContent"),
            icon: "itSupport",
            feedback: ["default", "create-incident"],
            indicies: selfServiceIndicies,
            issueList: serviceIssueList[language],
            disableSearch: false,
            cardPosition: 2,
            label: t("chatBot.askEon"),
            handleAiQuery: async (messages: Message[], query: string, action?: string, quickActionType?: QuickActionType) =>
                action == "user-query" ? await ai.aiClientWithRag(messages, query, language, selfServiceIndicies, featureFlagEnabled, action).then((response: OutputAndSource) => {
                    const source = response.source === "nexthink" ? "" : `<br /><b>Source: ${response.source}</b>`;
                    return ({
                        ...response,
                        output: `${parseLinks(response.output)} ${source}`,
                    })
                }
                ) : ai.aiClientQuickActions(messages, query, quickActionType).then((response: OutputAndSource) => ({
                    ...response,
                    output: `${parseLinks(response.output)}`,
                })
                )
        },
        knowledgeBase: {
            alice: "knowledgeBase",
            messageTitle: t("chatBot.knowledgeBaseTitle"),
            messageContent: t("chatBot.knowledgeBaseContent"),
            icon: "knowledgeBase",
            feedback: ["default"],
            indicies: knowledgeBaseIndicies,
            disableSearch: false,
            issueList: knowledgeBaseIssueList[language],
            cardPosition: 1,
            handleAiQuery: async (messages: Message[], query: string) =>
                await ai.aiClientWithRag(messages, query, language, knowledgeBaseIndicies, featureFlagEnabled).then((response: OutputAndSource) => ({
                    ...response,
                    output: `${parseLinks(response.output)} <br /><b>Source: Smarthub KB</b>`,
                })
                )
        },
        internetSearch: {
            alice: "internetSearch",
            messageTitle: t("chatBot.internetSearchTitle"),
            messageContent: t("chatBot.internetSearchContent"),
            icon: "internetSearch",
            issueList: generalIssueList[language],
            feedback: ["default"],
            disableSearch: true,
            cardPosition: 3,
            label: t("chatBot.askInternet"),
            handleAiQuery: async (messages: Message[], query: string) => {
                try {
                    const history: TInternetSearchMessage[] = [];
                    messages.forEach((message, index) => {
                        if (index % 2 === 0) {
                            history.push({ user: message.content as string });
                        } else {
                            history[history.length - 1].bot = message.content as string;
                        }
                    });
                    history.push({ user: query });
                    const queryBody = {
                        history,
                        overrides: {
                            retrieval_mode: "hybrid",
                            semantic_ranker: true,
                            semantic_captions: false,
                            top: 3,
                            language_model: "gpt-4o-mini",
                        },
                    };

                    const response = await axios({
                        method: "post",
                        url: `${EnvironmentConfig.genAiApiBaseUrl}/genai/ama/me/ask`,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${await jwt()}`,
                        },
                        data: queryBody,
                    });

                    const withReplacedLinks = response.data.answer.replace(
                        /genai-eon-public\//g,
                        `${EnvironmentConfig.genAiApiBaseUrl}/genai/ama/docs/me/download_by_name/`
                    );

                    return {
                        output: `${parseLinks(withReplacedLinks)} <br />`,
                        source: "internet",
                        action: "rag-eon-gpt",
                    };
                } catch (err) {
                    console.error(err);
                    return {
                        output: "This service is not available at the moment, please try again later.",
                        source: "internet",
                        action: "rag-eon-gpt",
                    };
                }
            },
        },
    };
};

export default useAiData;
