import { makeContextFor } from "@me8eon/context";
import React, { ReactElement } from "react";

export function idFrom(rootId: string, attribute: string) {
    return `${rootId}-${attribute}`;
}

export type RenderProps<T> = {
    rootId: string
    attribute: string
    value: T | undefined;
    label?: string;
    icon?: () => ReactElement;
};

export type TitleAndUrl = {
    title: string;
    url: string;
}
export type IconTitleAndUrl = {
    icon: () => ReactElement
    title: string;
    url: string;
}

export type Render<T> = (props: RenderProps<T>) => React.ReactNode

export type Renderers = {
    Text: Render<string>
    Html: Render<string>
    Markdown: Render<string>
    Json: Render<any>
    Date: Render<string>
    Url: Render<string>
    H1: Render<string>
    H1WithIconAndUrl: Render<IconTitleAndUrl>
    H1WithUrl: Render<TitleAndUrl>
    H2: Render<string>
    H3: Render<string>
    Label: Render<string>
}


export const { Provider: RenderProvider, use: useRenderers } = makeContextFor<Renderers, "renderers">("renderers");

export type UrlOnClickFn = (url: string | undefined) => () => void
export const emptyUrlOnClick: UrlOnClickFn = () => () => { };
export const { Provider: UrlOnClickProvider, use: useUrlOnClick } = makeContextFor<UrlOnClickFn, "urlOnClick">("urlOnClick", emptyUrlOnClick);

