import React, {ReactNode} from "react";
import {useRenderers} from "@me8eon/renderers";
import {DisplayDataWidget, DisplayDataWidgetProps} from "@me8eon/data";
import {useCommonComponents} from "@me8eon/components";
import {capitalizeFirstLetter} from "@me8eon/string_utils";




export const SimpleWidget = <Data, >(
    titles: string[],
    keys: (keyof Data)[],
    noWrap: (keyof Data)[]
): DisplayDataWidget<Data> => {
    const Component = ({data, type, rootId}: DisplayDataWidgetProps<Data>): ReactNode => {
        const H2 = useRenderers().H2;
        const Table = useCommonComponents().Table;
        return (<>
            <H2 rootId={rootId} attribute="title" value={capitalizeFirstLetter(type)}/>
            <Table titles={titles} keys={keys} data={(data||[]).slice(0, 10)} noWrap={noWrap}/>
        </>);
    };
    Component.displayName = `SimpleWidget(${titles.join(',')})`;
    return Component;
};
