import create from "zustand";
import {
    CapatityObjInterface,
    ManageBookingData,
    MeetingRoomType,
    RoomBookingInterface,
    UnavailabilityDataInterface,
    UserBookingInterface,
} from "../components/room-booking/RoomBookingInterface";
import { getAccessToken } from "../services/accessToken";
import ApiCall from "../services/ApiCall";
import { UserDataInterface } from "../interfaces/CommonInterface";
import { Location } from "../components/room-locations/RoomLocations";

function getCurrentTimeString() {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}`;
}

interface RoomBookingState {
    clickedFloor: string;
    roomBookingStatus: string;
    capacityInfo: CapatityObjInterface[];
    saveBookingModal: boolean;
    cancelBookingModal: boolean;
    editBookingModal: boolean;
    rebookingModal: boolean;
    isRoomBooking: boolean;
    pastTimeError: boolean;
    timePickerVal: string;
    timePickerValEditForm: string;
    unavailabilityInfo: UnavailabilityDataInterface[];
    newCapacityInfo: CapatityObjInterface[];
    roomDetailsData: RoomBookingInterface[];
    clickedCardData: RoomBookingInterface;
    isLocationDropdownOpen: boolean;
    locationDropdownError: string;
    datePickerError: string;
    numberOfParticipants: string;
    numberOfParticipantsRebooking: string;
    currentLocationIds: number[];
    isPageLoading: boolean;
    accessToken: string;
    userData: UserDataInterface;
    isError: boolean;
    startTime: string;
    startTimeEditForm: string;
    endTime: string;
    endTimeEditForm: string;
    datePicker: string;
    datePickerEditForm: string;
    toHr: string;
    toHrEditForm: string;
    roomType: MeetingRoomType;
    searchQuery: string;
    searchType: string;
    fromDate: string;
    toDate: string;
    invalidToDate: boolean;
    manageBookingRawData: any[];
    bookAgainRawData: any[];
    currentBookingData: any;
    manageBookingReload: number;
    locations: Location[];
    setLocations: (locations: Location[]) => void;
    setClickedFloor: (newFloor: string) => void;
    setRoomBookingStatus: (status: string) => void;
    setCapacityInfo: (newCapacityInfo: CapatityObjInterface[]) => void;
    setSaveBookingModal: (isOpen: boolean) => void;
    setCancelBookingModal: (isOpen: boolean) => void;
    setEditBookingModal: (isOpen: boolean) => void;
    setRebookingModal: (isOpen: boolean) => void;
    setIsPageLoading: (isPageLoading: boolean) => void;
    setAccessToken: (accessToken: string) => void;
    setIsRoomBooking: (roomBooking: boolean) => void;
    setIsError: (isError: boolean) => void;
    setPastTimeError: (hasError: boolean) => void;
    setTimePickerVal: (newValue: string) => void;
    setTimePickerValEditForm: (newValue: string) => void;
    setUnavailabilityInfo: (
        newUnavailabilityInfo: UnavailabilityDataInterface[]
    ) => void;
    setRoomDetailsData: (data: RoomBookingInterface[]) => void;
    setClickedCardData: (data: RoomBookingInterface) => void;

    setLocationDropdownOpen: (isOpen: boolean) => void;
    setLocationDropdownError: (error: string) => void;
    setDatePickerError: (error: string) => void;
    setNumberOfParticipants: (participants: string) => void;
    setNumberOfParticipantsRebooking: (participants: string) => void;
    setCurrentLocationIds: (ids: number[]) => void;
    setUserData: (userData: any) => void;
    setStartTime: (start_time: string) => void;
    setStartTimeEditForm: (start_time: string) => void;
    setEndTime: (end_time: string) => void;
    setEndTimeEditForm: (end_time: string) => void;
    setDatePicker: (date: string) => void;
    setDatePickerEditForm: (date: string) => void;
    setToHr: (time: string) => void;
    setToHrEditForm: (time: string) => void;
    setRoomType: (roomType: MeetingRoomType) => void;
    setSearchQuery: (searchQuery: string) => void;
    setSearchType: (searchType: string) => void;
    setFromDate: (date: string) => void;
    setToDate: (date: string) => void;
    setInvalidToDate: (status: boolean) => void;
    setManageBookingRawData: (data: UserBookingInterface[]) => void;
    setBookAgainRawData: (data: UserBookingInterface[]) => void;
    setCurrentBookingData: (data: ManageBookingData) => void;
    setManageBookingReload: (reload: number) => void;
    // setTime: (newTime: string) => void;
    fetchAccessToken: (userData: any) => void;
}

const useRoomBookingStore = create<RoomBookingState>((set) => ({
    clickedFloor: "",
    roomBookingStatus: "",
    capacityInfo: [],
    saveBookingModal: false,
    cancelBookingModal: false,
    editBookingModal: false,
    rebookingModal: false,
    isRoomBooking: false,
    pastTimeError: false,
    timePickerVal: getCurrentTimeString(),
    timePickerValEditForm: getCurrentTimeString(),
    unavailabilityInfo: [],
    newCapacityInfo: [],
    roomDetailsData: [],
    clickedCardData: {} as RoomBookingInterface,
    isLocationDropdownOpen: false,
    locationDropdownError: "",
    datePickerError: "",
    numberOfParticipants: "1",
    numberOfParticipantsRebooking: "1",
    currentLocationIds: [],
    isPageLoading: false,
    accessToken: "",
    isError: false,
    userData: {} as UserDataInterface,
    startTime: "",
    startTimeEditForm: "",
    endTime: "",
    endTimeEditForm: "",
    datePicker: "",
    datePickerEditForm: "",
    toHr: "15 Min",
    toHrEditForm: "15 Min",
    roomType: { id: 0, name: "All" },
    searchQuery: "",
    searchType: "All",
    fromDate: "",
    toDate: "",
    invalidToDate: false,
    manageBookingRawData: [],
    bookAgainRawData: [],
    currentBookingData: [],
    manageBookingReload: 0,
    locations: [],
    setLocations: (locations) => set({ locations }),
    // currentTime: getCurrentTimeString(),
    setCapacityInfo: (newCapacityInfo) =>
        set({ capacityInfo: newCapacityInfo }),
    setSaveBookingModal: (isOpen) => set({ saveBookingModal: isOpen }),
    setCancelBookingModal: (isOpen) => set({ cancelBookingModal: isOpen }),
    setEditBookingModal: (isOpen) => set({ editBookingModal: isOpen }),
    setRebookingModal: (isOpen) => set({ rebookingModal: isOpen }),
    setIsPageLoading: (loading) => set({ isPageLoading: loading }),
    setAccessToken: (token) => set({ accessToken: token }),
    setIsRoomBooking: (roomBooking) => set({ isRoomBooking: roomBooking }),
    setIsError: (error) => set({ isError: error }),
    setClickedFloor: (newFloor) => set({ clickedFloor: newFloor }),
    setRoomBookingStatus: (status) => set({ roomBookingStatus: status }),
    setPastTimeError: (hasError) => set({ pastTimeError: hasError }),
    setTimePickerVal: (newValue) => set({ timePickerVal: newValue }),
    setTimePickerValEditForm: (newValue) =>
        set({ timePickerValEditForm: newValue }),
    setUnavailabilityInfo: (newUnavailabilityInfo) =>
        set({ unavailabilityInfo: newUnavailabilityInfo }),
    setRoomDetailsData: (data) => set({ roomDetailsData: data }),
    setClickedCardData: (data) => set({ clickedCardData: data }),
    setLocationDropdownOpen: (isOpen) =>
        set({ isLocationDropdownOpen: isOpen }),
    setLocationDropdownError: (error) => set({ locationDropdownError: error }),
    setDatePickerError: (error) => set({ datePickerError: error }),
    setNumberOfParticipants: (participants) =>
        set({ numberOfParticipants: participants }),
    setNumberOfParticipantsRebooking: (participants) =>
        set({ numberOfParticipantsRebooking: participants }),
    setCurrentLocationIds: (ids) => set({ currentLocationIds: ids }),
    setUserData: (userData) => set({ userData: userData }),
    setStartTime: (start_time) =>
        set((state) => ({ ...state, startTime: start_time })),
    setStartTimeEditForm: (start_time) =>
        set((state) => ({ ...state, startTimeEditForm: start_time })),
    setEndTime: (end_time) => set((state) => ({ ...state, endTime: end_time })),
    setEndTimeEditForm: (end_time) =>
        set((state) => ({ ...state, endTimeEditForm: end_time })),
    setDatePicker: (date) => set({ datePicker: date }),
    setDatePickerEditForm: (date) => set({ datePickerEditForm: date }),
    setToHr: (time) => set({ toHr: time }),
    setToHrEditForm: (time) => set({ toHrEditForm: time }),
    setRoomType: (type) => set({ roomType: type }),
    setSearchQuery: (type) => set({ searchQuery: type }),
    setSearchType: (type) => set({ searchType: type }),
    setFromDate: (date) => set({ fromDate: date }),
    setToDate: (date) => set({ toDate: date }),
    setInvalidToDate: (status) => set({ invalidToDate: status }),
    setManageBookingRawData: (data) => set({ manageBookingRawData: data }),
    setBookAgainRawData: (data) => set({ bookAgainRawData: data }),
    setCurrentBookingData: (data) => set({ currentBookingData: data }),
    setManageBookingReload: (reload) => set({ manageBookingReload: reload }),
    // setTime: (newTime) => set({ currentTime: newTime }),
    fetchAccessToken: async (userData) => {
        set({ isPageLoading: true });
        try {
            const access_token = await getAccessToken();
            set({ accessToken: access_token });
            if (access_token && userData && Object.keys(userData).length === 0) {
                const userResponse = await ApiCall(
                    "api/gingco/GetCurrentUser",
                    "post",
                    {
                        accesstoken: access_token,
                    }
                );
                set({ isPageLoading: false });
                if (
                    userResponse.status === "success" &&
                    userResponse.data.user
                ) {
                    set({
                        userData: {
                            ...userResponse.data.user,
                            home_location_id:
                                userResponse.data.user.home_location_id || 0,
                        },
                    });
                } else {
                    setTimeout(() => {
                        localStorage.removeItem("accessToken");
                        getAccessToken();
                    }, 5000);
                }
            }

            // set((state) => ({
            //     isPageLoading: false,
            //     favData: { ...state.favData, ...response },
            //     displayedModal: response.tourTaken ? "" : "firstTour",
            //     userId: localAccountId,
            // }));
        } catch (error: any) {
            set({ isPageLoading: false });
        }
    },
}));

export default useRoomBookingStore;
