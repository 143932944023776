import { JiraData, JiraDataName, JiraDataPlugin } from "../../jira_data";
import { DataHasType, DataPluginProvider, DataPlugins, DisplayDataProps } from "../../data";
import React from "react";
import { ConfluenceData, ConfluenceDataName, ConfluenceDataPlugin } from "../../confluence_data";

import { MyGeniusData, MyGeniusDataName, MyGeniusDataPlugin } from "@me8eon/mygenius_data_plugin";
import { GitLabData, GitLabDataName, GitLabDataPlugin } from "@me8eon/gitlab_data_plugin";
import { OrderItData, OrderItDataName, OrderItDataPlugin } from "@me8eon/order_it_data_plugin";
import { idFrom } from "@me8eon/renderers";
import { PeopleData, PeopleDataName, PeopleDataPlugin } from "@me8eon/people_data_plugin";
import { SharepointData, SharepointDataName, SharepointDataPlugin } from "@me8eon/sharepoint_data_plugin";

export type HasDataid = {
    dataId: string
}
export const jiraData: JiraData & HasDataid = {
    type: "jira",
    id: "id1",
    index: "jira-prod",
    dataId: "jira-prod.id1",
    issue: "ME8-113",
    status: "In Progress",
    summary: "Do some hard work",
    created_by: "jdoe",
    url: "https://jira.meateon.com/browse/ME8-113",
    description: "# A big ticket item that we need to work on * Item1 * Item2",
    last_updated: "2021-09-01T00:00:00.000Z",
};
//

export const confluenceData: ConfluenceData & HasDataid = {
    type: "confluence",
    id: "id1",
    index: "confluence-prod",
    dataId: "confluence-prod.id1",
    title: "name1",
    body: "description1",
    last_update_date: "lastUpdated1",
    last_updated: "lastUpdated1",
    url: "http://localhost.example.com",
    icon: "confluence",
};

export const peopleData: PeopleData & HasDataid = {
    dataId: "people.567f9649-2f38-473d-8191-0742b2d0f64f@b914a242-e718-443b-a47c-6b4c649d8c0a",
    "hitId": "567f9649-2f38-473d-8191-0742b2d0f64f@b914a242-e718-443b-a47c-6b4c649d8c0a",
    "resource": {
        "displayName": "Rice, Philip",
        "companyName": "E.ON Digital Technology GmbH",
        "jobTitle": "external",
        "department": "D_TG6-A",
        "officeLocation": "EDT Essen",
        "userPrincipalName": "P34350@eon.com",
    },
    "type": "person",
    "index": "people",
};

export const sharepointData: SharepointData & HasDataid = {
    type: "sharepoint",
    index: "sharepoint-prod",
    dataId: "sharepoint-prod.id1",
    icon: "sharepoint",
    url: "http://localhost.example.com",
    resource: {
        id: "id1",
        name: "name1",
        createdAt: "createdAt1",
        lastModifiedDateTime: "lastModifiedDateTime1",
        webUrl: "webUrl1",
    },
    summary: "summary1",

}

export const myGeniusData: MyGeniusData & HasDataid = {
    type: "mygenius",
    id: "id1",
    ecl_id: "ecl_id1",
    index: "mygenius-prod",
    dataId: "mygenius-prod.id1",
    card_title: "GetAbstract",
    lastUpdate: "lastUpdated1",
    ecl_source_name: "source1",
    createdBy: "jdoe",
    card_description: "# A big ticket item that we need to work on\n * Item1 \n * Item2",
    card_languages: "English",
    Duration_HHMM: 0.053356,
};

export const gitlabData: GitLabData & HasDataid = {
    type: "gitlab",
    id: "id1",
    index: "gitlab-prod",
    dataId: "gitlab-prod.id1",
    icon: "gitlab",
    fullPath: "GitLab Data",
    source: "source1",
    url: "http://localhost.example.com",
    status: "In Progress",
    summary: "summary1",
    issue: "issue1",
    content: "# Aaaaa Gitlab data that we need to work on\n * Item1 \n * Item2",
};

export const orderItData: OrderItData & HasDataid = {
    type: OrderItDataName,
    id: "id1",
    index: "order-it-api",
    dataId: "order-it-api.id1",
    icon: "cart",
    name: "Copilot for Microsoft 365",
    deliveryTime: "2 Days",
    category: "O365 Apps",
    link: "https://siamtest2.service-now.com/orderit?id=sc_cat_item&sys_id=75e740addb0e86d0d6e53ec3f39619c9",
    desc: "# Microsoft Copilot is an artificial intelligence assistant service for Microsoft 365 apps and services\n * Item1 \n * Item2",
};


export const dataForSearchResult: (DataHasType & HasDataid)[] = [jiraData, confluenceData, myGeniusData, gitlabData, orderItData, peopleData, sharepointData];

export type TestDataPluginProviderProps = {
    dataPlugins?: DataPlugins
    children: React.ReactNode
}

export const TestDisplayData = (type: string) => <Data extends any>({ rootId, id, data }: DisplayDataProps<Data>) => {
    const fullId = idFrom(rootId, id);
    return <div data-testid={fullId}>{type}:{JSON.stringify(data)}</div>;
};

export const TestDataPlugins: DataPlugins = {
    [JiraDataName]: { ...JiraDataPlugin(), DisplayData: TestDisplayData(JiraDataName) },
    [ConfluenceDataName]: { ...ConfluenceDataPlugin(), DisplayData: TestDisplayData(ConfluenceDataName) },
    [PeopleDataName]: { ...PeopleDataPlugin(), DisplayData: TestDisplayData(PeopleDataName) },
    [MyGeniusDataName]: { ...MyGeniusDataPlugin(), DisplayData: TestDisplayData(MyGeniusDataName) },
    [GitLabDataName]: { ...GitLabDataPlugin(), DisplayData: TestDisplayData(GitLabDataName) },
    [OrderItDataName]: { ...OrderItDataPlugin(), DisplayData: TestDisplayData(OrderItDataName) },
    [SharepointDataName]: { ...SharepointDataPlugin(), DisplayData: TestDisplayData(SharepointDataName) },
};

export const legalValues = Object.keys(TestDataPlugins).sort();

export function TestDataPluginProvider({ dataPlugins = TestDataPlugins, children }: TestDataPluginProviderProps) {
    return <DataPluginProvider dataPlugins={dataPlugins}>{children}</DataPluginProvider>;
}