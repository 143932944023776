import {isLocalHost} from "../util/common-util";
import ApiCall from "./ApiCall";
import {EnvironmentConfig} from "@me8eon/config";

const isTokenValid = () => {
    const timeToken = localStorage.getItem("tokenTimeStamp");
    const timeDiff = Date.now() - parseInt(timeToken ?? "0");
    return timeDiff < 25 * 60 * 1000;
};

const getRedirectUrl = () => {
    const domain = window.location.origin;
    const redirect_url = `${domain}${
        isLocalHost() ? "/api/gingco/code" : "/roombooking"
    }`;
    return redirect_url;
};

const redirectToGingco = () => {
    localStorage.removeItem("accessToken");
    const redirect_uri = getRedirectUrl();
    const client_id = EnvironmentConfig.gingcoClientId;
    window.location.href = `${EnvironmentConfig.gingcoRedirectURL}/index.php?action=base_oauth20_endpoint_authorization&response_type=code&state=&client_id=${client_id}&scope=fullaccess&redirect_uri=${redirect_uri}`;
};

export const getAccessToken = async (newcallNeeded?: boolean) => {
    const temptoken = newcallNeeded ? null : localStorage.getItem("tempToken");
    const accessToken = localStorage.getItem("accessToken");
    if (temptoken && isTokenValid()) {
        if (accessToken) {
            return accessToken;
        } else {
            const firstAPIResp = await ApiCall(
                "api/gingco/gingcoAccessToken",
                "post",
                {
                    dummytoken: localStorage.getItem("tempToken"),
                    redirectUri: getRedirectUrl(),
                }
            );

            if (
                firstAPIResp &&
                firstAPIResp.data &&
                firstAPIResp.status === "success"
            ) {
                const accesstoken = firstAPIResp.data.access_token || "";
                localStorage.setItem("accessToken", accesstoken);
                return accesstoken;
            } else {
                console.log("Some error while fetching actual token");
                redirectToGingco();
                return false;
            }
        }
    } else {
        redirectToGingco();
    }
};
