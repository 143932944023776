{
  "PageTitle": "Bokning av mötesrum/flexibla arbetsplatser",
  "apiFailDefaultMsg": "Något gick fel. Försök igen senare.",
  "availability": {
    "busy": "Tyvärr, inte tillgänglig",
    "busyHint": "Prova att ändra datum eller tid. Du kan även söka efter rum i närheten",
    "free": "Ja, tillgänglig",
    "partialFree": "Delvis ledigt rum",
    "text": "Tillgänglighet",
    "unknown": "Okänd"
  },
  "availableLabel": "Tillgänglig",
  "bookAgain": "Boka igen",
  "bookingFailureLabel": "Något gick fel. Försök igen senare.",
  "bookingSuccessLabel": "Rumsbokningen lyckades",
  "btnReset": "Återställ",
  "btnSearch": "Sök",
  "btnSubmit": "Skicka in",
  "busyLabel": "Upptagen",
  "cancel": "Avboka",
  "cancelBookingApiSucessMsg": "Bokningen avbokades framgångsrikt",
  "cancelBookingConfrim": "Är du säker på att du vill avboka det här mötet?",
  "capacity": "Hur många kommer att delta?",
  "capacityLabel": "Kapacitet",
  "capacityOptionLabel": "Välj kapacitet",
  "close": "Stäng",
  "confirm": "Bekräfta",
  "confirmBookingBtn": "Bekräfta bokningen",
  "dashboard": "Visa mina bokningar",
  "date": "Datum",
  "descOfMeetingPlaceholder": "Lägg till beskrivning",
  "dummyLocationValueOne": "Plats 1",
  "dummyLocationValueTwo": "Plats 2",
  "duration": "Varaktighet",
  "durationOptionLabel": "Välj varaktighet",
  "errMsg": "Vänligen fyll i alla fält!",
  "fieldRequired": "Detta fält är obligatoriskt",
  "floorDropdownDummy": "EG Schulungsgebäude",
  "formReset": "Återställ formuläret",
  "from_date": "Från datum",
  "futureDateError": "Välj korrekt framtida datum/tid.",
  "hours": "timmar",
  "inputPlaceholder": "input...",
  "list": "Lista",
  "location": "Välj plats/rum",
  "locationOptionLabel": "Plats, rumsnamn/nummer",
  "manageBooking": {
    "bookAgain": "Boka igen",
    "cancelMeeting": "Avboka mötet",
    "meetingLocation": "Mötesplats",
    "reschedule": "Boka om",
    "rescheduleTo": "Boka om till",
    "searchPlaceholder": "Sök efter mötes-ID, beskrivning, plats"
  },
  "manageBookings": "Hantera bokningar",
  "max": "Max",
  "meetingDescriptionLabel": "Mötesbeskrivning (valfritt)",
  "meetingLocationLabel": "Mötesplats",
  "minutes": "minuter",
  "noPastBooking": "För närvarande har du inga tidigare bokningar",
  "noSlotAvailable": "Inga tider tillgängliga",
  "noUpcomingBooking": "För närvarande har du inga kommande bokningar",
  "partiallyFree": "Delvis ledigt",
  "participantError": "Du planerar att boka för",
  "participantErrorMsg": "deltagare, men rummets kapacitet är endast",
  "participants": "Antal deltagare",
  "peopleCount": "",
  "rebookingApiSucessMsg": "Bokat framgångsrikt",
  "roomAvailableFound": "Hittade",
  "roomAvailableSpaces": "platser på",
  "roomAvailableTextDummy": "Hittade 27 lediga platser för 24 april kl. 13.00–14.00 i Hallstadter Straße 119 - Schulungsgebäude",
  "roomAvailablein": "i",
  "roomBooked": "Rummet är redan bokat!",
  "roomPartiallyBooked": "Rummet är delvis bokat!",
  "search": "Sök",
  "searchText": "Vänta, vi försöker ansluta till servern!",
  "selectLocation": "Vänligen välj plats",
  "selectDate": "Please select a date for booking room",
  "selectRoomLocation": "Välj plats/rum",
  "selectedFloorOptionOneDummy": "BV",
  "selectedFloorOptionTwoDummy": "ÖV",
  "startOptionLabel": "Välj starttid",
  "startTime": "Starttid",
  "starting": "Startar",
  "startingTime": "Starttid",
  "timeFrom": "Starttid",
  "timeUntil": "Sluttid",
  "titleOfMeetingLabel": "Mötestitel",
  "titleOfMeetingPlaceholder": "Ange mötets titel eller ämne",
  "toDateError": "\"Till datum\" måste vara lika med eller senare än \"Från datum\" och det maximala gapet mellan de två datumen är 30 dagar.",
  "to_date": "Till datum",
  "tokenRequired": "Obligatorisk åtkomsttoken saknas eller har löpt ut.",
  "topic": "Ämne",
  "type": "Typ",
  "updateBookingApiSucessMsg": "Bokningen har uppdaterats",
  "durationOptions": {
      "15 Min": "15 Min",
      "30 Min": "30 Min",
      "45 Min": "45 Min",
      "1 Hr": "1 Hr",
      "2 Hr": "2 Hr",
      "4 Hr": "4 Hr",
      "8 Hr": "8 Hr" 
  },
  "meetingRoomTypes": {
      "All": "All",
      "Hot Desk": "Hot Desk",
      "Meeting Room": "Meeting Room"
  }
}