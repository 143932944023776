export function simpleTemplate(template: string, data: Record<string, any>): string | undefined {
    if (template === undefined) return undefined;
    if (data === undefined) return template;
    return template.replace(/\{(\w+)\}/g, (match, key) => {
        if (key in data) {
            return String(data[key]);
        }
        return match; // Return the original match if no corresponding key is found
    });
}
