import {DataPlugin, DisplayData, DisplayDataWidget} from "../../data";
import {MyGeniusDisplay, SimpleMyGeniusOneLineDisplay, SimpleMyGeniusWidget } from "./simple.mygenius.display";


export const MyGeniusDataName = 'mygenius';

//{
//   Duration_HHMM: 0.010416666666666666
//   card_description: "Manche beklagen die Schnelllebigkeit der Wirtschaftswelt, andere haben offenkundig richtig Spaß daran. Die drei Autoren dieses Buches – alle für Google tätig – liefern eine präzise Anleitung, wie man "
//   card_languages: "de"
//   card_resource_url: "https://www.getabstract.com/summary/28470?u=eon&utm_source=edcast&utm_medium=integration&utm_campaign=catalog"
//   card_title: "Sprint"
//   ecl_id: "1eb21eae-f388-47d1-9afc-2e917ce6997c"
//   ecl_source_name: "GetAbstract"
//   highlight: undefined
//   id: "1eb21eae-f388-47d1-9afc-2e917ce6997c"
//   index: "mygenius-prod"
//   model_id: ".multilingual-e5-small_linux-x86_64"
//   queryType: "boostAndPhrase"
//   score: 18.686104
//   standard_card_type: "Article"
//   type: "mygenius"
export type MyGeniusData = {
    id: string;
    index: string;
    ecl_id:string
    type: string
    ecl_source_name:string
    card_title: string
    card_description: string
    card_languages: string
    lastUpdate: string
    createdBy: string
    Duration_HHMM: number
}

export function MyGeniusDataPlugin(
    DisplayData: DisplayData<MyGeniusData> = MyGeniusDisplay,
    DisplayDataOneLine: DisplayData<MyGeniusData> = SimpleMyGeniusOneLineDisplay,
    DisplayDataWidget: DisplayDataWidget<MyGeniusData> = SimpleMyGeniusWidget): DataPlugin<MyGeniusData> {
    return {
        plugin: 'data',
        name: 'mygenius',
        DisplayData,
        DisplayDataOneLine,
        DisplayDataWidget,
        idFrom: data => `${data.index}.${data.id}`
    };
}
