import { FeatureFlag, flaggedValue } from "@me8eon/featureflags";
import { SelectableButton, SimpleSelectableButton } from "@me8eon/selection_button";
import { ClipHeight } from "@me8eon/clip_height";
import { SimpleTable, Table } from "@me8eon/table";
import { EonSelectionButton } from "@me8eon/eon_selection_button";
import { makeContextFor } from "@me8eon/context";
import { MouseOver, SimpleMouseOver, SimpleBgMouseOver, BgMouseOver } from "@me8eon/mouse_over";

const componentsFlagName = "eonOrPlain";
const componentsFeatureFlag: FeatureFlag = {
    value: "eon",
    options: ["eon", "plain"],
    description: "Use eon components or plain components",
};

export type CommonComponents = {
    SelectableButton: SelectableButton
    ClipHeight: ClipHeight
    Table: Table
    MouseOver: MouseOver,
    BgMouseOver: BgMouseOver
}
export const commonComponents: CommonComponents = {
    SelectableButton: flaggedValue(componentsFlagName, {
        eon: EonSelectionButton,
        plain: SimpleSelectableButton,
    }),
    ClipHeight: ClipHeight,
    Table: SimpleTable,
    MouseOver: SimpleMouseOver,
    BgMouseOver: SimpleBgMouseOver
};
export const { use: useCommonComponents, Provider: CommonComponentsProvider } = makeContextFor<CommonComponents, "commonComponents">("commonComponents", commonComponents);