import axios, { AxiosError, AxiosResponse } from "axios";
import { HttpServicePost } from "./httpService";
import { getAuthHeader } from "../util/auth";

export const GetServiceRequest = async (
    URL: string,
    retryCount = 0
): Promise<AxiosResponse> => {
    try {
        const headers = getAuthHeader();
        return await axios.get(URL, { headers });
    } catch (e: unknown) {
        if (
            e instanceof AxiosError &&
            e.response?.status === 401 
        ) {
            if (retryCount < 1) {
                await HttpServicePost();
                return GetServiceRequest(URL, retryCount + 1);
            }
        }
        throw e;
    }
};

export const PostServiceRequest = async (
    URL: string,
    payload: string,
    retryCount = 0
): Promise<AxiosResponse> => {
    try {
        const headers = getAuthHeader();
        return await axios.post(URL, payload, { headers });
    } catch (e: unknown) {
        if (
            e instanceof AxiosError &&
            e.response?.status === 401
        ) {
            if (retryCount < 1) {
                await HttpServicePost();
                return PostServiceRequest(URL, payload, retryCount + 1);
            }
        }
        throw e;
    }
};