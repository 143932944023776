{
    "PageTitle": "Booking of meeting rooms / hotdesk",
    "location": "Select location / room",
    "date": "Date",
    "from_date": "From date",
    "to_date": "To date",
    "toDateError": "The 'To date' must be equal to or later than the 'From date' and the maximum gap between the two dates is 30 days.",
    "topic": "Topic",
    "timeFrom": "Start time",
    "timeUntil": "End time",
    "startTime": "Start time",
    "capacity": "How many people will participate?",
    "btnSubmit": "Submit",
    "btnReset": "Reset",
    "formReset": "Reset form",
    "errMsg": "Please fill all the fields!",
    "inputPlaceholder": "input...",
    "hours": "hours",
    "minutes": "minutes",
    "starting": "Starting",
    "duration": "Duration",
    "btnSearch": "Search",
    "locationOptionLabel": "Location, Room name / Number",
    "startOptionLabel": "Select start time",
    "durationOptionLabel": "Select duration",
    "capacityOptionLabel": "Select capacity",
    "searchText": "please wait, we are trying to connect with the server!",
    "dummyLocationValueOne": "Location 1",
    "dummyLocationValueTwo": "Location 2",
    "roomAvailableTextDummy": "Found 27 Spaces for Apr 24, 1pm - 2pm in Hallstadter Straße 119 - Schulungsgebäude",
    "roomAvailableFound": "Found",
    "roomAvailableSpaces": "spaces at",
    "roomAvailablein": "in",
    "floorDropdownDummy": " EG Schulungsgebäude",
    "selectedFloorOptionOneDummy": "EG",
    "selectedFloorOptionTwoDummy": "OG",
    "meetingLocationLabel": "Meeting location",
    "titleOfMeetingLabel": "Meeting title",
    "titleOfMeetingPlaceholder": "Enter the title or topic of the meeting planned",
    "descOfMeetingPlaceholder": "Add description",
    "meetingDescriptionLabel": "Meeting description (optional)",
    "availableLabel": "Available",
    "busyLabel": "Busy",
    "confirmBookingBtn": "Confirm booking",
    "bookingSuccessLabel": "Room booking is successful",
    "bookingFailureLabel": "Something went wrong. Please try again later.",
    "selectRoomLocation": "Select location / room",
    "futureDateError": "Please select correct future date/time.",
    "noSlotAvailable": "No slots available",
    "tokenRequired": "Required access token missing or expired.",
    "roomBooked": "Room is already booked!",
    "roomPartiallyBooked": "Room is partially booked!",
    "participantError": "You plan to book for ",
    "participantErrorMsg": " participants, however the room capacity is only ",
    "partiallyFree": "Partially free",
    "startingTime": "Starting time",
    "participants": "No. of participants",
    "max": "Max",
    "capacityLabel": "Capacity ",
    "dashboard": "Show my bookings",
    "manageBookings": "Manage bookings",
    "bookAgain": "Book again",
    "cancelBookingConfrim": "Are you sure you want to cancel this meeting?",
    "list": "List",
    "search": "Search",
    "type": "Type",
    "updateBookingApiSucessMsg": "Booking updated successfully",
    "rebookingApiSucessMsg": "Booked Successfully",
    "cancelBookingApiSucessMsg": "Booking cancelled successfully",
    "noUpcomingBooking": "Currently you have no upcoming bookings",
    "noPastBooking": "Currently you have no past bookings",
    "apiFailDefaultMsg": "Something went wrong. Please try again later.",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "close": "Close",
    "fieldRequired": "This field is required",
    "selectLocation": "Please select location",
    "selectDate": "Please select a date for booking",
    "availability": {
        "text": "Availability",
        "free": "Yes, available",
        "busy": "Sorry, not available",
        "busyHint": "Try modifying date or time, You can also check for nearby rooms",
        "partialFree": "Partially Free Room",
        "unknown": "Unknown"
    },
    "manageBooking": {
        "meetingLocation": "Meeting location",
        "reschedule": "Reschedule",
        "bookAgain": "Book again",
        "rescheduleTo": "Reschedule to",
        "cancelMeeting": "Cancel meeting",
        "searchPlaceholder": "Search by Meeting ID, Description, Location"
    },
    "durationOptions": {
        "15 Min": "15 Min",
        "30 Min": "30 Min",
        "45 Min": "45 Min",
        "1 Hr": "1 Hr",
        "2 Hr": "2 Hr",
        "4 Hr": "4 Hr",
        "8 Hr": "8 Hr"
    },
    "meetingRoomTypes": {
        "All": "All",
        "Hot Desk": "Hot Desk",
        "Meeting Room": "Meeting Room"
    }
}