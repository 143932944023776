import { composeSearchFns, elasticSearch, emptySearchFn, ifThenSearchFn, SearchFn, SearchRequest } from "./search";
import { makeKnnQuery } from "./knn.search";
import { SearchContext } from "./searchContext";
import { makeKeywordsQuery } from "./keywords.search";
import { ESApiFinalResponseInterface } from "../interfaces/ElasticSearchInterface";
import { makeBoostAndPhraseQuery } from "./boostAndPhrase";
import { graphApiSearch } from "./graph.api.search";
import { jiraSearch } from "./jql.search";
import { JqlAiClient } from "../jira/jql.client.agent";

//Here we have the possible search functions.
//in the index.tsx file we should decide which one to use and pass it to a SearchFnProvider

// const jiraKeywords: string[] = ['jira', 'issue','bug','ticket','jql','sprint','story'];
// const questionWord: string[] = ['what'];

// export function heuristicJqlFilter(sr: SearchRequest){
//     if (!window.location.href.includes('jql=true')) return false;
//     return questionWord.some((kw =>sr.searchTerm.includes(kw))) && 
//     jiraKeywords.some((kw =>sr.searchTerm.includes(kw)));
// }


export function m365OrJiraOrAllSearch(
    context: SearchContext,
    graphApiToken: () => Promise<string>,
    jqlClient: JqlAiClient<any>,
): SearchFn<ESApiFinalResponseInterface> {
    return ifThenSearchFn(
        (sr) => sr.dataType === "m365" || sr.dataType === "people",
        graphApiSearch(graphApiToken),
        ifThenSearchFn(
            (sr) => sr.searchIndexes.includes("jql"),
            async (searchRequest) => {
                const searchFn = jiraSearch(jqlClient); // Now we pass the actual instance
                return searchFn(searchRequest);
            },
            ifThenSearchFn(
                (sr) => sr.searchIndexes.includes("assistance"),
                emptySearchFn<ESApiFinalResponseInterface>,
                ifThenSearchFn(sr => {
                        const result = sr.allowOfficeHack === true;
                        return result;
                    },
                    composeSearchFns(x => x.index + "$" + x.id,
                        boostAndPhraseSearch(context),
                        graphApiSearch(graphApiToken)),
                    boostAndPhraseSearch(context),
                )),
        ),
    );
}

export function boostAndPhraseSearch(
    context: SearchContext,
): SearchFn<ESApiFinalResponseInterface> {
    return elasticSearch(
        context,
        "boostAndPhrase",
        makeBoostAndPhraseQuery(context.boosts || []),
    );
}

export function knnSearch(
    context: SearchContext,
): SearchFn<ESApiFinalResponseInterface> {
    return elasticSearch(context, "knn", makeKnnQuery);
}

export function keywordsSearch(
    context: SearchContext,
): SearchFn<ESApiFinalResponseInterface> {
    return elasticSearch(context, "keywords", makeKeywordsQuery);
}

export function hybridSearch(
    context: SearchContext,
): SearchFn<ESApiFinalResponseInterface> {
    return composeSearchFns(
        (res) => `${res.index}/${res.id}`,
        keywordsSearch(context),
        knnSearch(context),
    );
}
