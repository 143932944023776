import React, { useEffect, useRef } from "react";
import "./RoomBooking.scss";
import { EonUiIcon, EonUiLink } from "@eon-ui/eon-ui-components-react";
import { useTranslation } from "react-i18next";
import CardComponent from "../../components/generic-components/card/CardComponent";
import axios from "axios";
import Modal from "../../components/modal/Modal";
import {
    RoomBookingInterface,
    UnavailabilityDataInterface,
} from "../../components/room-booking/RoomBookingInterface";
import MeetingPopup from "../../components/room-booking/MeetingPopup";
import { getParameterByName } from "../../util/common-util";
import { isLocalHost } from "../../util/common-util";
import LoaderComponent from "../../components/loader-component/LoaderComponent";
import {EnvironmentConfig} from"@me8eon/config";
import useRoomBookingStore from "../../store/roomBookingStore";
import RoomBookingForm from "../../components/room-booking/room-booking-form/RoomBookingForm";
import ManageBooking from "../../components/room-booking/manage-booking/ManageBooking";
import BookAgain from "../../components/room-booking/book-again/BookAgain";
import LocationBreadcrumb from "../../components/room-locations/LocationBreadcrumb";

const RoomBooking: React.FC = () => {
    const {
        isPageLoading,
        setIsPageLoading,
        saveBookingModal,
        setSaveBookingModal,
        pastTimeError,
        unavailabilityInfo,
        roomBookingStatus,
        setRoomBookingStatus,
        isRoomBooking,
        setIsRoomBooking,
        roomDetailsData,
        setClickedCardData,
        clickedCardData,
        setLocationDropdownOpen,
        numberOfParticipants,
        userData,
        fetchAccessToken,
        isError,
        startTime,
        endTime,
        manageBookingReload,
        currentLocationIds,
        setManageBookingReload,
        accessToken,
    } = useRoomBookingStore();
    const { t } = useTranslation("room-booking");
    const initialized = useRef(false);

    useEffect(() => {
        const tempTokenCode = getParameterByName("code");
        if (tempTokenCode) {
            localStorage.setItem("tempToken", tempTokenCode ?? "");
            localStorage.setItem("tokenTimeStamp", Date.now().toString());
        }
        // fetchAccessToken(userData);
        if (!initialized.current) {
            initialized.current = true;
            fetchAccessToken(userData);
        }
    }, []);

    const toggleBookingRoomModal = () => {
        setSaveBookingModal(!saveBookingModal);
    };

    const openSaveBookingModal = (cardData: RoomBookingInterface) => {
        setClickedCardData(cardData);
        setSaveBookingModal(true);
    };

    const saveBookingRoom = async (meetingTopic: string) => {
        const { user_id, timezone_id } = userData;
        setIsPageLoading(true);
        setSaveBookingModal(false);
        const getRoomApiUrl = `${
            isLocalHost() ? EnvironmentConfig.gatewayURL : ""
        }/api/gingco/SaveBookings`;
        const bookMeetingReq = {
            booking_container_templates: [
                {
                    user_id,
                    terms: [],
                    booking_templates: [
                        {
                            object_id: clickedCardData?.object_id,
                            cost: 0,
                            begin: {
                                datetime: startTime,
                                timezone_id: timezone_id,
                            },
                            end: {
                                datetime: endTime,
                                timezone_id: timezone_id,
                            },
                            topic: meetingTopic, //"Gingco Api Test Discussion test",
                            capacity: parseInt(numberOfParticipants),
                            accessories: [],
                        },
                    ],
                },
            ],
            send_mails: true,
            dummytoken: "",
            accesstoken: localStorage.getItem("accessToken") || "",
        };
        const getRoomApiResponse = await axios.post(
            getRoomApiUrl,
            bookMeetingReq
        );
        setRoomBookingStatus(
            getRoomApiResponse &&
                getRoomApiResponse.data.data.booking_container_results[0].result
        );
        setSaveBookingModal(!saveBookingModal);
        setIsRoomBooking(true);
        setIsPageLoading(false);
    };

    const reloadManageBookingList = () => {
        const reloadValue = manageBookingReload + 1;
        setManageBookingReload(reloadValue);
    };

    return (
        <div
            className="room-booking-wrapper"
            onClick={() => setLocationDropdownOpen(false)}
        >
            <LoaderComponent isLoading={isPageLoading} />
            { accessToken && (
            <>
            <Modal
                toggleModal={saveBookingModal}
                closeModal={toggleBookingRoomModal}
                isCloseIcon
                closeOnBackdrop={false}
            >
                <MeetingPopup
                    toggleBookingRoomModal={toggleBookingRoomModal}
                    saveBookingRoom={saveBookingRoom}
                    unavailabilityInfo={unavailabilityInfo.filter(
                        (unavailability: UnavailabilityDataInterface) =>
                            unavailability.object_id ===
                            clickedCardData?.object_id
                    )}
                />
            </Modal>
            <div className="page-title">
                {t("PageTitle")}
                { (roomBookingStatus || roomDetailsData.length > 0 || isError) && (
                        <span className="link-button">
                            <div>
                                <EonUiLink
                                    text={t("dashboard")}
                                    hideIcon={true}
                                    href={`/roombooking`}
                                ></EonUiLink>
                            </div>
                        </span>
                    )}
            </div>
            {pastTimeError && (
                <div className="room-not-available">
                    <EonUiIcon
                        size="normal"
                        name="alert"
                        className="error-icon eon-ui-svg-icon"
                    />
                    <span className="error-text">{t("futureDateError")}</span>
                </div>
            )}
            <RoomBookingForm />
            <div className="location-and-legends">
                {currentLocationIds.length > 0 && (
                    <LocationBreadcrumb hierarchy={currentLocationIds} />
                )}
                {roomDetailsData.length > 0 && (
                    <div className="availability-pointers__label">
                        <div className="indicator">
                            <span className="icon-theme icon-theme--free"></span>
                            <p className="label__text">{t("availableLabel")}</p>
                        </div>
                        <div className="indicator">
                            <span className="icon-theme icon-theme--partial"></span>
                            <p className="">{t("partiallyFree")}</p>
                        </div>
                    </div>
                )}
            </div>
            {isRoomBooking && (
                <div
                    className={`api-message api--${
                        roomBookingStatus ? "success" : "error"
                    } `}
                >
                    {roomBookingStatus
                        ? `${t("bookingSuccessLabel")} `
                        : `${t("bookingFailureLabel")} `}
                </div>
            )}
            {!isRoomBooking && roomDetailsData.length > 0 ? (
                <CardComponent
                    saveBookingModalHandler={(cardData: RoomBookingInterface) =>
                        openSaveBookingModal(cardData)
                    }
                />
            ) : (
                isError && (
                    <div className="api-message api--error">
                        {t("noSlotAvailable")}
                    </div>
                )
            )}
            {!isRoomBooking && !isPageLoading &&
                !isError &&
                roomDetailsData.length <= 0 &&
                <>
                    <BookAgain callback={reloadManageBookingList} />
                    <ManageBooking reload={manageBookingReload} />
                </>}
            </> ) }
        </div>
    );
};

export default RoomBooking;
