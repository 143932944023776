import { IconName, Icons } from "./icons";
import { CSSProperties } from "react";
import React from "react";

type IconColor = "red500"
export type IconProps = {
    name: IconName | string
    title?: string
    scheme?: IconColor
    themedColor?: string
    size?: IconSize
    style?: React.CSSProperties;
}
export type IconSize = "normal" | "small"

const colorLookup: Record<IconColor, string> = {
    red500: "#ea1b0a",
    // redHover:"#c90a01",
    // neutral100: "#f9f6f4",
    // neutral800: "#f7e8da",
};

export const sizeLookUp: Record<IconSize, CSSProperties> = {
    normal: {
        width: "20px",
        height: "22px",
    },
    small: {
        width: "16px",
        height: "18px",
    },
};

export function Icon({ name, scheme, title, style, size, themedColor }: IconProps) {
    let TheIcon = Icons[name as IconName];
    if (!TheIcon) {
        console.log(new Error(`Icon ${name} not found. Legal names are ${Object.keys(Icons).sort().join(", ")}`));
        TheIcon = Icons["close" as IconName];
    }
    const color = scheme ? colorLookup[scheme] : themedColor;
    const styleForSize = size ? sizeLookUp[size] : undefined;
    return <TheIcon style={{ fill: color, ...styleForSize, ...style }} title={title} aria-label={title} role="img" />;
}