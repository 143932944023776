import "./LoaderComponent.scss";
import {
    EonUiPreloaderCircle,
    EonUiPreloaderSquares,
} from "@eon-ui/eon-ui-components-react";
import React from "react";
const LoaderComponent = ({
    isLoading,
    isLinear,
    isInline,
    size,
}: {
    isLoading: boolean;
    isLinear?: boolean;
    isInline?: boolean;
    size?: "small" | "normal";
}) => {
    return isLoading ? (
        <>
            {isLinear ? (
                <EonUiPreloaderSquares data-testid="eonUiPreloaderSquares"></EonUiPreloaderSquares>
            ) : isInline ? <EonUiPreloaderCircle size={size} /> : (
                <div
                    className="loader-wrapper"
                    data-testid="eonUiPreloaderCircle"
                >
                    <div className="loader-overlay">
                        <EonUiPreloaderCircle />
                    </div>
                </div>
            )}
        </>
    ) : null;
};

export default LoaderComponent;
