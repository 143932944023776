import { PublicClientApplication } from "@azure/msal-browser";
import { emptyUserData, UserData, UserDataGetter } from "@me8eon/authentication";
import { EnvironmentConfig } from "@me8eon/config";

const activeRoleSessionName = "ActiveRoleIndex";
const activeRoleSessionObj = localStorage.getItem(activeRoleSessionName);
let currentRoleIndex = activeRoleSessionObj ? parseInt(activeRoleSessionObj as string) : 0;

const roleDisplayMapping: {
    [key: string]: string
} = {
    "APL213643-me8eon-Corp": "Default",
    "APL213643-me8eon-betausers": "Beta Version",
    "APL213643-me8eon-User": "Default",
    "ME8EON_POC_USERS": "Default",
    "ME8EON_POC_MANAGERS": "Beta Version",
}
export function setActiveRoleIndex(msal: any, index: number) {
    currentRoleIndex = index;
    msalUserDataGetter(msal)();
    localStorage.setItem(activeRoleSessionName, index.toString());
}

export function msalUserDataGetter(msal: PublicClientApplication): UserDataGetter {
    return (): UserData => {
        const accessArray = EnvironmentConfig.AiAccess as string[];
        const featureEnabledForUser = EnvironmentConfig.featureEnabledForUser as string[];
        const accounts = msal.getAllAccounts();
        const account = accounts[0];
        if (!account) return emptyUserData;
        // const roleArray = account?.idTokenClaims?.roles || [];
        const roleArrayData = account?.idTokenClaims?.roles || [];
        const roleArray = [...new Set(roleArrayData.map((r: string) => roleDisplayMapping[r] || r))];
        const currentRole = roleArray[currentRoleIndex] || roleArray[0];
        const limitedAccess = !accessArray.some((r) => roleArray.includes(r));
        // const featureFlagEnabled = featureEnabledForUser.some((r) => roleArray.includes(r));
        const featureFlagEnabled = featureEnabledForUser.includes(currentRole);
        const claims = account.idTokenClaims;
        const name: string = claims?.name?.toString() || "";
        const email: string = claims?.preferred_username?.toString() || "";
        const emailForOrgName = claims?.email || account.username;
        const company = claims?.CompanyName?.toString() || "";
        const country = claims?.ctry?.toString() || "";
        const org = (emailForOrgName && typeof emailForOrgName === "string") ? emailForOrgName.split("@")[1].split(".")[0] : "eon";
        const loggedIn = !!account;
        const localAccountId = account.localAccountId;
        const userData: UserData = {
            email, name, loggedIn, org, company, country, limitedAccess, localAccountId, featureFlagEnabled, allRoles: roleArray, currentRole
        };
        console.log({ userDataToLog: { ...userData, emailForOrgName } });
        return userData;
    };
}