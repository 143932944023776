import { EonUiForm, EonUiInput } from "@eon-ui/eon-ui-components-react";
import { DropDownInfo } from "../../domain/info";
import { FilterSetters } from "./elsearch.domain";
import { DataSourceFilter } from "../../interfaces/ElasticSearchInterface";
import { useDebouncedCallback } from "../../hooks/useDebouncedCallback";
import { useSearchTerm } from "@me8eon/filter_data_widget";
import React, { useEffect, useState } from "react";
export type SearchInputProps = {
    onChange: (searchTerm: string) => void
};

export function SearchInput({ onChange }: Readonly<SearchInputProps>) {
    const [searchTerm] = useSearchTerm();
    const [query, setQuery] = useState(searchTerm || "");

    useEffect(() => {
        setQuery(searchTerm)
    }, [searchTerm]);

    const debouncedOnChange = useDebouncedCallback(onChange, 700);
    const querySubmit = () => {
        debouncedOnChange(query);
    }
    return (
        <EonUiForm onFormSubmit={querySubmit} style={{ width: "100%" }}>
            <EonUiInput
                type="text"
                trailingIconName="search"
                placeholder="Search..."
                value={query}
                onIconClick={querySubmit}
                onValueChanged={(e) => {
                    const st = e.target.value;
                    setQuery(st);
                }}
            />
        </EonUiForm>
    );
}

export type DataType =
    | ""
    | "all"
    | "apps"
    | "allWithFilter"
    | "m365"
    | "jql"
    | "people"
    | "assistance";

export type SelectDataTypeProps = {
    options: DropDownInfo[];
    dataType: string;
    setters: FilterSetters;
    dataSourceFilters: DataSourceFilter[];
};
