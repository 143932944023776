import React, {ReactNode, useCallback} from "react";
import {translationEn} from "./translation.en";
import {RawTranslationFn, TranslationProvider} from "../../translation";

import {translationDe} from "./translation.de";
import {translationSe} from "./translation.se";
import {pathToValue} from "@me8eon/record_utils";
import {useThrowError} from "@me8eon/throw_error";
import {Language, useLanguage} from "@me8eon/language";

export function makeTranslationFn(translation: Record<string, any>): RawTranslationFn {
    return (key: string) => {
        return pathToValue(translation, key);
    };
}

type LanguageLookup = Record<Language, Record<string, any>>;
type LanguageLookupTranslationProvider = { children: ReactNode, languageLookup?: LanguageLookup }

export const defaultLanguageLookup: LanguageLookup = {
    en: translationEn,
    de: translationDe,
    se: translationSe
};

export function SimpleTranslationProvider({children, languageLookup = defaultLanguageLookup}: LanguageLookupTranslationProvider) {
    const [language] = useLanguage();
    const throwError = useThrowError();
    const translationFn = useCallback<RawTranslationFn>(
        key => {
            const lang = languageLookup[language];
            if (!lang) return throwError('s/w', `Language ${language} not found in languageLookup. Legal values are ${Object.keys(languageLookup)}`);
            return makeTranslationFn(lang)(key);
        }, [language, languageLookup]);
    return <TranslationProvider translationFn={translationFn}>{children}</TranslationProvider>;
}