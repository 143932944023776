import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { EonUiPreloaderSquares } from "@eon-ui/eon-ui-components-react";
import { AiAssistContext, MessageWithActionAndData } from "../AiAssist.context";
import { lookupTableAboutChatbotActions } from "../action-components/actions";
import userQueryComponent from "../action-components/userQuery.component";
import { getFrom } from "../../../util/map";
import { isHigherEnv } from "../../../util/common-util";
import AiWelcomeWrapper from "./AiWelcome";
import { Feedback } from "../../feedback/feedback.component";
import { AiAssistFeedbackPayload, LikeOrDislike } from "@me8eon/feedback";
import LiveAgentChat from "../../live-agent-chat/LiveAgentChat";
import useSearchStore from "../../../store/searchStore";
import QuickActionLinks from "../QuickActionLinks";
import SliderButton from "./SliderButton";
import { useAiSourceMemory } from "../../ai/clients/ai.rag.client";
import { ESApiFinalResponseInterface } from "../../../interfaces/ElasticSearchInterface";
import AssistanceTask from "../assistance-task/AssistanceTask";

export const indexToNameMap: Record<string, string> = {
    'ai-azureblob-test': 'E.ON Knowledge Base',
    'servicenow-prod': `EON IT Knowledge Base`,
    'confluence-prod': `Confluence`,
    'jira-prod': `Jira`,
    'sharepoint-prod': `SharePoint`,
    'default': `General knowledge`
};

export function getFeedbackDataFromSource(s: ESApiFinalResponseInterface) {
    const summary = indexToNameMap[s.index] || indexToNameMap['default'];
    return ({ summary, index: s.index, id: s.id });
}

const Content: React.FC = () => {
    const { showLiveAgentChat } = useSearchStore();
    const [sources] = useAiSourceMemory();
    const { messages, actualMessages, isLoading, cards, currentCardKey, isITIssue, quickActionType } =
        useContext(AiAssistContext);
    const messageEndRef = useRef<HTMLDivElement>(null);

    const showFeedback = useMemo(
        () => actualMessages[actualMessages.length - 1]?.role === "assistant" && messages[messages.length - 1]?.action !== "quick-actions" && !isLoading,
        [actualMessages, isLoading]
    );
    const showCreateIncident = useMemo(
        () => cards[currentCardKey]?.feedback?.includes("create-incident") && (quickActionType || isITIssue),
        [cards, currentCardKey]
    );


    const feedbackDataHandler = useCallback(
        (like: LikeOrDislike): AiAssistFeedbackPayload => {
            return {
                like,
                sources: sources.map(getFeedbackDataFromSource),
                source: "ai-assist",
                messages: actualMessages,
                query: actualMessages[actualMessages.length - 1]?.content || "",
            };
        },
        [actualMessages]
    );

    const messageComponents = useMemo(
        () => {
            let userTypedMsg = "";
            return messages.map((message: MessageWithActionAndData, index: number) => {
                switch (message.role) {
                case "user": {
                    const UserComponent = userQueryComponent;
                    userTypedMsg = message.content;
                    return (
                        <UserComponent key={`user-${index}`} {...message} />
                    );
                }
                case "assistant": {
                    const AssistantComponent = getFrom(
                        lookupTableAboutChatbotActions
                    )(message.action);
                    if (!AssistantComponent) {
                        console.error(
                            `No component found for action: ${message.action}`
                        );
                        return null;
                    }
                    const nextThinkApp = window.location.href.includes("nextThinkApp=true");
                    return (
                        <AssistantComponent
                            key={`assistant-${index}`}
                            {...message}
                            isActionTrigger={nextThinkApp && userTypedMsg === "Why is my application not loading?"}
                        />
                    );
                }
                default:
                    throw new Error("Invalid role");
                }
            });
        },
        [messages]
    );

    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    return (
        <>
            {messages.length <= 0 ? (
                <AiWelcomeWrapper />
            ) : (
                <div className="ai-assist-content-wrapper">
                    <div className="ai-assist-content">
                        {messageComponents}
                        {isLoading && (
                            <EonUiPreloaderSquares
                                className="preloader"
                            />
                        )}
                        {showFeedback && (
                            <Feedback
                                dataToSend={feedbackDataHandler}
                                showCreateIncident={!!showCreateIncident}
                                source="ai-assistance"
                            />
                        )}
                        <div ref={messageEndRef} />
                    </div>
                </div>
            )}
            {showLiveAgentChat && <LiveAgentChat />}
        </>
    );
};

const AiMessages: React.FC = () => {
    const { messages, currentCardKey } = useContext(AiAssistContext);

    return (
        <>
            <div className="ai-assist-right-panel-mobile">
                <SliderButton />
                {currentCardKey === "selfService" && <QuickActionLinks />}
            </div>
            <div className="ai-assist-wrapper">
                <div className="ai-assit-container" style={{ alignContent: messages.length ? 'normal' : 'center' }}>
                    <Content />
                </div>
                <div className="ai-assist-right-panel-desktop">
                    <SliderButton />
                    {currentCardKey === "selfService" && <QuickActionLinks />}
                    <AssistanceTask />
                </div>
            </div>
        </>
    );
};

export default AiMessages;
