import React, { useMemo } from "react";
import { AppsData } from "./apps.data";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { SimpleWidget } from "@me8eon/simple_widget";
// @ts-ignore
import AppsIcon from "./app.svg";


export const AppDisplay: DisplayData<AppsData> =
    ({ rootId, id, data }: DisplayDataProps<AppsData>) => {
        const { H1WithIconAndUrl,  Url } = useRenderers();
        const { Text, Date, DataLayout } = useAttributeValueComponents();
        const newRootId = `${rootId}.${id}`;
        const issueSummaryAndUrl = useMemo(() =>
            ({ url: data.target, title: data.title, icon: AppsIcon }), [data.target, data.title]);
        return <DataLayout rootId={newRootId} layout={[1, 1, 1]}>
            <H1WithIconAndUrl rootId={newRootId} attribute={translateLabel("name")} value={issueSummaryAndUrl} />
            <Text rootId={id} attribute={translateLabel("source")} value="apps" />
            <Url rootId={id} attribute={translateLabel("url")} value={data.target} />
        </DataLayout>;
    };

export const OneLineAppDisplay: DisplayData<AppsData> =
    ({ data }: DisplayDataProps<AppsData>) =>
        <span>{data.type} {data.title}</span>;

export const SimpleAppWidget: DisplayDataWidget<AppsData> =
    SimpleWidget(
        ["issue", "summary", "status"],
        ["title"],
        []);

