export type JiraIssue = {
    id: string
    key: string
    self: string
    summary: string
    fields: JiraIssueFields
}
export type JiraIssueCommentField = {
    comments: JiraIssueComment[]
}
export type JiraIssueComment = {
    body: JiraDoc | string
}
export type JiraIssueFields = {
    reporter: { email: string };
    assignee: { email: string },
    comment: JiraIssueCommentField
    description: JiraDoc
    project: { self: string, key: string, name: string }
    issuetype: {name: string}
    priority: any;
    summary: string
    creator: { name: string }
    created: string
    updated: string
    status: { name: string, }
}
export type JiraDoc = {
    type: 'doc',
    content: JiraDocContent[]
}
export type JiraDocContent = {
    type: 'paragraph',
    content: JiraParagraphContent[]
}
export type JiraParagraphContent = {
    type: 'text',
    text: string
}

export type JiraIndexedIssue = {
    id: string
    issue: string
    type: 'jira'
    issueType: string
    self: string
    key: string
    project: string
    created_by: string
    created: string
    last_updated: string
    priority: any
    url: string
    status: any
    summary: string
    assignee: string
    reporter: string
    description: string
    comments: string // probably want some better structure here
}

export function jiraIssueToIndexedJiraIssue(j: JiraIssue): JiraIndexedIssue {
    const self = new URL(j.self);
    const url = `${self.protocol}//${self.host}/browse/${j.key}`;
    try {
        return {
            id: j.id,
            type: 'jira',
            issueType: j.fields.issuetype.name,
            self: j.self,
            issue: j.key,
            key: j.fields.project.key,
            project: j.fields.project.name,
            created_by: j.fields.creator?.name,
            created: j.fields.created,
            last_updated: j.fields.updated,
            priority: j.fields?.priority?.name,
            status: j.fields?.status?.name,
            url,
            summary: j.fields?.summary,
            description: fromJiraDocOrString(j.fields.description),
            assignee: j.fields.assignee?.email,
            reporter: j.fields?.reporter?.email,
            comments: allComments(j)
        };
    } catch (e) {
        console.error('error in jira', JSON.stringify(j));
        throw e;
    }
}

export function allComments(j: JiraIssue) {
    return (j.fields.comment?.comments || [])
        .map(c => fromJiraDocOrString(c.body)).join('\n');
}

export function fromJiraDocOrString(d: JiraDoc | string): string {
    return typeof d === 'string' ? d : getAllParagraphContent(d);
}

export function getAllParagraphContent(doc: JiraDoc): string {
    if (doc == null || doc?.content === null) return '';
    return (doc.content || []).filter(c => c.type === 'paragraph')
        .map(p => p.content.filter(p => p.type === 'text')
            .map(c => c.text)
            .join('\n')).join('\n');
}