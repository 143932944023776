import React, { ReactNode } from "react";

import { debugSearchResultsName } from "./debug.search.results";
import { useDebug } from "@me8eon/debug";
import { DataHasType } from "@me8eon/data";
import { useDataViews, useSelectedDataViewName } from "@me8eon/data_views";
import { useThrowError } from "@me8eon/throw_error";
import { useDataWidgets } from "@me8eon/data_widget";
// import { dataViewWithFilters } from "@me8eon/all_data_views";

export type DisplaySearchResults = <Data extends DataHasType> (props: DisplaySearchResultsProps<Data>) => ReactNode

//At the moment we are just repeating the existing
//code in the main application
//So the data all came from one application, and there's just the list of it
export type DisplaySearchResultsProps<Data> = {
    rootId: string
    data: Data[]
}

export const DisplaySearchResults: DisplaySearchResults =
    <Data extends DataHasType>({ rootId, data }: DisplaySearchResultsProps<Data>): ReactNode => {
        const debug = useDebug(debugSearchResultsName);
        const dataWidgets = useDataWidgets();
        const [selected] = useSelectedDataViewName();
        const dataViews = useDataViews();
        const throwError = useThrowError();
        const dataView = dataViews[selected];
        if (!dataView) throwError(`s/w`, `No data view selected: ${selected}. Legal values are ${Object.keys(dataViews).sort()}`);
        const widgets = dataView.widgets;
        const Layout = dataView.SearchResultLayout;

        return <Layout rootId={rootId}>{
            widgets.map(w => {
                const widget = dataWidgets[w];
                if (!widget) return throwError(`s/w`, `No widget with name ${w}. Legal values are ${Object.keys(dataWidgets).sort()} `);
                const Display = widget.display;
                const isDataHasFilter = Object.values(dataViews).filter(e => e.widgets.length === 2 && e.widgets.indexOf(w) > -1 && w !== "filter").length > 0;
                return <div className={`${isDataHasFilter ? "widget-reduced-width" : ""}`}
                    data-widget={w} key={w}><Display rootId={rootId} data={data} widgetName={w} /></div>
            })
        }</Layout>;
    };
