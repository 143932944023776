function getMSALAccountKeys() {
    return localStorage.getItem("msal.account.keys");
}

function getSnowBearerToken() {
    return localStorage.getItem("snowBearerToken") ?? "";
}

function getSnowBearerTokenLiveAgentChat() {
    return localStorage.getItem("snowBearerTokenLiveAgentChat") ?? "";
}

function getLocalValue(key: string) {
    if (!key) return;
    return localStorage.getItem(key) ?? "";
}

function setLocalValue(key: string, data: string) {
    if (key && data) {
        localStorage.setItem(key, data);
    }
}
function removeLocalValue(key: string) {
    if (key ) {
        localStorage.removeItem(key);
    }
}


const isTokenExpired = (expiryInMilliSeconds: number, bufferInMinutes: number = 0): boolean => {
    if (!expiryInMilliSeconds || isNaN(expiryInMilliSeconds)) {
        return true;
    }
    return Date.now() >= expiryInMilliSeconds - (bufferInMinutes * 60 * 1000);
};

export {
    getMSALAccountKeys,
    getSnowBearerToken,
    getSnowBearerTokenLiveAgentChat,
    getLocalValue,
    setLocalValue,
    isTokenExpired,
    removeLocalValue
};
