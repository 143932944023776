import { TabDataInterface } from "../../interfaces/TileInterface";
import { useTranslation } from "react-i18next";
import {
    EonUiInput,
    EonUiLink,
    EonUiButton,
    EonUiDropdown,
    EonUiDropdownOption,
} from "@eon-ui/eon-ui-components-react";
import React, { useState } from "react";
import { uppercaseFirstLetterOnly } from "../../util/strings";
import { TileDataInterface } from "../../interfaces/TileInterface";
import TabDeleteConfirm from "./TabDeleteConfirm";
import useSearchStore from "../../store/searchStore";
import "./Organize.scss";

const PersonalizeDashboard = ({
    favTabs,
    favApps,
}: {
    favTabs: TabDataInterface[];
    favApps: TileDataInterface[];
}) => {
    const { showModal, setPrefData, updateFavObject, updateSearchData } =
        useSearchStore();

    const [tempFavTabs, setTempFavTabs] = useState<TabDataInterface[]>(
        JSON.parse(JSON.stringify(favTabs))
    );
    const [catTempState, setCatTempState] = useState<TileDataInterface[]>(
        JSON.parse(JSON.stringify(favApps))
    );
    const [saveError, setSaveError] = useState<number | null>(null);
    const [deleteTabObj, setDeleteTabObj] = useState<{
        data: TabDataInterface | null;
        index: number | null;
    }>({ data: null, index: null });
    const { t } = useTranslation();

    const tabNameChanged = (value: string, index: number) => {
        const changedVal = uppercaseFirstLetterOnly(value);
        const isDuplicate = tempFavTabs.some(
            (obj, i) => i !== index && obj.name === changedVal
        );
        const isValidationError =
            changedVal.length < 2 || changedVal.length > 20;
        const isInvalid =
            changedVal === "Favourite" || isDuplicate || isValidationError;
        setSaveError(isInvalid ? index : null);

        // Create a copy of the tabs and tiles to update them
        const updatedTabs = [...tempFavTabs];
        const oldTabName = updatedTabs[index].name;
        updatedTabs[index].name = changedVal;

        const updatedTiles = catTempState.map((tile) =>
            tile.cat === oldTabName ? { ...tile, cat: changedVal } : tile
        );

        // Update tab counts based on the new category names
        const tabsWithUpdatedCounts = updatedTabs.map((tab) => {
            const newCount = updatedTiles.filter(
                (t) => t.cat === tab.name
            ).length;
            return { ...tab, count: newCount };
        });

        setCatTempState(updatedTiles);
        setTempFavTabs(tabsWithUpdatedCounts);
    };

    const handleSave = () => {
        if (saveError === null) {
            updateFavObject({ favApps: catTempState, favTabs: tempFavTabs });
            setPrefData();
            showModal("");
        }
    };

    const handleCancel = () => {
        showModal("");
    };

    const getCorrectedData = (
        favAppData: TileDataInterface[],
        item: TileDataInterface,
        selectedCat: string
    ) => {
        const modifiedTiles: TileDataInterface[] = [];
        const { alias, cat, seq } = item;

        favAppData.forEach((obj) => {
            if (obj.alias !== alias) {
                let newSeq = obj.seq ?? 0;
                if (cat === obj.cat && obj.seq && seq && obj.seq > seq) {
                    newSeq = newSeq - 1;
                }
                modifiedTiles.push({ ...obj, seq: newSeq });
            } else {
                const existingCatLength = catTempState.filter(
                    (obj) => obj.cat === selectedCat
                ).length;
                modifiedTiles.push({
                    ...obj,
                    cat: selectedCat,
                    seq: existingCatLength + 1,
                });
            }
            return null;
        });
        return modifiedTiles;
    };

    const favItemDropdownChange = (
        tile: TileDataInterface,
        selectedCat: string
    ) => {
        const newData = getCorrectedData(catTempState, tile, selectedCat);
        setCatTempState(newData);
        const updatedTabs = tempFavTabs.map((tab) => {
            const newCount = newData.filter((t) => t.cat === tab.name).length;
            return { ...tab, count: newCount };
        });
        setTempFavTabs(updatedTabs);
    };

    const [showDelConfirm, setShowDelConfirm] = useState(false);
    const confirmMessage = (index: number) => {
        setDeleteTabObj({ data: tempFavTabs[index], index });
        setShowDelConfirm(true);
    };

    const isDeleteConfirmed = (isDeleteComfirm: boolean) => {
        if (isDeleteComfirm) {
            const { data, index } = deleteTabObj;
            const finalAppData = catTempState.filter(
                (obj) => obj.cat !== data?.name
            );
            const finalTabData = tempFavTabs.filter((_, i) => i !== index);
            finalTabData[0].active = true;
            updateFavObject({ favApps: finalAppData, favTabs: finalTabData });
            setPrefData();
            updateSearchData();
            showModal("");
        } else {
            setShowDelConfirm(false);
        }
    };

    return (
        <>
            {showDelConfirm ? (
                <TabDeleteConfirm
                    isDeleteConfirmed={isDeleteConfirmed}
                    deleteTabObj={deleteTabObj.data}
                />
            ) : (
                <div className="personalize-dashboard">
                    <h2 className="heading">
                        {t("moveTabAndTiles.personalizeTab")}
                    </h2>
                    {tempFavTabs.map(
                        (tabData: TabDataInterface, index: number) => (
                            <div key={index.toString()}>
                                <div className="tab-data-container">
                                    <EonUiInput
                                        value={tabData.name}
                                        name={tabData.name}
                                        type="text"
                                        size="small"
                                        disabled={index === 0}
                                        className="tab-input"
                                        autocomplete={false}
                                        onValueChanged={(e) =>
                                            tabNameChanged(
                                                e.target.value,
                                                index
                                            )
                                        }
                                        invalid={
                                            saveError === index && index > 0
                                        }
                                        error-message={t(
                                            "moveTabAndTiles.tabNameError"
                                        )}
                                    ></EonUiInput>
                                    <div>
                                        <EonUiLink
                                            title={t("delete")}
                                            disabled={index === 0}
                                            className={`tab-icon ${tabData.name === "Favourite" ? "disabled-icon" : ""}`}
                                            icon="trash_bin"
                                            onClick={() =>
                                                index !== 0 &&
                                                confirmMessage(index)
                                            }
                                            href="javascript:void(0)"
                                        ></EonUiLink>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                    <h2 className="heading">
                        {t("moveTabAndTiles.personalizeTiles")}
                    </h2>
                    {catTempState.map(
                        (tile: TileDataInterface, catIndex: number) => {
                            return (
                                <div
                                    className="margin-category"
                                    key={`${catIndex.toString()}${tile.cat}`}
                                    id={`hello${catIndex.toString()}${tile.cat}`}
                                >
                                    <div className="margin-title">
                                        {tile.title}
                                    </div>
                                    <div className="dropdown-wrapper">
                                        <EonUiDropdown
                                            onValueChanged={(e) =>
                                                favItemDropdownChange(
                                                    tile,
                                                    e.target.value
                                                )
                                            }
                                            value={tile.cat}
                                            name="dropdown"
                                        >
                                            {tempFavTabs.map(
                                                (
                                                    tab: TabDataInterface,
                                                    index: number
                                                ) => (
                                                    <EonUiDropdownOption
                                                        key={`${index.toString()}`}
                                                        value={tab.name}
                                                        label={tab.name}
                                                    ></EonUiDropdownOption>
                                                )
                                            )}
                                        </EonUiDropdown>
                                    </div>
                                </div>
                            );
                        }
                    )}
                    <div className="common-button-wrapper">
                        <EonUiButton
                            fullWidthOnMobile
                            text={t("save")}
                            rank="primary"
                            size="small"
                            onClick={handleSave}
                        ></EonUiButton>
                        <EonUiButton
                            fullWidthOnMobile
                            text={t("cancel")}
                            rank="secondary"
                            size="small"
                            onClick={() => handleCancel()}
                        ></EonUiButton>
                    </div>
                </div>
            )}
        </>
    );
};

export default PersonalizeDashboard;
