import React, { useMemo } from "react";
import { GitLabData } from "./gitlab.data";
import { useAttributeValueComponents, useRenderers } from "@me8eon/renderers";
import { DisplayData, DisplayDataProps, DisplayDataWidget, translateLabel } from "@me8eon/data";
import { ClipHeight } from "@me8eon/clip_height";
import { SimpleWidget } from "@me8eon/simple_widget";
// @ts-ignore
import icon from './gitlab.svg'
export const GitLabDisplay: DisplayData<GitLabData> =
    ({ rootId, id, data }: DisplayDataProps<GitLabData>) => {
        const { H1WithIconAndUrl, Markdown, Url } = useRenderers();
        const { Text, DataLayout } = useAttributeValueComponents();
        const newRootId = `${rootId}.${id}`;
        const issueSummaryAndUrl = useMemo(() =>
            ({ url: data.url, title: data.fullPath, icon }), [data.url, data.summary]);
        return <DataLayout rootId={newRootId} layout={[1, 1, 1, 1]}>
            <H1WithIconAndUrl rootId={newRootId} attribute={translateLabel('name')} value={issueSummaryAndUrl} />
            <ClipHeight maxHeight='3rem' force={false}>
                <Markdown rootId={id} attribute={translateLabel('description')} value={data.content} />
            </ClipHeight>
            <Text rootId={id} attribute={translateLabel('source')} value='gitlab' />
            <Url rootId={id} attribute={translateLabel('url')} value={data.url} />
        </DataLayout>;
    };

export const SimpleGitLabOneLineDisplay: DisplayData<GitLabData> =
    ({ data }: DisplayDataProps<GitLabData>) =>
        <span>{data.type} {data.summary}</span>;

export const SimpleGitLabWidget: DisplayDataWidget<GitLabData> =
    SimpleWidget(
        ['issue', 'summary', 'status'],
        ['issue', 'summary', 'status'],
        ['issue', 'status']);

