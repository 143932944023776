{
    "en": {
        "issue": "Key",
        "summary": "Summary",
        "project": "Project",
        "issueType": "Type",
        "priority": "Priority",
        "status": "Status"
    },
    "de": {
        "issue": "Key",
        "summary": "Zusammenfassung",
        "project": "Projekt",
        "issueType": "Type",
        "priority": "Priorität",
        "status": "Status"
    },
    "sv": {
        "issue": "Key",
        "summary": "Summary",
        "project": "Project",
        "issueType": "Type",
        "priority": "Priority",
        "status": "Status"
    }
}
