import React, {Context, createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useState} from "react";
import { useThrowError } from "@me8eon/throw_error";
import { capitalizeFirstLetter } from "@me8eon/string_utils";


export type Setter<T> = Dispatch<SetStateAction<T>>
export type GetterSetter<T> = [T, Setter<T>]

export type ContextResults<Data, FIELD extends string> = {
    use: () => Data
    Provider: (props: { children: ReactNode } & Record<FIELD, Data>) => ReactElement
    context: Context<Data | undefined>
}

export function makeContextFor<Data, FIELD extends string>(
    field: FIELD,
    defaultValue?: Data
): ContextResults<Data, FIELD> {
    // Create the context dynamically
    const context = createContext<Data | undefined>(defaultValue);


    function useField(): Data {
        const contextValue = useContext(context);
        const throwError = useThrowError();
        if (contextValue === undefined) {
            const upperedName = capitalizeFirstLetter(field);
            return throwError('s/w', `use${upperedName} must be used within a ${upperedName}Provider`);
        }
        return contextValue!;
    }

    type ProviderProps = { children: ReactNode, allowUndefined?:boolean } & Record<FIELD, Data>;

    // Provider component dynamically named like `${field}Provider`
    function FIELDProvider(props: ProviderProps) {
        const value = props[field];
        if (value === undefined && !props.allowUndefined) throw new Error (`${field} cannot be undefined.`);
        return <context.Provider value={props[field]}>{props.children}</context.Provider>;
    }

    // Return context, hook, and provider with dynamic names
    return {use: useField, Provider: FIELDProvider, context};
}


export type ContextResultsForState<Data, FIELD extends string> = {
    use: () => GetterSetter<Data>
    Provider: (props: { children: ReactNode } & Record<FIELD, Data>) => ReactNode
    context: Context<GetterSetter<Data> | undefined>
}

export function makeContextForState<Data, FIELD extends string>(field: FIELD, allowedUndefined?: boolean): ContextResultsForState<Data, FIELD> {
    const Context = React.createContext<GetterSetter<Data> | undefined>(undefined);


    function useField() {
        const contextValue = useContext(Context);
        const reportError = useThrowError();
        if (contextValue === undefined && !allowedUndefined) {
            const fieldWithCap = capitalizeFirstLetter(field);
            reportError('s/w', `use${fieldWithCap} must be used within a ${fieldWithCap}Provider`);
        }
        return contextValue!;
    }


    type ProviderProps = { children: ReactNode } & Record<FIELD, Data>;

    function FieldProvider(props: ProviderProps) {
        const getterSetter = useState<Data>(props[field]);
        return <Context.Provider value={getterSetter}>{props.children}</Context.Provider>;
    }

    return {use: useField, Provider: FieldProvider, context: Context};
}