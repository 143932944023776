import React, { useEffect, useRef, useState } from "react";
import { useDataViewNavBarLayout, useDataViews, useSelectedDataViewName } from "@me8eon/data_views";
import { CatchWidth } from "@me8eon/catch_width"; // Import the HOC
import { useTranslation } from "react-i18next";
import MoreDropdown from "./more.dropdown";
import DataList from "./data.list";
import "./data.view.nav.bar.scss";

export type DataViewNavBarProps = {
    rootId: string;
    width: number;
};

export const DataViewNavBarChild: React.FC<DataViewNavBarProps> = ({ rootId, width }: DataViewNavBarProps) => {
    const selectedOps = useSelectedDataViewName();
    const DisplayLayout = useDataViewNavBarLayout();
    const dataViews = useDataViews();
    const { t } = useTranslation();
    const actualData = Object.entries(dataViews);
    const dataViewsLength = actualData.length;
    let moreTextHidden = true;
    const selectedOpsName = selectedOps[0];
    let visibleElems: number = 0;
    let availableWidth: number = width - (48 + 220);

    const widths: number[] = actualData.map(([name]) => {
        const currentText = t(`filterPage.filterMenu.${name}`);
        const currentElemWidth = currentText.length * 9 + 64;
        return currentElemWidth;
    });

    for (let index = 0; index < dataViewsLength; index++) {
        const elementWidth = widths[index];
        availableWidth -= elementWidth;
        if (availableWidth > 0) {
            visibleElems += 1;
            if (actualData[index][0] === selectedOpsName) {
                moreTextHidden = false;
            }
        }
    }

    return (
        <div className="nav-bar-wrapper" style={{
            display: "flex",
            alignItems: "flex-start",
            height: "48px",
            position: "sticky",
            top: "68px",
            background: "#fff",
            zIndex: 100,
        }}>
            <DisplayLayout id={`${rootId}-navbar-container`}>
                <DataList position="horizontal" visibleElems={visibleElems} rootId={rootId} />
            </DisplayLayout>
            {dataViewsLength > visibleElems ? (
                <MoreDropdown moreTextHidden={moreTextHidden} menuItemSelected={t(`filterPage.filterMenu.${selectedOpsName}`)}>
                    <DataList maxChars={10} selectedTick={true} position="vertical" visibleElems={visibleElems} rootId={rootId} />
                </MoreDropdown>
            ) : null}
        </div>
    );
};
export const DataViewNavBar = CatchWidth(DataViewNavBarChild);
