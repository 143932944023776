import { SearchFn, SearchFnResult } from "./search";
import { ESApiFinalResponseInterface } from "../interfaces/ElasticSearchInterface";
import { PublicClientApplication } from "@azure/msal-browser";
import { sanitize } from "../util/strings";
import { firstIndexLookup } from "../domain/info/m365Info";
import { DataType } from "../components/el-search-advance/ElSearchAdvanceHelpers";


export interface GraphApiHit {
    name: string;
    webUrl: string;
    createdDateTime: string;
    lastModifiedDateTime: string;
    size: number;
    file?: any; // Add proper typing if possible
    parentReference?: any; // Add proper typing if possible
    hitHighlightedSummary?: string;
}

export interface GraphApiHitsContainer {
    hits: GraphApiHit[];
}

export interface GraphApiResponse {
    hitsContainers: GraphApiHitsContainer[];
}


export const searchBodyForGraphApi = (dataType: string, firstIndex: string, query: string) => {
    const lookedup = firstIndexLookup[firstIndex];
    const extraFilter = lookedup ? `${lookedup} and ` : "";
    const entityTypes = dataType === "people" ? ["people"] : ["driveItem"];
    return {
        requests: [
            {
                entityTypes,
                query: {
                    queryString: extraFilter + sanitize(query),
                },
                selectProperties: [
                    "name",
                    "webUrl",
                    "createdDateTime",
                    "lastModifiedDateTime",
                    "size",
                    "file",
                    "jobTitle",
                    "mobilePhone",
                    "businessPhones",
                    "aboutMe",
                    "parentReference",
                    "hitHighlightedSummary",
                ],
            },
        ],
    };
};

export function defaultGraphApiTokenFn(
    scopes: string[],
    msalInstance: PublicClientApplication,
): () => Promise<string> {
    return async () => {
        const account = msalInstance.getAllAccounts()[0];
        if (!account) throw new Error("No account found");
        const response = await msalInstance.acquireTokenSilent({ scopes, account });
        return response.accessToken;
    };
}

export function popupGraphApiTokenFn(
    scopes: string[],
    msalInstance: PublicClientApplication,
): () => Promise<string> {
    return async () => {
        const account = msalInstance.getAllAccounts()[0];
        if (!account) throw new Error("No account found");
        const response = await msalInstance.acquireTokenPopup({ scopes, account });
        return response.accessToken;
    };
}

export type MakeGraphApiRequest = (url: string, token: string, body: object) => Promise<GraphApiResponse[]>;

export async function _makeGraphApiRequest(url: string, token: string, body: object): Promise<GraphApiResponse[]> {
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Graph API request failed: ${response.status} - ${errorText}`);
    }

    const result = await response.json();
    if (!result.value || !Array.isArray(result.value)) {
        throw new Error(
            `Unexpected Graph API response format: ${response.status} - ${JSON.stringify(result, null, 2)}`,
        );
    }
    return result.value;
}

function pseudoIndexFor(dataType: string) {
    return dataType === "people" ? "people" : "m365";
}

export function graphApiSearch(
    token: () => Promise<string>,
    makeGraphApiRequest: MakeGraphApiRequest = _makeGraphApiRequest,
): SearchFn<ESApiFinalResponseInterface> {
    return async (query) => {
        if (query.append) return { data: [], dataSourceFilters: [], metadataFilters: {} };
        const firstIndex = query.m365DocTypes ? query.m365DocTypes[0] : query.searchIndexes[0];
        const requestBody = searchBodyForGraphApi(query.dataType, firstIndex, query.searchTerm);

        const data = await makeGraphApiRequest(`https://graph.microsoft.com/v1.0/search/query`, await token(), requestBody);

        const containers = data.flatMap((v) => (v.hitsContainers || []).flatMap((hc: any) => hc.hits || []));
        const formattedData = containers.map((c: any) => {
            const newType = c?.resource["@odata.type"] === "#microsoft.graph.driveItem" ? "sharepoint" : "person";
            const type = query.dataType === "m365" ? "sharepoint" : newType;
            return ({ ...c, type, id: c.hitId, index: pseudoIndexFor(query.dataType) });
        });

        const result: SearchFnResult<any> = { data: formattedData, dataSourceFilters: [], metadataFilters: {} };
        return result;
    };
}
