

// @ts-ignore
import MeAtEonLogo from "./meateon.png";
// @ts-ignore
import EonLogo from "./eon.png";
import { CommonDataLayoutStyles, commonDataStyles, commonRendererStyles, Theme } from "@me8eon/themes";

export const eonTheme: Theme = {
    name: "eon theme",
    value: "eon",
    description: "The theme for me@eon",
    header: {
        eonLogoUrl: "eon",
        meAtEonLogoUrl: "me8eon",
    },
    data: commonDataStyles,
    renderer: commonRendererStyles,
    dataLayout: CommonDataLayoutStyles,
};

console.log("eonTheme", eonTheme);