import React from "react";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import { TileDataInterface } from "../../interfaces/TileInterface";
import { useTranslation } from "react-i18next";
import useSearchStore from "../../store/searchStore";
import { isAppIndex } from "../../util/common-util";
import { Questionator } from "./SearchList";

interface ListItemProps {
    resultData: TileDataInterface | Questionator;
    listItemClicked?: (query: string) => void;
}

const ListItem: React.FC<ListItemProps> = ({ resultData, listItemClicked }) => {
    const { setPrefData, toggleFavourite } = useSearchStore();
    const { t } = useTranslation();
    const { title: queTitle } = (resultData as Questionator) || "";
    const { title, target, source, status, alias } =
        resultData as TileDataInterface;
    const translatedLabel = alias ? t(`appData.${alias}`) : title;
    const label = translatedLabel.startsWith("appData.")
        ? title || queTitle
        : translatedLabel;
    const index = (resultData as TileDataInterface).index;
    const isApp = index ? isAppIndex(index) : false;

    const handleAddFavorite = (item: TileDataInterface) => {
        delete item.full_text_embeddings;
        toggleFavourite(item);
        setPrefData();
    };

    const itemClicked = (question: string) => {
        if (listItemClicked) {
            listItemClicked(question);
        }
    };

    return (
        <li className="result-list">
            <a
                href={target ? target : `javascript:void(0)`}
                target={target ? "_blank" : "_self"}
                rel="noreferrer"
                className="result-redirection-wrapper"
                onClick={() => itemClicked(queTitle)}
            >
                {label && (
                    <div
                        className="sui-result__details"
                        dangerouslySetInnerHTML={{
                            __html: label,
                        }}
                    ></div>
                )}
                {source && <div className="source-text">{source}</div>}
            </a>
            {isApp && (
                <button
                    className="icon-align-right"
                    title={
                        status
                            ? t("searchPage.removeAppTooltip")
                            : t("searchPage.addAppTooltip")
                    }
                    onClick={() =>
                        handleAddFavorite(resultData as TileDataInterface)
                    }
                >
                    <EonUiIcon
                        name={status ? "subtract" : "add"}
                        className="add-remove-icon eon-ui-svg-icon"
                        scheme="red500"
                        title={status ? t("searchPage.removeAppTooltip") : t("searchPage.addAppTooltip")}
                    ></EonUiIcon>
                </button>
            )}
        </li>
    );
};
export default ListItem;
