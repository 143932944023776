import { SearchResultLayout, SearchResultLayoutProps } from "../search_results_layout/data.view.search.results.layout";
import React from "react";

// Individual search result
export const TestSearchResultLayout: SearchResultLayout =
    ({ rootId, children }: SearchResultLayoutProps) => {
        const childLength = children ? Object.keys(children).length : 0;
        return <div
            className={`advance-unified-assist ${childLength > 1 ? "advance-unified-assist--flex-rev" : ""}`}
            data-testid={`${rootId}-search-result-layout`}
        >
            {children}
        </div>;
    };

export const TestOneLineLayout: SearchResultLayout =
    ({ rootId, children }: SearchResultLayoutProps) => {
        const childrenArray = React.Children.toArray(children);
        return <div data-testid={`${rootId}-one-line-layout`}>{childrenArray.map((child, i) =>
            (<div key={i}>{child}</div>)
        )}</div>;
    };

