import React, { useState } from "react";
import { Icon, IconProps } from "./Icon";
import { useTheme } from "@me8eon/themes";

interface WithColorChangeProps extends IconProps {
    shouldChangeColor?: boolean;
    hoverColor?: string;
}

export function withColorChange<T extends IconProps>(
    WrappedIcon: React.ComponentType<T>
) {
    return function ColorChangingIcon(props: T & WithColorChangeProps) {
        const { shouldChangeColor = true, hoverColor = "#c90a01", scheme, style, ...restProps } = props;
        const { dataLayout } = useTheme();

        const computedStyle = {
            ...style,
            ...(shouldChangeColor && {
                fill: dataLayout.themedIconColor,
                color: dataLayout.themedIconColor,
            }),
        };

        return (
            <WrappedIcon
                {...(restProps as T)}
                style={computedStyle}
            />
        );
    };
}

// ✅ Define ThemedIcon using HOC
export const ThemedIcon = withColorChange(Icon);
