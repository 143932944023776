{
  "apiFailDefaultMsg": "Något gick fel. Försök igen senare.",
  "appData": {
    "approval": "Mina godkännanden",
    "approvalhover": "Med myInbox kan du enkelt och effektivt godkänna fakturor genom att ha all relevant information och åtgärder samlade på ett ställe.",
    "ariba": "Ariba - Classic och Classic+",
    "aribahover": "Med SAP Ariba kan du enkelt hantera dina inköpsprocesser genom att ha all relevant information och alla verktyg samlade på ett ställe.",
    "beeline": "Beeline",
    "beelinehover": "Med Beeline kan du effektivt hantera din tillfälliga personal och effektivisera dina bemanningsprocesser genom att ha alla nödvändiga verktyg och information samlade på ett ställe.",
    "brand": "E.ON Brand Space",
    "brandhover": "E.ON Brand Space",
    "concur": "Concur för rese- och utgiftshantering",
    "concurhover": "Med Concur kan du enkelt hantera och skicka in dina rese- och utgiftsrapporter genom att samla all relevant information och alla åtgärder på ett ställe.",
    "gertrud": "Gertrud",
    "gertrudhover": "Gertrud",
    "hr": "HR-portal",
    "hrLabel": "HR-portal",
    "hrhover": "Med myHR kan du enkelt komma åt och hantera din HR-relaterade information och dina uppgifter på ett ställe.",
    "incident": "Incidenter",
    "incidenthover": "Incidenter",
    "itresources": "IT-resurser",
    "itresourceshover": "IT-resurser",
    "journey": "Resor/Event",
    "journeyhover": "Resor/Event",
    "mail": "E-postlista",
    "mailhover": "E-postlista",
    "meeting": "Bokning av mötesrum",
    "meetinghover": "Med Gingco, vårt verktyg för rumsbokning, kan du enkelt hantera och schemalägga dina mötesrum och resurser, vilket säkerställer effektivt utnyttjande och god tillgänglighet, allt på ett ställe.",
    "myassets": "Mina tillgångar",
    "myassetshover": "Mina tillgångar",
    "mygenius": "MyGenius",
    "mygeniushover": "MyGenius",
    "notification": "Aviseringsportal",
    "notificationhover": "Aviseringsportal",
    "order": "Beställning",
    "orderhover": "Beställning",
    "password": "Återställning av SAP-lösenord",
    "passwordhover": "Med den här funktionen kan du enkelt återställa åtkomstlösenorden för dina SAP-system.",
    "pki": "PKI-portalen",
    "pkihover": "PKI-portalen",
    "pr": "Skapa inköpsrekvisition",
    "pra": "Skapa avancerad inköpsrekvisition",
    "prahover": "Här hittar du alla relevanta funktioner för att begära varor och tjänster. Detta kallas även BANF (inköpsrekvisition).",
    "prhover": "Här hittar du alla relevanta funktioner för att begära varor och tjänster. Detta kallas även BANF (inköpsrekvisition).",
    "prisma": "Prisma",
    "prismahover": "Med Prisma kan du enkelt övervaka och analysera din energiförbrukning och optimera din energianvändning genom att samla alla relevanta data och insikter på ett ställe.",
    "processing": "Min inkorg",
    "processinghover": "Med myInbox kan du enkelt och effektivt godkänna fakturor genom att ha all relevant information och alla åtgärder samlade på ett ställe.",
    "subTitle": {
      "approvalSubTitle": "SNOW/SAP",
      "aribaSubTitle": "PO-översikt",
      "beelineSubTitle": "Extern uthyrning",
      "brandSubTitle": "det är på oss",
      "concurSubTitle": "SAP Concur",
      "gertrudSubTitle": "Förbered styrelsemöten",
      "hrSubTitle": "MyHR",
      "incidentSubTitle": "SNOW",
      "itresourcesSubTitle": "SNOW",
      "journeySubTitle": "FuV §37b EstG",
      "mailSubTitle": "Underhåll",
      "meetingSubTitle": "GINGCO",
      "myassetsSubTitle": "SNOW",
      "mygeniusSubTitle": "Digital lärplattform",
      "notificationSubTitle": "Facility Services",
      "orderSubTitle": "Beställ IT-tjänster",
      "passwordSubTitle": "SAP-system",
      "pkiSubTitle": "Hantera certifikat",
      "prSubTitle": "BANF",
      "praSubTitle": "BANF",
      "prismaSubTitle": "HSE",
      "processingSubTitle": "SAP",
      "timeSubTitle": "CATS",
      "zukoSubTitle": "Organisera kontorsflytt"
    },
    "time": "Tidsspårning",
    "timehover": "Med CATS-appen kan du enkelt spåra och hantera dina arbetstimmar och aktiviteter genom att samla all relevant information och alla funktioner på ett ställe.",
    "zuko": "Zuko",
    "zukohover": "Zuko"
  },
  "cancel": "Avbryt",
  "chatBot": {
    "aiAssitanceSuggestion": "Du kan ställa dina frågor till AI Assistance genom att använda inmatningsrutan nedan.",
    "aiActionMsg": "Det verkar som om ditt interna minne är fullt. Jag kan hjälpa dig att lösa installationsproblemet genom att automatiskt rengöra dina temporära filer och tömma papperskorgen. Vill du att jag ska städa automatiskt?",
    "aiActionPrimaryBtn": "städa automatiskt",
    "aiActionSecondaryBtn": "städa inte automatiskt",
    "aiActionSuccess": "Din lagring har rensats.",
    "aiActionFailed": "Något gick fel. Försök efter en stund. Eller försök manuellt.",
    "appreciateService": "Vi uppskattar din hjälp med att förbättra våra tjänster.",
    "askEon": "Fråga E.ON",
    "askInternet": "Fråga Internet",
    "askMe": "Ställ dina frågor",
    "askTooltip": {
      "eon": "Få företagsspecifik information från interna källor",
      "internet": "Få information från interna och externa källor som E.ON GPT"
    },
    "card": {
      "efficiency": "Effektivitet",
      "ngService": "Nästa generations service",
      "selfService": "Självbetjäning"
    },
    "chatBotTitle": "Drivs av AI Assistance",
    "chatIcon": "ChatBot",
    "chatbotPlaceholder": "Skriv här...",
    "complete": "Fullständig",
    "confirmCreateIncident": "Bekräfta och skapa incident",
    "content": "Innehåll",
    "copyLink": "Kopiera länk",
    "createIncident": "Skapa incident",
    "creatingIncident": "Skapar en incident",
    "differentiator": "eller",
    "disclaimerText": "AI-genererat innehåll kan vara felaktigt",
    "duplicateError": "Dubblettfråga, vänligen ställ en annan fråga",
    "editCreateIncident": "Redigera och skapa incident",
    "feedbackMessage": "Tack för din feedback!",
    "feedbackMsg": "",
    "feedbackPrompt": "Skriv valfri feedback här",
    "feedbackTitle": "Var detta till hjälp?",
    "feedbackTitle2": "Var svaret hjälpsamt?",
    "feedbackThumbsUpLabel": "Ja, det hjälpte",
    "feedbackThumbsDownLabel": "Nej, jag behöver mer hjälp",
    "hey": "Hej",
    "incident": "Skapa en incident",
    "incorrectInformation": "Felaktig information",
    "information": "Information",
    "internetSearchContent": "Baserat på fråga mig något E.ON GPT",
    "internetSearchTitle": "Allmänna frågor",
    "irelevantContent": "Irrelevant innehåll",
    "knowledgeBaseContent": "Förbättra medarbetarupplevelsen genom information",
    "knowledgeBaseTitle": "Kunskapsbas",
    "lackOfDetail": "Brist på detaljer",
    "liveAgent": "Chatta med Service Desk",
    "liveAgentChat": {
      "agentLeftMsg": "Tack för att du kontaktade E.ON",
      "callback": "Begär återuppringning från Service Desk",
      "chat": "Chatta med Service Desk",
      "connectWithLiveAgent": "Anslut till Service Desk",
      "connectingHint": "Du kan återgå till assistenten genom att klicka på länken under chattrutan.",
      "connectingWithLiveAgent": "Ansluter till Service Desk...",
      "heading": "Snabba åtgärder",
      "help": "Hjälp mig lösa ett IT-problem",
      "switchToAssistance": "Exit chat and switch to assistance",
      "typeMessageHint": "Skriv ditt meddelande här...",
      "disconnectingMsg": "Avslutar chatten med servicedesk och växlar tillbaka till AI-assistenten"
    },
    "newChat": "Ny chatt",
    "ask": "Fråga",
    "internet": "Internet",
    "askFollowUpQuestion": "Ställ en uppföljande fråga",
    "openLink": "Öppna länk",
    "other": "Annat",
    "privacyMsg": "Vi respekterar din integritet och försäkrar att din feedback hanteras konfidentiellt.",
    "quickActions": {
      "heading": "Snabba åtgärder",
      "actions": {
        "createIncident": "Hjälp mig lösa ett IT-problem",
        "chatWithServiceDesk": "Chatta med Service Desk",
        "requestCallback": "Begär återuppringning från Service Desk",
        "ongoingConversation": "Ja, det är en del av min senaste konversation.",
        "newConversation": "Nej. Jag behöver hjälp med att lösa ett nytt problem.",
        "confirmAndCreateTicket": "Bekräfta och skapa ärende",
        "cancelTicketCreation": "Avbryt ärendeskapande",
        "confirmChatWithServiceDesk": "Bekräfta och anslut till Service Desk",
        "cancelChatWithServiceDesk": "Avbryt chat med Service Desk"
      },
      "state": {
        "whatsTheIssue": "Vad är problemet du vill lösa?",
        "lastConversation": "Absolut, är problemet en del av den senaste konversationen?",
        "createIncidentLabel": "Du är på väg att skapa ett ärende för en incident",
        "cancelAck": "Noterat. Kan jag hjälpa dig med något annat problem?",
        "chatWithServiceDeskLabel": "Du är på väg att ansluta till en Service Desk-agent",
        "createTicketLabel": "Bekräfta och skapa ärende",
        "cancelTicketLabel": "Avbryt ärendeskapande",
        "creatingIncidentTicket": "Skapar incidentärende",
        "ticketDescription": "Ärendebeskrivning",
        "connectWithServiceDeskLabel": "Bekräfta att du vill chatta med Service Desk"
      }
    },
    "relevantSearches": "Mest relevanta sökningar",
    "resolveIssue": "För att lösa ditt problem",
    "searchBack": "Tillbaka till föregående sida",
    "selfServiceContent": "AI-stött självbetjäningsstöd för snabb lösning av servicetillbud",
    "selfServiceTitle": "IT-support",
    "sendbtn": "Skicka",
    "showMore": "Visa mer",
    "ticketDescription": "Biljettbeskrivning baserad på senaste diskussionen",
    "ticketErrorMsg": "Något gick fel. Försök igen senare.",
    "ticketSuccessMsg": "Din incident har skapats framgångsrikt, biljettnummer:",
    "tone": "Ton",
    "topicStartMessage": "Vad kan jag hjälpa dig med?",
    "welcomeMessage": "Chattuppmaning",
    "wentWell": "Vad gick bra? (valfritt)",
    "wentWrong": "Vad gick fel? (valfritt)",
    "jiraIntegration": {
      "viewInJira": "View in Jira",
      "responseHeading": "Here is your Jira tickets summary:",
      "responseSubheading": "Showing top ten records",
      "noResponse": "Det verkar inte som att det finns ett JIRA-ärende registrerat för din förfrågan.",
      "supportedQuestionMsg": "Hey, I am sorry. I didn't quite understand your question. Can you please rephrase it again. I am sharing some sample questions to assist you:"
    },
    "nexthinkIntegration": {
      "failureMsg": "Oops! Something went wrong. Please try again later. If the issue persists please raise an issue from Quick Actions",
      "assistanceTask": "Assistance Task",
      "itReportTitle": "Here is your systems energy efficiency snapshot",
      "days": "Days",
      "device": "Device",
      "sinceRestarted": "since restarted",
      "deviceRestartRecommendation": "Immediate device restart is recommended",
      "deviceInCompliance": "Your device is in compliance",
      "energyConsumed": "Energy Consumed in last 7 days",
      "coEmitted": "CO2 Emitted in last 7 days",
      "diskClean": {
          "primaryBtn": "Yes, clean up my disk now",
          "secondaryBtn": "Don't clean up now",
          "successMsg": "Your disk has been cleaned up",
          "failureMsg": "Your disk could not be cleaned up at this point",
          "progressMsg": "Clearing up storage in progress",
          "disclaimer": "Please note we will not touch your personal or work data. While clean-up takes place in the background, feel free to use assistance."
      },
      "chromeCache": {
          "primaryBtn": "Yes, clear chrome cache now",
          "secondaryBtn": "Don't clear chrome cache now",
          "successMsg": "Your chrome cache has been cleaned up",
          "failureMsg": "Your Chrome Cache could not be cleaned up at this point",
          "progressMsg": "Clearing up chrome cache in progress",
          "disclaimer": "Please note we will not touch your personal or work data. While clean-up takes place in the background, feel free to use assistance."
      },
      "empowerApp": {
          "primaryBtn": "Yes, please repair now",
          "secondaryBtn": "Don't repair now",
          "successMsg": "Auto repair successfully completed",
          "failureMsg": "Your Empower Plugin could not be repaired at this point",
          "progressMsg": "Auto repair is in progress",
          "disclaimer": "We will be reinstalling the Empower plugin, Please close all your O365 Apps before proceeding further."
      }
    }
  },
  "close": "Stäng",
  "confirm": "Bekräfta",
  "continue_Tour": "Fortsätt rundturen",
  "dataTable": {
    "itemString": "föremål",
    "ofString": "av",
    "pagingData": "Objekt per sida"
  },
  "delete": "Radera",
  "edit": "Redigera",
  "feedbackWidget": "Feedback",
  "filterPage": {
    "dataSources": {
      "Confluence": "Confluence",
      "Gitlab": "Gitlab",
      "ItOrder": "min IT-beställning",
      "JIRA": "Jira",
      "Knowledge Base": "Kunskapsbas",
      "MyGenius": "MyGenius",
      "Sharepoint": "SharePoint",
      "powerpoint": "PowerPoint",
      "word": "Word",
      "excel": "Excel",
      "pdf": "PDF",
      "loop": "Loop",
      "other": "Annat"
    },
    "filterMenu": {
      "all": "Alla",
      "app": "Appar",
      "gitlab": "Gitlab",
      "apps": "Appar",
      "backBtn": "Tillbaka till föregående sida",
      "card_languages": "Språk",
      "community": "Gemenskap",
      "datasources": "Datakällor",
      "ecl_source_name": "Källa",
      "filterHeading": "Filter",
      "jql": "jql",
      "people": "Personer",
      "loading": "Laddar",
      "lookingFor": "Jag letar efter",
      "m365": "SharePoint - OneDrive",
      "mimeType": "Typ",
      "noData": "Vi kunde inte hitta några resultat för",
      "noMoreData": "Inga fler resultat hittades för din förfrågan!",
      "pages": "Sidor",
      "priority": "Prioritet",
      "refineResults": "Förfina resultaten",
      "reset": "Återställ filter",
      "space": "Utrymme",
      "standard_card_type": "Typ",
      "status": "Status",
      "timeRange": "Tidsintervall",
      "users": "Användare",
      "mygenius": "My Genius",
      "jira": "Jira",
      "confluence": "Confluence",
      "orderit": "my IT order",
      "aiassistant": "Assistance"
    },
    "searchResult": {
      "availableLanguages": "Tillgängliga språk",
      "category": "Kategori",
      "business": "företag",
      "mobile": "Mobil",
      "createdBy": "Skapad av",
      "createdOn": "Skapad den",
      "deliveryTime": "Leveranstid",
      "duration": "Varaktighet",
      "lastUpdate": "Senaste uppdatering",
      "lastUpdated": "Senaste uppdatering",
      "source": "Källa",
      "status": "Status",
      "type": "Typ",
      "company": "Företag",
      "department": "Avdelning",
      "job_title": "Befattning",
      "office_location": "Kontorsplats",
      "email": "Email"
    },
    "timeRangeOptions": {
      "1 Day": "1 dag",
      "1 Month": "1 månad",
      "1 Year": "1 år",
      "6 Months": "6 månader",
      "7 Days": "7 dagar",
      "All": "Alla"
    }
  },
  "footer": {
    "PKI": "PKI",
    "aboutMe8EON": "Om me@eon",
    "contact": "Kontakta",
    "copyrights": "",
    "imprint": "Avtryck",
    "privacyPolicy": "Sekretesspolicy",
    "privacySettings": "Sekretessinställningar",
    "termsofUse": "Användarvillkor"
  },
  "headerData1": "Välkommen till",
  "headerData2": "me@eon-portalen",
  "headerMessage": "24/7 onlinetjänst för dig",
  "landingPage": {
    "hello": "Hej",
    "searchApps": "Appar",
    "suggestions": "Förslag",
    "title": "Hur kan jag hjälpa dig?"
  },
  "logIn": "Logga in",
  "logOff": "Logga ut",
  "loginLable": "Logga in",
  "moveTabAndTiles": {
    "actionUndone": "Denna åtgärd kan inte ångras.",
    "addTab": "Lägg till ny flik",
    "addTabButton": "Lägg till flik",
    "addTabTitle": "Lägg till en ny flik. Du kan skapa maximalt 4 flikar.",
    "cardIn": "brickor i?",
    "categorizeTiles": "Personlig instrumentpanel",
    "categorizeTilesTitle": "Byt namn på flikar, ta bort flikar och flytta brickor inom flikar",
    "enterTabName": "Ange fliknamn",
    "favourite": "Favorit",
    "organiseTilesTitle": "För att ändra brickornas position",
    "personalizeTab": "Anpassa flikar",
    "personalizeTiles": "Anpassa brickor",
    "removeTabConfrim": "Är du säker på att du vill ta bort fliken",
    "tabInputLabel": "Fliknamn",
    "tabNameError": "Ange ett unikt fliknamn med minst 2 och högst 20 tecken!",
    "tabNames": "Flik",
    "with": "med"
  },
  "myApprovalDataTable": {
    "FilterLable": {
      "all": "Alla",
      "claim": "Krav",
      "leaveApptoval": "Ledighetsgodkännande",
      "serviceRequest": "Serviceförfrågan",
      "timeSheet": "Tidrapport"
    },
    "tableData": {
      "approve": "Godkänn",
      "description": "Beskrivning",
      "reject": "Avvisa",
      "requestId": "Begärans-ID",
      "requestedBy": "Begärd av",
      "requestedDate": "Önskat datum",
      "requestedType": "Begärd typ",
      "status": "Status"
    }
  },
  "no": "Nej",
  "organiseTiles": "Ordna brickor",
  "registerLable": "Registrera",
  "save": "Spara",
  "searchPage": {
    "addAppTooltip": "Lägg till på personlig instrumentpanel",
    "relatedApps": "Appar",
    "removeAppTooltip": "Ta bort från personlig instrumentpanel",
    "searchApps": "Sök här...",
    "searchPlaceholder": "Sök...",
    "viewMoreApps": "Flera"
  },
  "pwaText": {
    "header": "Installera den här appen på din startskärm!",
    "button": "Installera appen",
    "iphoneHeader": "För att installera den här appen på iPhone",
    "inst1": "Knacka på <b>Dela</b>-knappen i Safari.",
    "inst2": "Bläddra ner och välj <b>\"Lägg till på startskärmen\".</b>",
    "inst3": "Knacka på <b>Lägg till</b> i det övre högra hörnet.."
  },
  "skip_Tour": "Hoppa över rundturen",
  "snow": {
    "tabName": {
      "incidents": "Incidenter",
      "incidentsTable": {
        "btnText": "Öppna ny incident",
        "noData": "Inga tillgängliga data"
      },
      "itResources": "IT-resurser",
      "itResourcesTable": {
        "btnText": "Öppna en biljett för IT-resurser",
        "computerName": "Datornamn",
        "model": "Modell",
        "modelCategory": "Modellkategori",
        "noData": "Inga tillgängliga data",
        "serialNumber": "Serienummer",
        "serviceValue1": "Servicevärde1",
        "serviceValue2": "Servicevärde2"
      },
      "orderTable": {
        "approvalSet": "Godkännandeset",
        "btnText": "Beställ IT-tjänster",
        "catItem": "Kattobjekt",
        "createdOn": "Skapad den",
        "dueDate": "Förfallodatum",
        "noData": "Inga tillgängliga data",
        "number": "Nummer",
        "state": "Tillstånd"
      },
      "orders": "Order"
    },
    "welcomeNote": "Nedan hittar du en översikt över dina öppna incidentbiljetter, dina förfrågningar och dina IT-resurser."
  },
  "submit": "Skicka in",
  "switchLanguage": "Byt språk",
  "switchRole": "Byt roll",
  "tilePage": {
    "backToSearch": "Tillbaka till föregående sida",
    "myInboxCountSuffix": "I väntan"
  },
  "tourData": {
    "tab": {
      "step1": "Du kan lägga till en ny flik genom att klicka här.",
      "step2": "Klicka här för att organisera ordningen på brickorna.",
      "step3": "Här kan du byta namn på och organisera flikar."
    },
    "tile": {
      "step1": "Använd me@eon för att hitta dina vanliga arbetsapplikationer, processer, information och resurser som är relevanta för din organisation (till exempel HR-ämnen, utbildningar, företagsdokument, policys och mycket mer).",
      "step2": "Lär dig mer om me@eon och dess funktioner, hitta svar på vanliga frågor och ta del av användbara tips och tricks i användardokumentationen.",
      "step3": "Du kan när som helst ändra det föredragna språket här.",
      "step4": "Du kan logga ut genom att klicka här.",
      "step5": "Använd me@eon Assistance  för att ställa en fråga, få support och användbar information från interna och externa källor."
    },
    "userActions": {
      "back": "Tillbaka",
      "close": "Stäng",
      "last": "Avsluta",
      "next": "Nästa",
      "open": "Öppna dialogrutan",
      "skip": "Hoppa över",
      "start_tour": "Starta rundturen",
      "tour_info": "Vill du att vi visar dig de grundläggande funktionerna i en snabb guidad tur? Det tar bara 1-2 minuter..."
    }
  },
  "trash_bin": "Radera",
  "underDevelopmentMsg": "Denna sida är under utveckling",
  "userName": "Profilnamn",
  "yes": "Ja"
}