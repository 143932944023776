import { useDataViews, useSelectedDataViewName } from "@me8eon/data_views";
import { useCommonComponents } from "@me8eon/components";
import { useTheme } from "@me8eon/themes";
import { EonUiIcon } from "@eon-ui/eon-ui-components-react";
import React from "react";

type DataListProps = {
    position: "horizontal" | "vertical"
    visibleElems: number
    rootId: string
    maxChars?: number
    selectedTick?: boolean
};

const DataList: React.FC<DataListProps> = ({ position, visibleElems, rootId, maxChars, selectedTick }) => {
    const dataViews = useDataViews();
    const { navBarStyles } = useTheme().dataLayout;
    const selectedOps = useSelectedDataViewName();
    const { SelectableButton } = useCommonComponents();
    const isHorizontal = position === "horizontal";

    return (
        <ul className="nav-bar-ul" style={{ ...navBarStyles.headerUls, ...(isHorizontal ? { display: "flex" } : {}) }}>
            {Object.entries(dataViews).map(([name, dataview], i) => {
                const DisplayItem = dataview.DisplayNavItem || SelectableButton;
                const firstOperator = isHorizontal ? "inline" : "none";
                const secondOperator = isHorizontal ? "none" : "inline";
                return (
                    <li key={name} className={`nav-bar-li ${name}`} style={{ display: visibleElems > i ? firstOperator : secondOperator }}>
                        <DisplayItem selectedTick={selectedTick} maxChars={maxChars} className="tab-item" key={name} rootId={rootId} text={name} selectedOps={selectedOps} icon={dataview.icon} />
                        {/* {selectedOps[0] === name && (
                            <>
                                <EonUiIcon
                                    size="normal"
                                    className="turquoise-icon vipin"
                                    name="radio_checkbox_tick"
                                />
                            </>
                        )} */}
                    </li>
                );
            })}
        </ul >
    )
}

export default DataList;