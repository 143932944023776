export const allMimeTypes: Record<string, string[]> = {
    PDF: ["application/pdf"],
    Loop: ["application/loop"],
    Excel: [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    ],
    Document: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.ms-word.document.macroEnabled.12",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
        "application/vnd.ms-word.template.macroEnabled.12",
    ],
    Presentation: [
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    ],
    Note: ["application/msonenote"],
    Image: [
        "image/png",
        "image/jpeg",
        "image/svg+xml",
        "image/gif",
        "image/bmp",
        "image/tiff",
        "image/x-icon",
        "image/heic",
        "image/x-wmf",
        "image/vnd.ms-modi",
    ],
    PostScript: ["application/postscript"],
    Outlook: ["application/vnd.ms-outlook"],
    OctetStream: ["application/octet-stream"],
    Zip: ["application/zip"],
    Text: ["text/plain", "text/html", "text/xml", "text/css", "text/x-vcard"],
    Flash: ["application/x-shockwave-flash"],
    JavaScript: ["application/x-javascript"],
    RTF: ["application/rtf"],
    Visio: ["application/vnd.visio", "application/vnd.ms-visio.drawing"],
    ShellScript: ["application/x-sh"],
    MSDownload: ["application/x-msdownload"],
    Gzip: ["application/x-gzip"],
    Video: ["video/mp4", "video/mpeg", "video/quicktime", "video/x-ms-wmv"],
    Audio: ["audio/x-wav"],
    XML: ["application/xml", "application/xml-dtd"],
    JSON: ["application/json"],
    ODF: ["application/vnd.oasis.opendocument.spreadsheet"],
    TCL: ["application/x-tcl"],
    MSAccess: ["application/x-msaccess", "application/msaccess"],
    Publisher: ["application/x-mspublisher"],
    RFC822: ["message/rfc822"],
    XPS: ["application/vnd.ms-xpsdocument"],
    OfficeTheme: ["application/vnd.ms-officetheme"],
    PKI: ["application/vnd.ms-pkiseccat"],
    Schedule: ["application/x-msschedule"],
    Tar: ["application/x-tar"],
    WinHlp: ["application/winhlp"],
    Compressed: ["application/x-compressed"],
    InternetSignup: ["application/x-internet-signup"],
};

function getMimeType(input?: string | null) {
    if (input) {
        let derivedType = "Other";
        Object.keys(allMimeTypes).forEach((type) => {
            if (allMimeTypes[type].includes(input)) {
                derivedType = type;
            }
        });
        return derivedType;
    }
    return null;
}

export default getMimeType;
