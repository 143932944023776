import React, { useState, useEffect } from "react";
import { marked } from "marked";
import "./TypingEffect.scss";
import { useTranslation } from "react-i18next";
import { openPdfInNewTab, getBlobUrl } from "../openPdfInTab";
import { useJwtTokens } from "../../../diagnostics/diagnostics";
import {EnvironmentConfig} from "@me8eon/config";
import LoaderComponent from "../../../loader-component/LoaderComponent";
import { parsedLinkArray } from "../parseLinks";
import { EonUiButton } from "@eon-ui/eon-ui-components-react";
import { useJwtToken } from "@me8eon/react_authentication";

interface ITypingEffectProps {
    text: string;
    speed: number;
    effecttEnd?: () => void;
    className: string;
}
type Link = {
    url: string;
    text: string | null;
};

const TypingEffect: React.FC<ITypingEffectProps> = ({
    text,
    speed,
    effecttEnd,
    className,
}) => {
    const [displayText, setDisplayText] = useState("");
    const [linksArray, setLinksArray] = useState<Link[]>([]);
    const [textRendered, setTextRenderered] = useState<boolean>(false);
    const [showPreview, setShowPreview] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const jwt = useJwtToken()

    useEffect(() => {
        let currentIndex = -1;
        const interval = setInterval(() => {
            setDisplayText((prevText) => {
                if (currentIndex < text.length) {
                    if (currentIndex < 0) {
                        return text[0];
                    } else {
                        if (!text[currentIndex + 1]) {
                            setTextRenderered(true);
                        }
                        return prevText + text[currentIndex];
                    }
                } else {
                    clearInterval(interval);
                    effecttEnd && effecttEnd();
                    return prevText;
                }
            });
            currentIndex = currentIndex + 1;
        }, speed);
        return () => clearInterval(interval);
    }, [text, speed]);

    useEffect(() => {
        setLinksArray(parsedLinkArray(text));
    }, [text]);

    const handleCitationUrl = async (url: string) => {
        const jwtToken = await jwt();
        if (window.innerWidth > 920 && isPdfLink(url)) {
            setIsLoading(true);
            const blobUrl = isGingoPdfApi(url)
                ? await getBlobUrl(url, jwtToken)
                : url;
            setIsLoading(false);
            if (showPreview === blobUrl) {
                setShowPreview("");
                return;
            }
            setShowPreview(blobUrl);
        } else if (isGingoPdfApi(url)) {
            setIsLoading(true);
            await openPdfInNewTab(url, jwtToken);
            setIsLoading(false);
        } else {
            window.open(url, "_blank");
        }
    };

    const isPdfLink = (url: string): boolean => {
        return (url.match("snowkbforme8eon") !== null ||
        url.match("genai") !== null ||
        url.match(".pdf") !== null);
    }

    const isGingoPdfApi = (url: string): boolean => {
        return (
            url.startsWith(EnvironmentConfig.genAiApiBaseUrl as string) &&
            url.split(/[?#]/)[0].endsWith(".pdf")
        );
    };

    return (
        <>
            <div
                className={`ai-response ${className}`}
                dangerouslySetInnerHTML={{
                    __html: marked.parseInline(displayText) as string,
                }}
            />
            <LoaderComponent isLoading={isLoading} />
            <div className={`${showPreview ? "iframe-with-links" : ""}`}>
                {textRendered && linksArray && linksArray.length > 0 && (
                    <div className="citation-container">
                        {linksArray.map((link: Link, index: number) => (
                            isPdfLink(link.text ?? link.url) && (
                                <EonUiButton
                                    size="small"
                                    rank="secondary"
                                    text={link.text ?? link.url}
                                    onClick={() => handleCitationUrl(link.url)}
                                    key={`button_${index + 1}`}
                                    className="citation-item"
                                />
                            )
                        ))}
                    </div>
                )}
                {showPreview && (
                    <iframe
                        className="pdf-preview"
                        title="internal-documents"
                        src={showPreview}
                    />
                )}
            </div>
        </>
    );
};

export default TypingEffect;
